/* FORMS */
.node-webform .header h2 {
  color: green;
}

.node-webform {
  padding: 0;
  font-family: 'Graphik';
}

.node-webform .header {
  display: none;
}

.node-webform .fl {
  padding: 0 20px;
}

.node-webform .fl>div {
  margin-bottom: 35px;
}

.node-webform .fl>div:last-child {
  margin: 0;
}

.node-webform label {
  float: none;
  display: block;
  width: 100%;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Graphik';
  font-weight: 400;

  &.error {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.4;
  }
}

.node-webform .form-required {
  color: #ff3838;
  font-size: 16px;
  font-weight: 500;
}

.node-webform .description {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  color: gray;

  p {
    margin-bottom: 0px;
  }
}

.node-webform .messages {
  &.error {
    margin: 20px 0;
    border: 1px solid #ffb8b8;
    border-radius: 8px;
    background-color: rgba(255, 56, 56, 0.05);
    background-position: 15px 20px;
    padding: 20px 20px 20px 50px;

    li {
      font-size: 14px;
      color: #555;
    }
  }

  &.status {
    margin: 20px 0;
    border: 1px solid #2ec946;
    border-radius: 8px;
    background-color: rgba(46, 201, 70, 0.05);
    background-position: 15px 18px;
    padding: 20px 20px 20px 50px;
    font-size: 14px;
    color: #555;
  }
}

.node-webform input[type="text"],
.node-webform input[type="email"],
.node-webform input[type="file"],
.node-webform textarea,
.node-webform select {
  float: none;
  display: block;
  width: 100%;
  padding: 20px 15px;
  border: 1px solid #E6E6E6;
  background: #fff;
  border-radius: 0;
  font-family: 'Graphik';
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  margin-bottom: 5px;
  min-height: 58px;
}

.node-webform {

  .text--brown {
    font-size: 24px;
    line-height: 34px;
    font-family: "Crimson Text", serif;
    margin: 0;
  }

  .selectric,
  .selectric-wrapper {
    width: 100%;
  }

  .select-a11y-button {
    min-height: 58px;
  }

  .webform-component-date {

    .selectric,
    .selectric-wrapper {
      width: initial;
    }
  }

  .selectric-items {
    width: 100% !important;
  }

  .selectric-items li {
    white-space: normal !important;
  }
}

.selectric-error .selectric {
  background-color: #fef5f1;
  color: #8c2e0b;
  border-color: #ed541d;
}

.node-webform .form-item.webform-component-radios {
  margin: 0;
}

.node-webform .webform-component-boolean {
  position: relative;
  padding-left: 20px;
  margin-bottom: 35px !important;
}

.node-webform .webform-component-boolean label {
  margin: 0;
}

.node-webform .webform-component-boolean input {
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.node-webform fieldset {
  padding: 0;

  legend {
    display: none;
  }
}

.node-webform .form-item {
  @include rem(margin, 0 0 35px);
  min-height: 90px;

  &.webform-component-markup {
    min-height: unset;

    p {
      font-size: 15px;
      line-height: 22px;

      &.text--brown {
        font-size: 22px;
        line-height: 32px;
        font-family: "Crimson Text", serif;
        margin: 0;
      }
    }
  }

  &.webform-component-date,
  &.webform-component-radios,
  &.webform-component-boolean {
    min-height: unset;

    .form-item {
      min-height: unset;
    }
  }
}

.node-webform .form-item.webform-component-date {
  @include rem(margin, 0 0 10px);
}

.node-webform .webform-component-date .form-item {
  @include rem(margin, 0 0 5px);
}

.node-webform .form-managed-file {
  position: relative;
  overflow: hidden;

  // input[type="file"] {
  //   cursor: inherit;
  //   display: block;
  //   font-size: 999px;
  //   filter: alpha(opacity=0);
  //   min-height: 100%;
  //   min-width: 100%;
  //   opacity: 0;
  //   position: absolute;
  //   right: 0;
  //   text-align: right;
  //   top: 0;
  // }
  // span.file {
  //   position: relative;
  //   display: inline-block;
  //   padding: 0 15px;
  //   border: 1px solid #E6E6E6;
  //   background: #fff;
  //   border-radius: 0;
  //   font-family: Graphik;
  //   position: relative;
  //   border: 0;
  //   font-size: 14px;
  //   line-height: 16px;
  //   color: #979797;
  //   max-width: 49%;
  //   vertical-align: middle;
  //   z-index: 2;
  // }
  input[type="submit"] {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    color: #fff;
    background: #262626;
    padding: 20px 25px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 16px;
    border: none;
    outline: none;
    margin: 0;
    z-index: 9;
    vertical-align: middle;
  }
}

.node-webform .form-managed-file {
  position: relative;
  display: flex;
  @include rem(margin-bottom, 10px);

  @media (max-width:700px) {
    display: block;
  }

  input[type=file] {
    margin-bottom: 0;
    width: 100%;
  }

  input[type=submit] {
    width: 140px;

    @media (max-width:700px) {
      width: 100%;
    }
  }
}

.node-webform .actions {
  padding: 40px 0;

  @media(max-width:1100px) {
    padding: 20px 0;
  }
}

.node-webform .text--lead {
  @media(max-width:1100px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.node-webform .required {
  margin: 10px 0 40px 0;
  font-size: 14px;

  .form-required {
    margin-right: 5px;
  }
}

.node-webform .actions p {
  display: inline-block;
  float: none;
  margin: 0 40px 0 0;
  vertical-align: middle;
}

.node-webform .actions input {
  display: inline-block;
  float: none;
  padding: 20px 30px;
  color: #fff;
  width: auto;
  background: #BA9E66;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 0;
  transition: all 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.node-webform .actions input:focus,
.node-webform .actions input:hover {
  background: #000;
}

.node-webform p a{
  &:hover{
    opacity:.5;
  }
}

.node-webform .actions~p{
  line-height: 1.6;
}

.node-webform iframe{
  width: 302px;
  height: 90px;
}

.webform-confirmation {
  @include rem(padding, 50px 0 !important);
  text-align: center;
  @include font-size(30px);
  line-height: 1.4;
}

.node-webform .form-item.webform-component-radios .form-radios {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;

  @media(max-width:1100px) {
    justify-content: flex-start;
  }

  .form-type-radio {
    margin: 0;

    @media(max-width:1100px) {
      margin-right: 10px;
    }

    label {
      margin: 0;
    }
  }
}
