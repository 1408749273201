/*------------------------------------*\
    LIST.CSS
\*------------------------------------*/

/**
 * List
 */

ul, ol{
    margin-left: 2em;
}

ul.inline, ul.links.inline {
  margin: 0;
}

/**
 * List item
 */

li {
  @include rem(margin-bottom, 0.5);
}

ul.menu li {
  margin-left: 0;
  list-style-type: none;
}

li.expanded, li.collapsed, li.leaf {
  list-style-image: none;
  list-style-type: none;
}


/**
 * Definition List
 */

dd{
    margin-left: 0;
}

/**
 * Definition title
 */

dt{
    font-weight: 500;
}

/**
 * Inline List
 */

.list--inline{
    list-style: none;
    margin-left: 0;

    > li{
        display: inline;
        @include rem(margin-right, 0.5);
    }
}

/**
 * Inline List
 */

.list--unstyled{
    list-style: none;
    margin-left: 0;

    > li{

    }
}

/**
* Styled List
*/

.list--styled{
    margin-left: 0;
  @include rem(padding, 30px);
  list-style: none;
  background: $bg-primary;
  border-radius: 0.2em;
  li {
    position: relative;
    margin-bottom: em(15);
    padding-left: em(40);
    line-height: em(28);
    color: $color-secondary;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      text-align: center;
      width: em(28, 10);
      font-size: em(10);
      line-height: em(28, 10);
      background: #fff;
      border-radius: 50%;
      color: #4fd46d;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      content: "\e905";

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}


/**
 * Block list
 */

.list--block{
    list-style: none;
    margin-left: 0;

    > li{
        border-bottom: 1px $base-ui-color solid;
        @include rem(padding-bottom, 0.5);
    }
}

/**
 * Grid list
 */

.list--grid{

    list-style: none;
    margin-left: 0;
    font-size: 0;

    > li{
        @include font-size($base-font-size);
    }


    @include media-query(tablet-desktop){
        margin-left: -($grid-spacing/2);
        margin-right: -($grid-spacing/2);

        > li{
            display: inline-block;
            vertical-align: top;
            width: 33%;
            padding: 0 $grid-spacing/2;
        }
    }
}
