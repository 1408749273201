.view.view-widget-billet {
  @include rem(margin-bottom, 30px);
  .container {
    padding: 0;
  }
  .text-top{
    text-align: right;
    font-size: 12px;
    margin: 10px 0px;
  }
  .text-bottom {
    display: block;
    margin: 0;
    @include rem(margin, 10px 0);
    color: #9B9B9B;
    @include font-size-adapt(14px);
    @include line-height-adapt(20px);
    text-align: right;
    p {
      color: inherit;
      font-size: inherit;
      margin: 0;
      line-height: inherit;
      word-break: break-all;
    }
  }
  .view-accessibilite ul {
    text-align: right;
  }
}
