.view.view-visite-faq {
  & > .container {
    padding: 0 20px 20px;
    @include desktop {
      padding: 40px 30px;
    }
  }
  .nav-bar {
    text-align: center;
    @include desktop {
      padding: 0;
      text-align: left
    }
    .views-exposed-widgets {
      text
      &:before, &:after {
        display: none;
      }
      .views-exposed-widget {
        float: none;
      }
      .views-submit-button {
        display: none;
      }
    }
    .form-type-bef-link {
      display: inline-block;
      @include rem(margin-right, 40px);
      &:last-child {
        margin: 0;
      }
      a {
        display: block;
        color: #959595 !important;
        @include rem(padding, 24px 0 20px);
        @include rem(line-height, 28px);
        text-decoration: none !important;
        border-bottom: 4px solid transparent;
        &.active {
          color: #000 !important;
          border-bottom-color: #000;
        }
      }
    }
  }
  .view-content {
    & > div {
      @include rem(margin-bottom, 20px);
    }
  }
}
