.view.view-categories {
  border-bottom: 1px solid #e6e6e6;
  @media (max-width:700px) {
    border-bottom: none;
  }
  .view-header {
    h3.title {
      text-align: left;
    }
  }
  .row .col-md-6 {
    @include rem(margin-bottom, 40px);
    @include desktop {
      margin-bottom: 0;
    }
    &:nth-last-child(-n+2) {
      @include tablet {
        margin-bottom: 0;
      }
    }
  }
  .outer {
    position:relative;
    a {
      &:before {
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
      }
    }
    &:hover {
      a {
        text-decoration:underline;
        text-underline-offset: 3px;
        color:#ba9e66;
      }
    }
  }
  .image {
    position: relative;
    &:focus, &:hover {
      &:before, &:after {
        transition-delay: 0s;
      }
      .inner {
        &:before, &:after {
          transition-delay: 0.2s;
        }
        a {
          &:before {
            opacity: 0.5;
          }
        }
      }
    }
    &:before, &:after {
      content:"";
      width: 0;
      height: 2px;
      position: absolute;
      transition: all 0.2s linear;
      transition-delay: 0.2s;
      background: $color-primary;
      z-index: 3;
    }
    &:before {
      right: 0;
      top: 0;
    }
    &:after {
      left: 0;
      bottom: 0;
    }
    .inner {
      &:before, &:after {
        content:"";
        width:2px;
        height:0;
        position: absolute;
        transition: all 0.2s linear;
        background: $color-primary;
        transition-delay: 0s;
        z-index: 3;
      }
      &:before {
        left: 0;
        top: 0;
      }
      &:after {
        right: 0;
        bottom: 0;
      }
      a {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0;
          transition: all 0.2s;
        }
      }
    }
  }
  .image:hover::before, .image:hover::after{
    width: 100%;
  }
  .image:hover .inner::before, .image:hover .inner::after{
    height: 100%;
  }
  img {
    width: 100%;
    height: auto;
  }
  .link {
    color: #333;
    @include rem(margin, 18px 0 0);
    @include font-size(18px);
    line-height: 1;
    a {
      color: inherit;
    }
  }
}
