header#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include rem(height, 70px);
  @include rem(padding, 0 30px);
  background: #0F0F0F;
  z-index: 150;

  .block-lang_dropdown+div {
    margin-left: 20px;
  }

  .row {
    height: 100%;
  }

  &.banner-active {
    top: 84px;
  }

  &.fixed {
    position: fixed;
    @include rem(padding, 0 30px);

    @include desktop {
      display: none;
    }

    .bilette {
      svg {
        fill: #FFF;
      }

      &:focus,
      &:hover {
        svg {
          fill: #ba9e66;
          transition: .2s;

          path {
            transition: .2s;
            fill: #ba9e66;
          }
        }
      }
    }

    &.banner-active {
      top: 0;
    }

    nav.menu {
      display: none;
      margin: 0;
      vertical-align: middle;

      @include desktop {
        display: inline-block;
      }
    }

    .curtine {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
      }

      &:after {
        left: auto;
        right: 0;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
      }

      &:before {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  #logo {
    top: 50%;

    @include desktop {
      top: 100%;
    }
  }

  .left {
    float: left;
    text-align: left;
    align-items: center;

    @media (max-width:700px) {
      padding-left: 13px;
    }
  }

  .menu-button {
    display: inline-block;
    padding: 0;
    @include rem(margin-top, -3px);
    @include rem(margin-right, 35px);
    color: #fff;
    background: none;
    border: none;
    outline: none;
    vertical-align: middle;
    max-width: 24px;

    &:focus {
      outline: 2px solid #fff;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      @include rem(margin-left, 10px);
    }
  }

  .social-icons {
    display: none;
    vertical-align: middle;

    a {
      position: relative;
      display: none;

      @include tablet {
        display: inline-block;
      }

      @include rem(margin-right, 8px);
      color: #fff;
      @include font-size(14px);
      line-height: 1;
      vertical-align: middle;

      .ext {
        display: none;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  nav.menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: block;
    margin: 0;
    background: #000;

    @include desktop {
      display: inline-block;
      position: static;
      top: auto;
      left: auto;
      width: auto;
      background: none;
    }

    ul.menu,
    ul.main-menu {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;

      @include desktop {
        text-align: left;
      }

      i {
        display: none;
      }

      &>li {
        display: inline-block;
        width: auto !important;
        @include rem(margin, 0 0 0 -4px);
        padding: 0;
        list-style: none;
        vertical-align: middle;

        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        &:focus,
        &:hover {
          &>a:after {
            background: #ba9e66;
            opacity: 1;
          }
        }

        a {
          position: relative;
          display: block;
          @include rem(padding, 20px 10px);
          color: #fff;
          //font-family: $heading-font-family;
          @include font-size-adapt(11px, 14px);
          @include line-height-adapt(14px, 18px);
          font-weight: 500;
          transition: border 0.3s ease;

          @include desktop {
            @include rem(padding, 26px 12.5px);
          }

          &:after {
            content: '';
            position: absolute;
            @include rem((left: 12.5px,
                right: 12.5px,
                height: 3px));
            bottom: 0;
            background: transparent;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &>* {
            pointer-events: none;
          }

          &+button {
            width: 0;
            padding: 0;
            opacity: 0;
            display: none;
          }
        }

        svg {
          display: none;
        }

        &:hover,
        &.active,
        &.active-trail {
          &>a:after {
            background: $color-primary !important;
            opacity: 1;
          }
        }

        ul {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background: #171717;
          //transform: translateY(-100%);
          @include rem(padding, 0 30px 0 95px);

          @media (max-widtH:700px) {
            display: none !important;
          }
        }

        &.expanded.open {
          ul {
            display: block;
          }
        }
      }
    }
  }

  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 151;

    &:focus {
      outline-color: #fff !important;
    }

    img {
      display: block;
      //@include rem(width, 235px);
      @include rem(width, 150px);
      height: auto;
      outline-color: #fff;

      &:focus {
        outline-color: #fff !important;
      }
    }
  }

  .form-submit {
    display: none;
  }

  .right {
    text-align: right;
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .bilette {
      display: none;
      position: relative;
      color: #fff;
      @include font-size(15px);

      @include tablet {
        display: inline-block;
        vertical-align: middle;
        @include rem(padding, 5px);
      }

      @media (min-width:700px) {
        @include rem(padding, 9px 15px 9px 35px);
      }

      svg {
        position: absolute;
        left: 10px;
        bottom: 11px;
        transition: .2s;
      }

      &:focus,
      &:hover {
        color: #ba9e66 !important;
        transition: .2s;
        outline-color: #fff;

        svg {
          fill: #fff;
          transition: .2s;
        }
      }

      i {
        display: block;
        @include rem((margin: 0 auto 5px,
            width: 20px,
            height: 20px,
            font-size: 20px));

        @include desktop {
          position: absolute;
          top: 50%;
          left: 10px;
          @include rem((width: 16px,
              height: 16px,
              font-size: 16px));
          transform: translateY(-50%);
          line-height: 1;
        }
      }
    }

    .boutique {
      display: none;
      position: relative;
      color: #fff;
      @include font-size(15px);

      @include tablet {
        display: inline-block;
        vertical-align: middle;
        @include rem(margin, 25px 0 0 0);
        @include rem(padding, 5px);
      }

      @include desktop {
        @include rem(padding, 9px 15px 9px 15px);
        @include rem(margin, 0);
      }

      &:focus,
      &:hover {
        color: #ba9e66 !important;
        outline-color: #fff;
      }

      i {
        display: block;
        @include rem((margin: 0 auto 5px,
            width: 20px,
            height: 20px,
            font-size: 20px));

        @include desktop {
          position: absolute;
          @include rem((margin: 0 auto 5px,
              width: 11px,
              height: 11px,
              font-size: 11px));
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          line-height: 1;
        }
      }
    }

    .soutenir {
      display: none;
      position: relative;
      color: #fff;
      @include font-size(15px);

      @include tablet {
        display: inline-block;
        vertical-align: middle;
        @include rem(margin, 25px 0 0 0);
        @include rem(padding, 5px);
      }

      @include desktop {
        @include rem(padding, 9px 15px 9px 15px);
        @include rem(margin, 0);
      }

      &:focus,
      &:hover {
        color: #ba9e66 !important;
        outline-color: #fff;
      }
    }

    .sub-menu {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style: none;
      vertical-align: middle;

      li {
        @include rem(margin-right, 25px);
        display: inline-block;

        a {
          color: #fff;
          @include font-size(15px);

          &:focus,
          &:hover {
            color: $color-primary;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .block {
      display: none;
      vertical-align: middle;

      @include desktop {
        display: inline-block;
      }

      .title {
        display: none;
      }

      .view {
        padding: 0;
      }

      .form-item {
        display: block;
        margin: 0;
      }
    }

    #open-form {
      display: inline-block;
      vertical-align: middle;
      transition: all .3s;
      margin-left: 20px;

      @include tablet {
        @include rem(margin, 25px 0 0 0);
      }

      @include desktop {
        @include rem(margin, 0);
      }

      &:focus,
      &:hover {
        color: $color-primary;
        outline-color: #fff;
      }
    }
  }
}


.front header#header .right #open-form {
  @media (max-width:700px) {
    display: none;
  }
}

.not-front {
  #header {
    .bilette {
      svg {
        path {
          fill: white;
        }
      }

      &:focus,
      &:hover {
        svg {
          path {
            fill: #ba9e66;
          }
        }
      }
    }
  }
}

.home-header .buttons .btn:first-child:focus:after,
.home-header .buttons .btn:first-child:hover:after {
  color: #fff;
  border-color: #000;
  background: #000;
  transform-origin: center bottom;
  transform: scaleY(1) !important;
}

@media (min-width:700px) {
  body:not(.logged-in) .top-header+#header+#main {
    margin-top: 105px;
  }
}

// new logo fix for small desktop

@media screen and (min-width: 1100px) and (max-width: 1330px) {

  header#header .right .bilette,
  header#header nav.menu ul.menu>li.last {
    display: none;
  }
}

@media (max-width:900px) {
  header#header .right .bilette {
    display: none;
  }
}
