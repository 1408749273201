$white: #fff;
$brown: #BA9E66;

@keyframes line-scale-pulse-out-rapid {
  0% {
    transform: scaley(1.0);
  }
  80% {
    transform: scaley(0.3);
  }
  90% {
    transform: scaley(1.0);
  }
}

.slick-slide {
  outline: none !important;
}

.node-type-long-read {
  #main {
    @include rem(margin-top, 70px)
  }
  header#header {
    position: fixed;
    display: block !important;
    transform: translateY(0px) !important;
    #logo {
      top: 50% !important;
      @include rem(left, 30px);
      transform: translateY(-50%);
      border: none !important;
      @include tablet {
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      img {
        @include rem(width, 94px);
        @include tablet {
          @include rem(width, 150px);
        }
      }
    }
    a {
      @include rem(padding, 4px 0);
      @include font-size-rem-adapt(12px, 16px);
      line-height: 1;
      white-space: nowrap;
      border-bottom: 1px solid $brown;
      &:focus, &:hover {
        color: $white;
        border-bottom-color: $white;
      }
    }
    .long-back {
      display: none;
      color: $white;
      border: none !important;
      @include tablet {
        display: inline-block;
      }
      i { 
        display: inline-block;
        @include rem(margin-right, 20px);
        transform: rotate(90deg);
      }
    }
    .audio-wave {
      display: none;
      vertical-align: middle;
      @include rem((margin-right: 10px, width: 30px, height: 17px));
      @include tablet {
        display: inline-block;
      }
      &.play {
        & > div {
          animation: line-scale-pulse-out-rapid 0.9s -0.2s infinite cubic-bezier(.11,.49,.38,.78);
        }
      }
      & > div {
        display: inline-block;
        vertical-align: top;
        animation-fill-mode: both;
        @include rem((width: 1px, height: 17px, margin: 0 1px));
        border-radius: 1px;
        background: $white;
        transition: all 0.2s cubic-bezier(.11,.49,.38,.78);
        &:nth-child(1), &:nth-child(10) {
          transform: scaley(0.1);
          animation-delay: 0s !important;
        }
        &:nth-child(2) {
          transform: scaley(0.3);
          animation-delay: -0.1s !important;
        }
        &:nth-child(3), &:nth-child(6), &:nth-child(8) {
          transform: scaley(0.8);
          animation-delay: -0.25s !important;
        }
        &:nth-child(4), &:nth-child(9) {
          transform: scaley(0.6);
          animation-delay: -0.20s !important;
        }
        &:nth-child(5), &:nth-child(7) {
          transform: scaley(1);
          animation-delay: -0.15s !important;
        }

      }

    }
    .right .block {
      @include rem(margin-right, 20px);
    }
    .progress {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      @include rem(height, 3px);
      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: $brown;
      }
    }
  }
}

#toggleAudio {
  position: relative;
  display: none;
  padding: 0;
  @include rem((margin-right: 25px, width: 9px, height: 10px));
  border:none;
  background: none;
  vertical-align: middle;
  outline: none;
  @include tablet {
    display: inline-block;
  }
  &:focus, &:hover {
    &.play {
      &:before {
        border-color: transparent transparent transparent $brown;
      } 
    }
    &.pause {
      &:before, &:after {
        background: $brown;
      }
    }
  }
  &.play {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 9px;
      border-color: transparent transparent transparent $white;
      transform: translate(-50%, -50%);
    }
  }
  &.pause {
    &:before, &:after {
      position: absolute;
      top: 0;
      content: '';
      display: block;
      @include rem(width, 3px);
      height: 100%;
      background: $white;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  & + audio {
    display: none;
  }
}

.visiteurs {
  position: relative;
  min-height: 100vh;
  @include rem((min-height: 910px, padding: 50px 0 50px !important));
  color: $white;
  text-align: center;
  overflow: hidden;
  @include tablet {
    @include rem((min-height: 910px, padding: 50px 0 115px !important));
  }
  .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/longread.jpg);
    background-size: cover;
    background-position: center;
    transform: scale(1.2);
    z-index: 1;
  }
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 2;
  }
  .visiteurs__title {
    @include rem(margin, 0 0 20px);
    @include font-size-rem-adapt(20px, 48px);
    @include line-height-adapt(50px);
    opacity: 0;
    @include tablet {
      @include rem(margin, 0 0 35px);
    }
  }
  .visiteurs__date {
    @include rem(margin, 0 0 25px);
    @include font-size-rem-adapt(14px, 22px);
    @include line-height-adapt(20px, 30px);
    opacity: 0;
    color: $brown;
  }
  .visiteurs__description {
    @include rem((margin: 0 auto 25px, max-width: 725px));
    font-family: $font-crimson;
    @include font-size-rem-adapt(14px, 20px);
    @include line-height-adapt(19px, 30px);
    opacity: 0;
  }
  .visiteurs__entry {
    @include rem((margin: 0 auto 35px));
    @include font-size-rem-adapt(14px, 18px);
    font-weight: 500;
    opacity: 0;
  }
}

.visiteurs__intro {
  @include rem(margin, 0 -30px);
  .slick-track {
    display: block;
    margin: 0 auto;
    .slick-slide {
      display: block;
      text-align: center;
      @include rem(padding, 0 10px);
      @include tablet {
        padding: 0;
      }
      &.slick-current {
        .visiteurs-person {
          transform: scale(1);
        }
      }
      .row {
        margin: 0;
      }
      .visiteurs-person {
        width: 100%;
        opacity: 0;
        @include tablet {
          width: auto;
        }
      }
    }
  }
  .visiteurs-person {
    opacity: 0;
  }
  button.slick-arrow {
    background: none;
    border: 1px solid $brown;
  }
}

.visiteurs-person {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  max-width: 100%;
  margin: 2em 0em;
  //@include rem(margin-bottom, 20px);
  perspective: 1000px;
  &:focus, &:hover {
    .visiteurs-person__deco--lines {
      path {
        stroke-dashoffset: 0;
      }
    }    
  }

  .visiteurs-person__figure,
  .visiteurs-person__image {
    margin: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .visiteurs-person__figure {
    position: relative;
    & > * {
      transform: translateZ(0px);
    }
  }

  .visiteurs-person__figure::before {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    box-shadow: 0 30px 20px rgba(35,32,39,0.5);
  }


  .visiteurs-person__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .visiteurs-person__deco--lines {
    fill: none;
    stroke: $brown;
    stroke-width: 1.5px;
    path {
      stroke-dasharray: 760;
      stroke-dashoffset: 760;
      transition: all 1s;
      fill:none;
    }
  }


  &.visiteurs-person--active {
    cursor: pointer;
    // &:before, &:after {
    //   position: absolute;
    //   @include rem((left: 10px, top: 10px, right: 10px, bottom: 10px));
    //   content: '';
    //   opacity: 1;
    //   -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    //   transition: opacity 0.35s, transform 0.35s;
    //   @include tablet {
    //     opacity: 0;
    //   }
    // }
    // &:before {
    //   border-top: 1px solid $brown;
    //   border-bottom: 1px solid $brown;
    //   transform: scale(1);
    //   @include tablet {
    //     transform: scale(0,1);
    //   }
    // }
    // &:after {
    //   border-right: 1px solid $brown;
    //   border-left: 1px solid $brown;
    //   transform: scale(1);
    //   @include tablet {
    //     transform: scale(1,0);
    //   }
    // }
    // &:focus, &:hover {
    //   &:before, &:after {
    //     opacity: 1;
    //     transform: scale(1);
    //   }
    // }
  }
  &.visiteurs-person--disabled {
    .visiteurs-person__image {
      img {
        filter: grayscale(100%) blur(10px);
      }
    }
    .visiteurs-person__info {
      position: absolute;
      @include rem(padding, 70px 30px);
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      z-index: 5;
      @include tablet {
        @include rem(padding, 70px 50px);
      }
    }
    .visiteurs-person__title {
      color: $white;
    }
    .visiteurs-person__link {
      position: absolute;
      left: 50%;
      bottom: 0;
      @include rem(padding, 4px 0);
      @include font-size-rem-adapt(12px, 15px);
      font-weight: 500;
      border-bottom: 1px solid $brown;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
  .visiteurs-person__info {
    position: absolute;
    left: 0;
    bottom: 0;
    @include rem(padding, 50px 0);
    width: 100%;
    z-index: 2;
  }
  .visiteurs-person__image {
    overflow: hidden;
  }

  .visiteurs-person__title {
    @include rem(margin, 0 0 5px);
    @include font-size-rem-adapt(16px, 22px);
    color: $brown;
  }
  .visiteurs-person__role {
    margin: 0;
    color: #fff;
    @include font-size-rem-adapt(13px, 15px);
  }
  .visiteurs-person__date {
    position: absolute;
    top: 100%;
    left: 50%;
    margin: 0;
    color: #fff;
    @include rem(padding, 0 20px);
    @include font-size-rem-adapt(16px, 20px);
    @include line-height-adapt(24px, 37px);
    background: #000;
    transform: translate(-50%, -50%);
    z-index: 5;
    @include tablet {
      @include rem(padding, 0 30px);
    }
  }
  .visiteurs-person__popup {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    @include rem(padding, 50px 20px 20px);
    background: #000;
    z-index: 6;
    @include tablet {
      @include rem(padding, 70px 30px);
    }

    .errors-primal, .confirm-primal {
      @include rem(margin-top, 20px);
      @include font-size-rem-adapt(12px, 16px);
      .btn {
        width: 100%;
        @include rem(margin, 10px 0 0);
        @include rem(padding, 10px);
        color: $white !important;
        @include font-size-rem-adapt(12px, 16px);
        background: none;
        outline: none;
        border: 1px solid #CCCCCC !important;
        white-space: normal;
        cursor: pointer;
        @include tablet {
          @include rem(margin, 20px 0 0);
          @include rem(padding, 20px);
        }
      }
    }
    .errors-primal {
      color: red;
    }
  }
  .visiteurs-person__popup__close {
    position: absolute;
    display: block;
    @include rem((top: 30px, right: 30px, width: 16px, height: 16px));
    background: none;
    border: none;
    outline: none;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0%;
      width: 100%;
      height: 1px;
      background: $white;
    }
    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
  .visiteurs-person__popup__title {
    margin: 0;
    @include rem(max-width, 240px);
    text-align: left;
    @include font-size-rem-adapt(15px, 20px);
    @include line-height-adapt(20px, 27px);
  }
  .visiteurs-person__popup .simplenews-subscribe, .visiteurs-person__popup .simplenews-unsubscribe {
    @include rem(margin, 10px 0 0);
    @include tablet {
      @include rem(margin, 50px 0 0);
    }
    label {
      display: none;
    }
    input[type="text"] {
      @include rem(padding, 15px 0);
      width: 100%;
      @include font-size-rem-adapt(12px, 15px);
      @include line-height-adapt(15px);
      background: none !important;
      border: none;
      outline: none;
      border-bottom: 1px solid $white;

      &::-webkit-input-placeholder { /* WebKit browsers */
          color: $white;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: $white;
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: $white;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10+ */
          color: $white;
      }
      &.error {
        border-bottom: 1px solid red;
      }
    }
    input[type="submit"] {
      @include rem(margin, 10px 0 0);
      @include rem(padding, 10px);
      color: #808080 !important;
      @include font-size-rem-adapt(12px, 16px);
      background: none;
      outline: none;
      border: 1px solid #808080 !important;
      white-space: normal;
      @include tablet {
        @include rem(margin, 20px 0 0);
        @include rem(padding, 20px);
      }
    }
  }
}

.visiteurs__slider {
  display: none;
  visibility: hidden;
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  &.visiteurs__slider--visible {
    display: block !important;
    position: relative;
    top: 0;
    width: 100%;
    visibility: visible;
  }
  .row {
    @include rem(margin, 0 -10px);
    @include ie {
      min-width: 100%;
    }
  }
  .visiteurs-person {
    @include rem(margin, 40px 0);
    width: 100%;
    @include desktop {
      width: auto;
    }
    img {
      width: 100%;
      height: auto;
    }
    & > * {
      pointer-events: none;
    }
  }
  .visiteurs__slider__desc {
    margin: 0;
    @include font-size-rem-adapt(16px);
    @include line-height-adapt(26px);
    color: rgba($white, .6);
  }
  .visiteurs__slider__title {
    @include rem(margin, 35px 0 50px);
    @include font-size-rem-adapt(36px);
    @include line-height-adapt(45px);
  }

  button.slick-arrow {
    background: none;
    border: 1px solid $brown;
  }
}