/*------------------------------------*\
    CAROUSEL.CSS
\*------------------------------------*/
.slick-no-slide {
  .slick-track {
    width: 100% !important;
    transform: none !important;
    justify-content: center !important;
  }
}

.slick-dots {
  list-style: none;

  li {
    list-style: none;
  }
}

button.slick-arrow {
  position: absolute;
  top: 50%;
  display: block;
  @include rem((width: 50px, height: 50px));
  background: #fff;
  border: 1px solid #ccc;
  transform: translateY(-50%);
  transition: all 0.3s;

  @include desktop {
    @include rem((width: 80px, height: 80px));
  }

  &:focus,
  &:hover {
    color: #fff;
    background: $color-primary;
    border-color: $color-primary;
  }

  &:focus {
    outline: none;
  }

  &.slick-disabled {
    display: none !important;
  }

  i {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    @include rem((width: 16px, height: 16px));
    @include font-size(16px);
    transform: translate(-50%, -50%);

    @include desktop {
      @include rem((width: 23px, height: 23px));
      @include font-size(23px);
    }
  }

  &.slick-prev {
    left: 0;

    @include desktop {
      left: auto;
      right: calc(100% - 10px);
    }

    i {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.slick-next {
    right: 0;

    @include desktop {
      right: auto;
      left: calc(100% - 10px);
    }

    i {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
}

.dark {
  button.slick-arrow {
    border-color: #ba9e66;
    background: none;
    opacity: 0.8;

    i {
      color: #fff;
    }
  }
}

.ui-carousel.full-width {
  .container {
    max-width: 100%;
  }
}

.slider-full {
  .slick-slider {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: $flexboxgrid-max-width;
    @include rem(padding, 0 30px);

    @include desktop {
      padding: 0;
    }

    .slick-list {
      @include rem(margin, 0 -20px);
      overflow: visible;
    }
  }

  button.slick-arrow {
    border-color: #ba9e66;
    background: none;
    opacity: 0.8;

    i {
      color: #fff;
    }

    &.slick-prev {
      right: auto;
      @include rem(left, -15px);

      @include desktop {
        @include rem(left, 25px);
      }
    }

    &.slick-next {
      left: auto;
      @include rem(right, -15px);

      @include desktop {
        @include rem(right, 25px);
      }
    }

    &:focus,
    &:focus,
    &:hover {
      opacity: 1;
    }
  }
}

.view-widget-dates .slider-full button.slick-arrow {
  i {
    color: #000;
  }

  &:hover i,
  &:focus i {
    color: #fff;
  }
}

.slick {
  position: relative;

  .slick__slide:focus {
    outline: none;
  }
}

.slick-track {
  display: flex;

  .slick-slide {
    display: flex;
    height: auto;
    // align-items: center; //optional
    // justify-content: center; //optional

    .slide__content {
      display: flex;
      width: 100%;
    }
  }
}

.theme-places {
  .slick-no-slide {
    .slick__slide {
      opacity: 1 !important;
    }
  }

  .slide__content {
    display: flex;
  }

  .slick-slider {
    padding: 0;

    .slick-list {
      @include rem(margin, 0);

      @include desktop {
        @include rem(margin, 0 -60px);
      }
    }
  }

  .slick__slide {
    opacity: 0.37;
    transition: opacity 0.2s ease;

    &.slick-middle,
    &.slick-next,
    &.slick-prev {
      @include desktop {
        opacity: 1;
      }
    }

    &.slick-middle,
    &.slick-next {
      @include tablet {
        opacity: 1;
      }
    }

    &.slick-middle {
      opacity: 1;
    }

    figure {
      position: relative;
      display: flex;
      flex-flow: row wrap;

      width: 100%;
      height: auto;
      align-items: flex-start;
      @include rem(margin, 0 10px);
      overflow: hidden;
      background: none;

      @include desktop {
        max-width: 340px;
        @include rem(margin, 0 20px);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        transform: translateY(100%);
        background: $color-primary;
        @include transition(all 0.4s ease-in-out);
        z-index: -1;
      }

      &:focus,
      &:hover {
        &:after {
          transform: translateX(0);
        }

        &>a {
          &:before {
            opacity: 0.6;
          }
        }

        figcaption {

          h4,
          h5 {
            color: #fff;
          }
        }
      }

      &>a {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0;
          transition: all 0.3s;
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      a {
        flex: 1 100%;
      }

      figcaption {
        flex: 1 100%;
        padding: em(30) em(20);

        h4,
        h5 {
          color: #333333;
          margin: 0;
          @include font-size(24px);
          @include transition(all 0.1s ease-in-out);

          a {
            color: inherit;
            border-bottom: none !important;
          }
        }

        p {
          color: #D0021B;
        }
      }
    }
  }
}

.theme-people {
  &.brown {
    .slick__slide {
      figure {
        width: 100%;
        margin: 0 auto;

        figcaption {
          @include rem(padding-top, 20px);

          h5 {
            color: #333;

            a {
              border-bottom: none !important;
            }
          }

          p {
            color: #666;
          }
        }
      }
    }
  }

  figure {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
  }

  .slick-no-slide {
    .slick__slide {
      opacity: 1 !important;
    }
  }

  .slick__slide {
    opacity: 0.37;
    transition: opacity 0.2s ease;

    &.slick-middle,
    &.slick-next,
    &.slick-prev {
      @include desktop {
        opacity: 1;
      }
    }

    &.slick-middle {
      opacity: 1;
    }

    .slide__content {
      width: 100%;
    }

    figure {
      width: 100%;
      max-width: none;
      margin: 0 !important;
      background: none;

      img {
        margin: 0;
        display: block;
        width: 100%;
        height: auto;
        max-width: 340px;
      }

      figcaption {
        padding: em(30) em(20) em(30) 0;
        text-align: left;

        h5 {
          color: #fff;
          margin: 0 0 em(10);
          @include font-size(24px);
          line-height: 1.1;
        }

        p {
          margin: 0;
          color: #ccc;
          font-family: 'Crimson Text', serif;
          @include font-size(24px);
          line-height: 1.1;

          &.small {
            font-family: $base-font-family;
            @include font-size(15px);
          }
        }
      }
    }
  }

  button.slick-arrow {
    top: 42%;
  }
}

.theme-showcase {
  .slick-track {
    display: flex;
    align-items: flex-start;

    @include tablet-port {
      align-items: center;
    }

    .slick-slide {
      justify-content: flex-start;
      height: 100%;

      @include tablet-port {
        height: auto;
        justify-content: center;
      }
    }
  }

  .item {
    margin-right: 20px;

    figure {
      width: 100%;
      text-align: center;
      background: transparent;
      display: table;
      max-width: 100%;

      @media (min-width:900px) {
        display: flex;
      }

      .image {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        max-height: 600px;
        object-fit: contain;

        @include tablet-port {
          display: inline-block;
          width: 45%;
          margin-right: 4%;
          vertical-align: middle;
          opacity: 0;

          &:before {
            content: "";
            display: block;
            padding-top: 100%;

            @media (min-width:900px) {
              padding-top: 90%;
            }
          }
        }

        img {
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;

          @media (max-width:1000px) {
            margin-left: 0;
          }

          @include tablet-port {
            position: absolute;
            top: 0;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            transform: translateX(-50%);
          }
        }
      }

      figcaption {
        display: table-caption;
        caption-side: bottom;
        padding-bottom: 0;
        @include rem(padding-right, 100px);
        @include rem(min-height, 180px);
        text-align: left;

        @include tablet-port {
          display: table-caption;
          caption-side: bottom;
          width: 50%;
          padding: 0;
          vertical-align: middle;
          opacity: 0;
          animation-duration: 1s;
          animation-fill-mode: both;
          opacity: 0;
        }

        p.author {
          margin: 0;
          color: #8C8D93;
          @include font-size-adapt(14px, 24px);
        }

        h5 {
          @include rem(margin, 10px 0);
          color: #fff;
          @include font-size-adapt(20px, 36px);
          @include line-height-adapt(25px, 45px);

          @include tablet-port {
            @include rem(margin, 10px 0 40px);
          }

          a {
            padding: 0;
            border: none;

            &:after {
              display: none;
            }
          }
        }

        a {
          display: inline-block;
          color: #fff;
          padding: rem(5) 0;
          border-bottom: 1px solid #666666;
          transition: .1s;

          &:focus,
          &:hover {
            transition: .1s;
            color: #ba9e66;
            border-color: #ba9e66;
          }
        }
      }
    }
  }

  button.slick-arrow {
    border-color: #ba9e66;
    background: none;
    opacity: 1;
    z-index: 90;

    i {
      color: #fff;
    }

    &:focus,
    &:focus,
    &:hover {
      background: $color-primary;
    }
  }
}

.theme-video {
  .slide__content {
    width: 100%;
    @include rem(padding, 5px 20px 0);

    .popup-youtube {
      position: relative;
      display: block;
      border: none !important;
      text-decoration: none !important;

      @media(max-width:720px) {
        text-align: center;
      }

      &:focus,
      &:hover {
        .image:after {
          border-color: transparent transparent transparent $color-primary;
        }

        h4 {
          color: $color-primary;
        }
      }

      .image {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(#000, 0.6);
          z-index: 1;
          pointer-events: none;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 0 14px 25px;
          border-color: transparent transparent transparent #fafafa;
          transform: translateY(-50%) translateX(-50%);
          pointer-events: none;
          z-index: 2;
          transition: all 0.3s;
        }
      }

      .cookieConsentPopin {
        a {
          margin-left: 0;
          padding-top: 10px;
        }
      }
    }


    h4 {
      color: #fff;
      white-space: normal;
      text-decoration: none;
      transition: all 0.3s;
      min-height: 45px;
    }
  }
}

.slick--optionset--services {
  &.unslick {
    width: 100%;
    max-width: $flexboxgrid-max-width;
    margin: 0 auto;
    @include rem(padding, 0 30px);
    @include rem(padding-bottom, 40px);
    @include rem(margin-bottom, 40px);
    border-bottom: 1px solid #E6E6E6;

    @include desktop {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slick-slider {
    position: relative;
    @include rem(margin, 0 25px);

    @include desktop {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: $flexboxgrid-max-width;
    }

    &.slick-no-slide {
      @include rem(padding-bottom, 40px);
      @include rem(margin-bottom, 40px);
      border-bottom: 1px solid #E6E6E6;
    }

    .slick-list {
      overflow: visible;

      @include desktop {
        @include rem(margin, 0 -20px);
      }
    }

    .slick-slide {
      @include rem(padding, 0 5px);
      opacity: 0.5;
      transition: opacity 0.2s ease;

      @include desktop {
        @include rem(padding, 0 20px);
      }

      &.slick-active {
        opacity: 1;

        @include desktop {
          opacity: 1;
        }
      }

      &.slick-middle,
      &.slick-next,
      &.slick-prev {
        @include desktop {
          opacity: 1;
        }
      }

      &.slick-middle {
        opacity: 1;
      }
    }
  }

  .service {
    position: relative;
    width: 100%;
    @include rem(max-width, 380px);
    display: flex;
    flex-direction: column;
    background: #F1EFEA;
    // cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    &:focus,
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    img {
      width: 100%;
    }

    a {
      flex: 0 0 auto;
    }

    p a:hover {
      text-decoration: underline;
    }

    .inner {
      flex: 1 1 auto;
      @include rem(padding, 25px);

      @include tablet {
        @include rem(padding, 25px 25px 140px);
      }

      h4.title {
        @include rem(margin, 0 0 5px);
        @include font-size-adapt(22px);
        @include line-height-adapt(24px);
      }

      p {
        @include rem(margin, 0 0 10px);
        @include font-size-adapt(14px);
        @include line-height-adapt(18px);
      }

      .bottom {
        @include tablet {
          position: absolute;
          @include rem(height, 105px);
          @include rem((left: 25px, bottom: 25px));
          @include rem(padding-right, 25px);
        }

        ul {
          text-align: left;
          margin-left: 0;

          li {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .slick__arrow {
    // display: none;

    @include desktop {
      display: block;
    }
  }

  button.slick-arrow.slick-prev {
    @include rem(left, 5px);

    @include desktop {
      @include rem(left, 25px);
    }

    right: auto;
  }

  button.slick-arrow.slick-next {
    @include rem(right, 5px);

    @include desktop {
      @include rem(right, 25px);
    }

    left: auto;
  }

  .slick-dots {
    @include rem(padding, 20px 0);
    margin: 0;
    @include clearfix;

    li {
      @include rem(padding, 20px 0);
      position: relative;
      float: left;
      height: 1px;
      text-indent: -9999px;
      cursor: pointer;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        top: 50%;
        left: 0;
        background: #E6E6E6;
        transform: translateY(-0.5px);
      }

      &.slick-active:after {
        background: #BA9E66;
      }
    }
  }
}

.view-id-date_agenda.view-display-id-page .container,
.slick--optionset--news-date {
  &.unslick {
    width: 100%;
    max-width: $flexboxgrid-max-width;
    margin: 0 auto;
    @include rem(padding, 0 30px);
    @include rem(padding-bottom, 40px);
    @include rem(margin-bottom, 40px);
    border-bottom: 1px solid #E6E6E6;

    @include desktop {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slick-slider {
    position: relative;
    @include rem(margin, 0 25px);

    @include desktop {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: $flexboxgrid-max-width;
    }

    &.slick-no-slide {
      @include rem(padding-bottom, 40px);
      @include rem(margin-bottom, 40px);
      border-bottom: 1px solid #E6E6E6;
    }

    .slick-list {
      overflow: visible;

      @include desktop {
        @include rem(margin, 0 -20px);
      }
    }

    .slick-slide {
      @include rem(padding, 0 5px);
      opacity: 0.5;
      transition: opacity 0.2s ease;
      width: 285px;

      &>div {
        width: 100%;
      }

      @include desktop {
        @include rem(padding, 0 20px);
      }

      &.slick-active {
        opacity: 1;

        @include desktop {
          opacity: 1;
        }
      }
    }
  }
}
