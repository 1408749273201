// GENERAL IMPROVEMENTS

@media (max-width: 700px) {
  #header {
    #open-form {
      display: none !important;
    }
  }

  #page-footer {
    .top-footer {
      padding: 20px 0 0 0 !important;

      .col {
        margin-bottom: 20px !important;

        p {
          font-size: 14px;
          line-height: 20px !important;
        }
      }
    }

    .bottom-footer {
      .row {
        padding: 20px 0 0 !important;

        .field {
          font-size: 14px;
          line-height: 20px !important;

          .field-label {
            font-weight: 500;
          }

          .field {
            margin-bottom: 0;

            .field-item {
              padding: 5px 0;
            }
          }
        }
      }

      #logo-footer {
        padding: 20px 0 60px;

        img {
          max-width: 200px;
        }
      }
    }

    #go-top {
      position: absolute;
      top: 0;
      margin: 0;
      right: 0;
      width: 60px;
      height: 60px;
    }
  }
}

.node-type-home {
  .datepickerbox {
    .icon-calendair {
      &:before {
        content: "";
        background-image: url(../../images/clock_min.svg);
        height: 16px;
        width: 16px;
        display: inline-block;
        position: relative;
        top: -2px;
      }
    }

    input {
      font-size: 15px !important;
      text-shadow: none !important;
    }
  }

  .weatherbox {
    .weather {
      font-size: 15px !important;
    }
  }

  // DESK ONLY
  @media (min-width: 700px) {
    .bloc-liens-mobile {
      display: none;
    }

    .cta-container {
      display: block;
    }
  }

  // MOBILE ONLY
  @media (max-width: 700px) {
    .view-home {
      &.view-display-id-block_actus {
        border: none;

        .container {
          padding: 30px 20px;

          .col-lg-4 {
            margin-bottom: 20px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .outer {
              padding: 0;

              .news {
                position: relative;
                display: flex;

                a {
                  margin-bottom: 0;
                }

                img {
                  max-width: 130px;
                  height: auto !important;
                }

                .text {
                  margin-left: 20px;
                  width: 185px;
                  margin-top: 0;

                  .tags {
                    display: block;
                    font-size: 10px;
                    font-size: 12px;
                    color: #404041;
                    margin: 0;
                    margin-bottom: 0px;
                    min-height: unset;
                    margin-bottom: 15px;
                  }

                  h4 {
                    line-height: 20px;
                    margin: 5px 0 0 0;
                    overflow: hidden;
                    font-weight: 400;
                    font-size: 16px;
                  }

                  .accroche {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bloc-liens-mobile {
      display: block;
      margin: 0 20px;
      padding: 30px 20px;
      background: #f1efea;

      .field-items {
        display: flex;
        flex-direction: column;

        .field-item {
          padding: 0 0 40px 40px;

          .field-item {
            padding: 0;
            background-image: none !important;

            &:last-of-type {
              padding: 0;
            }
          }

          .field-name-field-titre {
            font-family: "Crimson Text";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #343434;
            margin-bottom: 10px;
          }

          .field-name-field-lien {
            a {
              padding: 0;
              min-height: 0;
              color: #000000;
              font-size: 14px;
              line-height: 24px;
              -webkit-text-decoration: underline #ba9e66 !important;
              text-decoration: underline #ba9e66 !important;
              text-underline-offset: 6px;
              text-align: left;
              display: flex;
              width: 100%;
              overflow: visible;

              &:before {
                content: none;
              }

              &:after {
                content: "→";
                position: relative;
                top: 0;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                max-height: 14px;
                left: 5px;
                transition: 0.5s;
              }
            }
          }

          &:nth-child(1) {
            background-image: url(/sites/all/themes/versailles/src/images/famille.svg);
            background-repeat: no-repeat;
            background-position: 0px 5px;
          }

          &:nth-child(2) {
            background-image: url(/sites/all/themes/versailles/src/images/clock.svg);
            background-repeat: no-repeat;
            background-position: 0px 5px;
          }

          &:nth-child(3) {
            background-image: url(/sites/all/themes/versailles/src/images/light.svg);
            background-repeat: no-repeat;
            background-position: 0px 5px;
          }

          &:last-of-type {
            padding: 0 0 0 40px;
          }
        }
      }
    }

    .cta-container {
      display: none;
    }
  }
}

// ALL CASES FOR NEW LAYOUT
.node-type-hub-decouvrir,
.node-type-hub-visite,
.node-type-hub-actualites,
.node-type-hub-espace-abonnes-2,
.node-type-hub-espace-enseignants,
.node-type-hub-famille,
.node-type-hub-espace-pros,
.node-type-hub-relais,
.node-type-hub-presse,
.node-type-visite-section,
.node-type-hub-ressources,
.node-type-collections,
.node-type-histoire,
.node-type-hub-domaine,
.node-type-hub-expositions,
.node-type-hub-spectacles,
.node-type-hub-editorial {
  .article-header {
    .parent-link {
      display: none;
    }
  }

  .page-nav-bottom {
    display: none;
  }

  @media (max-width: 700px) {
    #header {
      .menu {
        display: none;
      }
    }

    .page-nav {
      &.top {
        display: none;
      }
    }

    .article-header {
      padding: 15px 20px 20px 20px;
      background-image: none !important;
      background-color: #f1efea;
      background-size: unset !important;
      min-height: unset !important;

      .parent-link {
        display: inline-block;
        color: #666666;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding-left: 10px;

        &:before {
          content: "←";
          position: relative;
          top: 7px;
          background: 0 0;
          display: inline-block;
          overflow-y: hidden;
          text-decoration: none;
          transform: unset !important;
          max-width: 20px;
          left: -5px;
          transition: 0.5s;
        }
      }

      .text--center {
        text-align: left !important;
        margin: 20px 0 0 0;

        h1 {
          color: #000;
          text-align: left;
          font-weight: 400;
          font-size: 30px;
          line-height: 37px;
          margin: 0 0 20px 0;
        }

        .btn {
          margin: 5px 0 0 0;
          padding: 0;
          color: #504f4f;
          font-size: 16px;
          line-height: 16px;
          -webkit-text-decoration: underline #ba9e66 !important;
          text-decoration: underline #ba9e66 !important;
          text-underline-offset: 6px;
          text-align: left;
          display: block;
          min-height: unset;
          overflow: visible;

          &:before {
            content: none;
          }

          &:after {
            content: "→";
            position: relative;
            top: 4px;
            background: 0 0;
            display: inline-block;
            overflow-y: hidden;
            text-decoration: none;
            transform: unset !important;
            max-width: 20px;
            left: 7px;
            transition: 0.5s;
          }

          &:focus,
          &:hover {
            color: #ba9e66 !important;

            &:after {
              left: 10px;
            }
          }
        }
      }
    }

    .main-content {
      .view-filters {
        margin-bottom: 20px !important;

        .views-exposed-widgets {
          margin-bottom: 0px;

          .nav-bar {
            padding-top: 0px;
          }
        }
      }

      .page-nav-bottom {
        display: block;
        margin-top: 30px;
        margin-bottom: 15px;

        @media (max-width: 700px) {
          margin-top: 30px;
          margin-bottom: 20px;
        }

        h2 {
          text-align: left;
          font-size: 24px;
          line-height: 30px;
          margin: 0 0 15px 20px;
        }

        .menu {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          padding: 0 20px;
          align-items: flex-start;

          li {
            text-align: center;
            list-style-image: none;
            margin: 0 0 15px 0;
            display: flex;
            align-items: flex-start;
            justify-content: left;
            width: calc(100% / 2);
            border: none;

            .text,
            a {
              font-size: 14px;
              line-height: 24px;
              text-align: left;
              color: #000;
              display: inline-block;
              padding: 0;
              -webkit-text-decoration: underline #ba9e66 !important;
              text-decoration: underline #ba9e66 !important;
              text-underline-offset: 6px;

              &.active {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

// LEVEL +1 WITH MENU

.node-type-hub-decouvrir,
.node-type-hub-visite,
.node-type-hub-actualites,
.node-type-hub-espace-abonnes-2,
.node-type-hub-espace-enseignants,
.node-type-hub-famille,
.node-type-hub-espace-pros,
.node-type-hub-relais,
.node-type-hub-presse {
  @media (max-width: 700px) {
    #main {
      margin-top: 70px;
    }

    .article-header {
      padding: 15px 20px 20px 20px !important;

      .text--center {
        .btn {
          display: none;
        }
      }
    }

    .main-content {
      .page-nav {
        background: #f1efea;
        padding: 0 0 20px 0;
        border: unset;

        .container {
          padding: 0 20px;

          .menu {
            display: flex;
            row-gap: 10px;
            flex-wrap: wrap;

            li {
              width: 50%;
              text-align: left;

              &.don {
                border: none;
                padding: 0;
              }

              a {
                font-size: 14px;
                line-height: 18px;
                padding: 0;
                text-decoration: underline;
                text-decoration-color: #ba9e66;
                text-underline-offset: 5px;
                padding-right: 10px;
              }
            }
          }

          .btn {
            margin: 5px 0 0 0;
            padding: 0;
            color: #504f4f;
            font-size: 14px;
            line-height: 18px;
            -webkit-text-decoration: underline #ba9e66 !important;
            text-decoration: underline #ba9e66 !important;
            text-underline-offset: 6px;
            text-align: left;
            display: block;
            min-height: unset;
            overflow: visible;

            &:before {
              content: none;
            }

            &:after {
              content: "→";
              position: relative;
              top: 6px;
              background: 0 0;
              display: inline-block;
              overflow-y: hidden;
              text-decoration: none;
              transform: unset !important;
              max-width: 20px;
              left: 7px;
              transition: 0.5s;
            }

            &:focus,
            &:hover {
              color: #ba9e66 !important;

              &:after {
                left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// LEVEL +2 - SPECIFIC CASES (PRACTICAL INFO & BILLETS) / DESK + MOBILE

.node-type-visite-section {

  &.page-node-653,
  &.page-node-654,
  &.page-node-1161,
  &.page-node-1179,
  &.page-node-656,
  &.page-node-655,
  &.page-node-1162,
  &.page-node-1181,
  &.infos-mdv {
    .text-content p span.text--gray {
      font-size: 16px;

      a {

        &:focus,
        &:hover {
          color: #000;
        }
      }
    }

    @media (max-width: 700px) {
      header#header.fixed {
        position: relative;

        &+#main .view-filters .views-exposed-widgets .nav-bar {
          background-color: #f1efea;
          position: fixed;
          top: 0;
          padding-bottom: 0;
          padding-top: 5px;
          z-index: 200;
        }
      }
    }

    .article-header {
      // background-image: none !important;
      background-color: #f1efea;
      padding: 20px 0 0 0;

      .breadcrumbs {
        background: #f1efea;
        padding: 0;
        border: none;

        .ui-breadcrumbs {
          li {
            p {
              padding: 0;

              span {
                padding: 0;
                color: #0f0f0f;
                font-size: 14px;
                line-height: 16px;
                border: none;

                @media (min-width: 900px) {
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
                  color: #0f0f0f;
                }

                a {
                  margin: 0;
                  color: #0f0f0f;
                  font-size: 14px;
                  line-height: 16px;
                  -webkit-text-decoration: underline #ba9e66 !important;
                  text-decoration: underline #ba9e66 !important;
                  text-underline-offset: 4px;

                  @media (min-width: 900px) {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #0f0f0f;
                  }

                  &:focus,
                  &:hover {
                    color: #ba9e66;
                  }
                }
              }

              i {
                transform: none;
                color: #0f0f0f;
                vertical-align: unset;

                &:before {
                  content: "→";
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }

      .text--center {
        h1 {
          color: #000000;

          .small {
            color: #333;
            font-size: 22px;
            margin-bottom: 20px;
            line-height: 28px;
          }
        }

        .btn {
          margin: 0;
          padding: 0;
          color: #000000;
          font-size: 16px;
          line-height: 16px;
          -webkit-text-decoration: underline #ba9e66 !important;
          text-decoration: underline #ba9e66 !important;
          text-underline-offset: 6px;
          text-align: inherit;
          display: inline-block;
          min-height: 25px;
          min-width: 250px;

          &:before {
            content: none;
          }

          &:after {
            content: "→";
            position: relative;
            top: 4px;
            background: 0 0;
            display: inline-block;
            overflow-y: hidden;
            text-decoration: none;
            transform: unset !important;
            max-width: 20px;
            left: 7px;
            transition: 0.5s;
          }

          &:focus,
          &:hover {
            color: #ba9e66 !important;

            &:after {
              left: 10px;
            }
          }
        }
      }
    }

    @media (min-width: 700px) {
      .view.view-hub-actualites {
        .view-header {
          padding-bottom: 20px;

          h3 {
            font-weight: 400;
            font-size: 36px;
            line-height: 45px;
            color: #000000;
            text-align: left !important;
          }
        }
      }
    }

    @media (min-width: 700px) {
      &.logged-in .article-header {
        padding-top: 40px;
      }

      .article-header {
        height: 500px;
        position: relative;
        z-index: 20;

        .text--center {
          margin-bottom: auto;
          position: unset;
          margin-top: 45px;

          h1 {
            margin: 10px auto;
          }
        }
      }
    }

    @media (max-width: 700px) {
      .article-header {
        padding: 15px 20px 20px 20px;
        height: 400px;
        background-image: url(/sites/all/themes/versailles/src/images/chateau_min.svg) !important;
        background-size: 200% 100% !important;
        background-position: bottom -20px center;
        background-repeat: no-repeat;

        .breadcrumbs {
          display: none;
        }

        .text--center {
          margin-bottom: auto;
          position: unset;
        }
      }
    }
  }

  .anchor-map {
    left: -180px;

    &.fixed {
      left: auto;
      margin-left: -180px;
      top: 140px !important;
    }
  }
}

// LEVEL +2 - OTHER CASES (SUR PLACE / PARCOURS ET CONSEILS / FAQ) / MOBILE

.node-type-visite-section {

  &.page-node-657,
  &.page-node-1163,
  &.page-node-658,
  &.page-node-1183,
  &.page-node-659,
  &.page-node-1164,
  &.page-node-660,
  &.page-node-1184,
  &.page-node-661,
  &.page-node-1165,
  &.page-node-662,
  &.page-node-1185 {
    .article-header {
      a {
        display: none;
      }
    }

    @media (max-width: 700px) {
      .article-header {
        background-image: none !important;

        .parent-link {
          display: block;
        }

        .text--center {
          h1 {
            color: #000000;

            .small {
              color: #333;
              font-size: 22px;
              margin-bottom: 20px;
              line-height: 28px;
            }
          }

          a {
            display: block;
          }
        }
      }

      .page-nav {
        .btn {
          display: none;
        }
      }
    }
  }
}

// SECTION GROUP HANDICAP / PUBLICS ELOIGNES

.section-groupe-handicap,
.section-groupe-publics-eloignes {
  .page-nav-bottom {
    display: none !important;
  }

  @media (max-width: 700px) {
    .article-header {
      .text--center {
        h1 {
          margin-bottom: 0;

          .small {
            color: #333;
            font-size: 22px;
            margin-bottom: 20px;
            line-height: 28px;
          }
        }
      }
    }

    .page-nav {
      background: #f1efea;
      padding: 20px 0 20px 0 !important;
      border: unset;

      .container {
        padding: 0 20px;

        .menu {
          display: flex;
          row-gap: 10px;
          flex-wrap: wrap;

          li {
            width: 50%;
            text-align: left;

            &.don {
              border: none;
              padding: 0;
            }

            a {
              font-size: 14px;
              line-height: 18px;
              padding: 0;
              text-decoration: underline;
              text-decoration-color: #ba9e66;
              text-underline-offset: 5px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    .main-content {
      .view-visite-parcours {
        .container {
          padding: 0 20px;

          .view-header {
            h2 {
              font-size: 18px !important;
              line-height: 26px !important;
            }
          }
        }
      }
    }
  }
}

// LEVEL +2 - SPECIFIC CASES (PRACTICAL INFO) / DESK + MOBILE

.node-type-visite-section {

  &.page-node-653,
  &.page-node-654,
  &.page-node-1161,
  &.page-node-1179,
  &.infos-mdv {

    // COMMON (DESK + MOBILE)
    .article-header {
      overflow: visible;
      background-position: center bottom -50px;
      background-repeat: no-repeat;

      .attendance {
        font-family: "Crimson Text";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 42px;
        color: #343434;
        background: #fff;
        padding: 25px 0 0 0;
        text-align: center;
        position: absolute;
        margin: 0 auto;
        bottom: -10px;
        z-index: 20;
        width: 100%;
        left: 0;

        &:before,
        &:after {
          display: none;
        }

        span {
          color: #343434;
          position: relative;

          a {
            color: #ba9e66;
            text-decoration: underline;
            margin: 0;

            &:focus,
            &:hover {
              transition: 0.1s;
              color: #ba9e66;
              text-decoration: underline;
              text-decoration-color: #ba9e66;
            }
          }

          &:before {
            content: "";
            background-image: url(/sites/all/themes/versailles/src/images/check_min.svg);
            width: 33px;
            height: 32px;
            border: none;
            opacity: 1;
            margin-top: 0;
            position: absolute;
            left: -50px;
            top: 7px;
            background-repeat: no-repeat;

            @media (max-width: 1100px) {
              left: -40px;
              top: 1px;
              background-size: 20px;
              background-position: center;
            }

            @media (max-width: 700px) {
              background-size: 15px;
            }
          }
        }

        &.closed {
          span:before {
            background-image: none;
          }
        }
      }
    }

    .content {
      max-width: 720px;

      .top-info {
        @media (max-width: 700px) {
          margin-top: 10px;
          margin-bottom: 0;
        }

        .col-left {
          padding-top: 0;

          @media (max-width: 700px) {
            padding-top: 0;
            padding-bottom: 30px;
            margin-bottom: 30px;
          }

          .field-name-field-colonne-gauche {
            h2 {
              line-height: 30px;
              margin: 0 0 25px 0;

              @media (max-width: 700px) {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 20px;
              }
            }

            ul {
              margin: 0 0 15px 0;
            }

            p,
            li {
              color: #666666;
              font-size: 15px;
              line-height: 24px;
              margin: 0 0 15px 0;
              padding: 0;

              a.link-secondary {
                margin-top: 30px;
                display: block;
              }
            }
          }
        }
      }

      .bottom-info {
        .field-name-field-titre {
          font-family: "Apollon";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 30px;
          color: #000000;
          margin: 0 0 25px 0;
        }

        .field-name-field-informations-principales {
          &.horaires {
            .field-item {
              min-width: 160px;
              border-left: 1px solid #e6e6e6;
              padding-left: 16px;
              box-sizing: border-box;

              &:first-of-type {
                border-left: 0;
                padding-left: 0;
              }

              &:last-of-type {
                max-width: unset;
              }

              @media (max-width: 700px) {
                padding-left: 0;
                border-left: 0;
                max-width: 50%;
              }
            }
          }

          .field-items {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 35px;

            @media (max-width: 700px) {
              margin-bottom: 20px;
            }

            .field-item {
              max-width: 50%;

              @media (max-width: 700px) {
                max-width: unset;
              }

              &:last-of-type {
                max-width: 50%;

                @media (max-width: 700px) {
                  max-width: unset;
                }
              }

              p,
              li {
                color: #666666;
                font-size: 15px;
                line-height: 24px;
                margin: 0 0 15px 0;
              }
            }
          }
        }

        .accordion {
          margin-bottom: 30px;

          h4 {
            margin: 0;

            .accordion-trigger {
              background: 0 0;
              color: #000;
              display: block;
              padding: 25px 100px 25px 0px;
              position: relative;
              text-align: left;
              font-family: "Crimson Text";
              font-size: 20px;
              line-height: 24px;
              width: 100%;
              // outline: 0;
              border-left: none;
              border-right: none;
              border-top: 1px solid #e6e6e6;
              border-bottom: 1px solid #e6e6e6;
              position: relative;

              &[aria-expanded="true"] {
                border-bottom: none;
              }

              &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                border-color: #666 transparent transparent transparent;
                position: absolute;
                top: 45%;
                right: 19px;
              }

              .check-icon {
                width: 24px;
                position: absolute;
                left: 0;
              }

              &[aria-expanded="true"] {
                &:after {
                  transform: rotate(180deg);
                }
              }
            }
          }

          .accordion-panel {

            p,
            li {
              font-family: "Graphik";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              color: #666666;

              a {
                text-decoration: underline;
                font-weight: 500;
                border-bottom: unset;

                &.tooltip {
                  text-decoration: unset;
                  font-weight: unset;
                }

                &.link-secondary {
                  font-weight: unset;
                }

                &.btn {
                  font-weight: unset;
                }
              }
            }

            p,
            ul {
              margin-bottom: 15px;
            }
          }

          // tabs

          [role="tablist"] {
            // min-width: 550px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 10px;

            @media (max-width: 700px) {
              gap: 10px;
            }
          }



          [role="tab"],
          [role="tab"]:focus,
          [role="tab"]:hover {
            position: relative;
            z-index: 2;
            padding: 8px 16px;
            overflow: visible;
            outline: 0;
            font-weight: 700;
            border-radius: 24px;
            border: 1px solid #f1efea;
            background: transparent;
          }

          [role="tab"][aria-selected="true"] {
            background: #f1efea;
          }

          [role="tab"]:focus {
            background: #f1efea;
            border: 1px solid #8b8a87;
          }

          [role="tab"] span.focus {
            display: inline-block;
            margin: 0;
            padding: 0;
            font-family: "Graphik";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #000000;
          }

          [role="tab"]:hover {
            background: #f1efea;
          }

          /* span.focus,
          [role="tab"]:focus span.focus,
          [role="tab"]:active span.focus {
          } */

          [role="tabpanel"] {
            min-height: 10em;
            overflow: auto;
            display: flex;
            gap: 40px;
            margin-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e6e6e6;

            @media (max-width: 900px) {
              flex-direction: column;
              gap: 20px;
            }

            .col {
              font-family: "Graphik";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #666666;
              min-width: calc(50% - 20px);
            }
          }

          [role="tabpanel"].is-hidden {
            display: none;
          }

          [role="tabpanel"] p,
          [role="tabpanel"] ul {
            margin: 0 0 15px 0;
            color: #666666;
            font-size: 15px;
            line-height: 24px;

            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }

          [role="tabpanel"] p {
            strong {
              &.bold--title {
                font-size: 17px;
                color: #000000;
              }
            }
          }

          [role="tabpanel"] ul {
            padding: 0 0 0 15px;
          }
        }
      }

      .field-collection-container {
        .col-md-8 {
          .btn {
            .ext {
              position: relative;

              &:before {
                display: block !important;
                content: "→";
                position: relative;
                top: 0;
                background: 0 0;
                display: inline-block;
                text-decoration: none;
                transform: unset !important;
                left: 7px;
                transition: 0.5s;
                max-height: 19px;
              }
            }

            &:focus,
            &:hover {
              .ext {
                color: #fff;
              }
            }
          }
        }

        .col-md-4 {
          .field-items {
            .field-item {
              margin: 0 0 20px 0;

              a {
                font-size: 14px;
                line-height: 18px;
                border: none;
                text-decoration: underline #ba9e66 !important;
                text-underline-offset: 4px;
              }
            }
          }
        }
      }
    }

    // ONLY DESK

    @media (min-width: 1100px) {
      .article-header {
        .attendance {
          width: 1100px;
          left: calc(50vw - 550px);

          span {
            margin-left: 50px;
          }
        }
      }
    }

    @media (min-width: 700px) {
      .page-nav {
        &:not(.fixed) {
          margin-top: 40px;
          border-top: 1px solid #e6e6e6;
          padding: 15px 0;
        }
      }
    }

    @media (max-width: 1100px) {
      .article-header .attendance {
        font-size: 22px;
        width: calc(100% - 40px);
        left: 20px;

        &:before {
          left: 20px;
        }
      }
    }

    // ONLY MOBILE
    @media (max-width: 700px) {
      .article-header {
        height: 400px;
        background-image: url(/sites/all/themes/versailles/src/images/chateau_min.svg) !important;
        background-size: 270% 100% !important;
        background-position: bottom 10px center;

        .text--center {
          margin-bottom: auto;
          position: unset;
        }

        .attendance {
          padding: 16px;
          bottom: -30px;
          padding-left: 56px;
          text-align: left;
          line-height: 24px;

          &:before {
            left: 24px;
            top: 22px;
            width: 16px;
            height: 16px;
          }
        }
      }

      .main-content {
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid #e6e6e6;
      }
    }
  }
}

// LEVEL +2 - SPECIFIC CASE (BILLETS) / DESK + MOBILE

.node-type-visite-section {

  &.page-node-656,
  &.page-node-655,
  &.page-node-1162,
  &.page-node-1181 {

    // COMMON (DESK + MOBILE)
    .main-content {
      .nav-bar {
        padding-top: 0px;

        &.fixed {
          background-color: #f1efea;
          position: fixed;
          top: 0;
          padding: 10px 0px;
          z-index: 5000;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .visit-description {
        .row {
          padding: 30px 20px 0 20px;

          .accordion {
            min-width: 100%;
            border-bottom: 1px solid #e6e6e6;

            @media (max-width: 700px) {
              margin-bottom: 30px;
            }

            h4 {
              margin: 0;

              .accordion-trigger {
                background: 0 0;
                color: #000;
                display: block;
                padding: 25px 100px 25px 40px;
                position: relative;
                text-align: left;
                font-family: "Crimson Text";
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                border-left: none;
                border-right: none;
                border-top: 1px solid #e6e6e6;
                border-bottom: none;
                position: relative;

                &:after {
                  content: "";
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 4px 0 4px;
                  border-color: #666 transparent transparent transparent;
                  position: absolute;
                  top: 45%;
                  right: 19px;
                }

                .check-icon {
                  width: 24px;
                  position: absolute;
                  left: 0;
                }

                &[aria-expanded="true"] {
                  &:after {
                    transform: rotate(180deg);
                  }
                }
              }
            }

            .accordion-panel {
              padding: 0px 0 25px 40px;

              &>*:last-child {
                margin-bottom: 0;
              }

              @media (max-width: 700px) {
                padding-top: 0;
              }

              p,
              li {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #666666;

                a {
                  text-decoration: underline;
                  font-weight: 500;

                  &.tooltip {
                    text-decoration: unset;
                  }

                  &.link-secondary {
                    font-weight: unset;
                  }

                  &.btn {
                    font-weight: unset;
                  }
                }
              }

              p,
              ul {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }

    #page-footer {
      z-index: 1;
    }

    // ONLY DESK
    @media (min-width: 1100px) {
      .article-header .text--center {
        margin-top: 55px;
      }

      #ui-datepicker-div {
        z-index: 101 !important;
        width: 530px !important;
      }
    }

    // ONLY MOBILE
    @media (max-width: 1100px) {
      #ui-datepicker-div {
        z-index: 101 !important;
        width: 300px !important;
      }

      .main-content {
        &>.container {
          position: unset;
        }
      }
    }

    @media (max-width: 700px) {
      #ui-datepicker-div {
        width: calc(100% - 40px) !important;
      }
    }
  }
}

// LEVEL +2
// .node-type-hub-lieu,
// .node-type-visite-section,
// .node-type-hub-ressources,
// .node-type-collections,
// .node-type-histoire,
// .node-type-hub-visite,
// .node-type-hub-domaine,
// .node-type-hub-expositions,
// .node-type-hub-spectacles,
// .node-type-hub-editorial {
 body:not(.node-type-home) {

  &:not(:has(.smartbanner)) {
    @media (max-width: 700px) {
      #main {
        margin-top: 70px;
      }
    }
  }

  &:has(.smartbanner) {
    @media (max-width: 700px) {
      #main {
        margin-top: 0;
      }
    }
  }
}

// CATEGORIES BOTTOM LIST

@media (max-width: 700px) {
  .view-categories {
    &.view-display-id-block_services {
      .container {
        padding: 20px;
        border: none !important;

        .view-header {
          padding: 0;

          h3 {
            text-align: left !important;
            font-size: 24px;
            line-height: 30px;
            margin: 0 0 15px 0;
          }
        }

        .view-content {
          .col-md-6 {
            margin-bottom: 20px;

            .outer {
              display: flex;
              align-items: center;

              .image {
                margin-right: 20px;

                img {
                  width: 100px;
                }
              }

              .field-content {
                margin: 0;

                a {
                  font-size: 16px;
                }
              }
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// BOUTIQUE BOTTOM LIST

@media (max-width: 700px) {
  .view-boutique {
    &.view-display-id-block {
      border-bottom: none !important;

      .container {
        padding: 20px;
        border: none !important;

        .view-header {
          padding: 0 0 25px 0;

          h3 {
            text-align: left !important;
            font-size: 24px;
            line-height: 30px;
            margin: 0;
          }
        }

        .view-content {
          .col-md-6 {
            margin-bottom: 20px;

            .outer {
              figure {
                display: flex;
                align-items: center;

                .img-container {
                  min-width: 130px;
                  height: 150px;
                  padding: 10px;

                  a {
                    top: 10px;
                    right: 10px;
                    bottom: 10px;
                    left: 10px;
                  }
                }

                .text--left {
                  margin-left: 15px;

                  h5 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                  }
                }
              }
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        .view-footer {
          margin-top: 20px;

          .text--center {
            text-align: left !important;

            .btn {
              margin: 0;
              padding: 0;
              color: #333;
              text-align: left;
              min-height: unset;
              padding: 0;
              min-height: 0;
              font-size: 14px;
              line-height: 24px;
              -webkit-text-decoration: underline #ba9e66 !important;
              text-decoration: underline #ba9e66 !important;
              text-underline-offset: 6px;
              text-align: inherit;

              &:before {
                content: none;
              }

              &:after {
                content: "→";
                position: relative;
                top: 7px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: 0.5s;
              }

              &:focus,
              &:hover {
                color: #ba9e66 !important;

                &:after {
                  left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ACTUALITES BOTTOM LIST

@media (max-width: 700px) {
  .view-hub-actualites {
    &.view-display-id-block_rapport {
      border-bottom: none !important;
      padding: 0;
      background: #fff;

      .container {
        .view-header {
          padding: 0 0 25px 0 !important;

          h3 {
            text-align: left !important;
            font-size: 24px;
            line-height: 30px;
          }
        }

        .view-content {
          //padding: 0 20px;

          .col-xs-12 {
            margin-bottom: 20px;

            .outer {
              padding: 0;
              position: relative;
              display: flex;

              a {
                margin-bottom: 0;
              }

              .text {
                margin-left: 20px;
                width: 185px;

                p {
                  display: none;

                  &.cat {
                    display: block;
                    font-size: 10px;
                    line-height: 11px;
                    color: #404041;
                    margin: 0;
                    top: 0;
                  }
                }
              }

              img {
                max-width: 130px;
                height: auto;
              }

              h4 {
                line-height: 20px;
                margin: 5px 0 0 0;
                overflow: hidden;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }

        .view-footer {
          margin: 0;

          .text--center {
            text-align: left !important;

            .btn {
              margin: 0;
              padding: 0;
              color: #333;
              text-align: left;
              min-height: unset;
              padding: 0;
              min-height: 0;
              font-size: 14px;
              line-height: 24px;
              -webkit-text-decoration: underline #ba9e66 !important;
              text-decoration: underline #ba9e66 !important;
              text-underline-offset: 6px;
              text-align: inherit;

              &:before {
                content: none;
              }

              &:after {
                content: "→";
                position: relative;
                top: 7px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: 0.5s;
              }

              &:focus,
              &:hover {
                color: #ba9e66 !important;

                &:after {
                  left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.view-hub-actualites {
  &.view-display-id-block_rapport {
    .outer {
      img {
        margin-bottom: 20px;
      }
    }
  }
}

// HUB PRESS FIX

.node-type-hub-presse {
  @media (max-width: 700px) {
    #header {
      a {
        span {
          line-height: unset !important;
        }
      }
    }

    #main {
      margin-top: 70px !important;
    }
  }
}

// BILLETS VIEW
.view-widget-visite,
.view-widget-billet,
.view-billets-tarifs,
.view-billets-tarifs-mdv {
  position: relative;

  .view-filters {
    .date {
      .icon-calendair {
        &:before {
          content: "";
          background-image: url(../../images/clock_min.svg);
          height: 16px;
          width: 16px;
          display: inline-block;
          top: -2px;
          left: 0;

          @media (max-width: 700px) {
            top: 0;
          }
        }
      }

      .bef-datepicker {
        font-size: 15px !important;
        text-shadow: none !important;
        color: #333 !important;
      }
    }

    @media (min-width: 1100px) {
      .date {
        position: absolute !important;
        left: calc(50% - 90px);
        margin: 0;
        bottom: 300px;
        z-index: 2;
      }
    }

    @media (max-width: 700px) {
      .nav-bar {
        padding-bottom: 0;

        .bef-select-as-links {
          display: flex;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .form-item {
            display: flex;
            align-items: flex-end;

            .form-type-bef-link {
              margin: 0 25px 0 0 !important;

              a {
                line-height: 20px !important;
                padding: 12px 0 12px 0 !important;
                white-space: nowrap;
                font-weight: 400;
                font-size: 16px;
                border-bottom-color: transparent;
                border-width: 2px;

                &.active {
                  border-bottom-color: #ba9e66;
                  border-width: 2px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .block-offer {
        margin: 0 !important;
        padding: 0 !important;

        .inner {
          padding: 25px !important;

          .row {
            margin: 0;

            .col-xs-12 {
              margin: 0;
              padding: 0;

              .text {
                margin-bottom: 25px;

                h4 {
                  margin-bottom: 15px;
                }

                p {
                  color: #000000;
                  margin-bottom: 0;
                }
              }
            }

            .button {
              a {
                border: 1px solid #ba9e66;
                color: #000000;

                &:after {
                  background: transparent;
                }
              }
            }
          }
        }
      }

      .lo-circular {
        position: absolute;
        top: -50px;
        left: 0;
      }
    }
  }

  .view-content {
    .ticket {
      @media (min-width: 700px) {
        background-image: none !important;

        &.ui-billet {
          .inner>.row .row>div {
            min-width: fit-content;
          }

          img {
            display: block;
          }
        }

        h4 {
          line-height: 24px;
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;

          img {
            width: 20px;
            margin-right: 8px;
            margin-top: 1px;
          }
        }

        .mobile {
          display: none;
        }
      }

      @media (max-width: 700px) {
        padding: 25px 25px 0px 25px !important;
        background-repeat: no-repeat;
        background-size: cover;
        border: none !important;
        min-height: 280px !important;
        margin-bottom: 15px !important;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(180deg,
              #78787821 0,
              #000000bd 75%,
              #000000cb 100%);
        }

        .row {
          display: none;
        }

        .billet,
        .visite {
          position: relative;
          z-index: 1;
          padding: 0 !important;

          &.mobile {
            @media (min-width: 700px) {
              display: none;
            }
          }

          .main-info {
            display: flex;
            flex-direction: column;
            height: 190px;

            .top-info {
              margin: 0;

              .picto {
                margin-bottom: 10px;
                display: inline-block;
                margin-right: 10px;

                img {
                  margin-right: 8px;

                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }

              h4 {
                font-size: 20px !important;
                line-height: 24px !important;
                margin-bottom: 10px !important;
                color: #fff !important;
              }

              p {
                font-size: 14px;
                line-height: 16px;
                color: #fff;
                text-align: left;
                margin-bottom: 0;
              }

              .ticket-desc-short {
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
              }
            }

            .bottom-info {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              margin-top: auto;

              .price {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #ffffff;

                &:focus,
                &:hover {
                  background: #fff !important;
                  color: #000000 !important;
                }

                &:before,
                &:after {
                  content: none !important;
                }
              }

              .duration {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 15px;
                color: #ffffff;
                position: relative;

                &:before {
                  content: "";
                  position: absolute;
                  left: -25px;
                  top: 2px;
                  width: 16px;
                  height: 16px;
                  background-image: url(/sites/all/themes/versailles/src/images/watch.svg);
                  background-size: 100%;
                }
              }
            }

            .open-overlay {
              color: #fff !important;
              font-size: 14px;
              line-height: 16px;
              -webkit-text-decoration: underline #ba9e66 !important;
              text-decoration: underline #ba9e66 !important;
              text-underline-offset: 6px;
              display: block;
              min-height: unset;
              overflow: visible;
              border-bottom: none !important;
              cursor: pointer;

              &:after {
                content: "→";
                position: relative;
                top: 4px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: 0.5s;
              }
            }

            &.open+.overlay-info {
              display: block;
              right: 0;
              transition: right 0.2s;
            }
          }
        }

        .overlay-info {
          position: fixed;
          right: -100vw;
          width: calc(100vw - 40px);
          top: 70px;
          height: calc(100vh - 70px);
          background: #ffffff;
          padding: 25px 20px 20px 30px;
          transition: right 0.2s;
          display: block;
          z-index: 10;

          .overlay-close {
            position: absolute;
            right: 5px;
            top: 35px;
            cursor: pointer;

            &:before,
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 14px;
              width: 17px;
              height: 2px;
              height: 0.125rem;
              background: #000;
            }

            &:before {
              transform: rotate(-45deg);
            }

            &:after {
              transform: rotate(45deg);
            }
          }

          .overlay-content {
            .ticket-title {
              font-family: "Graphik";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 26px;
              color: #000000;
              margin-bottom: 24px;
              max-width: 90%;
              display: flex;
              gap: 16px;
              flex-wrap: wrap;

              .picto {
                margin-bottom: 0;
              }
            }

            .ticket-desc {
              margin-top: 25px;

              p,
              li {
                font-size: 13px;
                line-height: 18px;
                color: #000000;
                text-align: left;
              }

              p,
              ul {
                margin: 0 0 10px 0;
              }

              ul {
                padding: 0 0 0 20px;
              }
            }

            .bottom-stick {
              background: #f1efea;
              padding: 10px 20px 60px 30px;
              position: fixed;
              width: 100%;
              margin-left: -30px;
              bottom: 55px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  body:not(.node-type-visite-section) {
    .ticket-popup-container {
      padding-top: 20px;

      .fixed-content img {
        margin-top: 20px;
        width: 100%;
      }

      .bottom-stick {
        .text-top {
          font-size: 14px;
          text-align: right;
          font-size: 10px;
          line-height: 14px;
        }
      }

      .overlay-close {
        top: 15px;
      }

      .overlay-info .overlay-content {
        .ticket-title {
          margin-bottom: 0;
        }

        .ticket-desc {
          margin-top: 0;
          padding-right: 0;

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .view-widget-visite .ticket,
    .view-widget-billet .ticket {
      background-color: #ba9e66;

      img {
        display: inline-block;
        width: 20px;
      }

      .inner {
        border: none;
        padding: 0;

        h4 {
          font-family: "Apollon";
          font-weight: 400;
          text-transform: none;
        }

        .main-info .bottom-info .duration:before {
          top: -2px;
        }
      }
    }
  }
}

// TICKETS OPEN OVERLAY
.ticket-popup-container {
  display: none;

  @media (max-width: 700px) {
    position: fixed;
    right: -100vw;
    width: calc(100vw - 40px);
    top: 0;
    height: 100dvh;
    background: #fff;
    padding: 20px;
    transition: right 0.2s;
    display: block;
    z-index: 1115;

    &.open {
      display: block;
      right: 0;
      transition: right 0.2s;

      &::before {
        content: "";
        position: absolute;
        left: -40px;
        top: 10px;
        width: 40px;
        height: 100vh;
        background: #000;
        opacity: 0.5;
      }

      &.ticket-popup-container.open::after {
        content: "";
        background: #000;
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 55px;
        top: -55px;
        left: 0;
      }
    }

    .overlay-close {
      position: absolute;
      right: 5px;
      top: 15px;
      padding: 20px;
      z-index: 20;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 14px;
        width: 17px;
        height: 2px;
        height: 0.125rem;
        background: #000;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    .overlay-info {
      .overlay-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 54px);
        height: 100vh;

        .uppertitle {
          font-size: 14px;
          line-height: 20px;
          margin: 0 0 10px 0;
        }

        .ticket-title {
          font-family: "Graphik";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: #000000;
          margin-bottom: 20px;
          max-width: 90%;
          display: flex;
          flex-wrap:wrap;

          .picto {
            margin-bottom: 0;
            display: flex;
            margin-bottom:10px;
            margin-right: 16px;
            align-items: flex-start;
            padding-top: 1px;

            img {
              max-width: 25px;
            }
          }
          span:not(.picto) {
            width:100%;
          }
        }

        .ticket-desc {
          margin-top: 25px;
          padding-right: 5px;

          p,
          li {
            font-size: 13px;
            line-height: 18px;
            color: #000000;
            text-align: left;
          }

          p,
          ul {
            margin-bottom: 10px;
          }

          ul {
            padding: 0;
            margin-left: 20px;

            li {
              margin-bottom: 15px;
            }
          }
        }

        .lieu-duree {
          font-size: 14px;
          line-height: 20px;

          p {
            margin-top: 0px;
          }
        }

        select {
          option {
            color: #000;
            padding: 5px 0;
          }
        }

        .bottom-stick {
          background: #f1efea;
          padding: 20px 20px 20px 20px;
          margin-left: -20px;
          width: calc(100vw - 40px);
          margin-bottom: -35px;

          .view-accessibilite ul {
            display: flex;
            gap: 12px;
            align-items: flex-end;
            margin-left: auto;
            width: fit-content;
            margin-top: 10px;
            margin-bottom: 0;

            li {
              margin-left: 0;

              i {
                width: 20px;
                height: 28px;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;

                &.icon-accessibilite-17 {
                  background-image: url(../images/accessibilite-17.svg);

                  &:before {
                    display: none;
                  }
                }

                &.icon-accessibilite-18 {
                  background-image: url(../images/accessibilite-18.svg);

                  &:before {
                    display: none;
                  }
                }

                &.icon-accessibilite-260 {
                  background-image: url(../images/accessibilite-260.svg);

                  &:before {
                    display: none;
                  }
                }

                &.icon-accessibilite-261 {
                  background-image: url(../images/accessibilite-261.svg);

                  &:before {
                    display: none;
                  }
                }
              }
            }
          }

          .text-top {
            font-size: 14px;
            text-align: right;
            font-size: 10px;
            line-height: 14px;
          }

          .tooltip {
            font-size: 10px;
            line-height: 14px;
            text-align: right;
            color: #333333;
            padding-bottom: 10px;
            padding-top: 10px;
            margin: 0;
            margin-bottom: 0;

            &:after {
              content: "?";
              background: #fff;
              margin-left: 10px;
              padding: 5px 7px;
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }
          }

          .selectric-wrapper {
            width: 100%;
            margin-bottom: 15px;

            .selectric {
              color: #666666;
              background-image: url(../../src/images/date-icon.svg);
              background-repeat: no-repeat;
              background-position: left 20px top 18px;
              padding-left: 50px;
            }
          }

          label {
            display: none;
          }

          .select-a11y {
            margin-bottom: 10px;
            background: #FFF;
          }

        }

        .billet .billet {
          margin-top: 10px;
        }

        .billet .billet,
        .btn-group {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          position: relative;

          .price {
            margin: 0;
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
            color: #000000;
            max-height: 48px;
            padding-bottom: 0;
            min-height: 48px;
            width: 100px;
            padding-left: 0;
            padding-right: 0;
            background: #fff;

            &:focus,
            &:hover {
              background: #fff !important;
              color: #000000 !important;
            }

            &:before,
            &:after {
              content: none !important;
            }
          }

          .btn--primary {
            max-width: calc(100% - 100px);
            max-height: 48px;
            margin: 0;
            min-height: 48px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span.ext {
              &:after {
                content: "→";
                position: relative;
                top: 4px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: 0.5s;
              }
            }
          }
        }

        .text-bottom {
          font-size: 12px;
          text-align: right;
          margin: 5px 0 5px 0;
          line-height: 12px;

          p {
            margin: 0;
            padding: 3px 0;
            line-height: 16px;

            br {
              position: relative;
              display: inline-block;
              font-size: 8px;
              line-height: 8px;
              content: "A";
              width: 5px;
            }
          }
        }
      }
    }
  }
}

// PUSH IMAGE

.view-push-image {
  &.view-display-id-block_full {
    .btn {
      &.btn--primary {
        .ext {
          color: #000;
        }

        &:focus,
        &:hover {
          .ext {
            color: #fff;
          }
        }
      }

      &.btn--secondary {
        .ext {
          color: #fff;
        }

        &:focus,
        &:hover {
          .ext {
            color: #000;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .view-push-image {
    &.view-display-id-block_full {
      .container {
        padding: 20px 20px 30px 20px !important;

        .btn {
          .ext {
            color: #000;
          }
        }

        .col-xs-12 {
          margin-bottom: 15px !important;

          &.views-column-last {
            margin-bottom: 0 !important;
          }

          .inner {
            padding: 25px 25px 60px 25px;

            h4 {
              margin: 0;
            }
          }
        }
      }
    }

    &.view-display-id-block {
      .container {
        padding: 20px 20px 30px 20px !important;

        .col-lg-6 {
          margin-bottom: 30px;

          &.views-column-last {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// WIDGET CTA

.view-widget-cta {
  .ui-header-mobile {
    background-image: unset;
  }

  &.view-display-id-full {
    .btn {
      .ext {
        color: #fff;
      }

      &:focus,
      &:hover {
        .ext {
          color: #000;
        }
      }
    }
  }

  &.view-display-id-moy2 {
    .btn {
      .ext {
        color: #000;
      }

      &:focus,
      &:hover {
        .ext {
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 700px) {
    &.view-display-id-moy2 {
      h3 {
        margin-top: 120px;
      }
    }

    &.view-display-id-full {
      .ui-header-mobile {
        background-size: cover;

        .ui-header {
          background-image: unset !important;

          &.no-subtitle {
            h3 {
              margin-top: 100px;
            }
          }
        }

        .btn {
          .ext {
            color: #000;
          }

          &:focus,
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

// HUB LIEU & LIEU

.node-hub-lieu {
  .text--lead {
    text-align: left;
  }
}

// HUB VISITER

.view-hub-visiter {
  @media (max-width: 700px) {
    >.container {
      padding: 20px !important;

      .view-header {
        padding: 0;

        h3 {
          text-align: left !important;
        }
      }

      .outer {
        padding-top: 20px;

        .btn {
          .ext {
            color: #000;
          }

          &:focus,
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

// TOOLTIP ADJUSTMENTS

.tooltip-theme .tooltipster-content p {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Graphik";
  font-size: 14px;
  font-size: 0.875em;
  line-height: 1.2;
}

/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
  -webkit-appearance: none;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px);
  /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%);
  /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap;
  /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.view-billets-tarifs .view-content .ticket .read-more-content .read-more-content-bottom .tooltip-theme .tooltipster-content a,
.view-billets-tarifs-mdv .view-content .ticket .read-more-content .read-more-content-bottom .tooltip-theme .tooltipster-content a {
  margin-left: 3px;
}

.ext .ext:before {
  display: none;
}

.node.node-visite-section .field-collection-view .ui-header a {
  color: #fff;
  border: none;

  &:focus,
  &:hover {
    color: #000;
  }

  &:before {
    background-color: #000;
  }

  &:after {
    background-color: #ba9e66;
  }
}

html {
  overflow: unset;
}

.view .selectric-wrapper .selectric-items {
  max-height: 300px;
  overflow-y: scroll !important;
}

@media (max-width: 1100px) {

  .not-front header#header,
  .front header#header {
    .right {
      .bilette {
        min-width: 117px;
      }

      #open-form {
        margin-top: 0 !important;
      }

      .soutenir,
      .boutique {
        margin-top: 0 !important;
      }
    }
  }
}

.view-billets-tarifs .view-content .ticket,
.view-billets-tarifs-mdv .view-content .ticket {
  @media (min-width: 700px) {
    background-image: none !important;

    .mobile {
      display: none;
    }
  }
}

.view.view-billets-tarifs .ticket .view-accessibilite ul,
.view.view-billets-tarifs-mdv .ticket .view-accessibilite ul {
  li {
    i {
      width: 20px;
      height: 28px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: 10px;

      &.icon-accessibilite-17 {
        background-image: url(../images/accessibilite-17.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-18 {
        background-image: url(../images/accessibilite-18.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-260 {
        background-image: url(../images/accessibilite-260.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-261 {
        background-image: url(../images/accessibilite-261.svg);

        &:before {
          display: none;
        }
      }
    }
  }
}

.nav-bar {
  .lo.lo-circular {
    position: absolute;
    right: auto;
    left: -50px;
    top: 30px;

    @media (max-width:900px) {
      position: absolute;
      top: 0;
      right:0;
      left:auto;
    }
  }
}

// NODE EVENEMENT / ACTUALITE / HUB LIEU
.node.node-evenement,
.node.node-actualite,
.node.node-hub-lieu {
  .page-nav-bottom {
    display: none;
  }

  @media (max-width: 700px) {

    .big-header,
    .lieu-header {
      min-height: 300px;
      position: relative;

      h1 {
        text-align: left;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        margin: 0 0 20px 0;

        .date {
          display: block;
          margin: 0 0 20px 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          max-width: 80%;
        }
      }

      &:before {
        content: none !important;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: linear-gradient(to top,
            #222 0,
            rgba(255, 255, 255, 0) 100%);
        transition: all 0.3s;
      }
    }

    .breadcrumb {
      display: none;
    }

    .ui-intro {
      padding: 0 30px;
    }

    .text--lead {
      margin: 30px 0 !important;
      font-size: 18px !important;
      line-height: 26px !important;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 30px;
    }

    .text-content {

      .align-right,
      .align-left {
        max-width: 150px;
        margin: 0 20px 10px 10px;
      }

      p {
        margin: 0 0 20px 0;
      }
    }

    .page-nav-bottom {
      padding: 40px 20px;
      display: block;
      background: #fff;

      h2 {
        text-align: left;
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 15px 0;
      }

      a {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: #000;
        display: inline-block;
        padding: 0;
        -webkit-text-decoration: underline #ba9e66 !important;
        text-decoration: underline #ba9e66 !important;
        text-underline-offset: 6px;
      }
    }
  }
}

// HUB FAMILLE
@media (max-width: 700px) {
  .node-type-hub-famille {
    .section-activite {
      .view {
        .container {
          padding: 40px 20px;

          .view-header {
            text-align: left;

            .desc {
              max-width: 100%;

              p {
                text-align: left;
              }
            }
          }
        }
      }
    }

    .view.view-id-push_image {
      .outer {
        .inner {
          text-align: left;
          padding: 20px 10px 20px 0 !important;

          h4 {
            margin: 0 0 10px 0;
          }

          .btn {
            margin: 0;
          }
        }
      }
    }
  }
}

// HUB ABONNE
@media (max-width: 700px) {
  .node-hub-espace-abonnes-2 {
    .view-espace-abonnes {
      .container {
        padding: 40px 20px !important;

        .view-header {
          padding: 0 0 20px;

          .text--center {
            text-align: left !important;
            padding-bottom: 10px;

            p {
              text-align: left !important;
              font-size: 18px !important;
              line-height: 26px !important;
            }
          }
        }

        .view-footer {
          margin-top: 0 !important;
          padding: 20px !important;

          .text--gold {
            padding: 0 !important;
          }
        }

        .container {
          padding: 0 !important;
        }
      }
    }
  }

  .view-espace-abonnes {
    >.container {
      padding: 20px !important;
    }
  }
}

// HUB ENSEIGNANTS
@media (max-width: 700px) {
  .node-hub-espace-enseignants {
    .top-info {
      margin: 0 !important;

      .col-left {
        margin: 30px 0 0 0 !important;
        font-size: 18px !important;
        line-height: 26px !important;
        border-bottom: 1px solid #e6e6e6;
        padding: 0 0 30px 0 !important;
        width: 100%;

        .text--gold {
          font-size: inherit !important;
          line-height: inherit !important;
        }
      }

      .col-right {
        padding: 20px 0 !important;
      }
    }
  }
}

// HUB PRESSE
@media (max-width: 700px) {

  .node-type-hub-presse .article-header .text--center,
  .node-type-hub-presse .article-header .text--center h1 {
    text-align: center !important;
  }
}

// BLOCK RESSOURCES
@media (max-width: 700px) {

  .view-hub-ressources,
  .view-hub-famille {

    &.view-display-id-block_hub_decouvrir,
    &.view-display-id-ressources {
      border-bottom: none !important;
      padding: 20px 0;
      background: #fff;

      .container {
        padding: 0 20px;

        .view-header {
          padding: 0 0 25px 0 !important;

          h3 {
            text-align: left !important;
            font-size: 24px;
            line-height: 30px;
          }
        }

        .view-content {
          .col-lg-6 {
            margin-bottom: 30px !important;

            .outer {
              padding: 0 !important;
            }

            .news {
              padding: 0;
              border: none;
              height: unset;

              .row {
                margin: 0;

                .col-xs-12 {
                  margin-bottom: 0;
                  font-size: 14px;

                  &:first-of-type {
                    max-width: 130px;
                    padding: 0;
                  }
                }

                .inner {
                  margin-left: 20px !important;
                  max-width: 185px;
                  padding: 0;
                  margin-bottom: 0;

                  h4 {
                    line-height: 20px;
                    margin: 0;
                    overflow: hidden;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0;
                  }

                  p {
                    margin-bottom: 0;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        .view-footer {
          text-align: left !important;
          margin: 0;

          .text--center {
            text-align: left !important;
          }

          .btn {
            margin: 0;
            padding: 0;
            color: #333;
            text-align: left;
            min-height: unset;
            padding: 0;
            min-height: 0;
            font-size: 14px;
            line-height: 24px;
            -webkit-text-decoration: underline #ba9e66 !important;
            text-decoration: underline #ba9e66 !important;
            text-underline-offset: 6px;
            text-align: inherit;

            &:before {
              content: none;
            }

            &:after {
              content: "→";
              position: relative;
              top: 7px;
              background: 0 0;
              display: inline-block;
              overflow-y: hidden;
              text-decoration: none;
              transform: unset !important;
              max-width: 20px;
              left: 7px;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
}

// SPECIAL CASE - SIMPLE PAGE IN HUBS

.node-type-page-simple,
.node-type-programmation-dediee,
.node-type-hub-avantages,
.node-type-enseignants-visites-activites,
.node-type-hub-contenus,
.node-type-enseignants-modalites,
.node-type-hub-relais,
.node-type-relais-visites-activites,
.node-type-relais-modalites,
.node-type-sous-espace-dedie {
  .parent-link {
    display: none;
  }
}

@media (max-width: 700px) {

  .section-abonnes,
  .section-subscribers,
  .section-soutenir-versailles,
  .section-support-versailles,
  .section-enseignants,
  .section-teachers,
  .section-associations-ce,
  .section-associations,
  .section-relais-culturels,
  .section-groupe-handicap,
  .section-groupe-publics-eloignes,
  .section-professionnels-tourisme,
  .section-tourism-professionals {

    &.node-type-page-simple,
    &.node-type-programmation-dediee,
    &.node-type-hub-avantages,
    &.node-type-enseignants-visites-activites,
    &.node-type-hub-contenus,
    &.node-type-enseignants-modalites,
    &.node-type-hub-relais,
    &.node-type-relais-visites-activites,
    &.node-type-relais-modalites,
    &.node-type-sous-espace-dedie {
      .article-header {
        background-image: none !important;
        background-color: #f1efea;
        background-size: unset !important;
        min-height: unset !important;
        padding: 15px 20px 20px 20px !important;

        .parent-link {
          display: inline-block;
          color: #666;
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          padding-left: 10px;

          &:before {
            content: "←";
            position: relative;
            top: 7px;
            background: 0 0;
            display: inline-block;
            overflow-y: hidden;
            text-decoration: none;
            transform: unset !important;
            max-width: 20px;
            left: -5px;
            transition: 0.5s;
          }
        }

        .text--center {
          text-align: left !important;
          margin: 20px 0 0 0;

          h1 {
            color: #000;
            text-align: left;
            font-weight: 400;
            font-size: 30px;
            line-height: 37px;
            margin: 0 0 20px 0;

            .small {
              color: #333;
              font-size: 22px;
              margin-bottom: 20px;
              line-height: 28px;
            }
          }
        }
      }

      .page-nav {
        background: #f1efea;
        padding: 0 0 20px 0;
        border: unset;

        .container {
          padding: 0 20px;

          .menu {
            display: flex;
            row-gap: 10px;
            flex-wrap: wrap;

            li {
              width: 50%;
              text-align: left;

              a {
                font-size: 14px;
                line-height: 20px;
                padding: 0;
                text-decoration: underline;
                -webkit-text-decoration-color: #ba9e66;
                text-decoration-color: #ba9e66;
                text-underline-offset: 5px;
                padding-right: 10px;
              }
            }
          }
        }
      }

      .text-content {
        p {
          margin-bottom: 20px;

          .text--citation {
            font-size: 18px !important;
            line-height: 26px !important;
            font-family: "Crimson Text", serif;
          }
        }
      }

      .col-right {
        ul {
          margin: 0;
          padding-left: 20px;
        }
      }

      .text--lead {
        font-size: 18px !important;
        line-height: 26px !important;
        font-family: "Crimson Text", serif;
      }
    }
  }
}

@media (min-width: 700px) {

  .view-billets-tarifs,
  .view-billets-tarifs-mdv,
  .view-widget-visite,
  .view-widget-billet {
    .view-accessibilite ul {
      display: flex;
      gap: 12px;
      width: fit-content;

      li {
        margin-left: 0;

        i {
          width: 20px;
          height: 28px;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;

          &.icon-accessibilite-17 {
            background-image: url(../images/accessibilite-17.svg);

            &:before {
              display: none;
            }
          }

          &.icon-accessibilite-18 {
            background-image: url(../images/accessibilite-18.svg);

            &:before {
              display: none;
            }
          }

          &.icon-accessibilite-260 {
            background-image: url(../images/accessibilite-260.svg);

            &:before {
              display: none;
            }
          }

          &.icon-accessibilite-261 {
            background-image: url(../images/accessibilite-261.svg);

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {

  .view-collections,
  .view-domaine {

    &.view-display-id-node,
    &.view-display-id-block_hub_lieux {
      padding: 0 !important;

      >.container {
        padding: 30px 20px !important;

        .view-filters {
          margin-bottom: 0;

          .views-exposed-widgets {
            padding: 0 20px;

            .selectric-wrapper {
              display: none !important;
            }

            .form-item {
              text-align: left;

              .form-type-bef-link {
                display: block !important;
                margin: 0 0 5px 0;

                a {
                  padding: 0;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {

  .view.view-services,
  .view.view-visite-faq {
    .view-filters {
      .selectric-wrapper {
        display: none !important;
      }

      .bef-select-as-links {
        .form-item {
          text-align: left;

          .form-type-bef-link {
            display: block !important;
            margin: 0 0 5px 0;

            &:last-of-type {
              margin: 0;
            }

            a {
              padding: 0;
              font-size: 15px;
              border: none;
              line-height: 22px;

              &.active {
                text-decoration: underline #ba9e66 !important;
                text-underline-offset: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {

  .view-collections,
  .view-domaine {

    &.view-display-id-node,
    &.view-display-id-block_hub_lieux {
      .container {
        .view-header {
          h3 {
            text-align: left !important;
          }
        }
      }
    }
  }
}

.view.view-billets-tarifs .view-filters .nav-bar .selectric,
.view.view-billets-tarifs-mdv .view-filters .nav-bar .selectric,
.view.view-services .view-filters .selectric-wrapper,
.view.view.view-visite-faq .view-filters .selectric-wrapper {
  @media (max-width: 1100px) {
    display: none !important;
  }
}

@media (min-width:1100px) {
  .page-nav-container {
    height: 82px;
    // margin-top: 40px
  }
}


.view-accessibilite ul {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  margin-left: auto;
  width: fit-content;
  margin-top: 10px;

  li {
    margin-left: 0;

    i {
      width: 20px;
      height: 28px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;

      &.icon-accessibilite-17 {
        background-image: url(../images/accessibilite-17.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-18 {
        background-image: url(../images/accessibilite-18.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-260 {
        background-image: url(../images/accessibilite-260.svg);

        &:before {
          display: none;
        }
      }

      &.icon-accessibilite-261 {
        background-image: url(../images/accessibilite-261.svg);

        &:before {
          display: none;
        }
      }
    }
  }
}

.view-domaine {
  &.view-display-id-block_hub_lieux {
    .select-a11y {
      display: none;
    }
  }
}

.node-mdv-detail,
.node-visite-section {
  .bottom-info {
    .flex-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media (max-width:900px) {
        flex-direction: column-reverse;
      }

      .col {
        width: 50%;

        @media (max-width:900px) {
          width: 100%;

          &.image {
            margin-bottom: 30px;
          }
        }

        &.text {
          .field-item {
            max-width: 90% !important;
          }
        }
      }
    }
  }
}

.node.node-evenement {
  &>header {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacitY: .2;
    }
  }
}

.node-type-personnage {
  .ui-intro:after {
    displaY: none;
  }

  .content .mt-4 {
    margin-top: 170px;

    @media (max-width:900px) {
      margin-top: 30px;
    }
  }
}

.slick--view--widget-timeline {
  .slick__arrow {
    button {

      &:hover,
      &:focus {
        background: #ba9e66;
        outline: 2px solid #fff;
        outline-offset: 3px;
      }
    }
  }
}

@media (min-width: 1100px) {
  #main {
    margin-top: 69px;
  }
}
