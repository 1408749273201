#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateX(-100%);
  // z-index: 152;
  z-index: 200;

  @media (min-widtH:380px) {
    width: 375px;
  }

  .simplebar-track {
    display: none !important;
  }

  .sidebar-menu {
    background: #FFFFFF;
    max-width: 375px;
    overflow-y: auto;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    // position: fixed;
    z-index: 1000;
    // display: none;
    left: 0;
    transition: .2s;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &.close {
      left: -400px;
      transition: .2s;
    }

    ul {
      padding-left: 0;
      margin-left: 0;

      a {
        outline-color: #000;
      }
    }

    .menu-close {
      position: relative;
      border: none;
      background: none;
      margin-left: auto;
      margin-right: 0;
      text-align: right;
      width: 100%;
      padding-right: 50px;
      padding-top: 4px;
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #000000;
      width: 110px;
      margin-left: auto;
      display: block;
      margin-top: 2px;
      margin-right: 2px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 14px;
        width: 21px;
        height: 2px;
        height: 0.125rem;
        background: #000;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    .scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .big-link {
      padding: 20px;
      display: block;
      max-width: calc(100% - 40px);
      margin: 0 auto;
      margin-bottom: 5px;
      text-align: center;
      color: #000;
      background: #BA9E66;

      &:focus,
      &:hover {
        background: #000;
        color: #FFF;
      }
    }

    .gss {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 16px;

      .form-actions {
        button {
          background-image: url(../../images/search-icon-menu.svg);
          background-repeat: no-repeat;
          background-position: center;
          padding-right: 40px;

          i {
            opacity: 0;
          }
        }
      }

      .container-inline {
        position: relative;
      }

      .form-item-search-block-form input {
        display: block;
        width: 100%;
        color: #000;
        padding: 15px 60px 15px 15px;
        padding: 0.9375rem 3.75rem 0.9375rem 0.9375rem;
        font-size: 15px;
        font-size: .9375em;
        line-height: 1;
        background: 0 0;
        border: none;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;

        &:focus {
          outline: 2px solid #000;
        }
      }
    }

    .social-icons {
      @include rem(padding, 25px 40px 20px 25px);
      display: flex;
      width: 80%;
      justify-content: space-between;
      padding-bottom: 0;
      padding-left: 20px;

      a {
        display: inline-block;
        @include rem(margin-right, 10px);
        color: #666666;
        @include font-size(16px);
        line-height: 1;
        vertical-align: middle;

        .ext {
          display: none;
        }

        i {
          transition: all 0.3s;
        }

        &:last-child {
          margin: 0;
        }

        svg {
          path {
            fill: #666;
          }
        }
      }
    }

    .menu-block-3.menu-level-1,
    .menu-block-1.menu-level-1,
    #block-menu_block-1 .menu-level-1 {
      ul {
        padding-left: 0;
        margin-left: 0;

        li {
          position: relative;
          list-style: none;
          padding-right: 0;

          a.open {
            color: #BA9E66;

            .arrow {
              transform: rotate(180deg);
            }
          }

          &::marker {
            display: none;
          }

          .arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 4px 0 4px;
            border-color: #000 transparent transparent transparent;
            position: absolute;
            top: 24px;
            right: 19px;
            padding: 0;
            background-color: transparent;

            &:before {
              content: '';
              position: absolute;
              width: 50px;
              height: 30px;
              left: -30px;
              top: -20px;
            }

            i {
              display: none;
            }

            &[aria-expanded="true"] {
              transform: rotate(180deg);
            }
          }
        }
      }

      nav>ul {
        border-top: 1px solid #E6E6E6;

        &>li {
          border-bottom: 1px solid #E6E6E6;
          margin-bottom: 16px;
          padding-bottom: 13px;
          width: 100%;

          &>.arrow {
            top: 19px;
          }

          &>a {
            padding-left: 20px;
          }

          &:first-of-type {
            padding-top: 16px;

            &>.arrow {
              top: 26px;
            }
          }

          a {
            font-family: 'Apollon';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            position: relative;
            text-decoration: none;
            display: block;

            &:focus,
            &:hover {
              color: #BA9E66;
            }
          }

          &>ul {
            height: 100%;
            max-height: 0;
            overflow: hidden;
            // transition: max-height .2s;
            background: #F1F1F1;

            &:focus-within,
            &.open {
              max-height: 1000px;
              // transition: max-height .2s;
              margin-bottom: -16px;
              margin-top: 12px;
            }

            &>li {
              border-bottom: 1px solid #CCCCCC;
              margin-left: 40px;
              margin-right: 20px;
              padding-bottom: 16px;
              padding-top: 16px;

              .arrow {
                right: 0;
              }

              &:last-of-type {
                border-bottom: none;
              }

              a {
                font-family: 'Graphik';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                color: #000000;
              }

              ul {
                height: 100%;
                max-height: 0;
                overflow: hidden;
                // transition: max-height .2s;

                &:focus-within,
                &.open {
                  max-height: 1000px;
                  // transition: max-height .2s;
                  margin-top: 14px;
                  border-top: 1px solid #CCCCCC;
                  padding-top: 6px;
                }


                li {
                  padding-left: 20px;
                  padding-bottom: 8px;

                  &:first-of-type {
                    padding-top: 8px;
                  }

                  &:last-of-type {
                    padding-bottom: 0;
                    margin-bottom: -5px;
                  }

                  a {
                    font-family: 'Graphik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #000000;

                    &:focus {
                      outline-offset: -2px;
                    }

                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }

    .menu-block-4,
    .menu-block-2 {
      ul {
        list-style: none;
        padding: 0;
        padding-left: 20px;
        margin-top: 25px;

        li {
          padding-bottom: 8px;
          padding-top: 0;
          list-style: none;
          max-height: 35px;

          &::marker {
            display: none;
          }

          a {
            font-size: 16px;
            line-height: 24px;
            color: #000;
            text-decoration: none;

            &:focus,
            &:hover {
              color: #BA9E66;
            }
          }

          &.arrow {
            &.billetterie {
              margin-top: 30px;
            }

            .ext {
              font-size: 14px;
              display: inline-block;
              padding-left: 5px;
            }

            .text {
              border-bottom: 1px solid #BA9E66;
              padding-bottom: 3px;
              font-size: 17px;
            }
          }
        }
      }
    }

    a.gold {
      position: relative;
      border-bottom: 1px solid #ba9e66;
      padding-bottom: 3px;

      &:after {
        content: '→';
        position: absolute;
        width: 10px;
        height: 10px;
        font-size: 13px;
        margin-left: 5px;
        transition: .2s;
        right: -15px;
      }

      &:focus,
      &:hover {
        &:after {
          transition: .2s;
          right: -20px;
        }
      }

      &:first-of-type {
        margin-top: 10px;
        display: inline-block;
        padding-bottom: 0;
      }
    }

    .select-wrapper {
      display: flex;
      width: 100%;
      padding: 0 20px;
      margin-top: 30px;
      margin-bottom: 20px;
      justify-content: space-between;

      form:not(.lang_dropdown_form) {
        width: calc(60% - 15px);

        .selectric-items {
          right: auto;

          ul li {
            &.highlighted {
              background: #d5be86;
              outline: 2px solid #000;
            }
          }
        }

        .selectric {
          background-image: url(../../images/vousetes.svg);
          background-repeat: no-repeat;
          background-position: center left 20px;
          padding-left: 50px;
          padding-top: 14px;
          padding-bottom: 14px;
          font-size: 17px;
          color: #666666;
        }
      }

      form.lang_dropdown_form {
        width: 40%;

        .selectric {
          text-transform: uppercase;
          text-align: center;
        }

        .selectric-items {
          min-width: 134px;
          text-transform: uppercase;

          ul {
            li {
              text-align: center;
              background-size: 16px
            }

            li[data-index="0"] {
              background-image: url(../../images/flag-menu-fr.svg);
              background-repeat: no-repeat;
              background-position: center left 18px;
              background-size: 20px;
            }

            li[data-index="1"] {
              background-image: url(../../images/flag-menu-en.svg);
              background-repeat: no-repeat;
              background-position: top 24px left 18px;
              background-size: 21px;
            }

            li[data-index="2"] {
              background-image: url(../../images/flag-menu-es.svg);
              background-repeat: no-repeat;
              background-position: center left 18px;
              background-size: 20px;
            }
          }
        }
      }

      form {
        .selectric {
          font-size: 16px;
          color: #666666;
          padding-top: 15px;
          padding-bottom: 15px;

          @media (max-width:700px) {
            font-size: 17px;
            color: #666666;
          }
        }
      }

      .selectric-wrapper,
      .container-inline div .selectric-wrapper {
        margin: 0;
      }
    }
  }

  .container-inline div,
  .container-inline label {
    display: block;
    margin: 0;

    .selectric-wrapper {
      @include rem(margin, 0 40px 40px 25px);
    }
  }

  label#edit-jump--2-label {
    display: none;
  }

}

.sidebar-opacity {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(#0F0F0F, 0.75);
  z-index: 151;
}


#sidebar .sidebar-menu .select-wrapper form.lang_dropdown_form .select-a11y .select-a11y-suggestions__list .select-a11y-suggestion {
  background-repeat: no-repeat;
  background-position: center left 20px;
  text-align: center;
  justify-content: center;
}

#sidebar .sidebar-menu .select-wrapper form.lang_dropdown_form .select-a11y .select-a11y-suggestions__list .select-a11y-suggestion:nth-child(1) {
  background-image: url(../../images/flag-menu-fr.svg);
  background-repeat: no-repeat;
  background-position: center left 18px;
  background-size: 20px;
}

#sidebar .sidebar-menu .select-wrapper form.lang_dropdown_form .select-a11y .select-a11y-suggestions__list .select-a11y-suggestion:nth-child(2) {
  background-image: url(../../images/flag-menu-en.svg);
  background-repeat: no-repeat;
  background-position: center left 15px;
  background-size: 26px;
}

#sidebar .sidebar-menu .select-wrapper form.lang_dropdown_form .select-a11y .select-a11y-suggestions__list .select-a11y-suggestion:nth-child(3) {
  background-image: url(../../images/flag-menu-es.svg);
  background-repeat: no-repeat;
  background-position: center left 18px;
  background-size: 20px;
}

@media (max-width:700px) {

  .view-billets-tarifs .view-content .ticket .billet,
  .view-billets-tarifs .view-content .ticket .visite {
    z-index: 1;
  }
}

.selectric {
  font-family: unset;
}
