.node-sous-espace-dedie {
  .page-nav {
    text-align: right;
    @include rem(padding, 10px 0);
  }
  .top-info {
    .col-left {
      margin: 0;
    }
    .col-right {
      .share-print-size {
        @include rem(margin-bottom, 30px);
        position: relative;
        right: auto;
      }
    }
    & + div {
      @include rem(margin-bottom, 40px);
      @include desktop {
        @include rem(margin-bottom, 80px);
      }
    }
  }
  .view.view-programmation-abonnes>.container {
    padding-bottom: 0;
  }
  /*.view.view-programmation-abonnes .views-row-last .ticket {
    margin-bottom: 0;
  }*/
  .view.view-billets-tarifs {
    & > .container {
      padding-top: 0;
    }
    .nav-bar .form-type-bef-link {
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
  .push-image + .field-collection-container {
    .full-width {
      @include rem(padding, 40px 0);
      @include desktop {
        @include rem(padding, 80px 0);
      }
    }
  }
  .field-collection-view {
    .full-width {
      @include rem(padding, 0 0 40px);
      border-bottom: 1px solid #E6E6E6;
      @include desktop {
        @include rem(padding, 0 0 80px);
      }
    }
    .ui-header a {
      margin: 0;
    }
    .ui-header {
      @include rem(margin, 0 -30px);
      @include rem(padding, 30px);
      height: auto;
      @include desktop {
        margin: 0;
        padding: 0;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        opacity: 0;
        transition: all 0.3s;
      }
      &:focus, &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    .inner {
      h4 {
        @include rem(margin, 0 0 20px);
        font-family: $base-font-family;
        @include font-size-adapt(24px);
        @include line-height-adapt(24px);
        text-transform: none;
      }
      a {
        @include rem(margin, 0 0 10px);
        display: inline-block;
        @include rem(padding-bottom, 5px);
        color: #0F0F0F;
        @include font-size(16px);
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
  .ui-intro {
    color: #333333;
    @include rem(padding, 25px 20px);
    @include desktop {
      @include rem(padding, 100px 180px);
    }
    &:before {
      height: auto;
      @include rem(bottom, 30px);
    }
    &:after {
      display: none;
    }
    * {
      position: relative;
      z-index: 2;
    }
    p {
      @include line-height-adapt(28px);
    }
    a:not(.tooltip):not(.btn) {
      color: $color-primary;
      border-bottom: solid 1px;
    }
  }
}
