/*------------------------------------*\
    NEWS.CSS
\*------------------------------------*/


.ui-news {
	.news {
		.text {
			margin-top:10px;
		}
		p.tags {
			margin-bottom: rem(10px);
			@include font-size-adapt(13px, 15px);
			min-height: 35px;
			display: flex;
			align-items: flex-end;
			line-height: 17px;
			text-transform: uppercase;
		}
		& > a, .outer > a {
			position: relative;
			display: block;
			overflow: hidden;
			&:hover:before {
				opacity: 1;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(#000, 0.6);
				opacity: 0;
				transition: all 0.3s;
				z-index: 2;
			}
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		h4 {
			margin-bottom: rem(20px);
			line-height: rem(26px);
			a {
				color: #333333;
			}
		}
		p.date {
			margin-bottom: rem(80px);
			color: #666666;
			@include font-size(15px);
			line-height: 1.2;
		}
		p {
			a:not(.btn) {
				color:inherit;
			}
		}
	}
}

.view-id-hub_actualites.view-display-id-block_rapport,
.view-id-date_agenda.view-display-id-news {
	.news {
		position:relative;
		h4 a {
			&:before {
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
	}
}

.node-unpublished {
  background-color: #FFF4F4;
  opacity: 0.8;
}