.view-widget-cta.view {
  @include rem(margin, 0 0 30px);

  .container {
    @include rem(padding, 0 30px);
  }

  &.view-display-id-full,
  &.view-display-id-moy2 {
    .ui-header {
      position: relative;

      &:focus,
      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(#000, 0.6);
        transition: all 0.3s;
        z-index: 2;

        @include tablet {
          opacity: 0;
        }
      }

      .image {
        z-index: 1;
      }

      .container {
        position: relative;
        z-index: 3;

        .tags {
          @include rem(margin, 0 0 20px);
        }
      }
    }

    @media (max-width:700px) {
      .ui-header {
        padding: 32px 0 22px 0;

        .container.text--center {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          max-height: 100%;
          min-height: 355px;

          h3 {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 0;
          }

          h3+p {
            display: none;
          }

          .btn-group a {
            color: #000;
          }
        }
      }
    }
  }
}

.cta-container {
  border-bottom: 1px solid #E6E6E6;

  .row {
    margin: 0;

    [class^="col-"] {
      position: relative;
      padding: 0;
    }
  }

  .entity {
    position: relative;
    cursor: pointer;

    &:focus-within,
    &:focus,
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }

  .hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: all 0.5s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .inner {
    position: relative;
    @include rem(padding, 30px 20px);
    height: 100%;
    text-align: center;
    z-index: 2;

    @include tablet {
      @include rem(padding, 60px 20px 140px);
    }

    h4 {
      @include rem(margin, 0 0 10px);
      @include font-size(24px);
      @include rem(line-height, 26px);
      font-family: $base-font-family;
      text-transform: uppercase;
    }

    p {
      @include rem(max-width, 250px);
      @include rem(margin, 0 auto);
      @include rem(line-height, 24px);
    }

    .btn {
      @include rem(margin, 20px auto 0);
      cursor: pointer;

      @include tablet {
        margin: 0;
        position: absolute;
        left: 50%;
        @include rem(bottom, 60px);
        white-space: nowrap;
        transform: translateX(-50%);
      }

      &:focus {
        outline-color: #000;
      }
    }
  }
}
