.widget-multiple-visuels.widget-visuels {
  width: 300%;
  margin-right: 0 !important;
  max-width: 942px !important;
  padding-left: 60px;

  @media (max-width: 1120px) {
    width: 100%;
    padding-right: 0;
  }

  @media (max-width: 900px) {
    margin-left: 0 !important;
    padding-left: 0;
  }

  .title h2 {
    margin-bottom: 40px;
    @media (max-width: 900px) {
      margin-top:10px;
      margin-bottom: 30px;
    }
  }

  .links {
    margin-top: 42px;
  }

  .links .btn {
    color: #ba9e66;
    margin-left: 20px;
    display: inline-flex;
    align-items: center;

    @media (max-width: 720px) {
      margin-left: 0;
    }

    svg {
      margin-right: 5px;
      width: auto;
    }

    &.primary {
      color: black;
    }

    &.secondary {
      color: #fff;
      border-color: #fff;

      &:before {
        border-color: #fff;
      }

      svg path {
        fill: #fff;
      }

      &:focus, &:hover {
        color: #333;

        svg path {
          fill: #333;
        }
      }
    }

    &:before {
      border: 1px solid #ba9e66;
    }

    &:after {
      background: #ba9e66;

      @media (max-width: 1100px) {
        transform: scaleY(0);
      }
    }

    &:focus, &:hover {
      color: #333;

      svg path {
        fill: #333;
      }
    }
  }

  .links .visuel-download {
    margin-left: 30px;
    border: 1px solid #ba9e66;

    @media (max-width: 720px) {
      margin-left: 0;
    }

    &:before {
      background: #ba9e66;
      border: 1px solid #ba9e66;
    }

    &:focus, &:hover {
      color: #ba9e66;
      border: 1px solid #ba9e66;

      &:after {
        background: #000;
      }

      svg path {
        fill: #ba9e66;
      }
    }

    &:after {
      background: #000;
    }

    img {
      margin-right: 5px;
    }
  }

  .visuels-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }
  }

  .visuels-thumbnails {
    width: 215px;
    margin-right: 20px;
    max-height: 500px;
    min-height: 420px;

    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    @media (max-width: 900px) {
      display: flex;
      width: 100%;
      min-height: 0;
      padding-top: 20px;
      margin-top: 20px;
      margin-right: 0;
    }

    @media (max-width: 700px) {
      display: none;
    }

    .simplebar-scroll-content {
      padding-right: 10px;
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
    }

    .visuel-small {
      margin-bottom: 10px;
      display: block;
      text-decoration: none;
      border: none !important;
      padding-left: 20px;
      position: relative;
      opacity: 0.4;
      cursor: pointer;
      transition: all 0.3s ease;

      @media (max-width: 900px) {
        min-width: 160px;
        max-width: 160px;
        padding: 0;
        margin-right: 10px;
      }

      .image-thumb {
        &:before {
          content: "";
          position: absolute;
          left: 20px;
          top: 0;
          height: 100%;
          width: 1px;
          background: transparent;
          transition: all 0.3s ease;

          @media (max-width: 900px) {
            height: 1px;
            width: 100%;
            left: 0;
            top: 0;
          }
        }
      }

      &.active {
        opacity: 1;

        .image-thumb {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: #ba9e66;

            @media (max-width: 900px) {
              height: 1px;
              width: 100%;
              left: 0;
              top: 0;
            }
          }
        }
      }

      &:focus, &:hover {
        opacity: 0.8;
      }

      .special-visuel-download,
      .visuel-title,
      .visuel-credits,
      .visuel-desc {
        display: none;
      }
    }
  }

  .visuel-main {
    width: calc(100% - 225px);

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: auto;
      cursor: pointer;
      max-height: 425px;
      margin: 0 auto;
      display: block;
    }

    .visuel-credits-wrapper {
      display: block;

      button {
        font-size: 14px;
        line-height: 15px;
        color: #fff;
        border: none;
        display: block;
        padding-left: 22px;
        background: url(../images/copy-icon.svg) no-repeat left center;
        text-decoration: underline;
        text-decoration-color: #ba9e66;
        text-underline-offset: 3px;
        margin-left: 0px;

        &:focus, &:hover {
          color: #ba9e66;
        }
      }
    }

    .visuel-title {
      font-size: 22px;
      line-height: 20px;
      font-family: "Crimson Text";
      margin: 20px 0 0;
    }

    .visuel-desc {
      font-size: 14px;
      line-height: 20px;
      padding-top: 20px;
    }

    .visuel-credits {
      font-size: 13px;
      line-height: 20px;
      padding-top: 0;
    } 

    .visuel-bottom {
      display: flex;
      justify-content: space-between;
    }

    .visuel-info {
      width: calc(100% - 100px);
      max-width: 430px;
    }

    .visuels-buttons {
      width: 80px;
      display: flex;
      margin: 20px 0 0;

      .next-visuel-please,
      .prev-visuel-please {
        width: 40px;
        height: 40px;
        border: 1px solid #959595;
        display: flex;
        color: #959595;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: url(../images/arrow-slider-white.svg) no-repeat center center;
        color: transparent;
      }

      .next-visuel-please {
        border-right: none;
        transform: rotate(180deg);
      }
    }
  }
}

.slick-track .slick-slide.visuel-small {
  flex-direction: column;
  margin: 0 30px;
  cursor: initial;
  display: table;
  max-width: 80vw;
  cursor: pointer;

  @media(max-width:600px) {
    margin: 0;
    cursor: initial;
    display: block;
    max-width: 100vw;
    cursor: pointer;
    width: calc(100vw - 40px) !important;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .visuel-desc {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    max-width: 400px;
  }

  .visuel-title {
    margin-top: 10px;
  }

  .caption {
    height: 100%;
    display: table-caption;
    caption-side: bottom;
    position: relative;
    padding-bottom: 30px;
    color: white;

    .special-visuel-download {
      position: absolute;
      right: 0;
      top: 10px;
      border-color: #ba9e66 !important;
      background-color: #ba9e66;
      color: #000;
      display: flex;
      align-items: center;

      &:focus, &:hover {
        color: #ba9e66;
        border-color: #ba9e66;
      }
    }
  }
}

i.download {
  width: 20px;
  height: 20px;
  background-image: url("/sites/all/themes/versailles/build/images/download.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  display: inline-block !important;
  transform: none !important;
}

.slick-popup.visuels-popup {
  button.copy-button {
    display: none;
  }

  button.slick-arrow {
    background: none !important;

    i {
      color: #959595 !important;
    }
  }

  button.slick-arrow.slick-next {
    right: 10px;

    @media (max-widtH:700px) {
      display: block;
      top: 20%;
      right: 0;
    }
  }

  button.slick-arrow.slick-prev {
    right: 90px;
    left: auto;

    @media (max-widtH:700px) {
      display: block;
      top: 20%;
      left: 0;
    }
  }

  .slick-slide {
    opacity: 0;
    transition: all 0.3s ease;

    .caption {
      opacity: 0;
    }

    &.slick-current {
      opacity: 1;
      min-width: 600px;

      .caption {
        opacity: 1;
      }
    }

    .visuel-credits {
      font-size: 13px;
    }
  }
}

.mfp-close-btn-in .white-popup .mfp-close {
  font-size: 50px;
  margin-top: 10px;
  color: #fff;
  position: fixed;
  top: 0;
  right: 20px;
}

.node-galerie-media .media-carrousel {
  position: relative;

  .visuel-small {
    width: 100%;
    // margin-left: 0 !important;
  }
}

.white-popup.media-gallery-popup {
  .slick-popup>.visuel-small {
    display: none !important;
  }
}

.media-carrousel {
  padding-bottom: 90px;

  .arrows {
    position: absolute;
    width: 20px;
    right: 100px;
    top: calc(100% - 30px);

    @media(max-width:900px) {
      width: 100px;
      right: 0;
      left: auto;
    }

    button.slick-arrow {
      background: transparent;
      border-color: #000;

      i {
        color: #000;
      }

      &.slick-prev {
        right: calc(100% + 4px);

        @media (max-widtH:700px) {
          right: calc(100% + 4px);
          left: 5px;
        }
      }

      &.slick-next {
        left: calc(100% - 25px);

        @media (max-widtH:700px) {
          left: calc(100% - 48px);
        }
      }
    }
  }
}
