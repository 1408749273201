/*------------------------------------*\
    NAVIGATION.CSS
\*------------------------------------*/

@mixin nav{
    .nav-label {
        display: inline-block;
        vertical-align: middle;
        padding: $input-padding;
    }

    ul{
        display: inline-block;
        vertical-align: middle;
        list-style: none;
        margin: 0;
    }

    > ul > li{
        display: inline-block;
        margin: 0;
        &:first-child {
            margin-left: 0;
            & > a {
                padding-left: 0;
            }
        }
    }

    /* Links */

    > ul > li >a{
        display: inline-block;
        color: $text-primary;
        padding: $input-padding;
    }

    /* Forms */

    form, .text-input, .form-control{
        display: inline-block;
        margin: 0;
    }

    /* Buttons */

    .btn{
        margin-bottom: 0;
    }
}

/**
 * Nav Primary
 */

.nav-primary{

    @include nav;    
    float: left;

    display: none;

    /**
     * Show on tablet
     */
    
    @include media-query(tablet-desktop){
        display: block;
    }

}

/**
 * Nav Secondary
 */

.nav-secondary{
    @include nav;

    float: right;
    display: none;


    /**
     * Show on tablet
     */
    
    @include media-query(desktop){
        display: block;
    }
}