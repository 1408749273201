/* BLOCKS */

.social-networks-block {
  &>.container {
    @include rem(padding, 40px 20px);

    @include desktop {
      @include rem(padding, 60px 0);
    }

    @media (max-width:700px) {
      .link i:before {
        color: #BA9E66;
      }
    }
  }

  @include desktop {
    .view {
      padding: 0;
    }

    .video-container {
      width: 100%;
      @include rem(padding, 0 20px);

    }
  }

  .view {
    .container {
      padding: 0;
    }

    .view-header {
      padding: 0;
    }
  }

  .equelHeight {
    position: relative;
  }

  .link {
    @include rem(margin-top, 20px);

    @media (max-width:700px) {
      @include rem(margin-top, 10px);
    }

    i {
      @include rem(margin-right, 10px);
    }

    i,
    a {
      display: inline-block;
      vertical-align: middle;
      transition: all 0.3s;
    }

    &:focus,
    &:hover {

      i,
      a {
        color: $color-primary !important;
      }
    }
  }

  a.underline {
    display: inline-block;
    text-align: left;
    border-bottom: 1px solid #ba9e66 !important;
    @media (max-width: 700px) {
      font-size: 14px;
    }

    @include desktop {
      margin: 0;
    }
  }

  .video-container {
    //@include rem(margin-bottom, 40px);
    h3{
      font-size: 17px;
      margin: 0 0 10px 0;
      font-family: Graphik,serif;
      text-transform: unset;
      font-weight: 500;
      color: #666;
      min-height: 43px;
      display: flex;
      align-items: flex-end;
      @media (max-width: 700px) {
        min-height: unset;
        font-size: 15px;
      }
    }
    @include desktop {
      margin: 0;
    }



    .image {
      display: block;
      position: relative;
      border: none;
      background: transparent;
      padding:0;
      &:focus,
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }

      &:focus {
        outline-color: #000;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        z-index: 1;
        transition: all 0.3s;
      }

      img {
        width: 100%;
        height: auto;
      }

      i {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        @include rem((width: 62px,
            height: 62px));
        @include font-size(62px);
        line-height: 1;
        color: #fff;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        transition: all 0.3s;
      }
    }
  }

}
