.view.view-instagram {
    .view-header {
        a {
            display: block;
            margin-bottom: rem(20px);
            color: $color-primary;
            @include font-size(18px);
        }
    }
    .view-content {
        .row {
            &:last-child {
              display: none;
              margin-bottom: 0;
              @include tablet {
                display: flex;
              }
            }
            @include rem((
                margin-left: -10px,
                margin-right: -10px
            ));
            [class^="col-"] {
                @include rem((
                    margin-bottom: 15px,
                    padding-left: 10px,
                    padding-right: 10px,
                ));
            }
            @include tablet {
                @include rem(margin-bottom, 20px);
                [class^="col-"] {
                    margin: 0;
                }
            }
            a {
              position: relative;
              display: block;
              background: #f1efea;
              overflow: hidden;
              &:before {
                content: "";
                display: block;
                padding-top: 100%;
              }
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.6);
                opacity: 0;
                z-index: 2;
                transition: opacity .3s;
              }
              &:focus, &:hover {
                &:after {
                  opacity: 1;
                }
              }
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              height: auto;
              min-width: 100%;
              min-height: 100%;
              object-fit: cover;
              transform: translateX(-50%) translateY(-50%);
            }
        }
    }
    .view-footer {
        @include rem(margin-top, 10px);
        text-align: left;
        .link {
          i {
            @include rem(margin-right, 10px);
          }
          i, a {
            display: inline-block;
            vertical-align: middle;
            transition: all 0.3s;
          }
          &:focus, &:hover {
            i,a {
              color: $color-primary !important;
            }
          }
        }
    }
}
