.node.node-programmation-dediee {
  .breadcrumbs {
    border-bottom: none;
  }
  .view-espace-abonnes {
    .view-header {
      text-align: center;
      @include rem(padding-bottom, 80px);
      @media (max-width:700px) {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      p {
        margin: 0 auto;
        @include rem(max-width, 720px);
        color: $color-primary;
        font-family: $font-crimson;
        @include font-size-rem-adapt(24px);
        @include line-height-adapt(34px);
        @media (max-width:700px) {
          font-size: 18px!important;
          line-height: 26px!important;
          text-align: left!important;
        }
      }
    }
    .view-content {

      .outer {
        @include rem(padding-bottom, 50px);
        text-align: center;
        p {
          @include rem(margin, 20px 0 15px);
          @include font-size-rem-adapt(14px);
          @include line-height-adapt(24px);
        }
        h4 {
          margin: 0;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(22px);
          @media (max-width:700px) {
            text-align: left;
          }
        }
      }
    }
    .view-footer {
      position: relative;
      @include rem(margin-top, 0);
      @include rem(padding, 30px 30px);
      text-align: center;
      background: #F1EFEA;
      @include tablet {
        @include rem(padding, 30px 90px);
      }
      @media (max-width:700px) {
        @include rem(padding, 20px);
      }
      p {
        font-family: $heading-font-family;
        @include rem(margin, 11px 0);
        @include font-size-rem-adapt(24px);
        @include line-height-adapt(38px);
        text-align: left;
        @media (max-width:700px) {
          font-size: 20px;
          line-height: 30px;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
      .btn {
        font-family: $base-font-family;
        @include font-size-rem-adapt(16px);
        line-height: 1.2;
        text-decoration: none;
        &:before {
          border-color: $color-primary; 
        }
        @include desktop {
          position: absolute;
          @include rem((top: 30px, right: 60px));
        }
      }
    }
  }
  .top-info {
    @include rem(margin-top, 10px);
    .col-left, .col-right {
      padding-top: 0;
    }
    .col-left {
      h2 {
        @include rem(margin-bottom, 35px);
        @include font-size-rem-adapt(18px);
        line-height: 1;
      }
      ul {
        li {
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }
      }
    }
    .col-right {
      border: none;
      p.text--gold {
        font-family: $base-font-family;
        @include font-size-rem-adapt(24px);
        @include line-height-adapt(30px);       
      }
      p {
        @include rem(margin-bottom, 30px);
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(25px);
      }
      .btn {
        &:before {
          border-color: $color-primary; 
        }
      }
    }
  }
  .view.view-push-abonnement {
    background: #f1efea;
    .view-content {
      .text {
        @include rem(padding, 60px 20px);
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
      }
      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include desktop {
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
    }
  }
  .view.view-id-push_image .outer {
    background: none;
    text-align: center;
    .inner {
      @include rem(padding, 30px 30px 45px);
      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }
      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;
        .ext {
          display: none;
        }
        &:before {
          background: none;
        }
      }
    }
  }
}
