.view.view-widget-onglets {
  & > .container {
    padding-top: 0;
  }
  h3.title {
    @include rem(margin, 60px 0 30px 0);
    @include desktop {
      margin-top: 0;
      @include rem(margin-bottom, 50px);
    }
  }
  .view-row {
    @include desktop {
      @include rem(margin-bottom, 80px);
    }
    &:last-child {
      margin: 0;
    }
  }
  .tabs-nav {
    margin: 0 !important;
    @include desktop {
      border-right: 1px solid #E6E6E6;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include rem((
        margin-bottom: 35px,
        padding-bottom: 10px
      ));
      border-bottom: 1px solid #E6E6E6;
      @include desktop {
        margin: 0;
        padding: 0;
        border: none;
      }
      li {
        @include rem(margin-bottom, 20px);
        h5 {
          margin: 0;
          font-family: $base-font-family;
          @include font-size-adapt(17px, 17px);
          @include line-height-adapt(20px, 20px);
          color: #504F4F;
          cursor: pointer;
          text-transform: none;
          transition: all 0.3s;
          &:focus, &:hover {
            color: #504F4F;
          }
          &.active {
            color: #504F4F;
            font-weight: 500;
          }
          span {
            display: block;
            //@include rem(margin-top, 10px);
            @include font-size-adapt(12px, 13px);
            @include line-height-adapt(20px, 20px);
          }
        }
      }
    }
  }
  .tab-content {
    display: none;
    @include desktop {
      @include rem(padding, 0 20px);
    }
    &.active {
      display: block;
    }
    .two-columns {
      @include tablet-port {
        display: flex;
        .single-column {
          width: 50%;
        }
      }
      .single-column {

      }
    }
    a:not(.btn):not(.tooltip) {
      color: $color-primary;
      border-bottom: solid 1px;
    }
    p, ul {
      @include rem(margin, 0 0 25px);
      @include font-size-adapt(14px, 17px);
      @include line-height-adapt(22px, 28px);
      @include desktop {
        @include rem(margin, 0 0 20px);
      }
    }
    ul {
      @include rem(padding-left, 20px);
    }
  }
}
