.node-type-hub-famille {

  .top-info {
    .col-left {

      &>h3 {
        margin-top: 0;
      }
    }
  }

  .view-push-image {
    .view-header {
      padding-top: 30px;

      h2 {
        color: #333;
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        font-family: 'Apollon', Arial, sans-s;
      }
    }
  }

  .view-display-id-tiers {
    .row {
      .col {
        &:first-of-type {
          @media (max-width:600px) {
            margin-bottom: 30px;
          }
        }

        .ui-header {
          &>div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0 40px;
            min-height: 100%;
            height: 320px;

            @media (max-width:800px) {
              padding: 0 20px;
              height: auto;
            }
          }
        }

        &+.col {
          .ui-header h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
          }
        }
      }
    }
  }

  .view-display-id-newsletter_famille {
    margin: 0;
    background: #f1efea;

    .view-content {
      .text {
        @include rem(padding, 60px 20px);

        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);
        }

        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }

        .btn {
          color: #fff;

          &:before {
            background: $color-primary;
          }

          .ext {
            display: none;
          }
        }
      }

      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;

        @include desktop {
          margin: 0;
        }

        img {
          margin: 0;
        }
      }
    }
  }

  .view-display-id-ressources {
    .news {
      padding: 1.5625rem 0;
      border-bottom: 1px solid #f1efea;
    }

    h4 {
      font-size: 24px;
      margin-top: 0;
    }
  }

  .view-display-id-faq {
    background-color: #F1EFEA;
    margin-top: 100px;

    .view-header {
      img {
        margin: 0 auto;
        display: block;
        margin-top: -120px;
        margin-bottom: 50px;
        max-width: 448px;
        width: 100%;
      }
    }

    h3 {
      background: #fff;
      margin-bottom: 20px;
      position: relative;
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      user-select: none;
      border: 1px solid #e6e6e6;
      text-transform: none;
      z-index: 2;
      transition: all .3s;
      margin-bottom: 20px;
      font-family: Graphik, serif;

      &:after {
        position: absolute;
        top: 50%;
        right: 40px;
        right: 2.5rem;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 6px 0 6px;
        border-color: #e6e6e6 transparent transparent transparent;
        transform: translateY(-50%);
        transform-origin: 50% 0;
      }

      &.open:after {
        transform: rotate(180deg) translateY(-50%);
      }

      button {
        background-color: transparent;
        outline: 0;
        border: 0;
        width: 100%;
        text-align: left;
        padding: 25px 60px 25px 30px;

        @media (max-width:900px) {
          padding: 20px;
          padding-right: 60px;
          font-size: 14px;
        }

        &:focus {
          outline: 2px solid #000;
        }

      }

      span {
        text-align: left;
        display: block;
      }

      &:focus,
      &:hover {
        background: #000;

        span {
          color: #fff;
        }
      }
    }

    .accordion-panel {
      margin-bottom: 20px;
      position: relative;
      margin-top: -20px;
      padding: 20px 30px;
      padding: 1.25rem;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-top: 0;
      z-index: 1;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    // .views-field-title {
    //   position: relative;
    //   margin: 0;
    //   padding: 20px 60px 20px 20px;
    //   font-size: 14px;
    //   line-height: 20px;
    //   cursor: pointer;
    //   user-select: none;
    //   border: 1px solid #e6e6e6;
    //   text-transform: none;
    //   z-index: 2;
    //   transition: all .3s;
    //   background-color: #fff;
    //   margin-bottom: 20px;

    //   &:after {
    //     position: absolute;
    //     top: 50%;
    //     right: 40px;
    //     right: 2.5rem;
    //     content: '';
    //     width: 0;
    //     height: 0;
    //     border-style: solid;
    //     border-width: 7px 6px 0 6px;
    //     border-color: #e6e6e6 transparent transparent transparent;
    //     transform: translateY(-50%);
    //     transform-origin: 50% 0;
    //   }
    // }

    .views-field-field-faq-reponse {
      display: none;
    }
  }

  .view.view-display-id-block_full {
    .ui-header {
      .subtitle {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        text-transform: none;
        margin-bottom: 5px;
      }

      h2 {
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 10px;
      }
    }
  }

  .view.view-id-push_image .outer {
    background: none;
    text-align: center;

    .inner {
      @include rem(padding, 30px 30px 45px);

      p {
        color: #333333;
        font-family: Graphik, serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        @include rem(margin, 10px 0 30px);
      }

      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;

        .ext {
          display: none;
        }

        &:before {
          background: none;
        }
      }
    }
  }

  .section-activite {
    text-align: center;

    .view-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .activite {
        max-width: calc(100% / 3 - 20px);
        width: 100%;
        border: 1px solid #e6e6e6;
        transition: .3s;

        @media (max-width:800px) {
          max-width: 100%;
          margin-bottom: 30px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &:focus,
        &:hover {
          transition: .3s;
          box-shadow: 0px 0px 6px;
        }

        img {
          padding: 30PX;
          padding-bottom: 10px;
          max-width: 230px;
          height: 200px !important;
        }

        .row-title {
          font-family: 'Apollon', Arial, sans-serif;
          padding-bottom: 30px;
          padding-top: 20px;
          color: #333;
          font-size: 18px;
          margin: 0;
        }
      }
    }

    .view .view-header {
      padding-left: 50px;
      padding-right: 50px;
      text-align: center;

      @media (max-width:600px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      h2 {
        color: #333;
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        font-family: 'Apollon', Arial, sans-s;
      }

      .desc {
        padding-top: 30px;
        max-width: 80%;
        margin: 0 auto;

        p {
          color: #333333;
          font-family: Graphik, serif;
          font-size: 17px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    .link {
      margin-bottom: 20px;
    }

    .section-activite-footer a:hover {
      text-decoration: underline;
    }
  }

  .section-activite-footer {
    p {
      color: #666666;
      font-size: 17px;
      line-height: 26px;
    }
  }
}

.view-billets-tarifs,
.view.view-billets-tarifs-mdv {
  .view-content {
    h3 {
      &.section-title {
        text-align: left;
        margin-top: -30px;
        padding-top: 50px;
      }
    }

    .ticket {
      padding: 30px;

      .row {
        min-height: auto !important;

        .col-xs-12 {
          margin-bottom: 0px;
        }
      }

      h4 {
        font-family: 'Apollon', Arial, sans-serif;
        font-weight: 400;
        font-size: 20px;
        text-transform: lowercase;
        line-height: 26px;
        margin-bottom: 20px;
      }

      .read-more-content {
        .read-more-content-top {
          color: #333;
          line-height: 20px;
          font-size: 14px;

          p {
            margin: 0 0 10px 0;
            color: #333;
            line-height: 20px;
            font-size: 14px;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        .read-more-content-bottom {
          display: none;
          color: #333;
          line-height: 20px;
          font-size: 14px;

          p {
            margin: 0 0 10px 0;
            color: #333;
            line-height: 20px;
            font-size: 14px;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        p {
          line-height: 20px;
          font-size: 14px;
        }

        ul {
          li {
            a {
              margin-top: 0;
            }
          }
        }

        a.more,
        a.close {
          display: inline-block;
          margin-top: 10px;
          color: #ba9e66;
          border-bottom: solid 1px;
          font-size: 14px;
          line-height: 20px;
        }

        +.row {
          margin-top: 30px;
        }
      }

      .lieu-duree {
        font-size: 14px;
        line-height: 20px;

        .col-xs-12 {
          .text--gray {
            margin-bottom: 5px;
            display: block;
          }

          p {
            line-height: 22px;
          }

          a {
            border: 0 !important;

            &:hover {
              text-decoration: underline;
            }
          }

          &.col-md-8 {
            p {
              max-width: 80%;
            }
          }
        }
      }
    }

    .billet {
      margin-bottom: 10px !important;

      .btn-group {
        margin-bottom: 10px;
      }

      .text-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
          color: #9b9b9b;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          width: 100%;
        }
      }
    }

    .view-accessibilite {
      margin-top: 0px !important;
    }
  }
}

.logged-in {

  .view-billets-tarifs,
  .view.view-billets-tarifs-mdv {
    .view-content {
      h3 {
        &.section-title {
          text-align: left;
          margin-top: -30px;
          padding-top: 80px;
        }
      }
    }
  }
}
