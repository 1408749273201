.view.view-billets-tarifs {
  .view-filters {
    .select-a11y {
      display: none;
    }
  }
}

.jsdatepicker {
  height: 66px;
  max-width: 530px;
  margin: 80px auto 30px;
  background: #fff;
  border: 1px solid #e6e6e6;
  vertical-align: middle;
  padding:20px;
  display:flex;
  align-items:center;
  position:relative;
  @media (max-width: 700px) {
    width: calc(100% - 100px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    @include rem(right, 15px);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    opacity: 0.4;
  }
  .icon-calendair {
    position: relative;
    z-index: 4;
    top:-2px;
    &:before {
      background-image: url(/sites/all/themes/versailles/images/clock_min.svg);
      height: 16px;
      width: 16px;
      content:''; 
      display:block;
    }
  }

  input {

    max-width: 530px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @include rem(padding, 0 30px 0 50px);
    @include font-size(17px);
    line-height: 1.4;
    color: transparent;
    text-shadow: 0 0 0 #666;
    border: 0;
    cursor: pointer;
    font-size: 15px !important;
    text-shadow: none !important;
    color: #333 !important;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.view.view-billets-tarifs,
.view.view-billets-tarifs-mdv {
  border-bottom: 1px solid #E6E6E6;

  @media (max-width:700px) {
    border-bottom: none;
  }

  .view-filters {
    @include rem(margin-bottom, 60px);

    .nav-bar {
      max-height: 85px;
      background-color: #f1efea;

      @media only screen and (max-width: 1100px) and (min-width: 700px) {
        padding-bottom: 0;
        max-height: 85px;
      }
    }
  }

  .nav-bar:not(.nav-bar--inverted) {

    @include desktop {
      padding: 0;
    }

    .views-exposed-widgets {

      &:before,
      &:after {
        display: none;
      }
    }

    .form-type-select {

      @media only screen and (max-width: 1100px) and (min-width: 700px) {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        padding: 0;

        select {
          display: none !important;
        }
      }
    }

    .form-type-bef-link {
      @include rem(margin-right, 35px);

      @media only screen and (max-width: 1100px) and (min-width: 700px) {
        @include rem(margin-right, 20px);
      }

      display: inline-block !important;

      a {
        display: block;
        color: #504F4F !important;
        @include rem(padding, 28px 0 24px);
        @include rem(line-height, 28px);
        text-decoration: none !important;
        border-bottom: 4px solid transparent;

        &.active {
          color: #000 !important;
          border-bottom-color: #000;
        }
      }
    }
  }

  .views-exposed-form {
    .date {
      position: relative;
      display: block;
      background: #fff;
      @include rem((height: 62px,
          padding: 20px,
          margin-bottom: 30px)) border: 1px solid #e6e6e6;
      vertical-align: middle;

      @include desktop {
        display: inline-block;
        margin-bottom: 0;
        @include rem((margin-right: 20px,
            width: 190px));
      }

      i {
        position: relative;
        color: #ba9e66;
        z-index: 4;
        top: 1px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        @include rem(right, 15px);
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        opacity: 0.4;
      }

      .bef-datepicker {

        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        // margin: 0;
        // @include rem(padding, 0 30px 0 50px);
        // @include font-size(17px);
        // line-height: 1.4;
        // color: transparent;
        // text-shadow: 0 0 0 #666;
        // border: 0;
        // cursor: pointer;
      }
    }

    .form-radios {
      display: block;

      .form-type-radio {
        display: block;
        margin: 0;
        @include rem(margin-bottom, 20px);

        &:last-child {
          margin-bottom: 0;
        }

        @include desktop {
          display: inline-block;
        }
      }

      @include desktop {
        display: inline-block;
        vertical-align: middle;

        .form-type-radio {
          margin-bottom: 0;
          @include rem(margin-right, 20px);

        }
      }

    }
  }

  .visit-description {

    .row {
      display: none;
      @include rem((padding-top: 35px,
          padding-bottom: 0));

      &.visible {
        display: flex;
      }

      .col {
        @include rem(margin-bottom, 30px);

        @include desktop {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a:not(.tooltip):not(.btn) {
          color: $color-primary;
          border-bottom: solid 1px;
        }
      }
    }

    .block-offer {
      @include rem(margin-top, 40px);
      padding: 0;

      .inner {
        position: relative;
        @include rem(padding, 40px 20px);
        background: #F1EFEA;

        @include desktop {
          @include rem(padding, 30px 40px);
        }
      }

      .row {
        display: flex;
        padding: 0;
      }

      .text {
        @include desktop {
          @include rem(padding-left, 160px);
        }

        h4 {
          @include rem(margin, 0 0 10px);
          @include font-size-adapt(20px, 24px);
          @include rem(line-height, 26px);

          @include desktop {
            @include rem(margin, 0 0 5px);
          }
        }

        p {
          margin: 0;
          color: $color-primary;
          font-family: $font-crimson;
          @include font-size-adapt(16px, 20px);
        }
      }

      .button {
        text-align: right;
      }

      img {
        display: none;
        position: absolute;
        top: 50%;
        @include rem(left, 30px);
        transform: translateY(-50%);

        @media (min-width:1100px) {
          display: block;
        }
      }
    }

    p.icon {
      position: relative;
      @include rem(padding-left, 40px);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include rem((width: 26px,
            height: 26px));
        border-radius: 50%;
        z-index: 1;
      }

      &:before {
        position: absolute;
        @include rem((top: 7px,
            left: 7px,
            width: 12px,
            height: 12px));
        @include font-size(12px);
        line-height: 1;
        color: #fff;
        z-index: 2;
      }

      &.icon-checked {
        &:after {
          background: #48D668;
        }
      }

      &.icon-fountain {
        &:after {
          background: #7293CB;
        }
      }

      &+ul {
        @include rem(margin-top, -20px);
        @include rem(padding-left, 60px);
      }
    }

    p,
    ul {
      @include rem(margin, 0 0 25px);

      &:last-child {
        margin: 0;
      }
    }

    p,
    li {
      color: #666;
      @include font-size(17px);
      @include rem(line-height, 21px);
    }

    ul {
      @include rem(padding-left, 20px);
    }
  }

  .ticket {
    @include rem(margin-bottom, 40px);
    @include rem(padding, 35px 25px);
    border: 1px solid #e6e6e6;

    .row {

      @include rem((margin-left: -10px, margin-right: -10px)) @include desktop {
        @include rem(min-height, 220px);
      }

      [class^="col-"] {
        @include rem((margin-bottom: 20px,
            padding-left: 10px,
            padding-right: 10px))
      }
    }

    &.withimage {
      .billet_content {

        @include desktop {
          @include rem(padding-left, 170px);
        }

        img {
          display: none;

          @include desktop {
            display: block;
          }
        }
      }
    }

    .billet_content {
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    h4 {
      @include rem(margin, 0 0 10px);
      color: #262626;
      font-family: $base-font-family;
      @include font-size-adapt(20px, 24px);
      text-transform: none;

      @include desktop {
        @include rem(margin, 0 0 5px);
      }
    }

    p {
      @include rem(margin-bottom, 10px);
      line-height: 20px;
      font-size: 14px;

      &:last-child {
        margin: 0;
      }

      a:not(.tooltip):not(.btn) {
        color: $color-primary;
        border-bottom: solid 1px;
      }

      &.uppertitle.text--gold {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 10px 0;
      }
    }

    ul a:not(.tooltip):not(.btn) {
      border-bottom: solid 1px;
    }

    ul {
      margin-left: 0;
      padding-left: 20px;
    }

    li {
      line-height: 20px;
    }

    .billet {
      @include rem(margin-bottom, 20px);

      &.right {
        text-align: right;

        .btn-group {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          position: relative;

          @include desktop {
            display: inline-block;
            @include rem(min-width, 150px);
          }

          .selectric-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 100%;
            @include rem(min-width, 150px);

            @include desktop {
              @include rem(min-width, 200px);
            }

            .selectric {
              width: 100%;
              color: $color-primary;
              border-color: $color-primary;
              text-transform: uppercase;

              &:after {
                border-top-color: $color-primary;
              }
            }

            .selectric-items {
              padding: 0;
              width: auto;
              @include rem((left: 0,
                  right: 0));
            }
          }

          &>.btn {

            @include rem(min-height, 60px);

            @include desktop {
              float: none;
              @include rem(margin-left, -4px);

              &:first-child {
                margin-left: 0;
              }
            }

          }
        }

        label {
          display: none;
        }

        .select-a11y {
          margin-top: 20px;
          margin-bottom: 20px;
          max-width: 240px;
          margin-left: auto;
        }
      }

      &:last-child {
        margin: 0;
      }

      p {
        position: relative;
        @include rem(margin, 0 0 10px);
        @include font-size(19px);
        line-height: 1.3rem;
        text-align: right;

        &.text-top {
          @include font-size(12px);
        }
      }

      p.tooltip {
        position: relative;
        display: block;
        color: inherit;
        height: 20px;
        line-height: 18px;
        @include font-size(12px);
        @include rem(margin-bottom, 10px);
        @include rem(padding-right, 30px);
        padding-bottom: 0;
        background: none;

        &:after {
          display: block;
          content: '?';
          @include font-size(16px);
          @include rem((width: 20px,
              height: 20px,
            ));
          line-height: 20px;
          text-align: center;
          background: #f1efea;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          @include rem(margin-top, -10px);
        }
      }

      .btn-group {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        position: relative;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;

          @include desktop {
            display: inline-block;
            width: auto;
          }
        }

        span.price {
          width: 40%;
          margin: 0;
          @include rem(min-height, 60px);
          padding-left: 0;
          padding-right: 0;
          @include font-size(24px);
          @include rem(padding, 15px 10px 14px);

          @include desktop {
            @include rem(width, 115px);
          }

          &:after {
            display: none !important;
          }
        }

        a.btn {
          width: 60%;
          margin: 0;

          @include desktop {
            width: auto;
          }
        }
      }

      .text-bottom {
        text-align: right;

        p {
          display: inline-block;
          @include rem(max-width, 250px);
          @include font-size(14px);
          line-height: 1.1rem;
          line-break: anywhere;
        }
      }
    }

    .view-accessibilite {
      @include rem(margin-top, 10px);

      ul {
        text-align: right !important;
        margin-left: auto;
        margin-bottom: 0;

        @media (max-width:700px) {
          margin-left: auto;
        }
      }
    }
  }
}

.view-id-widget_billet,
.view-id-espace_enseignants {
  .uppertitle {
    color: $color-primary;
  }

  .selectric-wrapper {
    width: 100%;
    border-color: $color-primary;

    .selectric {
      width: 100%;
      border-color: $color-primary;
    }
  }

  .read-more-content {
    &+.row {
      @include rem(margin-top, 10px);

      p {
        @include rem(margin, 5px 0);
      }
    }

    .read-more-content-bottom {
      display: none;

      a {
        margin: 0;
      }
    }

    a {
      display: inline-block;
      @include rem(margin-top, 20px);
    }
  }

  .text-bottom {
    display: block;
    margin: 0;
    @include rem(margin, 10px 0);
    color: #9B9B9B;
    @include font-size-adapt(14px);
    @include line-height-adapt(20px);
    text-align: right;

    p {
      color: inherit;
      font-size: inherit;
      margin: 0;
      line-height: inherit;
      word-break: break-all;
    }
  }

  .view-accessibilite ul {
    text-align: right;
  }
}