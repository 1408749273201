.node-hub-decouvrir {
  .intro {
    margin: 0 auto;
    @include rem(padding-top, 45px);
    @include rem(max-width, 720px);

    @include desktop {
      @include rem(padding-top, 60px);
    }

    h3.title {
      @include font-size-adapt(20px, 24px);
      @include rem(margin, 0 0 10px);
      text-align: left;

      @include desktop {
        text-align: center;
      }
    }

    p {
      margin: 0;
      @include font-size-adapt(18px, 24px);
      line-height: 1.4;
      text-align: left;

      @include desktop {
        text-align: center;
      }
    }
  }

  .quote-wrapper {
    @include rem(margin, 60px auto);
    @include rem(max-width, 570px);
    text-align: center;

    @include tablet {
      @include rem(margin, 120px auto);
    }

    p.quote-text {
      font-size: 24px;
      line-height: 1.4;
    }

    blockquote {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: normal;

      p.quote-text {
        font-size: 24px;
        line-height: 1.4;
      }
    }

    figcaption.quote-author {
      color: #ba9e66;
      text-transform: uppercase;
    }

    p.quote-text {
      margin: 0;
      font-family: $heading-font-family;
      @include font-size-adapt(22px, 24px);
      @include line-height-adapt(35px);
    }

    p.quote-author {
      @include rem(margin, 40px 0 0 0);
      color: $brown;
      @include font-size-adapt(16px);
      text-transform: uppercase;

      @include tablet {
        display: block;
      }
    }
  }

  .carousel-popup .slick-slider button.slick-arrow.slick-prev,
  .carousel-popup .slick-slider button.slick-arrow.slick-next {
    display: block !important;
  }
}
