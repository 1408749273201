@charset "UTF-8";

@font-face {
  font-family: "versailles";
  src: url("../fonts/versailles-2.eot");
  src: url("../fonts/versailles-2.eot?#iefix") format("embedded-opentype"),
    url("../fonts/versailles-2.woff") format("woff"),
    url("../fonts/versailles-2.ttf") format("truetype"),
    url("../fonts/versailles-2.svg#versailles") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "versailles" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "versailles" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendair:before { content: '\e800'; } /* '' */
.icon-checked:before { content: '\e801'; } /* '' */
.icon-clock:before { content: '\e802'; } /* '' */
.icon-font:before { content: '\e803'; } /* '' */
.icon-marker:before { content: '\e804'; } /* '' */
.icon-flickr-1:before { content: '\e805'; } /* '' */
.icon-phone:before { content: '\e806'; } /* '' */
.icon-media-1:before { content: '\e807'; } /* '' */
.icon-share:before { content: '\e808'; } /* '' */
.icon-print:before { content: '\e809'; } /* '' */
.icon-ticket:before { content: '\e80a'; } /* '' */
.icon-twitter-1:before { content: '\e80b'; } /* '' */
.icon-twitter:before { content: '\e80c'; } /* '' */
.icon-fountain:before { content: '\e80d'; } /* '' */
.icon-accessibilite-260:before { content: '\e80e'; } /* '' */
.icon-accessibilite-17:before { content: '\e80f'; } /* '' */
.icon-accessibilite-261:before { content: '\e810'; } /* '' */
.icon-accessibilite-18:before { content: '\e811'; } /* '' */
.icon-arrow-long:before { content: '\e812'; } /* '' */
.icon-weahter-14:before { content: '\e813'; } /* '' */
.icon-weather-4:before { content: '\e814'; } /* '' */
.icon-weather-5:before { content: '\e815'; } /* '' */
.icon-weather-6:before { content: '\e816'; } /* '' */
.icon-weather-8:before { content: '\e817'; } /* '' */
.icon-weather-7:before { content: '\e818'; } /* '' */
.icon-weather-9:before { content: '\e819'; } /* '' */
.icon-weather-11:before { content: '\e81a'; } /* '' */
.icon-weather-13:before { content: '\e81b'; } /* '' */
.icon-weather-10:before { content: '\e81c'; } /* '' */
.icon-weather-14:before { content: '\e81d'; } /* '' */
.icon-weather-17:before { content: '\e81e'; } /* '' */
.icon-weather-12:before { content: '\e81f'; } /* '' */
.icon-weather-15:before { content: '\e820'; } /* '' */
.icon-loop:before { content: '\e821'; } /* '' */
.icon-weather-16:before { content: '\e822'; } /* '' */
.icon-wechat-1:before { content: '\e823'; } /* '' */
.icon-train:before { content: '\e824'; } /* '' */
.icon-googleplus:before { content: '\e825'; } /* '' */
.icon-weather-1:before { content: '\e826'; } /* '' */
.icon-weather-2:before { content: '\e827'; } /* '' */
.icon-weather-3:before { content: '\e828'; } /* '' */
.icon-youtube:before { content: '\e829'; } /* '' */
.icon-flickr:before { content: '\e82a'; } /* '' */
.icon-facebook:before { content: '\e82b'; } /* '' */
.icon-googleplus-1:before { content: '\e82d'; } /* '' */
.icon-instagram:before { content: '\e82e'; } /* '' */
.icon-wechat:before { content: '\e82f'; } /* '' */
.icon-cart:before { content: '\e830'; } /* '' */
.icon-media:before { content: '\e831'; } /* '' */
.icon-youtube-1:before { content: '\e833'; } /* '' */


a:focus .icon-facebook-1,
a:focus .icon-facebook,
.icon-facebook:hover,
.icon-facebook-1:hover {
  color: #3B5998 !important;
}

a:focus .icon-facebook path,
svg.icon-facebook:hover path {
  fill: #3B5998;
}

a:focus .icon-twitter,
a:focus .icon-twitter-1,
.icon-twitter:hover,
.icon-twitter-1:hover {
  color: #55ACEE !important;
}

a:focus .icon-twitter path,
svg.icon-twitter:hover path {
  fill: #55ACEE;
}


a:focus .icon-instagram,
a:focus .icon-instagram-1,
.icon-instagram:hover,
.icon-instagram-1:hover {
  color: #833AB4 !important;
}

a:focus .icon-instagram path,
svg.icon-instagram:hover path {
  fill: #833AB4;
}

a:focus .icon-youtube,
a:focus .icon-youtube-1,
.icon-youtube:hover,
.icon-youtube-1:hover {
  color: #CD201F !important;
}

a:focus .icon-youtube path,
svg.icon-youtube:hover path {
  fill: #CD201F;
}


a:focus .icon-wechat,
a:focus .icon-wechat-1,
.icon-wechat:hover,
.icon-wechat-1:hover {
  color: #09B83E !important;
}

a:focus .icon-wechat path,
svg.icon-wechat:hover path {
  fill: #7bb32e;
}

a:focus .icon-flickr,
a:focus .icon-flickr-1,
.icon-flickr:hover,
.icon-flickr-1:hover {
  color: #FF48A3 !important;
}

a:focus .icon-media,
a:focus .icon-media-1,
.icon-media:hover,
.icon-media-1:hover {
  color: $color-primary !important;
}


.front header#header .social-icons a:last-of-type {
  &:focus {
    svg path {
      fill: #ba9e66;
    }
  }
}
