.js-calendar {
	border: 1px solid #d8d8d8;

	.view-header,
	.calendar-header {
		@include rem(padding, 0 30px);
		text-align: center;
		background: #e6e6e6;

		.date-header-wrapper {
			position: relative;
			margin: 0;
			padding: 0;
			text-align: center;
			border-bottom: 1px solid #d8d8d8;
			.date-nav,
			.date-heading {
				position: relative;
				display: inline-block;
				margin: 0;
				padding: 0;
				width: auto;
			}
			.pager {
				padding: 0;
				position: static;
			}
			h3 {
				display: inline-block;
				color: #333;
				font-family: Apollon, Arial, sans-serif;
				@include rem(margin, 15px 0);
				@include rem(padding, 0 20px);
				// @include font-size-adapt(20px, 24px);
				font-size: 1em;
				font-weight: 700;
				line-height: 1;
				// @include line-height-adapt(24px);
			}
			.date-prev,
			.date-next {
				margin-top: -6px;
				display: inline-block;
				vertical-align: middle;
				z-index: 1;
				border: none;
				background: none;
				padding: 10px;
				span {
					display: block;
					margin: 0;
					padding: 0;
					min-width: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 10px 5px 0;
					border-color: transparent #000 transparent transparent;
					line-height: 1;
					text-indent: -99999px;
					background: none;
				}
			}
			.date-next {
				position: absolute;
				right: 0;
				margin-top: 0;
				top: 6px;
				span {
					border-width: 5px 0 5px 10px;
					border-color: transparent transparent transparent #000;
				}
			}
			.date-prev {
				position: absolute;
				left: 0;
				margin-top: 0;
				top: 6px;
			}
		}
	}

	.calendar-data {
		caption{
			margin:0;
			font-size: 16px;

		}
		.month-view {
			border: 0;
			width: 100%;
			th.days {
				@include rem(padding, 0);
				color: #333;
				@include font-size-adapt(16px);
				@include line-height-adapt(50px);
				font-weight: normal;
				background: #e6e6e6;
				border: none;
				text-align: center;
				border-bottom: 10px solid #fff;
				@include desktop {
					@include rem(padding, 0 15px);
				}
				&:first-child {
					@include rem(padding-left, 0);
					@include desktop {
						@include rem(padding-left, 30px);
					}
				}

				&:last-child {
					@include rem(padding-right, 0);
					@include desktop {
						@include rem(padding-right, 30px);
					}
				}

				span {
					display: block;
					min-width: 40px;
					text-decoration: none;
				}
			}

			tbody td {
				@include rem(padding, 0);
				color: #8c8d93;
				@include font-size-adapt(16px);
				@include rem(line-height, 40px);
				font-weight: normal;
				text-align: center;
				border: none;
				background: none !important;
				@include desktop {
					@include rem(padding, 0 15px);
				}
				&:first-child {
					@include rem(padding-left, 0);
					@include desktop {
						@include rem(padding-left, 30px);
					}
				}

				&:nth-child(7) {
					@include rem(padding-right, 0);
					@include desktop {
						@include rem(padding-right, 30px);
					}
				}

				&.cal-ferme a {
					background-color: rgba(204, 204, 204, 0.8);
					&:focus, &:hover {
						background-color: rgba(204, 204, 204, 1);
						font-weight: 500;
					}
				}

				&.cal-faible a {
					background-color: rgba(74, 144, 226, 0.3);
					&:focus, &:hover {
						background-color: rgba(74, 144, 226, 0.7);
						font-weight: 500;
					}
				}

				&.cal-moyenne a {
					background-color: rgba(79, 212, 109, 0.3);
					&:focus, &:hover {
						background-color: rgba(79, 212, 109, 0.7);
						font-weight: 500;
					}
				}

				&.cal-soutenue a {
					background-color: rgba(245, 166, 35, 0.3);
					&:focus, &:hover {
						background-color: rgba(245, 166, 35, 0.7);
						font-weight: 500;
					}
				}

				&.cal-importante a {
					background-color: rgba(208, 6, 31, 0.3);
					&:focus, &:hover {
						background-color: rgba(208, 6, 31, 0.5);
					}
				}

				a,
				span {
					display: block;
					min-width: 40px;
					line-height: 40px;
					text-decoration: none;
				}

				a {
					color: #000;
				}

				span {
					color: rgba(#979797, 0.5) !important;
				}
			}
		}
	}

	.calendar-footer {
		margin-top: 15px;
		border-top: 1px solid #d8d8d8;
		display: flex;
		flex-direction: column;
		@include rem(padding, 20px);
		@include desktop {
			flex-direction: row;
		}
		p {
			margin: 0 20px 0 0;
			@include font-size-adapt(14px);
			color: #666666;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				@include rem(margin-right, 20px);
				@include font-size-adapt(14px);
				line-height: 1;
				color: #666666;
				background: none !important;
				&:last-child {
					margin-right: 0;
				}

				&.cal-faible,
				&.cal-moyenne,
				&.cal-soutenue,
				&.cal-importante,
				&.cal-ferme {
					position: relative;
					@include rem(padding-left, 13px);
					&:after {
						content: "";
						display: block;
						position: absolute;
						top: 50%;
						left: 0;
						@include rem(
							(
								width: 8px,
								height: 8px,
							)
						);
						border-radius: 50%;
						transform: translateY(-50%);
					}
				}
				&.cal-faible:after {
					background-color: rgba(#4a90e2, 0.3);
				}

				&.cal-moyenne:after {
					background-color: rgba(#4fd46d, 0.3);
				}

				&.cal-soutenue:after {
					background-color: rgba(#f5a623, 0.3);
				}

				&.cal-importante:after {
					background-color: rgba(#d0061f, 0.3);
				}
				&.cal-ferme:after {
					background-color: rgba(204, 204, 204, 0.8);
				}
			}
		}
	}
}

#agenda-box {
	position: relative;
	@include rem(margin-top, 40px);
	@include rem(padding, 50px 30px);
	border: 1px solid #d8d8d8;
	@include tablet-port {
		margin-top: 0;
		position: absolute;
		top: 0;
		@include rem(right, 1px);
		@include rem(width, 286px);
		height: 100%;
	}
	.header {
		margin: 0;
		position: absolute;
		top: 0;
		@include rem(
			(
				left: 30px,
				right: 30px,
			)
		);
		@include font-size-adapt(16px);
		@include line-height-adapt(50px);
		text-align: center;
		border-bottom: 1px solid #d8d8d8;
		i {
			display: inline-block;
			@include rem(
				(
					width: 20px,
					height: 20px,
					margin-right: 10px,
				)
			);
			@include font-size(20px);
			line-height: 1;
			vertical-align: middle;
			cursor: help;
		}
		.weather {
			display: inline-block;
			vertical-align: middle;
			cursor: help;
		}
	}

	.box-content {
		position: relative;
		@include rem(padding, 40px 0);
		width: 100%;
		@include desktop {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			padding: 0;
			transform: translateY(-70px);
		}

		span {
			display: block;
			text-align: center;
			@include font-size-adapt(16px);
			@include line-height-adapt(19px);
			font-family: $heading-font-family;

			&.day {
				@include rem(margin, -15px 0 -30px);
				@include font-size-adapt(100px);
				@include line-height-adapt(124px);
			}
		}
	}

	#agenda-weekday {
		margin-bottom: 10px;
	}

	#agenda-month {
		margin-top: 10px;
	}

	.chateau {
		margin: 0;
		position: absolute;
		@include rem(
			(
				left: 30px,
				right: 30px,
				bottom: 30px,
			)
		);
		color: #979797;
		@include font-size-adapt(13px);
		line-height: 1;
		text-align: center;

		span.hours {
			@include rem(margin-bottom, 10px);
			color: #666666;
			@include font-size(14px);
			@include rem(line-height, 20px);

			&.closed {
				color: #d0021b;
			}
		}

		span.grandes-eaux {
			position: relative;
			display: inline-block;
			@include rem(line-height, 16px);
			@include rem(padding-left, 25px);
			vertical-align: top;

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				@include rem(
					(
						margin-top: -10px,
						width: 20px,
						height: 20px,
					)
				);
				color: #7293cb;
				@include font-size(20px);
				line-height: 1;
			}
		}

		span.attendance {
			position: relative;
			display: inline-block;
			@include rem(line-height, 16px);
			@include rem(padding-left, 20px);
			vertical-align: top;
			cursor: help;

			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				@include rem(
					(
						margin-top: -8px,
						width: 16px,
						height: 16px,
					)
				);
				border: 2px solid transparent;
				opacity: 50%;
				border-radius: 50%;
			}

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				@include rem(
					(
						margin-top: -3px,
						width: 6px,
						height: 6px,
						left: 5px,
					)
				);
				border-radius: 50%;
			}

			&.cal-faible {
				background: none;

				&:before {
					border-color: #7293cb;
				}

				&:after {
					background: #7293cb;
				}
			}

			&.cal-soutenue {
				background: none;

				&:before {
					border-color: #eaa673;
				}

				&:after {
					background: #eaa673;
				}
			}

			&.cal-moyenne {
				background: none;

				&:before {
					border-color: #4fd46d;
				}

				&:after {
					background: #4fd46d;
				}
			}

			&.cal-importante {
				background: none;

				&:before {
					border-color: #d0021b;
				}

				&:after {
					background: #d0021b;
				}
			}
		}
	}
}
