.node.node-enseignants-visites-activites,
.node.node-relais-visites-activites {
  .breadcrumbs {
    @include rem((
      padding: 25px 0 0,
      margin: 0 0 30px
    ));
    border-bottom: none;
  }
  .intro {
    @include desktop {
      padding-top: 0;
    }
    p {
      font-family: $base-font-family !important;
      @include font-size-adapt(17px);
      @include line-height-adapt(26px);
      @media (max-width:700px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .text--gold {
    color: $color-primary;
  }
  .bloc-visites {
    @include rem(margin-top, 60px);
    @media (max-width:700px) {
      margin-top: 20px;
    }
    background: #F1EFEA;
    overflow: hidden;
    h2 {
      @include rem(margin, 60px 0 50px);
      text-align: center;
      @media (max-width:700px) {
        @include rem(margin, 30px 0 20px);
        text-align: left;
      }
    }
    .views-exposed-widgets {
      @include rem(margin-bottom, 20px);
    }
    .ctools-auto-submit-full-form {
      .form-radios {
        display: flex;
        flex-wrap: wrap;
        @include rem(margin, 0 -25px);
        .form-radio {
          &:checked + label {
            .term-wrapper {
              box-shadow: 3px 6px 27px 0 rgba(0,0,0,0.25);
            }
          }
        }
        label:before, label:after {
          display: none;
        }
        .views-exposed-widget {
          position: absolute;
          top: 100%;
          left: 100%;
          visibility: hidden;
        }
        .form-item {
          display: flex;
          position: relative;
          width: 100%;
          margin: 0;
          @include rem(padding, 0 25px 40px);
          overflow: hidden;
          @include desktop {
            max-width: 20%;
          }
          .option {
            display: flex;
            width: 100%;
          }
          .term-wrapper {
            position: relative;
            width: 100%;
            @include rem(min-height, 245px);
            @include rem(padding, 25px 10px 50px);
            text-align: center;
            background: #fff;
            transition: all 0.3s;
            cursor: pointer;
            @media (max-width:700px) {
              min-height: unset;
              @include rem(padding, 10px);
            }
            &:focus, &:hover {
              box-shadow: 3px 6px 27px 0 rgba(0,0,0,0.25);
            }
            .term-svg {
              img {
                margin: 0 auto;
                @include rem(max-width, 100px);
                height: auto;;
              }
            }
            .term-name {
              color: #000;
              font-family: $heading-font-family;
              @include font-size-adapt(22px);
              @include line-height-adapt(24px);
            }
            .term-subtitle {
              position: absolute;
              left: 0;
              margin: 0;
              @include rem(bottom, 25px);
              width: 100%;
              padding: 0;
              color: #9B9B9B;
            }
          }
        }
      }
    }
  }
  .view.view-espace-enseignants,
	.view.view-espace-relais{
    background: none;
    > .container {
      padding: 0;
    }
    .col-md-4 {
      .ui-header {
        h3 {
          @include rem(margin-bottom, 16px);
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(30px);  
        }
      }
    }
    .ui-header {
      @include rem(margin-bottom, 80px);
      @include rem(padding, 40px);
      justify-content: flex-end;
      @media (max-width:700px) {
        @include rem(margin-bottom, 30px);
        @include rem(padding, 20px);
      }
    }
  }
  .view.view-push-abonnement {
    margin: 0;
    background: #f1efea;
    .view-content {
      .text {
        @include rem(padding, 60px 20px);
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
      }
      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include desktop {
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
    }
  }
}

.node.node-relais-visites-activites {
  .bloc-visites{
	.views-exposed-form{
		.form-radios > div:first-of-type{
			display:none;
		}
	}
  }
  .intro{
    .col-right{
      ul{
        li{
          color: #666;			
          @include font-size-rem-adapt(14px);
          @include line-height-adapt(20px);  
          @include rem(margin, 0 0 10px 0);
        }
      }
    }
  }
  bloc-visites{
    .term-wrapper {
      @include rem(padding, 25px 10px 25px);
    }
  }

  .view.view-id-push_image .outer{
    background: none;
    text-align: center;
    .inner {
      @include rem(padding, 30px 30px 45px);
      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }
      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;
        .ext {
          display: none;
        }
        &:before {
          background: none;
        }
      }
    }
  }
 
 
	.text-content{
		@include rem(margin, 50px 0 0 0);
		h2{
			border-bottom: none;
		}
	}

 
}
