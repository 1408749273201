.node.node-cycle-abonne {
  .breadcrumbs {
    border-bottom: none;
  }
  .text--lead {
    @include font-size-rem-adapt(22px);
    @include line-height-adapt(32px);
    border: none;
  }


  .top-info {
    margin: 0;
    @include rem(padding, 40px 0);
    background: #F1EFE9;
    h2.title {
      @include rem(margin-bottom, 45px);
    }

    .col-md-7  {
      p {
        @include rem(margin-left, 20px);
      }
    }

    .col-md-5 {
      p {
        @include rem(margin-bottom, 30px);
        color: #333333;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(25px);    
        &.text--gold {
          @include rem(margin-bottom, 20px);
          font-family: $base-font-family;
          color: $color-primary;
          @include font-size-rem-adapt(22px);
          @include line-height-adapt(32px);        
        }
      }
      .btn {
        &:before {
          border-color: $color-primary;
        }
      }
    }
  }






  .view-espace-abonnes.view-id-espace_abonnes.view-display-id-cycle {
    .view-content {
      @include rem(padding, 40px 0 48px);
      h2.title {
        @include rem(margin, 0 0 35px);
      }
      img {
        display: block;
        width: 100%;
        height: auto;
        @include rem(margin-bottom, 35px);
      }
      .ticket {
        position: relative;
        @include rem(margin-bottom, 35px);
        @include rem(padding, 30px 20px);
        border: 1px solid #E6E6E6;
        @media (max-width: 700px) {
          .row{
            .col-xs-12{
              margin:0;
            }
          }
        }

        .select-a11y{
          margin-top: 20px;
          margin-bottom: 0;
          max-width: 240px;
          margin-left: auto;
          @media (max-width: 700px) {
            max-width: unset;
          }
        }
        .btn-group{
          padding-top: 20px;
        }
        .billet label{
          display: none;
        }

        &.class--full {
          @include rem(padding, 60px 20px 40px 20px);
          @include tablet {
            @include rem(padding, 60px 20px 40px 55px);
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            pointer-events: none;
          }
          & > .row {
            opacity: 0.7;
          }
          * {
            pointer-events: none;
          }
          .read-more-content a {
            pointer-events: auto;
          }
          p.title, span.text--gray, p.field-content, .read-more-content, .read-more-content a {
            color: #333 !important;
          }
        }
        p.full {
          position: absolute;
          margin: 0;
          @include rem((top: 25px, left: 55px, padding: 5px));
          color: #D0021B;
          @include font-size-rem-adapt(14px);
          @include line-height-adapt(14px);
          text-transform: uppercase;
          border: 1px solid #D0021B;
        }
        p.title {
          @include rem(margin, 0 0 15px);
          color: #010101;
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(32px);
        }
        p {
          color: #333333;
          font-size: 16px;
          line-height: 25px;
        }
        .read-more-content {
          .read-more-content-top{
            a{
              display: inline-block;
              margin-top: 20px;
            }
          }
          .read-more-content-bottom{
            display: none;
          }
        }
        .price {
          &:focus, &:hover {
            color: inherit;
            &:after {
              display: none;
            }
          }
        }
        .billet {
          text-align: right;
          p.tooltip {
            position: relative;
            display: block;
            color: inherit;
            height: 20px;
            @include font-size-rem-adapt(12px);
            @include line-height-adapt(20px);
            @include rem(margin-bottom, 16px);
            @include rem(padding-right, 30px);
            padding-bottom: 0;
            background: none;
            &:after {
              display: block;
              content: '?';
              @include font-size-rem-adapt(16px);
              @include rem((
                width: 20px,
                height: 20px,
              ));
              line-height: 20px;
              text-align: center;
              background: #f1efea;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              right: 0;
              @include rem(margin-top, -10px);
            }
          }
        }
        .selectric-wrapper {
          width: 100%;
          border-color: $color-primary;
          @include tablet {
            @include rem(width, 205px);
          }
          .selectric {
            width: 100%;
            border-color: $color-primary;
          }
        }
        .btn-group {
          display: flex;
          margin-left: auto;
          margin-right: 0;
          @include rem(margin-bottom, 10px);
          width: 100%;
          @include tablet {
            @include rem(width, 205px);
          }
          & > .btn {
            flex: 1 1 auto;
            @include rem(padding, 20px);
            @include line-height-adapt(28px);
          }
        }
        .text-bottom {
          display: inline-block;
          margin: 0;
          @include rem(margin-bottom, 10px);
          color: #9B9B9B;
          @include font-size-adapt(14px);
          @include line-height-adapt(20px);
          text-align: left;
          @include tablet {
            @include rem(width, 205px);
          }
        }
        .view-accessibilite {
          .item-list {
            text-align: right;
          }
        }
      }
      .concours {
        .row {
          margin: 0;
        }
        p {
          margin: 0;
          display: block;
          width: 100%;
          @include rem(margin-bottom, 35px);
          &.title {
            font-family: $heading-font-family;
            @include font-size-rem-adapt(20px);
            @include line-height-adapt(26px);
          }
        }
        p, li {
          position: relative;
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }
        ol {
          margin: 0;
          padding: 0;
          list-style: none;
          font-weight: 500;
          counter-reset: parent;
          & > li {
            @include rem(margin-bottom, 35px);
          }
          li {
            list-style: none;
            @include rem(padding-left, 30px);
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              color: $color-primary;
              counter-increment: parent;
              content: counter(parent, decimal) ". ";
            }
          }
          ol {
            margin: 0;
            counter-reset: children;
            @include rem(margin-top, 15px);
            @include rem(margin-left, -30px);
            font-weight: 400;
            li {
              margin: 0;
              &:before {
                color: #333333;
                counter-increment: children;
                content: counter(children, lower-alpha) ". ";
              }
            }
          }
        }
      }
    }
  }

  .view-espace-abonnes.view-display-id-avantages_partenaires {
    .view-content {
      .outer {
        position: relative;
        @include tablet {
          margin-bottom: 2.5rem;
        }
        @include desktop {
          margin-bottom: 0;
        }
        img {
          width: 100%;
        }
        p {
          margin: 0;
          @include rem(padding, 20px 15px);
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          color: #fff;
          font-family: $heading-font-family;
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(20px);
          z-index: 2;
        }
      }
    }
  }

  .view-espace-abonnes.view-display-id-autres_cycles {
    .container {
      @include rem(padding-bottom, 100px);
    }
    .view-content {
      .outer {
        margin-bottom: 1.25rem;
        img {
          width: 100%;
        }
        a {
          display: block;
          color: #333333;
          @include rem(margin-bottom, 15px);
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(22px);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .view.view-push-abonnement {
    background: #f1efea;
    .view-content {
      .text {
        @include rem(padding, 60px 20px);
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
      }
      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include desktop {
          margin: 0;
        }
      }
    }
  }
}