.section-ressources-pedagogiques,
.node-type-ressource-pedagogique,
.node-type-webform-presse,
.page-presse-recherche,
.page-ressources-pedagogiques-recherche,
.presse-category,
.node-type-hub-presse,
.node-type-presse-communique {

  .empty-image {
    height: 210px;
    display: flex;
    align-items: center;
    background: #000;
    justify-content: center;

    @media (max-width:1100px) {
      height: 306px;
    }

    @media (max-width:700px) {
      height: 250px;
    }

    img {
      max-width: 65%;
    }
  }

  .horizontal-menu-bar {
    @media (max-width:1100px) {
      display: block;

      .menu-block-wrapper>ul.menu {
        display: flex;
        flex-wrap: wrap;

        li a {
          font-size: 0.8em;
          padding-left: 10px;
        }
      }
    }

    @media (max-width:900px) {
      display: none;
    }
  }

  #main {
    @media (max-width:1100px) {
      margin-top: 74px;
    }

    @media (max-width:700px) {
      margin-top: 0;
    }
  }

  .smartbanner {
    display: none;
  }

  footer#page-footer div.bottom-footer .field .field-label {
    font-weight: 300;
  }

  .views-row-first .btn {
    @media (max-width:700px) {
      display: none;
    }
  }

  button#go-top {
    @media (max-width:700px) {
      position: absolute;
      bottom: 0;
      width: 50px;
      height: 50px;

      i:before {
        font-size: 0.8em;
      }
    }
  }

  .view.ui-news {
    border-bottom: none;

    .row {
      margin-bottom: 20px;
    }

    &>.container {
      border-bottom: 1px solid #e6e6e6;
      padding-top: 80px;
    }

    &.view-display-id-hub_presse_trois>.container,
    &.view-display-id-hub_presse_deux>.container,
    &.view-display-id-hub_presse_une>.container {
      .view-header {
        padding-bottom: 18px;

        h2 {
          font-size: 2em;
          line-height: 45px;

          @media (max-width:700px) {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 16px;
          }
        }
      }

      .col-lg-4 .outer {
        border-bottom: none;
      }
    }
  }

  .article-header {
    background-color: #f1efea;
    background-size: cover;
    padding-top: 30px;
    min-height: 224px;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width:700px) {
      min-height: 125px;
      padding-top: 80px;
      padding-bottom: 30px !important;
      background-position: center;
      margin-top: 40px;
      background-size: cover;

      .header-wrapper {
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    h1 {
      color: #000;

      @media (max-width:700px) {
        font-size: 36px;
        line-height: 45px;
        // text-align: left;
        max-width: 100%;
      }
    }
  }

  header#header {
    &.banner-active {
      @media (max-width:900px) {
        top: 0;
      }
    }

    .left {
      @media (max-width:900px) {
        display: none;
      }

      a {
        font-size: 15px;
        line-height: 17px;
        color: #fff;
        background: url(../images/externalink.svg) no-repeat center right 30px;
        padding-right: 50px;

        &:focus, &:hover {
          color: #ba9e66;
          background: url(../images/externalink-gold.svg) no-repeat center right 30px;
          transition: all .3s;
        }
      }
    }

    .right {
      @media (max-width:900px) {

        &>a,
        button#open-form {
          display: none;
        }

        .mobile-menu div#block-lang_dropdown-language {
          display: block;
          position: absolute;
          right: 20px;
          border: 1px solid white;

          form {
            display: block;

            .form-type-select {
              position: relative;

              &::after {
                content: none;
                position: absolute;
                width: 5px;
                height: 5px;
                left: 30px;
                top: 6px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
              }
            }

            select {
              width: 165px;
              opacity: 1;
              background: transparent;
              color: #fff;
              border: none;
              text-transform: uppercase;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              option {
                color: #000;
              }
            }
          }
        }
      }

      #open-form {
        @media (min-width:720px) {
          margin: 0;
          top: 0;
          position: relative;
        }
      }

      #block-views-publics-block {
        display: none;
      }

      .press-login,
      &>a {
        font-size: 15px;
        line-height: 17px;
        color: #FFFFFF;
        transition: all .3s;

        &:focus, &:hover {
          color: #ba9e66;
          transition: all .3s;
        }
      }

      &>a {
        padding-right: 20px;
      }

      .press-login {
        background: url(../images/login.svg) no-repeat center left;
        padding-left: 25px;
        margin-left: 20px;

        &:focus, &:hover {
          background: url(../images/login-gold.svg) no-repeat center left;
        }
      }
    }

    span.press-login {
      @media (max-widtH:900px) {
        display: none;
      }
    }

    #logo {
      display: flex;
      align-items: center;
      left: calc(50% - 36px);

      @media (max-widtH:900px) {
        width: 250px;
        left: calc(50% - 14px);
      }

      span {
        font-family: 'Apollon';
        font-weight: normal;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        display: block;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #ccc;

        @media (max-widtH:980px) {
          font-size: 18px;
          line-height: 18px;
          padding-right: 10px;
        }
      }

      img {
        margin-bottom: auto;
        width: 120px;
      }
    }
  }

  .menu-level-2 {
    position: relative;
    width: 100%;

    @media (max-width:1100px) {
      overflow-x: scroll;
      overflow-y: hidden;
      will-change: transform;
      user-select: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    ul.menu {
      display: flex;
      padding-left: 0;
      list-style: none;
      margin-left: 0;
      margin-top: 32px;
      min-width: 800px;
      width: fit-content;

      li {
        list-style-image: none;
        margin-right: 32px;

        a {

          font-size: 16px;
          line-height: 28px;
          color: #000000;

          &.active {
            background: #F1EFEA;
            border-radius: 24px;
            padding: 14px 16px;

            &:focus, &:hover {
              background: #ba9e66;
              color: #fff;
            }
          }

          &:focus, &:hover {
            color: #ba9e66;
          }
        }
      }
    }
  }

  .view-ressources-pedagogiques,
  .view-taxonomy-term-presse,
  .view-presse {
    p.subtitle {
      line-height: 30px;
      color: #333333;
      font-weight: 500;
      margin-bottom: 8px;
      font-family: 'Crimson Text';
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;

      @media (max-widtH:700px) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    p.accroche {
      font-size: .9em;
      line-height: 28px;
      color: #666666;
      margin-bottom: 40px;

      @media (max-widtH:700px) {
        margin-bottom: 0;
        font-size: 0.8em;
        line-height: 24px;
      }
    }

    p.date-lieu {
      text-transform: none;
    }

    p.tags {
      color: #000000;
      height: 20px;
    }

    h4 {
      margin-top: 8px;
      margin-bottom: 16px;

      @media (max-widtH:700px) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .col-lg-4 {
      .outer {
        border-bottom: 1px solid #ccc;
      }

      h4 {
        font-size: 1.18em;
        line-height: 22px;
        margin: 16px 0;
        // margin-top: 8px;

        @media (max-width:700px) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }

      .subtitle {
        font-family: 'Crimson Text';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #333333;

        @media (max-width:700px) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .accroche {
        padding-bottom: 40px;
        margin-bottom: 0;
        font-size: 1.1em;
        line-height: 22px;

        @media (max-width:700px) {
          font-size: 16px;
          line-height: 22px;
          padding-bottom: 5px;
        }
      }
    }

    .cat {
      position: relative;
    }

    &.ui-news {
      &>a {
        margin-bottom: 16px;
      }
    }

    @media (max-width:700px) {
      &:not(.view-display-id-hub_presse_une) {
        .row [class^=col-] .outer {
          border-bottom: none;
          padding-top: 0;
        }

        .news>a {
          margin-bottom: 8px;
        }
      }
    }


    &.view-display-id-hub_presse_une {
      h4 {
        margin-bottom: 20px;
      }

      .view-header {
        padding-bottom: 30px;

        h2 {
          font-size: 2em;
          line-height: 45px;
        }
      }

      p.cat {
        position: relative;
        top: -3px;
      }

      .btn {
        font-size: 0.8em;
        margin-top: 16px;

        @media (max-widtH:700px) {
          margin-top: 20px;
          display: none;
        }
      }

      p.subtitle {
        font-family: 'Crimson Text';
        font-size: 22px;
        line-height: 30px;
      }

      .row {
        div:last-child {
          padding-top: 14px;

          @media (max-width:700px) {
            margin-bottom: 10px;
          }
        }
      }


      @media (max-width:700px) {
        &>.container {
          padding: 0 20px;

          .view-header {
            padding-top: 40px;
            padding-bottom: 30px;

            h2 {
              font-size: 24px;
              line-height: 30px;
            }
          }

          .view-content {
            p.cat {
              position: relative;
              top: -10px;
            }

            .row {
              img {
                width: calc(100% + 40px);
                max-width: calc(100% + 40px);
                margin-left: -20px;
              }

              &>.col {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }

  }

  .widget-documents {
    h2#titre-du-widget-documents {
      @media (max-width:700px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .documents-wrapper {
      border: 1px solid #E6E6E6;
      padding: 0 40px;
      margin-top: 32px;
      margin-bottom: 80px;

      @media (max-width:700px) {
        padding: 20px 14px;
        margin-top: 16px;
      }

      .document-multiple {
        padding: 40px 0;
        border-bottom: 1px solid #E6E6E6;

        button.slick-arrow.slick-disabled {
          display: block !important;
          opacity: 0.5;
          pointer-events: none;
        }

        &:last-of-type {
          border-bottom: none;

          @media (max-width:700px) {
            padding-bottom: 20px;
          }
        }

        .document-image {
          img {
            max-width: 100px;
          }
        }
      }

      .document-single {
        .row {
          @media (max-width:700px) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .document-image {
          padding: 0;
          margin-left: -20px;
          max-width: 245px;
          border-right: 1px solid #CCCCCC;
          background: #F1EFEA;

          @media (max-width:700px) {
            margin-left: 0;
            margin-bottom: 25px;
            max-width: 100%;

            img {
              max-width: 100%;
              margin: 0 auto;
              display: block;
            }
          }

          &.no-image {
            img {
              display: block;
              margin: 0 auto;
              margin-top: calc(50% - 80px);
            }

            &+.document-text {
              padding-bottom: 40px;

              @media (max-width:700px) {
                padding-bottom: 0;
                margin-bottom: 20px;
              }

              .title {
                margin-bottom: 14px;
              }
            }
          }
        }

        .document-text {
          padding-left: 40px;
          padding-top: 40px;
          display: flex;
          flex-direction: column;
          padding-bottom: 39px;

          @media (max-width:700px) {
            padding: 0;
            margin-bottom: 0;
          }

          .download {
            margin-top: auto;
          }

          ul {
            margin-left: 0;
            padding-left: 20px;
            margin-bottom: 40px;

            @media (max-width:700px) {
              margin-top: 7px;
              margin-bottom: 24px;
            }
          }

          .btn {
            background: #BA9E66;
            color: #fff;
          }
        }
      }

      .title {
        font-family: 'Apollon';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 16px;
      }

      .document-info p {
        font-size: 0.8em;
        line-height: 15px;
        color: #666666;
        margin-top: 8px;
      }

      .document-info {
        display: flex;
        justify-content: flex-end;

        p {
          font-size: 0.8em;
          line-height: 15px;
          color: #666666;
          margin-bottom: 0;
          margin-top: 16px;
          padding-left: 20px;

          &:first-of-type {
            text-transform: uppercase;
            padding-left: 0;
          }

          &:last-of-type {
            padding-left: 15px;
            position: relative;
            margin-left: 5px;

            &:before {
              content: '__';
              position: absolute;
              left: 0;
              top: -6px;
            }
          }
        }
      }

      .description {
        font-size: 0.8em;
        line-height: 22px;
        color: #333333;

        ul {
          padding-left: 0;
          margin-left: 15px;
          margin-top: 0;
        }

        p {
          margin: 0;
        }
      }

      .document-desc {
        padding: 0;

        @media (max-width:700px) {
          padding: 0 20px;
        }
      }

      .document-download {
        text-align: right;
        padding-right: 40px;
        padding-left: 0;

        @media (max-width:700px) {
          margin-bottom: 0;
        }

        a {
          font-size: 0.8em;
          line-height: 16px;
          color: #000;
          display: block;
          margin-top: 0;
          position: relative;
          background: url(../images/download.svg) no-repeat top 4px left 0;
          padding-left: 20px;
          border-bottom: none;
          display: inline-block;
          padding-bottom: 4px;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: calc(100% - 20px);
            height: 1px;
            background: #ba9e66;
            left: 20px;
          }
        }
      }

    }
  }

  .view-widget-presse {

    &.view-display-id-contact,
    &.view-display-id-citation {
      max-width: 720px;
      margin: 80px auto;
      padding-left: 40px;
      border-left: 1px solid #BA9E66;

      @media (max-width:700px) {
        margin: 50px auto;
        padding-left: 13px;
      }

      &>.container {
        padding: 0;

        .citation-title {
          font-family: 'Graphik';
          font-style: normal;
          font-weight: normal;
          margin-bottom: 23px;
          font-size: 18px;
          line-height: 20px;
          text-transform: uppercase;
          color: #666666;

          @media (max-width:700px) {
            margin-bottom: 16px;
          }
        }

        .citation-text {
          font-family: 'Crimson Text';
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 44px;
          color: #333333;
          margin-bottom: 16px;

          @media (max-width:700px) {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .citation-source {
          font-size: 16px;
          line-height: 28px;
          color: #666666;

          @media (max-width:700px) {
            font-size: 0.8em;
            line-height: 20px;
          }
        }

        .copy-button {
          font-size: 0.8em;
          line-height: 15px;
          color: #666;
          border: none;
          background: 0 0;
          display: block;
          margin-bottom: 32px;
          padding-left: 20px;
          background: url(../images/copy-icon.svg) no-repeat left center;
          position: relative;

          &:after {
            content: '';
            background: #ba9e66;
            width: calc(100% - 30px);
            position: absolute;
            height: 1px;
            bottom: -3px;
            right: 7px;
          }

          &:focus, &:hover {
            color: #ba9e66;
          }
        }
      }
    }

    &.view-display-id-documents {
      &>.container {
        padding: 10px 0;
      }

    }

    &.view-display-id-contact {
      position: relative;
      margin-top: 120px;

      h2 {
        position: absolute;
        top: -60px;
        margin-top: 0;
        left: -40px;
        font-size: 24px;
        line-height: 30px;

        @media (max-width:700px) {
          left: -15px;
          font-size: 18px;
          line-height: 22px;
          top: -40px;
        }
      }

      .contact-text {
        font-size: 19px;
        line-height: 32px;
        color: #000000;
        margin-bottom: 30px;

        @media (max-width:700px) {
          font-size: 0.8em;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }

      .contact-phone {
        padding-left: 30px;
        background: url(../images/mail.svg) no-repeat left center;
        white-space: nowrap;
        margin-bottom: 30px;
      }

      .email {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        padding-left: 0;

        @media (max-width:700px) {
          flex-direction: column;
          align-items: flex-start;

        }

        .contact-email {
          padding-left: 30px;
          background: url(../images/phone.svg) no-repeat left center;
          position: relative;
        }

        .copy-button {
          margin-bottom: 0;
          margin-left: 20px;

          @media (max-width:700px) {
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }

    &.view-display-id-visuels {
      width: 100vw;
      position: relative;
      margin-left: -50vw;
      left: 50%;
      background: #000;
      padding: 80px 0;

      @media(max-width:900px) {
        padding: 0;
        left: 50% !important;
        width: 100vw !important;
      }

      &>.container {
        max-width: 812px;
        margin: 0 auto;
        padding: 0;
        padding-left: 52px;

        @media(max-width:900px) {
          padding: 30px;
        }

        .widget-visuels {
          margin-left: 0 !important;
          color: #fff;
          max-width: 100%;
        }
      }
    }
  }

  .view-taxonomy-term-presse {
    border-bottom: none;

    .news>a:before {
      background: rgba(0, 0, 0, .6);
      opacity: .4;
    }

    &>.container {
      padding-top: 25px;
      padding-bottom: 0;
      border-bottom: none !important;

      .attachment.attachment-before {
        padding-bottom: 60px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 60px;

        @media (max-width:700px) {
          padding-bottom: 0;

          .row.news>div:first-of-type {
            padding: 0;
          }
        }
      }
    }

    .view-content {
      p.category {
        position: absolute;
        top: 28px;
        right: 8px;
        z-index: 5;
        color: #fff;
        font-size: 0.8em;
        line-height: 22px;
        padding: 4px 8px;
        border: 1px solid #BA9E66;
      }

      h4 {
        margin-top: 8px;
        margin-bottom: 16px;
      }

      p.acrroche {
        font-size: 0.8em;
        line-height: 22px;
      }
    }

    .show-archives {
      margin: 0 auto;
      display: block;
      max-width: 270px;
      cursor: pointer;
      margin-bottom: 0;
    }

    .view-header {
      &.show-more {
        padding-bottom: 80px;

        &.open {
          padding-bottom: 30px;
          margin-top: -115px;

          @media (max-widtH:700px) {
            margin-top: -65px;
          }

          a {
            display: none;
          }
        }

        &+.view-content {
          display: none;
        }

        &.open+.view-content {
          display: block;
          padding-bottom: 60px;
        }
      }
    }
  }

  .view {
    p.date-lieu {
      font-size: 0.8em;
      line-height: 16px;
      text-transform: none;
    }
  }

  .now-section {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    background: #F1EFEA;
    padding: 80px 0;

    @media (max-width:700px) {
      padding: 30px 0;
    }

    &>h2 {
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 20px;
      margin-bottom: 34px;
      font-size: 2em;
      line-height: 45px;

      @media (max-width:700px) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 16px;
      }
    }

    .paragraphs-items-field-presse-cours-onglet {
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 20px;

      .entity-paragraphs-item {
        border: none !important;
        box-shadow: none;
        background: transparent;
        padding-bottom: 0;
        margin-left: 0;
        padding: 0;
        margin-bottom: 0;

        &:focus, &:hover {
          background: transparent;
        }

        &:before {
          display: none;
        }

        .content.ui-accordion {
          background: #fff;
          padding: 20px 0;
        }

        .field-items {
          margin-left: 0;
        }

        .accordion h4 {
          &:after {
            border-color: #666666 transparent transparent transparent;
            right: 0;
            position: absolute;
            top: 30px;
            right: 40px;
            right: 2.5rem;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 6px 0 6px;
            transform: translateY(-50%);
            transform-origin: 50% 0;
          }

          &.active:before {
            display: none;
          }

          &.open:after {
            transform: rotate(180deg);
          }
        }

        .accordion h4 button,
        .accordion__header {
          border-left: none;
          border-right: none;
          border-top: none;
          margin-left: 40px;
          padding-left: 0;
          margin-right: 40px;
          padding-right: 0;
          font-size: 24px;
          line-height: 30px;
          font-family: 'Apollon';
          border: none;
          background-color: transparent;
          width: 100%;
          text-align: left;
          max-width: calc(100% - 40px);
          padding-top: 20px;
          padding-bottom: 20px;

          @media (max-width:700px) {
            font-size: 18px;
            line-height: 22px;
            margin-left: 20px;
            margin-right: 20px;
            max-width: calc(100% - 20px);
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:after {
            border-color: #666666 transparent transparent transparent;
            right: 0;
          }

          &:focus, &:hover {
            color: #000;
            background: #fff;
          }

          &.active:before {
            display: none;
          }

          &.open-by-default {
            border-bottom: none;

            +.accordion__content {
              display: block;
            }
          }
        }

        .accordion-panel,
        .accordion__content {
          border: none;
          padding-top: 0;
          padding-bottom: 1px;
          padding-left: 40px;
          padding-right: 40px;

          .paragraphs-items .entity-paragraphs-item .field-item {
            border-bottom: 1px solid #CCCCCC;
          }

          .date {
            font-size: 1em;
            line-height: 16px;
            color: #666;
            background: url(../images/date-icon.svg) no-repeat 20px -1px;
            padding-left: 40px;

            @media (max-width:700px) {
              margin-bottom: 18px;
            }
          }

          .title {
            font-size: 18px;
            line-height: 22px;
            color: #000002;
            font-family: 'Apollon';

            @media (max-width:700px) {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 16px;
            }
          }

          .text {
            p {
              font-size: 1em;
              line-height: 22px;
              color: #666666;
              margin-bottom: 0;

              @media (max-width:700px) {
                margin-bottom: 16px;
              }
            }
          }

          .link {
            font-size: 1em;
            line-height: 16px;
            color: #666666;
            text-align: right;

            a {
              color: #000;
              border-bottom: 1px solid #ba9e66;
              position: relative;
              display: inline-block;
              margin-right: 23px;
              padding-bottom: 2px;

              &.contact-press {
                border-bottom: none;
              }

              &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                right: -25px;
                background: url(../images/right-arrow-black.svg) no-repeat 4px 5px;
              }

              &:focus, &:hover {
                color: #ba9e66;

                &:after {
                  color: #ba9e66;
                  background: url(../images/right-arrow-gold.svg) no-repeat 4px 5px;
                }
              }
            }
          }
        }

        .field-item {
          padding: 30px 0;
        }

        .field-items>.field-item:first-of-type {
          padding-top: 10px;
        }
      }
    }
  }

  .paragraphs-items-field-presse-contact {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #FFFFFF;
    z-index: 5;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    ul.anchor-map {
      display: none !important;
    }

    .entity-paragraphs-item {
      border: none !important;
      box-shadow: none;
      background: transparent;
      padding-bottom: 0;
      margin-left: 0;
      padding: 0;
      margin-bottom: 0;
      height: 77px;
      display: flex;
      align-items: center;

      @media (max-width:1100px) {
        height: auto;
        padding: 20px 10px 20px 10px;
      }

      &:focus, &:hover {
        background: transparent;
      }

      .content {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;

        @media (max-width:1100px) {
          max-width: 100%;
        }

        .row {
          display: flex;
          align-items: center;

          @media (max-width:1100px) {
            align-items: flex-start;
          }

          .title {
            @media (max-width:1100px) {
              position: relative;
              top: -5px;
              max-width: 100%;
              width: 100%;
              flex-basis: 100%;
              padding-left: 0;
              left: 20px;

              .contact-title {
                font-size: 0.8em;
                line-height: 16px;
                color: #000000;
              }
            }
          }

          .phone {
            // @media (max-width:1100px) {
            //   margin-left: 60px;
            //   margin-top: 10px;
            // }

            @media (max-width:1100px) {
              max-width: 40%;
              width: 100%;
              flex-basis: 40%;
              margin-left: 0;
              padding-left: 20px;
              padding-right: 0;
            }

            .contact-phone {
              margin-left: -50px;
              padding-left: 30px;
              background: url(../images/mail.svg) no-repeat left center;
              white-space: nowrap;

              @media (max-width:1100px) {
                background: none;
                padding-left: 0;
                margin-left: 0;
                font-size: 0.8em;
                line-height: 16px;
                color: #000000;
                border-bottom: 1px solid #ba9e66;
                max-width: fit-content;
              }
            }
          }

          .email {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;
            padding-left: 0;
            margin-left: -20px;
            margin-right: 20px;

            // @media (max-width:1100px) {
            //   margin-top: 10px;
            //   margin-left: 20px;
            // }

            @media (max-width:1100px) {
              margin-left: 0px;
              max-width: 65%;
              width: 100%;
              flex-basis: 50%;
              padding-right: 0;
            }

            .contact-email {
              margin-left: 20px;
              padding-left: 30px;
              background: url(../images/phone.svg) no-repeat left center;
              position: relative;

              @media (max-width:1100px) {
                background: none;
                padding-left: 0;
                font-size: 0.8em;
                line-height: 16px;
                color: #000000;
                border-bottom: 1px solid #ba9e66;
              }

              &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: -2px;
                width: calc(100% - 30px);
                height: 1px;
                background: #ba9e66;

                @media (max-width:1100px) {
                  display: none;
                }
              }
            }

            .copy-button {
              font-size: 0.8em;
              line-height: 15px;
              color: #666;
              border: none;
              background: 0 0;
              display: block;
              margin-left: 20px;
              padding-left: 22px;
              background: url(../images/copy-icon.svg) no-repeat left center;

              @media (max-width:1100px) {
                display: none;
              }

              &:focus, &:hover {
                color: #ba9e66;
              }
            }
          }

          .link {
            // @media (max-width:1100px) {
            //   margin-top: 10px;
            //   margin-left: auto;
            //   margin-right: 10px;
            // }

            @media (max-width:1100px) {
              display: none;
            }

            .contact-link {
              text-align: right;

              a {
                color: #000;
                border-bottom: 1px solid #ba9e66;
                position: relative;
                display: inline-block;
                padding-bottom: 2px;

                &:focus, &:hover {
                  color: #ba9e66;
                }
              }
            }
          }
        }
      }
    }
  }

  .paragraphs-items-field-presse-push-noir {

    .entity-paragraphs-item:before {
      display: none;
    }

    .full-width {
      background: #000;

      .entity-paragraphs-item {
        border: none !important;
        box-shadow: none;
        background: transparent;
        color: #fff;
        padding: 40px 20px;

        @media (max-width:700px) {
          margin-left: 0;
        }

        .content {
          max-width: 1140px;
          margin: 0 auto;
          padding: 0;
          box-sizing: border-box;

          p {
            margin-top: 0;
            font-size: 0.8em;
            line-height: 21px;

            @media (max-widtH:700px) {
              margin: 32px 0 24px 0;
            }
          }

          a {
            color: #fff;
            margin-left: 20%;
            margin-right: 30px;
            text-align: right;
            display: inline-flex;
            position: relative;
            border-bottom: 1px solid #ba9e66;
            padding-bottom: 2px;

            @media (max-widtH:700px) {
              margin-left: 0;
            }

            &:after {
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              top: 0;
              right: -23px;
              background: url(../images/right-arrow-white.svg) no-repeat 4px 5px;
            }

            &:focus, &:hover {
              color: #ba9e66;

              &:after {
                color: #ba9e66;
                background: url(../images/right-arrow-gold.svg) no-repeat 4px 5px;

              }
            }
          }
        }
      }
    }
  }

  footer#page-footer {
    .prefooter {
      background: #F1EFEA;
      position: relative;

      .entity-paragraphs-item {
        border: none !important;
        background: 0 0;
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 1280px;
        margin: 0 auto;

        &:before {
          display: none;
        }
      }

      .prefooter-top {
        padding: 60px 64px;
        max-width: 1280px;
        margin: 0 auto;

        @media (max-width:700px) {
          padding: 40px 0;
          padding-bottom: 110px;
        }

        .col {
          @media (max-width:700px) {
            padding: 0;
            padding-top: 30px;
          }

          .prefooter-link a {
            position: relative;
            background: 0 0;
            border: none !important;
            transition: all .4s ease-in-out;
            overflow: hidden;
            z-index: 2;
            color: #fff;
            display: inline-block;
            padding: 22px 24px;
            margin-left: 74px;

            @media (max-width:700px) {
              margin: 0;
              width: 100%;
              text-align: center;
            }

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 1px solid #ba9e66 !important;
              background: #ba9e66;
              z-index: -1;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              transform-origin: center top;
              transition: transform .4s ease-in-out;
              transition-delay: 0;
              z-index: -1;
              transform: scaleY(0) !important;
            }

            &:hover:after {
              transform-origin: center bottom;
              transform: scaleY(1) !important;
            }
          }

          &:first-of-type {
            border-right: 1px solid #CCCCCC;

            @media (max-width:700px) {
              padding-bottom: 30px;
              padding-top: 0;
              border-right: none;
              border-bottom: 1px solid #ccc;
            }

            .prefooter-title {
              font-size: 18px;
              line-height: 20px;
              color: #333333;
              margin-bottom: 24px;
            }

            .prefooter-email,
            .prefooter-text {
              font-size: 17px;
              line-height: 24px;
              color: #666666;
              max-width: 90%;

              a {
                color: #666666;
              }

              p {
                margin-bottom: 10px;
              }

              .phone {
                padding-left: 25px;
                background: url(../images/mail.svg) no-repeat left center;
                margin-top: 10px;
                display: block;
                color: #666666;
              }
            }

            .prefooter-email {
              text-decoration: underline;
            }

            .copy-button {
              font-size: 0.8em;
              line-height: 15px;
              color: #666666;
              border: none;
              background: transparent;
              display: block;
              margin-top: 20px;
              padding-left: 22px;
              background: url(../images/copy-icon.svg) no-repeat left center;
            }
          }

        }
      }

      .prefooter-bottom {
        background: #232323;
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;

        a {
          font-size: 17px;
          line-height: 19px;
          color: #fff;
          display: inline-block;
          margin: 20px 0;
          padding-right: 30px;
          padding-left: 30px;
          background: url(../images/arrow-right-white.svg) no-repeat top 2px left 0px;

          &:focus, &:hover {
            color: #ba9e66;
            background: url(../images/arrow-right.svg) no-repeat top 2px left 0px;

          }
        }
      }
    }

    .top-footer {
      display: none;
    }

    .bottom-footer {
      .app-banner {
        display: none;
      }

      .social-icons {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .anchor-map.fixed {
      margin-left: 70px;
    }
  }

  .social-networks-block {
    .equelHeight {
      @media (max-width:1100px) {

        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &>.container {
      @media (max-width:700px) {
        padding-top: 1px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;

        .view-tweets {
          padding-bottom: 2px;
        }
      }

      h2 {
        font-size: 2em;
        line-height: 45px;
      }
    }

    .row {
      .link {
        margin-bottom: 16px;
        color: #ba9e66;

        a {
          color: #000;
          border-bottom: 1px solid #BA9E66;

          &:focus, &:hover {
            color: #ba9e66;
          }
        }
      }

      .view-tweets {
        .view-content {
          background: #FFFFFF;
          border: 1px solid #D6D4CF;
          margin-bottom: 16px;
          padding: 16px;

          td.views-field.views-field-text {
            font-size: 17.3333px;
            line-height: 24px;
            color: #333333;
          }
        }

        .view-footer {
          display: none;
        }
      }
    }

    .video-container {
      padding: 0;
    }

    i.icon-tiktok {
      background: url(../images/tiktok.svg) no-repeat center center;
      width: 17px;
      height: 17px;
    }
  }
}

.presse-category {
  .article-header {
    @media (max-width:900px) {
      padding-top: 16px;
      padding-bottom: 16px;
      min-height: unset;
      padding-left: 20px;
      padding-right: 20px;
    }

    .text--center {
      margin: 35px 0;

      @media (max-width:700px) {
        margin-top: 50px;
      }

      .uppertitle.small {
        font-family: 'Apollon';
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;

        @media (max-width:700px) {
          font-size: 18px;
          line-height: 22px;
          text-align: left !important;
        }
      }
    }

    ul.ui-breadcrumbs {
      display: flex;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto;
      width: 100%;
      padding-left: 0;

      li i {
        margin-left: 18px;
        margin-right: 18px;
        color: #666666;
        font-size: 13px;
      }

      &>li span {
        border-color: #ba9e66;
        padding-bottom: 0;
        padding-top: 0;
      }

      &>li a {
        margin-top: 0;
        font-size: 16px;
        line-height: 18px;
        color: #0F0F0F;

        &:focus, &:hover {
          color: #ba9e66;
        }
      }
    }
  }

  .view-taxonomy-term-presse {
    &>.container {
      @media (max-width:700px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px !important;
      }
    }

    &.view-display-id-attachment_1>.container {
      @media (max-width:700px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .view-empty {
      margin-bottom: 30px;
    }
  }

  .menu-name-menu-menu-presse>ul>li {
    &.active-trail {
      &>a {
        font-weight: 500;
        position: relative;
        color: #000;

        &:before {
          content: '';
          height: 3px;
          background: #ba9e66;
          position: absolute;
          right: 0;
          bottom: -25px;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.node-type-presse-communique {

  .article-header {
    @media (max-width:700px) {
      padding: 20px;

      .header-wrapper.row {
        &>div {
          padding: 40px 0 0 0;

          &:first-of-type {
            margin-bottom: 16px;

            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
            }
          }

          h1 {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 24px;
          }

          p.subtitle {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .paragraphs-items-field-presse-contact {
    z-index: 20;
  }

  .header-wrapper.row {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 10px 0;

    @media (max-widtH:1100px) {
      max-width: 100%;
      padding-top: 30px;
    }

    &>div:first-of-type {
      padding-left: 0;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    .text-wrapper {
      .uppertitle-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          font-size: 0.8em;
          line-height: 21px;
          color: #000000;
          margin-right: 16px;
          margin-bottom: 16px;

          &:last-of-type {
            margin-right: 0;
          }

          &.category {
            padding: 4px 6px;
            border: 1px solid #BA9E66;
            margin-bottom: 15px;
          }
        }
      }

      .document-wrapper {
        box-sizing: border-box;
        margin-top: 34px;

        .document-single {
          overflow: hidden;
        }

        .document-multiple {
          display: flex;
          margin-bottom: 40px;

          .slick-slide {margin-right:20px;}

          button.slick-arrow.slick-disabled {
            display: block !important;
            opacity: 0.5;
            pointer-events: none;
          }

          .slider__counter {
            position: absolute;
            bottom: -55px;
            right: 98px;
            font-size: 16px;
            line-height: 28px;
            color: #000000;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          .document {
            // padding-right: 36px;
            // margin-right: 16px;
            max-width: 460px;
            overflow-y: hidden;
            height:100%;
          }

          button.slick-arrow {
            width: 40px;
            height: 40px;
            background: transparent;
            font-size: 0;
            border-color: #000;
            top: auto;
            bottom: -80px;
            right: 0;
            left: auto;
            background: url(../images/arrow-slider.svg) no-repeat center center;

            &.slick-next {
              transform: rotate(180deg);
              bottom: -60px;
            }

            &.slick-prev {
              right: 40px;
              border-right: 0;
            }
          }
        }

        .document { 
          display: flex;
          background: #FFFFFF;
          border: 1px solid #CCCCCC;
          min-height: 160px;
          // max-height: 160px;
          height:100%;
          &.no-image {
            padding-left: 16px;
          }

          .image {
            margin: 14px 20px 0px 20px;

            img {
              min-width: 120px;
              filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));

              @media (max-widtH:700px) {
                min-width: 100%;
              }
            }
          }

          &.has-image {
            @media (max-width:700px) {
              flex-direction: column;
              max-height: 400px;
              // padding-left: 20px;
              // padding-right: 20px;

              .image {
                margin: 20px;
                margin-bottom: 0;
                margin-top: 40px;
                overflow: hidden;
                max-height: 70px;
              }
            }

            .text .document-info p {
              &:first-of-type {
                padding-left: 34px;
                text-transform: uppercase;
              }
            }

            .text .document-download a {
              display: inline-block;
              padding-bottom: 2px;
              margin-left: 12px;
              text-decoration: underline;
              text-decoration-color: #ba9e66;
              text-underline-offset: 3px;

              &:after {
                display: none
              }
            }
          }

          .text {
            margin-top: 32px;
            margin-bottom: 24px;
            padding-left:6px;
            padding-right:16px;
            .document-download {
              a {
                font-size: 0.8em;
                line-height: 16px;
                color: #000;
                display: block;
                margin-top: 0;
                position: relative;
                background: url(../images/download.svg) no-repeat top 2px left 0;
                background-size: 15px;
                padding-left: 20px;
                text-decoration: underline;
                -webkit-text-decoration-color: #ba9e66;
                text-decoration-color: #ba9e66;
                text-underline-offset: 3px;

                &:focus, &:hover {
                  color: #BA9E66;
                }

                &:after {
                  display: none;
                  content: '';
                  position: absolute;
                  bottom: 0;
                  width: calc(100% - 20px);
                  height: 1px;
                  background: #BA9E66;
                  left: 20px;
                }
              }
            }

            .document-info {
              display: flex;

              p {
                font-size: 0.8em;
                line-height: 15px;
                color: #666666;
                margin-bottom: 0;
                margin-top: 16px;
                padding-left: 20px;

                &:first-of-type {
                  text-transform: uppercase;
                }

                &:last-of-type {
                  padding-left: 15px;
                  position: relative;

                  &:before {
                    content: '__';
                    position: absolute;
                    left: 0;
                    top: -6px;
                  }
                }
              }
            }
          }
        }
      }

      h1 {
        font-size: 2em;
        line-height: 45px;
        color: #0F0F0F;
        text-align: left;
        margin-bottom: 8px;

        @media (max-width:900px) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      p.subtitle {
        font-size: 22px;
        line-height: 30px;
        color: #666666;
        font-family: 'Crimson Text';
        text-transform: none;

        @media (max-widtH:700px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .main-content {
    &>.container {
      @media (max-widtH:700px) {
        padding: 0 20px;
      }
    }

    .anchor-map {
      margin-top: 50px;

      @media (max-widtH:1100px) {
        display: inline-block !important;
      }

      @media (max-widtH:700px) {
        li p,
        li a {
          font-size: 0.8em;
        }
      }
    }

    .anchor-content {
      &>.row {
        @media (max-width:900px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      &>.row>div {
        padding-left: 80px;
        max-width: 820px;
        flex-basis: 100%;
        margin-top: 45px;

        @media (max-width:1100px) {
          margin-left: auto !important;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;
        }

        @media (max-width:900px) {
          max-width: 100%;
          margin-top: 10px;
        }
      }

      .breadcrumbs {
        padding-top: 40px;
        border-bottom: none;
        padding-bottom: 60px;

        ul.ui-breadcrumbs {
          white-space: nowrap;
        }

        .icon-arrow-long:before {
          color: #666666;
        }
      }
    }

    .text--lead {
      font-family: 'Crimson Text';
      font-size: 24px;
      line-height: 34px;
      color: #BA9E66;
      margin-top: 0;
      border: none;
      padding-bottom: 20px;
      margin-bottom: 20px;

      @media (max-width:700px) {
        font-size: 20px;
        line-height: 28px;
        color: #ba9e66;
        margin: 20px 0;
        padding: 0;
      }
    }
  }

  .related {
    .view-presse {
      .view-header {
        padding-bottom: 10px;
      }

      div .outer {
        border-bottom: none;

        .accroche {
          padding-bottom: 0;
        }
      }
    }
  }
}

// div#page {
//   overflow-y: hidden;
// }

.node-type-hub-presse {

  // .article-header{
  //   .text--center{
  //     img{
  //       width: 250px;
  //       height: auto;
  //       @media (max-width:700px) {
  //         width: 200px;
  //       }
  //     }
  //   }
  // }

  @media (max-width:700px) {

    .view.ui-news {
      &.view-display-id-hub_presse_deux {
        &>.container {
          .view-content {
            .row-first .views-column-first {
              .news>a {
                overflow: visible;
                margin-bottom: 19px;

                img {
                  position: relative;
                  z-index: 2;
                  left: -20px;
                  width: calc(100% + 40px);
                  max-width: calc(100% + 40px);
                }
              }

              h4 {
                font-size: 1.18em;
                line-height: 22px;
              }
            }


          }
        }
      }

      &>.container {
        padding: 40px 20px;
        padding-top: 0;
        border-bottom: none;

        .view-header {
          h2 {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.page-espace-presse-programmation-exposition {
  .presse-category .article-header ul.ui-breadcrumbs {
    position: relative;
    top: -60px;
  }
}

.page-espace-presse footer#page-footer div.bottom-footer .field .field-label {
  font-weight: normal;
}

// mobile menu
.section-ressources-pedagogiques,
.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique,
.node-type-webform-presse,
.page-presse-recherche,
.node-type-presse-communique,
.presse-category,
.page-espace-presse,
.node-type-hub-presse {
  .article-header {
    @media (max-width: 700px) {
      // margin-top: 30px;
    }
  }

  .mobile-menu {
    display: none;

    @media (max-width:900px) {
      display: flex;
      flex-direction: column;

      &>.container {
        display: none;

        ul {
          margin: 0;
          padding-left: 0;
          list-style: none;
          // overflow-y: scroll;
          // max-height: 450px;

          li {
            list-style: none;
            max-height: 70px;
            transition: .2s;
            padding-right: 0;
            padding-top: 0;

            a {
              font-size: 16px;
              line-height: 26px;
              color: #333333;
              display: block;
              padding: 24px 20px;
              border-bottom: 1px solid #E6E6E6;
            }

            &.expanded {
              &>a {
                &:after {
                  top: 38px;
                  right: 28px;
                }
                &:before{
                  content:none;
                }
              }

              // second level menu ==========

              ul {
                position: relative;
                top: 0;
                left: 0;
                transform: none;
                opacity: 0;
                visibility: visible;
                box-shadow: none;
                border-bottom: 1px solid #E6E6E6;
                transition: .1s;
                pointer-events: none;
                padding-bottom: 0;

                li {
                  a {
                    font-size: 16px;
                    line-height: 26px;
                    color: #333333;
                    padding-top: 0;
                    padding-bottom: 16px;
                    border-bottom: none;
                    padding-left: 47px;

                    &:focus, &:hover {
                      background: transparent;
                    }
                  }
                }
              }

              &.open {
                max-height: 400px;
                height: 100%;
                transition: .2s;
                border-bottom: none;

                &>a:after {
                  top: 27px;
                  transform: rotate(180deg);
                }

                &>a {
                  border-bottom: none;
                  font-weight: 500;
                }

                ul {
                  opacity: 1;
                  transition: .1s;
                  pointer-events: all;
                }
              }
            }

            &.last a {
              border-bottom: none;
            }
          }
        }
      }

      button#menu-toggle {
        position: absolute;
        right: 20px;
        margin-right: 0;
        margin-top: 0;
        top: 26px;
      }

      svg g {
        fill: #fff;
      }

      &.open>.container {
        display: block;
        position: absolute;
        left: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        height: calc(100vh - 70px);
        top: 70px;
        background: #fff;
        overflow-y: scroll;
        padding: 0;
        ul.menu{
          display:block !important;
        }
      }
    }

    .menu-bottom {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 520px);
      background: #000;
      padding: 24px 20px;
      text-align: left;
      min-height: 220px;

      a {
        color: #fff;
        display: block;
        margin-bottom: 20px;
        margin-left: 0 !important;
        text-decoration: underline;
        font-size: 14px !important;

        &:last-of-type {
          margin-top: auto;
          margin-bottom: 0;
          padding-bottom: 20px;
          @media (max-width:900px) {
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      @media (max-width:900px) {
        .search-open {
          color: #fff;
          margin-bottom: 10px;
          border-bottom: 1px solid white;
        }

        form {
          display: none;

          &.search {
            position: absolute;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            padding-top: 80px;
            justify-content: center;

            &.open {
              display: flex;
              z-index: 1;
              background-color: #000;
            }

            .close {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 27px;
              top: 30px;
              padding: 0;

              &::after {
                display: inline-block;
                content: "\00d7";
                font-size: 40px;
              }
            }

            button {
              position: relative;
            }
          }
        }
      }
    }

    &.open {

      svg g {
        polygon {
          transition: .2s;

          &.line-1 {
            transition: .2s;
            transform: translate(1px, 1px) rotate(45deg);
          }

          &.line-2 {
            transition: .2s;
            opacity: 0;
          }

          &.line-3 {
            transition: .2s;
            transform: translate(-13px, 8px) rotate(-45deg);
          }
        }
      }
    }
  }
}

html.menu-open {
  overflow: hidden;
}

.page-press-office-what-on-exhibitions,
.page-espace-presse-programmation-expositions {
  header.article-header.ui-header {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 0;
  }

  .view.ui-news>.container {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

div#colorbox {
  background: #fff;
  width: 720px !important;
  padding: 50px 95px;

  .close {
    position: absolute;
    right: 45px;
    top: 35px;
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;

    @media (max-width:700px) {
      right: 20px;
      top: 20px;

      &:after {
        font-size: 35px;
      }
    }

    &:after {
      display: inline-block;
      content: "\00d7";
      font-size: 40px;
    }
  }

  @media (max-width:800px) {
    width: calc(100vw - 40px) !important;
    padding: 30px;
    max-height: 90vh;
    height: 90vh !important;
    overflow-y: scroll !important;
  }

  li.first.last {
    text-align: left;
    list-style: none;
    margin-left: 0;
    margin-top: -10px;
    font-size: 0.8em;
  }

  .login-pop-title {
    font-family: 'Apollon';
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 24px;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 24px;

    @media (max-width:700px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .login-pop-text {
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 40px;

    @media (max-width:700px) {
      margin-bottom: 32px;
      font-size: 0.8em;
      line-height: 24px;
    }
  }

  label {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;

    @media (max-width:700px) {
      font-size: 0.8em;
      line-height: 24px;
    }
  }

  input:not([type=submit]) {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    height: 56px;
    margin-top: 8px;
    margin-bottom: 5px;
    width: 100%;

    @media (max-width:700px) {
      height: 40px;
      max-width: 100%;
    }

    &+.description {
      margin-bottom: 24px;

      @media (max-width:700px) {
        font-size: 12px;
      }
    }
  }

  .item-list {
    display: none;
  }

  .form-actions {
    position: relative;
    display: inline-block;
    margin-bottom: 32px;

    @media (max-width:700px) {
      width: 100%;
      text-align: center;
    }

    input {
      border: none;
      background: transparent;
      font-size: 19px;
      line-height: 23px;
      padding: 22px 30px;
      color: #fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #ba9e66 !important;
      background: #ba9e66;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      transform-origin: center top;
      transition: transform .4s ease-in-out;
      transition-delay: 0;
      z-index: -1;
      transform: scaleY(0) !important;
    }

    &:hover:after {
      transform-origin: center bottom;
      transform: scaleY(1) !important;
    }
  }

  .login-pop-contact {
    color: #000;
    margin-bottom: 16px;
  }

  .login-pop-email {
    display: flex;

    @media (max-width:700px) {
      flex-direction: column;
    }

    .contact-email {
      padding-left: 23px;
      margin-right: 15px;
      background: url(../images/phone.svg) no-repeat left top 7px;
      position: relative;
      height: 21px;
      text-decoration: underline;
      text-decoration-color: #ba9e66;
      text-underline-offset: 4px;
    }

    .copy-button {
      font-size: 0.8em;
      line-height: 15px;
      color: #666;
      border: none;
      background: 0 0;
      display: block;
      margin-bottom: 32px;
      padding-left: 20px;
      background: url(../images/copy-icon.svg) no-repeat left center;
      position: relative;

      @media (max-width:700px) {
        margin-top: 27px;
        text-align: left;
        margin-left: 0;
        margin-bottom: 30px;
      }

      &:focus, &:hover {
        color: #ba9e66;
      }
    }
  }
}

.page-ressources-pedagogiques-recherche,
.page-presse-recherche {

  .view-empty {
    p {
      font-family: 'Crimson Text';
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      color: #000;
    }

    a {
      color: #000;
      border-bottom: 1px solid #ba9e66;
      position: relative;
      display: inline-block;
      margin-right: 23px;
      padding-bottom: 2px;
      font-size: 0.8em;
      line-height: 16px;
      font-family: 'Graphik';

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        right: -25px;
        background: url(../images/right-arrow-black.svg) no-repeat 4px 5px;
      }

      &:focus, &:hover {
        color: #ba9e66;

        &:after {
          color: #ba9e66;
          background: url(../images/right-arrow-gold.svg) no-repeat 4px 5px;
        }
      }
    }
  }

  .view-header {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    padding-bottom: 0;
    background: #f1efea;
    margin-bottom: 40px;

    @media (max-width:1160px) {
      left: 0;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width:1100px) {
      width: calc(100% + 60px);
      margin-left: -30px;
      padding-left: 20px;
      padding-right: 20px;
      margin-right: -30px;
    }

    h1 {
      font-size: 2em;
      line-height: 45px;
      padding-top: 80px;
      padding-bottom: 150px;
      max-width: 1100px;
      margin: 0 auto;
      display: block;

      @media (max-width:700px) {
        font-size: 24px;
        line-height: 30px;
        margin-top: 40px;
      }
    }

    form {
      background: #fff;
    }

    .nav-bar {
      border-bottom: none;

      @media (max-width:700px) {
        &>.container {
          padding: 0 20px;
        }
      }
    }

    .views-exposed-widget {
      display: flex;
      margin-right: 0;

      .form-radios {
        display: flex;
        align-items: center;

        @media (max-width:1100px) {
          flex-wrap: wrap;
        }
      }

      @media (max-width:700px) {
        flex-direction: column;

        .form-radios {
          align-items: flex-start;
          flex-direction: column;
          margin-top: -20px;
          border-bottom: 1px solid #E6E6E6;
          padding-bottom: 24px;
          margin-bottom: -30px;
        }
      }

      input[type=text] {
        height: 56px;
        border: none;
        width: 530px;
        position: absolute;
        top: -130px;
        padding: 14px 16px;
        background: url(../images/recherche.svg) no-repeat center right 10px;
        background-color: #fff;

        @media (max-width:700px) {
          width: 100%;
          top: -140px;
          max-width: calc(100vw - 40px);
        }
      }

      &>label {
        font-size: 0.8em;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #666666;
        font-weight: normal;
        margin-right: 43px;

        @media (max-width:700px) {
          position: absolute;
          top: -10px;
          right: -15px;
        }
      }

      .form-type-radio {
        padding: 0;
        margin-right: 24px;

        &:last-of-type {
          margin-right: 0;
        }

        @media (max-width:1100px) {
          margin-bottom: 5px;
        }

        .option {
          font-size: 16px;
          line-height: 28px;
          color: #000000;
          cursor: pointer;

          @media (max-width:700px) {
            font-size: 0.8em;
            line-height: 24px;
            margin-top: 8px;
            display: block;
          }

          &:before,
          &:after {
            opacity: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          &:focus, &:hover {
            color: #BA9E66;
          }
        }

        input:checked+.option {
          background: #F1EFEA;
          border-radius: 24px;
          padding: 8px 16px;
          display: inline-block;
        }
      }

    }

    // }
  }

  .view-display-id-recherche {
    &>.container {
      padding-top: 0;

      @media (max-width:1160px) {
        padding: 0 20px;
      }
    }

    .row.news {
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #E6E6E6;
      max-width: 1100px;
      margin: 0 auto;

      &>div {
        padding-left: 0;

        @media (max-width:700px) {
          padding-right: 0;
          margin-bottom: 0;
        }

        &+div {
          padding-top: 10px;
          padding-left: 0;
          padding-right: 0;

          @media (max-width:700px) {
            padding-top: 0;
          }
        }
      }

      .cat {
        margin-top: 0;
        margin-bottom: 8px;
      }

      p.accroche {
        margin-bottom: 0;
      }

      p.subtitle {
        margin-top: 16px;

        @media (max-width:700px) {
          margin-top: 8px;
        }
      }

      img {
        @media (max-width:700px) {
          display: block;
          width: 100%;
        }
      }

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  .item-list {
    .pager {
      align-items: center;

      &>li.pager-previous,
      &>li.pager-next {
        font-size: 0;

        a {
          width: 48px;
          height: 48px;
          padding: 0;
          min-width: 48px;
          margin-left: auto;
          margin-right: 24px;
          background: url(../images/arrow-slider.svg) no-repeat center center;
          border-color: #000;
        }
      }

      &>li.pager-next {
        transform: rotate(180deg);
      }

      &>li.pager-previous {
        margin-left: 0;
        margin-right: 24px;
        max-width: 98px;
      }
    }
  }
}

.logged-in span.press-login:hover {
  background: url(../images/login.svg) no-repeat center left !important;
  color: #fff !important;
}


.page-user,
.node-type-webform-presse {
  .article-header {
    padding: 65px 0;

    h1 {
      font-size: 2em;
      line-height: 45px;
      text-align: center;
      color: #000000;
      max-width: 520px;

      @media (max-width:900px) {
        text-align: left;
      }
    }
  }

  h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;

    &+p {
      color: #666666;
    }
  }

  .main-content {
    margin-bottom: 60px;
    margin-top: 70px;

    form {
      &>div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .form-item {
        display: inline-block;
        max-width: 340px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 40px;

        &.section-title {
          max-width: 100%;
          margin-bottom: 0;
        }

        &.webform-component--message {
          max-width: 100%;
          margin-bottom: 56px;

          textarea {
            font-size: 16px;
            line-height: 28px;
            padding: 16px 14px;
            background: transparent;
            border-color: #ccc;
          }
        }

        &.webform-component--section-title-2 {
          margin-bottom: 40px;

          h3 {
            margin-bottom: 0;
          }

          p {
            font-size: 16px;
            line-height: 28px;
            color: #666666;
            margin-bottom: 0;
            margin-top: 16px;
          }
        }

        label {
          margin-bottom: 8px;
        }

        input {
          max-width: 100%;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E6E6E6;
          height: 56px;
          padding-left: 10px;
        }

        &.webform-component-boolean,
        &.webform-component--interets-preciser,
        &.webform-component--interets,
        &.webform-component--adresse {
          max-width: 100%;
        }

        &.webform-component-boolean {
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-start;
          cursor: pointer;

          &.webform-component--traitement {
            margin-bottom: 24px;
          }

          &.webform-component--communiques {
            label {
              margin-right: auto;
            }
          }

          label {
            font-weight: 400;
          }

          input {
            max-width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-right: 8px;
          }
        }

        .selectric-wrapper,
        .selectric {
          width: 100%;
        }

        .form-checkboxes {
          display: flex;
          flex-wrap: wrap;
          margin-left: 15px;

          .form-item {
            margin-bottom: 26px;
            max-width: max-content;
            cursor: pointer;
            padding-left: 0;
            margin-right: 50px;

            input:checked+label {
              color: #fff;
            }

            label {
              position: relative;
              color: #000;

              &:before {
                width: calc(100% + 32px);
                height: 38px;
                background: transparent;
                left: -16px;
                top: -1px;
                border-color: #BA9E66;
                cursor: pointer;
              }

              &:after {
                width: calc(100% + 30px);
                height: 36px;
                left: -15px;
                top: -6px;
                z-index: -1;
              }
            }
          }
        }
      }

      h3 {
        font-family: 'Crimson Text';
        font-size: 22px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 40px;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .form-actions {
        position: relative;
        display: inline-block;
        margin-bottom: 31px;
        margin-top: 5px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #ba9e66 !important;
          background: #ba9e66;
          z-index: -1;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          transform-origin: center top;
          transition: transform .4s ease-in-out;
          transition-delay: 0;
          z-index: -1;
          transform: scaleY(0) !important;
        }

        input:not([type="submit"]) {
          border: none;
          background: 0 0;
          font-size: 16px;
          line-height: 20px;
          padding: 22px 30px;
          color: #fff;
        }

        input[type="submit"] {
          display: inline-block;
          float: none;
          padding: 20px 30px;
          color: #fff;
          width: auto;
          background: #ba9e66;
          border: none;
          cursor: pointer;
          vertical-align: middle;
          border-radius: 0;
          transition: all .3s;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          &:hover {
            background: #000;
          }
        }

        &:hover:after {
          transform-origin: center bottom;
          transform: scaleY(1) !important;
        }
      }
    }
  }

  .translation_fr {
    display: none;
  }
}

.page-user {
  @media (max-width:700px) {
    .article-header {
      min-height: auto;
    }
  }

  form {
    max-width: 480px;
    margin: 0 auto;
    text-align: left;

    &>div {
      text-align: center;
      justify-content: center;
    }

    .form-item {
      max-width: 100%;

      input {
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-widtH:700px) {
  .node-type-hub-presse {
    .main-content>.content>.full-width:first-of-type .view.ui-news>.container {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      margin-left: 20px;
      margin-right: 20px;
      max-width: calc(100% - 40px);
    }

    .view-presse .col-lg-4 .outer {
      border-bottom: none;
    }

    .view.ui-news.view-display-id-hub_presse_deux>.container .view-header h2 {
      margin-top: 40px;
      margin-bottom: 8px;
    }
  }
}

.node-type-hub-presse .view-presse p.accroche {
  margin-bottom: 0px;
}

.not-logged-in li.translation_en {
  display: none !important;
}

.node-type-webform-presse {
  div#page {
    overflow-y: hidden;
  }
}

.page-taxonomy.section-presse,
.page-taxonomy.section-press {
  .article-header {
    padding-top: 11px;
  }
}

.node-type-hub-presse .view-presse .col-lg-4 .accroche {
  @media (max-width:700px) {
    padding-bottom: 5px;
  }
}

#cboxContent,
#cboxLoadedContent,
#cboxWrapper {
  width: 100% !important;
}

// font size fixes for .em transfonter
body.page-ressources-pedagogiques-recherche .view-ressources-pedagogiques .view-content .outer p.accroche,
.node-type-presse-communique .view-presse .col-lg-4 .accroche,
.presse-category .view-taxonomy-term-presse .col-lg-4 .accroche,
body.node-type-hub-presse .view-presse .col-lg-4 .accroche {
  font-size: .9em;
  line-height: 21px;
}

.page-user #user-login{
 text-align: center;
}

.page-user #user-login .form-item{
  max-width: 100%;
 }

