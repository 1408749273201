.node.node-enseignants-visites,
.node.node-relais-visites {
  .breadcrumbs {
    @include rem((padding: 25px 0 0,
        margin: 0 0 80px));
    border-bottom: none;
  }

  .intro {
    @include rem(margin-bottom, 40px);

    @include desktop {
      padding-top: 0;
    }

    p,
    li {
      margin-top: 0;
      @include rem(margin-bottom, 20px);
      font-family: $base-font-family !important;
      @include font-size-adapt(17px);
      @include line-height-adapt(26px);
    }

    ul {
      margin: 0;
      @include rem(padding, 0 0 0 15px);

      li {
        color: #666;
        @include rem(padding-left, 15px);
      }
    }

    a {
      text-decoration: underline;
    }
  }

  .text--gold {
    color: $color-primary;
  }

  .nav-bar.with-icons {
    padding: 0;

    .form-radios {
      @include tablet {
        display: inline-flex;
        flex-wrap: wrap;
        max-width: 90%;
      }

      .form-type-radio {
        display: block;
        margin: 0;
        padding: 0;
        @include rem(margin-right, 30px);
        box-shadow: none;

        &:first-child label {
          padding-left: 0;
        }

        &:last-child {
          margin: 0;
        }

        input:checked+label {
          color: #000;
          @include rem(border-bottom, 26px);
          border-bottom: 4px solid #000;
        }

        label {
          position: relative;
          display: flex;
          color: #666666;
          @include rem(padding, 25px 0 25px 30px);
          cursor: pointer;

          &:before,
          &:after {
            display: none;
          }

          img {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include rem((max-width: 25px,
                max-height: 25px));
          }
        }
      }
    }
  }

  .visit-description {
    @include rem(margin-bottom, 20px);

    @include desktop {
      @include rem(margin-bottom, 40px);
    }

    .row {
      display: none;

      &.visible {
        display: flex;
        @include rem(padding-top, 60px);
      }

      .col {
        @include rem(margin-bottom, 40px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p.icon {
      position: relative;
      @include rem(padding-left, 40px);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include rem((width: 26px,
            height: 26px));
        border-radius: 50%;
        z-index: 1;
      }

      &:before {
        position: absolute;
        @include rem((top: 7px,
            left: 7px,
            width: 12px,
            height: 12px));
        @include font-size(12px);
        line-height: 1;
        color: #fff;
        z-index: 2;
      }

      &.icon-checked {
        &:after {
          background: #48D668;
        }
      }

      &.icon-fountain {
        &:after {
          background: #7293CB;
        }
      }

      a {
        margin: 0;
      }

      &+ul {
        @include rem(margin-top, -20px);
        @include rem(padding-left, 60px);
      }
    }

    p,
    ul {
      @include rem(margin, 0 0 25px);

      &:last-child {
        margin: 0;
      }
    }

    p,
    li {
      color: #666;
      @include font-size(17px);
      @include rem(line-height, 21px);
    }

    a {
      display: inline-block;
      @include rem(margin-top, 20px);
      text-decoration: underline;
    }

    ul {
      @include rem(padding-left, 20px);
    }
  }

  .view.view-espace-enseignants,
  .view.view-espace-relais {
    .ticket {
      position: relative;
      @include rem(margin-bottom, 35px);
      @include rem(padding, 40px 20px 0);
      border: 1px solid #E6E6E6;

      @include tablet {
        @include rem(padding, 40px 20px 40px 55px);
      }

      &.class--full {
        @include rem(padding, 60px 20px 40px 20px);

        @include tablet {
          @include rem(padding, 60px 20px 40px 55px);
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          pointer-events: none;
        }

        &>.row {
          opacity: 0.7;
        }

        * {
          pointer-events: none;
        }

        .read-more-content a {
          pointer-events: auto;
        }

        p.title,
        span.text--gray,
        p.field-content,
        .read-more-content,
        .read-more-content a {
          color: #333 !important;
        }
      }

      p.uppertitle {
        display: inline-flex;
        position: relative;
        @include rem(padding-left, 25px);
        color: $color-primary;
        margin: 0;

        .svg-wrapper {
          @include rem(margin-right, 5px);

          svg {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: auto;
            height: auto;
            @include rem((max-width: 25px,
                max-height: 25px));

            * {
              fill: $color-primary;
            }

            mask {
              display: none;
            }
          }
        }
      }

      p.full {
        position: absolute;
        margin: 0;
        @include rem((top: 25px, left: 55px, padding: 5px));
        color: #D0021B;
        @include font-size-rem-adapt(14px);
        @include line-height-adapt(14px);
        text-transform: uppercase;
        border: 1px solid #D0021B;
      }

      p.title {
        @include rem(margin, 0 0 15px);
        color: #010101;
        @include font-size-rem-adapt(24px);
        @include line-height-adapt(32px);
      }

      p {
        color: #333333;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(25px);
      }

      .read-more-content {
        &+.row {
          @include rem(margin-top, 10px);

          p {
            @include rem(margin, 5px 0);
          }
        }

        .read-more-content-bottom {
          display: none;

          a {
            margin-top: 0;
          }
        }

        a {
          display: inline-block;
          @include rem(margin-top, 20px);
          text-decoration: underline;
        }
      }

      .price {

        &:focus,
        &:hover {
          color: inherit;

          &:after {
            display: none;
          }
        }
      }

      .billet {
        text-align: right;

        p.tooltip {
          position: relative;
          display: block;
          color: inherit;
          height: 20px;
          @include font-size-rem-adapt(12px);
          @include line-height-adapt(20px);
          @include rem(margin-bottom, 16px);
          @include rem(padding-right, 30px);
          padding-bottom: 0;
          background: none;

          &:after {
            display: block;
            content: '?';
            @include font-size-rem-adapt(16px);
            @include rem((width: 20px,
                height: 20px,
              ));
            line-height: 20px;
            text-align: center;
            background: #f1efea;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            @include rem(margin-top, -10px);
          }
        }

        label {
          display: none;
        }

        .select-a11y {
          @media (min-width:700px) {
            max-width: 250px;
            margin-left: auto;
          }
        }

        .view-accessibilite+.select-a11y {
          margin-top: 20px;
        }
      }

      .selectric-wrapper {
        width: 100%;
        border-color: $color-primary;

        @include desktop {
          @include rem(width, 250px);
        }

        .selectric {
          width: 100%;
          border-color: $color-primary;
        }
      }

      .btn-group {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: 0;
        @include rem(margin-bottom, 10px);
        width: 100%;

        @include desktop {
          @include rem(width, 250px);
        }

        &>.btn {
          flex: 0 0 100%;
          @include rem(padding, 20px);
          @include line-height-adapt(26px);

          @include desktop {
            flex: 1 1 auto;
          }
        }
      }

      .text-bottom {
        display: inline-block;
        margin: 0;
        @include rem(margin-bottom, 10px);
        color: #9B9B9B;
        @include font-size-adapt(14px);
        @include line-height-adapt(20px);
        text-align: right;

        @include tablet {
          // @include rem(max-width, 205px);
        }

        p {
          color: inherit;
          font-size: inherit;
          margin: 0;
          line-height: inherit;
        }
      }

      .view-accessibilite {
        .item-list {
          text-align: right;
        }
      }
    }
  }
}

.view-espace-enseignants.view-id-espace_enseignants,
.view-espace-relais.view-id-espace_relais {
  .container {
    padding-top: 0;
  }
}

#block-views-espace_enseignants-bloc_fixe,
#block-views-espace_relais-bloc_fixe {
  @include rem(padding, 45px 20px);
  @include rem(margin-bottom, 35px);
  background: #F1EFEA;

  @include tablet {
    @include rem(padding, 45px 50px);
  }

  .col-xs-12+.col-xs-12 {
    text-align: right;
  }

  h4 {
    margin: 0;
    @include font-size-adapt(24px);
    @include line-height-adapt(26px);
  }

  p {
    color: $color-primary;
    font-family: $font-crimson;
    @include rem(margin, 4px 0 0);
    @include font-size-adapt(20px);
    @include line-height-adapt(26px);
  }

  .btn {
    @include rem(margin-top, 20px);
    color: #000;

    @include tablet {
      margin: 0;
    }
  }
}


.node.node-relais-visites {
  .view.view-id-push_image .outer {
    background: none;
    text-align: center;

    @media (max-widtH:700px) {
      background: #fff;
    }

    .inner {
      @include rem(padding, 30px 30px 45px);

      @media (max-widtH:700px) {
        padding-left: 0;
        text-align: left;
        padding-bottom: 0;
      }

      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }

      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;

        .ext {
          display: none;
        }

        &:before {
          background: none;
        }
      }
    }
  }

  .content {
    .views-exposed-form {
      .nav-bar {
        .form-radios {
          .form-type-radio {
            @include rem(margin-right, 15px);
          }
        }
      }
    }
  }
}

.node-hub-contenus {
  &.relais-evenements {
    .breadcrumbs {
      border-bottom: none;
    }

    .view-hub-contenus {
      .view-header {
        text-align: center;

        h2 {
          @include font-size-adapt(34px);
          max-width: 900px;
          margin: 0 auto;
        }
      }
    }
  }
}

.node-relais-modalites,
.node.relais-evenements-handicap,
.node.relais-evenements-publics,
.node.parcours-handicap,
.node.parcours-publics,
.node.devenir-relais {
  .breadcrumbs {
    border-bottom: none;
  }

  .view.view-id-push_image .outer {
    background: none;
    text-align: center;

    @media (max-widtH:700px) {
      background: transparent;
      text-align: left;
    }

    .inner {
      @include rem(padding, 30px 30px 45px);

      @media (max-widtH:700px) {
        padding-top: 20px;
        padding-left: 0;
        text-align: left;
        padding-bottom: 0;
      }

      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }

      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;

        @media (max-widtH:700px) {
          margin-left: auto;
        }

        .ext {
          display: none;
        }

        &:before {
          background: none;
        }
      }
    }
  }
}

.parcours-handicap,
.parcours-publics {
  .breadcrumbs {
    border-bottom: none;
  }
}

.node.node-enseignants-visites {
  .content {
    .views-exposed-form {
      .visit-description {
        .relais {
          display: none;
        }
      }
    }
  }
}

.node.node-relais-visites {
  .content {
    .views-exposed-form {
      .visit-description {
        .enseignants {
          display: none;
        }
      }
    }
  }
}
