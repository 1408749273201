.node-hub-agenda {
  .agenda-calendar {
    @include rem(padding, 40px 0);
    border-bottom: 1px solid #E6E6E6;

    .col-left {
      position: relative;
    }
  }
  .calendar-box {
    position: relative;
  }

  .view.view-categories {
    border: none;
    @include rem(margin-top, 40px);
    text-align: center;
    @include desktop {
      margin-top: 0;
      text-align: left;
    }
    & > .container {
      padding: 0;
    }
    .view-content {
      display: inline-block;
      & > div {
        text-align: left;
      }
    }
    span {
      position: relative;
      display: block;
      @include rem(padding-left, 20px);
      @include rem(padding, 10px 0 10px 20px);
      cursor: pointer;
      opacity: 1;
      transition: all 0.2s;
      &.transparent {
        opacity: 0.2;
      }
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        display: inline-block;
        @include rem(( margin-top: -5px, width: 10px, height: 10px ));
        border-radius: 50%;
      }
    }
  }

  /* Activités famille */
  .visite-tid-6:before {
    background: #44D620;
  }
  /* Auditorium  */
  .visite-tid-323:before {
    background: #B22986;
  }
  /* Expositions  */
  .visite-tid-331:before {
    background: #D67A24;
  }
  /* Spectacles  */
  .visite-tid-324:before {
    background: #931944;
  }
  /* Visites guidées */
  .visite-tid-2:before {
    background: #67A6A6;
  }

  .view-home.view {
    .view-header {
      display: none;
    }
  }
}

#agenda-billets-list {
  h2 {
    font-family: $heading-font-family;
    @include rem(margin, 70px 0 30px);
    @include font-size-adapt(20px, 24px);
    text-transform: lowercase;
    text-align: center !important;
  }
  .nav-bar--inverted {
    display: none;
  }
  .nav-bar {
    @include desktop {
      border: none;
    }
    .container {
      @include desktop {
        border-bottom: 1px solid #E6E6E6;
      }
      .bef-select-as-links .form-type-bef-link {
        @include rem(margin-right, 20px);
        &:last-child {
          margin-right: 0;
        }
        a {
          @include rem(margin-bottom, -1px);
          @include rem(padding, 10px);
          border-bottom: 1px solid transparent;
          &:hover, &.active {
            border-color: #000;
          }
        }
      }
    }
  }
  .view.view-billets-tarifs {
    border: none;
    & > .container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

#agenda-lieux-attendance {
  border-bottom: 1px solid #E6E6E6;
  h2 {
    font-family: $heading-font-family;
    @include rem(margin, 70px 0 30px);
    @include font-size-adapt(20px, 24px);
    text-transform: lowercase;
    text-align: center !important;
  }
}


.view-date-agenda.view.ui-news h4{
  position: unset;
  
  a{
    position: unset;
  }
}