.view.view-hub-ressources {

  &.view-display-id-page {
    @include rem(padding, 20px 0);

    @media (max-width:700px) {
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 0;

      .view-filters {
        margin-bottom: 0 !important;
      }
    }

    .view-content {
      // & > *{
      // opacity:1;
      // }
      // & > *:last-child{
      // opacity:0.5;
      // }

      #go-top {
        display: none;
      }

      @include desktop {
        #go-top {
          display: block;
          position: fixed;
          bottom: 50%;
        }
      }

      .row {
        margin-bottom: 0 !important;
        @media (max-width: 900px) {
          display: flex;
          flex-direction: row;
          .col-xs-12{
            flex: auto;
            &.col-md-4{
              padding-right: 0;
              width: 40%;
            }
            &.col-md-8{
              width: 60%;
              h4{
                padding-top: 0;
                margin-bottom: 10px;
              }
              p{
                line-height: 20px;
              }
            }
          }
        }
        .inner {
          padding: 0 20px;
        }
      }
    }
  }

  &.view-display-id-block_une {
    .outer {
      border-bottom: none;

      p {
        &.text {
          text-align: left;
          margin-bottom: 0px;

          @include desktop {
            margin-bottom: 80px;
          }
        }
      }
    }
  }

  .row {
    [class^="col-"] {
      margin: 0 !important;
    }

    h4 {
      padding-top: 20px;
      text-align: left;
      margin: 0 0 20px 0;

      @include desktop {
        padding-top: 0px;
      }
    }
  }

  .view-filters {
    @include rem(margin-bottom, 0px);

    @include desktop {
      @include rem(margin-bottom, 40px);
      transition: margin-bottom 0.3s linear;

      &.expanded {
        @include rem(margin-bottom, 90px);
      }

    }

    .bef-select-as-radios {
      width: 100%;

      .bef-tree {
        margin: 0;
        padding: 0;
        list-style-type: none;
        @media (max-width: 900px) {
          white-space: nowrap;
          position: relative;
          overflow-x: auto;
          overflow-y: hidden;
          padding: 0 0 40px 20px;
          .bef-tree-child{
            padding: 5px 0 5px 20px !important;
            width: auto !important;
            label{
              font-size: 12px !important;
              &:before{
                margin-top: -9px;
                width: 15px;
                height: 15px;
              }
              &:after{
                left: 6px;
                margin-top: -5px;
              }
            }
          }
        }

        li {
          display: inline-block;
          @include rem(margin-right, 35px);

          @media (max-width: 900px) {
            @include rem(margin-right, 15px);
          }

          .form-item {
            padding-left: 0px;
            margin-right: 0px;
            @include rem(line-height, 28px);
            border-bottom: 4px solid transparent;
            cursor: pointer;
            transition: all .3s;

            &.active {
              border-bottom-color: #000;
            }

            label {
              @include rem(font-size, 16px);
              @include rem(padding, 30px 0 26px);
              color: #666;
              cursor: pointer;
              transition: all .3s;
              display: inline-block;

              @media (max-width: 900px) {
                @include rem(font-size, 14px);
                @include rem(padding, 5px 0 5px);
              }

              &:before,
              &:after {
                display: none;
              }
            }

            &:focus, &:hover {
              label {
                color: #ba9e66;
              }
            }

            &.checked {
              border-bottom-color: #000;

              label {
                color: #000;
              }
            }
          }
        }

        .bef-tree-child {
          display: none;
          margin: 0;
          @include rem(padding, 20px 0);
          position: absolute;
          left: 0;
          background-color: #f1efea;
          width: 100vw;
          margin-left: -50vw;
          left: 50%;
          text-align: center;

          li {
            margin-right: 0px;

            .form-item {
              margin-right: 20px;
              padding-left: 25px;
              padding-bottom: 0;
              padding-top: 0;
              border: 0;

              label {
                @include rem(font-size, 14px);
                color: #666;
                padding: 0;
                font-weight: 400;

                &:before,
                &:after {
                  display: block;
                  top: 55%;
                }
              }
            }
          }

          &.visible {
            display: block;
          }
        }
      }
    }
  }

  .view-empty{
    @media (max-width: 900px) {
      padding: 20px 0 20px 0;
      font-size: 14px;
    }
  }

  .nav-bar {
    padding: 0;
    border: none;

    @include desktop {
      border-bottom: 1px solid rgba(15, 15, 15, .1);
    }

    .views-exposed-widgets {
      @include desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &:before,
      &:after {
        display: none;
      }

      .views-exposed-widget {
        float: none;
        padding: 0;
        @include rem(margin-top, 30px);

        @include desktop {
          margin: 0;
        }

        &:first-child {
          margin: 0;
        }

        div.selectric-wrapper div.selectric {
          width: 100%;

          @include desktop {
            @include rem(width, 240px);
          }
        }

        .form-item-field-ressource-type-tid {
          padding: 0;
          margin: 0;

          .bef-select-as-links {
            &>.form-item {
              margin: 0;
            }
          }

          .description {
            display: none;
          }
        }

        &.views-widget-filter-field_ressource_public_tid_i18n {
          .form-item {
            margin: 0;
          }
        }
      }

      &.desk {
        //display: none;
        @media (max-width: 900px) {
          padding: 0px;
        }
      }

      &.mobile {
        display: block;
        padding: 20px 0 20px 0;
        border-top: 1px solid #F0F0F0;
        background-color: #f1efea;

        .shs-select {
          opacity: unset;
          margin: 0 auto 10px;
          padding: 20px;
          width: 80%;
          border: 1px solid #F0F0F0;
        }

        &.shs-select-level-2 {
          margin-bottom: 0;
        }
      }

      @include desktop {
        &.mobile {
          display: none;
        }

        &.desk {
          display: block;
        }
      }

      .lo-circular {
        position: absolute;
        right: 0;
        top: 30px;
      }

    }

    .form-type-bef-link {
      @include rem(margin-right, 35px);

      a {
        display: block;
        @include rem(padding, 30px 0 26px);
        @include rem(line-height, 28px);
        border-bottom: 4px solid transparent;

        &.active {
          border-bottom-color: #000;
        }
      }
    }
  }

  .outer {
    padding-top: 0 !important;
    padding-bottom: 20px;

    @include desktop {
      border-bottom: 1px solid #f1efea;
      padding-bottom: 0px;
    }

    .btn {
      display: none;

      @include desktop {
        display: block;
      }
    }
  }

  .view-header {
    @include rem(padding-bottom, 25px);
  }

  .row:last-child {
    .views-column-last .news {
      border-bottom: 0;
    }
  }

  .row .outer {
    @media (max-width:700px) {
      padding-top: 30px !important;
      padding-bottom: 0 !important;
    }
  }

  &.view.ui-news.view-display-id-block_une>.container {
    @media (max-width:700px) {
      padding: 0 20px;
    }
  }

  .news {
    height: 100%;
    @include rem((padding: 25px 0));

    @media (max-width:700px) {
      padding: 0;
    }

    @include desktop {
      border: none;
    }

    .col-md-5 {
      @include rem(margin-bottom, 10px !important);

      @include desktop {
        @include rem(padding-right, 5px);
        @include rem(margin-bottom, 0px !important);
      }
    }

    .col-md-7 {
      margin-bottom: 0 !important;
    }

    p.tags {
      @include rem(margin-bottom, 0);
      @include rem(line-height, 26px);

      @include desktop {
        @include rem(margin-bottom, 25px);
        line-height: 1;
      }
    }

    .col-xs-12>a {
      position: relative;
      display: block;
      overflow: hidden;

      &:hover:before {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        opacity: 0;
        transition: all 0.3s;
        z-index: 2;
      }
    }

    img {
      margin: 0;
    }

    .inner {
      @include rem(padding-top, 10px);

      @include tablet {
        padding: 0;
        padding-left: 0;
      }

      p {
        line-height: 1.8;

        &.tags {
          margin-top: 0;
          @include rem(margin-bottom, 10px);
          line-height: 1.7;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 1.2;
      }

    }
  }

  .item-list .pager {
    @include rem(margin, 40px 0 0);

    @include desktop {
      @include rem(margin, 60px 0);
    }

    @media (max-width:700px) {
      margin: 20px 0;
      padding: 20px 0;
    }
  }

  .view-footer {
    @include rem(margin-top, 40px);

    .btn {
      width: 100%;

      @include tablet {
        width: auto;
      }
    }
  }
}
