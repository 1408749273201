.node-lieu {
  .ui-intro {
    @include rem((
      margin: 0 -30px,
      padding: 40px 30px
    ));
    @include desktop {
      margin: 0;
      @include rem(margin-top, -330px);
    }
    .row {
      .text-content .full-width:last-child {
        margin-bottom: 0;
        .view {
          margin-bottom: 0;
        }
      }
    }
    .text--lead{
      text-align: left;
    }
  }
  .share-print-size {
    position: absolute;
    @include rem((
      top: 120px,
      right: 25px
    ))
    a {
      display: block;
      margin: 0 0 -1px;
      float: none;
    }
  }
  .view-breves {
    border: none;
    @include rem(padding, 30px 0);
    @include rem(margin-bottom, 40px);
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    .container {
      padding: 0;
      .view-grouping-content {
        padding: 0;
        .views-row {
          border: none;
        }
        p {
          margin: 0;
          font-family: $font-crimson;
          @include rem(font-size, 18px);
          @include rem(line-height, 22px);
        }
      }
    }
  }
  .view-widget-cta {
    @include rem(margin-top, 40px);
    @include desktop {
      @include rem(margin-top, 60px);
    }
  }
  .share-print-size {
    position: absolute;
    @include rem((
      top: 120px,
      right: 30px
    ))
    li {
      float: none;
      position: relative;
      @include rem(margin-top, -1px);
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
