.node.node-ressource {
  .content {
    @include rem(padding, 50px 30px);

    @include tablet {
      @include rem(padding, 50px 0);
    }

    .text--lead {
      margin-top: 0;
    }
  }
}

#exit-warning-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

#popup-content {
  background-color: #f1efea;
  padding: 1em 2em 1.5em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80vw;
  max-height: 80vh;
  overflow: auto;

  strong {
    font-family: Apollon, Arial, sans-serif;
    color: #ba9e66;
    text-transform: uppercase;
  }
}

#popup-actions {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

#popup-actions button {
  padding: 0.5em 1em;
  margin: 0 0.5em;
  border: 1px solid black;
  background-color: #f1efea;
  cursor: pointer;

  &:focus,
  &:hover {
    color: #f1efea;
    background-color: black;
  }
}
