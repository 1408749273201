.node-epoque {
  .view-actualites {
    border-top: 1px solid #e6e6e6;
  }
  .text-content {
    & > h3 {
      @include font-size(20px, 24px);
      @include line-height-adapt(24px, 28px);
    }
  }
}
