.section-ressources-pedagogiques,
.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique {
  header#header #logo {
    left: 50%;

    img.mobile {
      display: none;

      @media (max-width:700px) {
        display: block;
        max-width: 70px;
      }
    }

    img:not(.mobile) {
      @media (max-width:700px) {
        display: block;
        max-width: 100px;
      }
    }
  }
}

.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique {
  .view-ressources-pedagogiques .col-lg-4 .accroche {
    font-size: .95em;
  }

  #main {
    margin-top: 50px;
  }

  header#header {
    top: 36px;

    @media (max-width:700px) {
      top: 0;
    }

    &.fixed {
      top: 0;
    }

    .bottom-header {
      .search-icon {
        padding: 0;
        display: inline-block;
        font-size: 16px;
        position: relative;
        top: 2px;

        @media (max-width:900px) {
          position: absolute;
          left: 20px;
          top: 25px;
        }

        @media (max-width:900px) {
          display: none;
        }
      }
    }
  }

  .top-header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: #2d2d2d;
    padding: 8px 40px;

    @media (max-width:700px) {
      display: none;
    }

    a {
      font-size: 0.8em;
      line-height: 15px;
      color: #FFFFFF;
      background: url(../images/externalink.svg) no-repeat top -1px right 11px;
      padding-right: 30px;

      &:focus,
      &:hover {
        color: #ba9e66;
        background: url(../images/externalink-gold.svg) no-repeat top -1px right 11px;
        transition: all .3s;
      }
    }
  }

  header#header #logo span {
    @media (max-widtH:700px) {
      font-size: 11px;
    }
  }

  .breadcrumbs {
    padding-bottom: 11px;
    padding-top: 14px;
  }

  .article-header {
    background-size: cover;
    min-height: 500px;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;

    .black-bg {
      width: 100vw;
      position: relative;
      margin-left: -50vw;
      left: 50%;
      background: #000;
      padding: 40px;

      @media (max-width:700px) {
        padding: 40px 30px 10px;
      }
    }

    h1 {
      color: #fff;
      text-align: left;
      font-size: 2em;
      line-height: 45px;

      @media (max-width:700px) {
        font-size: 30px;
        line-height: 35px;
      }
    }

    img {
      display: block !important;
      min-height: 375px !important;
      object-fit: cover;

    }
  }

  .main-content {
    .col-md-offset-2 {
      @media (min-width:1100px) {
        margin-left: auto !important;
        margin-right: 50px;
      }

      .chapter>p {
        color: #333333;
        font-size: 1.19em;
        line-height: 32px;

        @media (max-width:700px) {
          font-size: 15px;
          line-height: 27px;
        }
      }
    }

    .chapter {
      position: relative;

      &.fade-bg {
        overflow: hidden;
      }
    }

    .text--lead {
      margin-bottom: 20px;
      color: #666;
      border: none;
      margin-top: 50px;
      font-size: 1.7em;
      line-height: 34px;

      @media (max-widtH:700px) {
        margin-top: 30px;
        margin-bottom: 0;
      }
    }

    .text-content {
      h3 {
        font-family: 'Crimson Text';
        font-size: 1.37em;
        line-height: 30px;
        color: #000000;
      }
    }

    .widget-documents .documents-wrapper {
      margin-bottom: 20px;

      @media (max-width:700px) {
        margin-bottom: 10px;
      }

      .document-single {

        .document-image.no-image {
          display: flex;
          align-items: center;

          img {
            margin-top: -10px;
          }
        }

        .document-text {
          margin-bottom: 40px;

          .description {
            margin-bottom: 40px;
          }

          ul {
            margin-bottom: 10px;
          }
        }

        .document-info {
          justify-content: flex-start;
        }
      }
    }
  }

  .related {
    .view-ressources-pedagogiques {
      .view-header {
        padding-bottom: 10px;
      }

      div .outer {
        border-bottom: none;

        .accroche {
          padding-bottom: 0;

        }
      }
    }
  }


  // mobilem enu fixes only for resso pedago
  // .mobile-menu.open>.container {
  //   @media (max-widtH:900px) {
  //     display: flex;
  //     flex-direction: column;

  //     a.teacher-link {
  //       color: #333;
  //       text-align: left;
  //       padding-left: 20px;
  //       padding-top: 24px;
  //       border-top: 1px solid #e6e6e6;
  //       border-bottom: 1px solid #e6e6e6;
  //       padding-bottom: 24px;
  //     }

  //     form.search {
  //       width: 100%;
  //       padding-left: 50px;
  //       padding-bottom: 22px;
  //       margin-top: 22px;
  //       border-bottom: 1px solid #e6e6e6;
  //       position: relative;

  //       input {
  //         width: calc(100% - 20px);
  //         border: none;
  //         margin-right: 20px;
  //       }

  //       .form-actions button {
  //         left: 16px;
  //         top: -10px;
  //       }
  //     }

  //     .menu-bottom {
  //       margin-top: auto;

  //       a:last-of-type {
  //         background: url(../images/externalink.svg) no-repeat top 2px left 192px;
  //       }
  //     }
  //   }
  // }
}

.view-display-id-plus_loin {
  margin-bottom: 80px;

  @media (max-width:700px) {
    margin-bottom: 0;
  }

  .view-content div {
    .outer {
      background: #F1EFEA;

      h4,
      .accroche {
        padding: 0 32px;
      }

      .accroche {
        margin-bottom: 32px;
        font-size: 20px;
        line-height: 24px;
        font-family: 'Crimson Text';
      }

      .image-wrapper {
        overflow: hidden;
      }

      h4 {
        margin-top: 32px !important;
        color: #000000;
      }
    }
  }
}

.view-id-taxonomy_term_ressources_pedagogiques {
  .view-footer {
    margin-top: 0;
  }

  .view-header {
    h2 {
      font-size: 2em;
      line-height: 45px;
    }
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    .row {
      width: 100%;
      max-width: calc(100% / 4 - 30px);
      margin-bottom: 40px;
      margin-left: 0;
      margin-right: 0;
      height: 100%;

      @media (max-width:1100px) {
        max-width: calc(100% / 3 - 30px);
      }

      @media (max-width:900px) {
        max-width: calc(100% / 2 - 30px);
      }

      @media (max-width:700px) {
        max-width: 100%;
        margin-bottom: 20px;

      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #ba9e66;
        padding: 16px;
        width: 100%;
        align-self: center;
        // min-height: 255px;

        // img {
        //   max-width: 100px;
        // }

        .svg-wrapper {
          height: 150px;
          display: flex;
          width: 100%;
          justify-content: center;

          svg {
            max-width: 100px;
          }
        }

        .png-wrapper {
          align-self: center;
          margin-top: auto;
          margin-bottom: 20px;
        }

        span {
          font-size: 1em;
          line-height: 22px;
          text-align: center;
          color: #000000;
          font-family: 'Apollon';
          margin-top: auto;
        }

        &:focus,
        &:hover {
          background: #F1EFEA;

          span {
            color: #000;
          }
        }
      }
    }
  }

  &.sub-cat .view-content {
    &:after {
      content: '';
      max-width: calc(100% / 3 - 30px);
      widtH: 100%;
    }

    .row {
      max-width: calc(100% / 3 - 30px);
      height: 100%;

      @media (max-width: 700px) {
        max-width: calc(100% / 2 - 30px);
      }

      @media (max-width: 700px) {
        max-width: 100%;
        margin-bottom: 40px;

        img {
          width: 100%;
        }
      }

      img {
        max-width: unset;
      }

      h4 {
        margin-bottom: 0;
        width: 100%;
        margin: 16px 0;
        margin-bottom: 0px;

        &:focus,
        &:hover {
          background: #000;
        }

        a {
          height: 100%;
          display: block;
          padding: 32px !important;

          &:focus,
          &:hover {
            color: #fff;
          }
        }
      }

      &:hover h4 {
        background: #000 !important;

        a {
          color: #fff !important;
        }
      }
    }
  }

  &.sub-cat-content {
    .view-content .row {
      max-width: 100%;

      p.tags {
        font-size: 1.1em;
        line-height: 14px;
        text-transform: uppercase;
        color: #000;
        margin: 8px 0;
      }

      p.accroche {
        margin-bottom: 0;
        font-size: .95em;
        line-height: 22px;
        color: #666;
      }

      h4 {
        font-size: 18px;
        line-height: 22px;
        margin: 16px 0;
      }

      a {
        padding: 0;
        border: none;
        text-align: left;
        align-items: flex-start;

        &:focus,
        &:hover {
          background: none;
        }

        img {
          padding: 0;
        }
      }
    }
  }
}

.node-type-hub-ressources-pedagogiques {
  .paragraphs-items-field-presse-push-noir .full-width .entity-paragraphs-item {
    margin-top: 30px;
  }

  .view>.container {
    @media (min-widtH:1100px) {
      padding-top: 80px;
    }
  }

  .article-header.ui-header {
    min-height: 375px !important;

    .text--center {
      position: absolute;
      top: 160px;
      width: 100%;

      h1 {
        text-align: center;
        font-size: 3.5em;
        line-height: 80px;

        @media (max-width:900px) {
          font-size: 36px;
          line-height: 45px;
        }
      }
    }
  }

  .view.view-ressources-pedagogiques {
    .view-header {
      padding-bottom: 10px;

      h2 {
        font-size: 2em;
        line-height: 45px;
        margin-bottom: 12px;
      }
    }

    .view-content div .outer {
      border-bottom: none;

      h4 {
        margin-top: 16px;
      }

      p.tags {
        font-size: 0.85em;
        line-height: 14px;
        text-transform: uppercase;
        color: #000000;
        margin: 8px 0px;
      }

      .accroche {
        padding-bottom: 0;
      }
    }
  }
}

body .ressources-pedagogiques-category {

  header#header {
    top: 36px;

    @media (max-width:700px) {
      top: 0;
    }

    &.fixed {
      top: 0;
    }

    .bottom-header {
      .search-icon {
        padding: 0;
        display: inline-block;
        font-size: 16px;
        position: relative;
        top: 2px;

        @media (max-width:900px) {
          display: none;
        }
      } 
    }
  }

  .top-header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: #2d2d2d;
    padding: 8px 40px;

    @media (max-width:700px) {
      display: none;
    }

    a {
      font-size: 0.8em;
      line-height: 15px;
      color: #FFFFFF;
      background: url(../images/externalink.svg) no-repeat top -1px right 11px;
      padding-right: 30px;

      &:focus,
      &:hover {
        color: #ba9e66;
        background: url(../images/externalink-gold.svg) no-repeat top -1px right 11px;
        transition: all .3s;
      }
    }
  }

  .article-header {
    height: 400px;
    background-color: #DBCEBE;

    @media (min-width:1700px) {
      background-size: 70%;
    }

    @media (max-width:700px) {
      height: 250px;
      padding-top: 20px;
    }

    h1 {
      font-size: 2em;
      line-height: 45px;
      color: #FFFFFF;

      @media (max-width:700px) {
        font-size: 26px;
        line-height: 31px;
        margin-top: 20px;
      }
    }
  }

  .view-id-taxonomy_term_ressources_pedagogiques.sub-cat .view-content .row {
    a {
      padding: 0;
      border: none;
      max-height: 208px;
      min-height: auto;

      img {
        padding: 0;
        display: block;
        max-width: 100%;
      }
    }

    &>a:hover {
      background: none;
    }

    h4 {
      margin-top: 0;
      bordeR: none;
      background: #F1EFEA;
      max-height: 95px;

      &:focus,
      &:hover {
        background: #000;

        a {
          background: transparent;
          color: #fff !important;
        }
      }
    }
  }

  .view>.container {
    @media (max-widtH:700px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding: 2.5rem 0.875rem;

      .row a img {
        width: 100%;
      }
    }
  }

}

.ressources-pedagogiques-category.child-hub {
  .article-header {
    @media (min-width:1700px) {
      background-size: 100%;
    }
  }
}

.view-widget-ressources-pedagogiques.ressource-externe {
  &>.container {
    padding: 20px 0;

    @media (max-width:700px) {
      padding: 0;
    }
  }

  .view-content {
    .row>div {
      display: flex;

      @media (max-widtH:900px) {
        flex-direction: column;
        margin-bottom: 10px;
      }

      .left {
        width: 245px;

        @media (max-widtH:900px) {
          width: 100%;
        }

        img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
        }
      }

      .right {
        width: calc(100% - 245px);
        background: #F1EFEA;
        padding: 32px 40px;

        @media (max-widtH:900px) {
          width: 100%;
          padding: 20px;
        }

        .row-title {
          font-family: 'Apollon';
          font-style: normal;
          font-weight: normal;
          font-size: 1.18rem;
          line-height: 22px;
        }

        .row-link a {
          position: relative;
          padding-bottom: 4px;
          color: #000;
          border-color: #ba9e66;
          background: url(../images/externalink.svg) no-repeat top -1px right 11px;

          &:focus,
          &:hover {
            color: #ba9e66;
          }
        }

        p {
          font-size: 1.07rem;
          line-height: 120%;
          color: #666666;
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.node-type-ressource-pedagogique,
.section-ressources-pedagogiques {

  header#header #logo {
    left: 50%;

    img.mobile {
      display: none;

      @media (max-width:700px) {
        // display: block;
        display: none;
        max-width: 70px;
      }
    }

    img:not(.mobile) {
      @media (max-width:700px) {
        // display: none;
      }
    }
  }

  #main {
    margin-top: 50px;
  }

  //menu in header select 
  header#header .right {
    margin-top: 10px;
   
  }

  header#header .left {
    .menu-block-wrapper {
      position: relative;

      &>ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0;
        margin-left: 10px;
        list-style: none;
        margin-top: 20px;

        a {
          cursor: pointer;
          background: none;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        &>li {
          list-style: none;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 50px;
            top: 30px;
            z-index: 5;
          }

          &.last:after {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 5px 6px 4px 0;
            border-color: transparent #fff transparent transparent;
            transform: rotate(-90deg);
            right: 12px;
            top: 10px;
          }

          a {
            cursor: pointer;
          }
        }
      }

      ul.menu {
        li {
          .menu {
            display: none;
            border: 1px solid #e6e6e6;
            box-shadow: 0 0 2px #333;

            ul {
              display: none;
              list-style: none;
              padding-left: 0;

              li {
                list-style: none;


                a {
                  color: #000;
                }
              }
            }

            &>li>a {}
          }
        }

        &>li {}
      }

      &.open {
        &>.menu>.last {
          &:after {
            transform: rotate(90deg);
            border-color: transparent #ba9e66 transparent transparent;
          }

          a {
            color: #ba9e66;
          }
        }

        ul.menu li .menu {
          &.visible {
            display: block;
            position: absolute;
            top: 50px;
            widtH: 300px;
            background-color: #fff;
            padding-left: 0;
            margin-left: 0;
            // max-height: 60vh;
            // overflow: hidden;
            // overflow-y: auto;

            li {
              list-style: none;
              padding: 0;
              position: relative;
              border-bottom: 1px solid #e6e6e6;

              &:focus,
              &:hover {
                background: #f1efea;
              }

              a {
                color: #000;
                display: block;
                padding: 1.4375rem 0.9375rem;
                font-size: 16px;
              }

              ul.visible {
                right: -301px;
                left: auto;
                top: -1px;
                border-left: none;
                box-shadow: none;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.node-type-ressource-pedagogique,
.section-ressources-pedagogiques,
.node-type-hub-ressources-pedagogiques {
  .view.view-ressources-pedagogiques {
    .view-content div .outer {

      .news>a {
        display: block;
        overflow: hidden;
      }

      img {
        transform: scale(1);
        transition: .2s;
        width: 100%;
      }

      &:hover img {
        transform: scale(1.05);
        transition: .2s;
      }
    }

    &.view-display-id-related_ref {
      .row [class^=col-] {
        @media (max-width:700px) {
          margin-bottom: 20px;
        }
      }
    }
  }

  footer#page-footer .prefooter .prefooter-bottom.text--center>a {
    position: relative;
    background: none;

    &:before {
      content: '';
      background: url(../images/right-arrow-white.svg) no-repeat 4px 5px;
      position: absolute;
      left: 6px;
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      top: -1px;
      transition: .1s;

    }

    &:focus,
    &:hover {
      background: none;
      transition: .1s;

      &:before {
        left: 4px;
        transition: .1s;
        background: url(../images/right-arrow-gold.svg) no-repeat 4px 5px;
      }
    }
  }

  header#header #logo span {
    @media (max-width:700px) {
      max-width: 160px;
      text-align: right;
      font-size: 16px;
      line-height: 18px;
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  .view-header h2 {
    @media (max-width:700px) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }

  .view.view-display-id-une>.container {
    @media (max-width:700px) {
      padding-bottom: 20px;

      .view-content .row>div:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

a.back-home {
  color: #000;
  position: relative;
  display: inline-block;
  padding-bottom: 1px;
  margin-bottom: 100px;
  background: url(../images/arrow-right.svg) no-repeat top 2px left 0px;
  padding-left: 25px;
  text-decoration: underline;
  text-decoration-color: #ba9e66;
  text-underline-offset: 4px;

  &:focus,
  &:hover {
    color: #ba9e66;
  }
}

.page-ressources-pedagogiques-recherche {

  header#header {
    top: 36px;

    @media (max-width:700px) {
      top: 0;
    }

    &.fixed {
      top: 0;
    }

    .bottom-header {
      .search-icon {
        padding: 0;
        display: inline-block;
        font-size: 16px;
        position: relative;
        top: 2px;

        @media (max-width:700px) {
          display: none;
        }
      }
    }
  }

  .top-header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: #2d2d2d;
    padding: 8px 40px;

    @media (max-width:700px) {
      display: none;
    }

    a {
      font-size: 14px;
      line-height: 15px;
      color: #FFFFFF;
      background: url(../images/externalink.svg) no-repeat top -1px right 11px;
      padding-right: 30px;

      &:focus,
      &:hover {
        color: #ba9e66;
        background: url(../images/externalink-gold.svg) no-repeat top -1px right 11px;
        transition: all .3s;
      }
    }
  }

  .view-header {
    div#edit-combine-wrapper {
      padding: 0;
    }

    .nav-bar {
      padding-bottom: 0;
      padding-top: 32px;
    }

    form {
      @media (max-width:700px) {
        background: transparent;
      }

      .views-exposed-form {
        @media (max-width:700px) {
          padding: 0;
        }
      }
    }

  }

  .view-ressources-pedagogiques .col-lg-4 .outer {
    border-bottom: none;
  }

  .views-exposed-form {
    .selectric-scroll {
      li[data-index="0"] {
        font-weight: 500;
      }
    }
  }
}

.node-type-ressource-pedagogique .article-header {
  @media (min-width:900px) {
    margin-top: 55px;
  }

  @media (max-width:700px) {
    margin-top: 0px;
    min-height: auto;
  }
}

.logged-in.node-type-ressource-pedagogique .article-header {
  @media (min-width:900px) {
    margin-top: 25px;
  }
}

.hub-ressources-peda {
  .view-id-taxonomy_term_ressources_pedagogiques.view-display-id-main {
    &>.container {
      padding-top: 0;
    }
  }
}


.hub-ressources-peda-toutes {
  .view-id-taxonomy_term_ressources_pedagogiques.view-display-id-main {
    padding-bottom: 0;

    &>.container {
      padding-bottom: 0;
    }

    .view-header,
    .view-footer {
      display: none;
    }
  }
}

.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique,
.section-ressources-pedagogiques {
  footer#page-footer .prefooter .prefooter-top+.prefooter-bottom {
    display: none;
  }

  .mobile-menu.open>.container {
    @media (max-width:900px) {
      display: flex;
      flex-direction: column;

      a.teacher-link {
        color: #333;
        text-align: left;
        padding-left: 20px;
        padding-top: 24px;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 24px;
      }

      form.search {
        width: 100%;
        padding-left: 50px;
        padding-bottom: 22px;
        margin-top: 22px;
        border-bottom: 1px solid #e6e6e6;
        position: relative;

        input {
          width: calc(100% - 20px);
          border: none;
          margin-right: 20px;
        }

        .form-actions button {
          left: 16px;
          top: -10px;
        }
      }

      .menu-bottom {
        margin-top: auto;

        a:last-of-type {
          background: url(../images/externalink.svg) no-repeat top 2px left 192px;
        }
      }
    }
  }
}

.presse-search .nav-bar .selectric-wrapper .selectric-items {
  min-width: 260px;
  left: 0;
}

.node-type-ressource-pedagogique {
  ul.anchor-map.fixed {
    opacity: 1 !important;
    z-index: 20 !important;
  }

  .view-widget-presse.view-display-id-visuels {
    width: calc(100vw + 120px);
    left: calc(50% - 132px);
  }

  section#main {
    overflow: hidden;
  }
}

.hub-ressources-peda {
  .ui-breadcrumbs {
    li:nth-child(n+2) {
      display: none;
    }

  }

}

.view-id-taxonomy_term_ressources_pedagogiques.view-display-id-main .view-content .row a {
  min-height: 255px;

  img {
    max-width: 100px;
  }
}

.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique {
  .messages {
    position: absolute;
    width: 100%;
    top: 100px;
    z-index: 1;
    color: #fff;
    background-color: #00b860;
    border-color: #00b860;
    padding: 10px 20px;
    font-weight: 500;
    background-image: none;

    @media (max-width:700px) {
      top: 65px;
    }
  }
}

.node-type-ressource-pedagogique {
  .messages {
    top: 223px;
  }
}

.page-403.node-type-ressource-pedagogique section#main {
  margin-top: 200px;
}

.page-403.node-type-ressource-pedagogique #content-area {
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1100px;
  margin-bottom: 100px;
  text-align: center;
}

.node-ressource-pedagogique {
  .article-header {
    @media (max-width:700px) {
      background-image: none !important;
      padding-bottom: 0 !important;
    }

    .mobile-image {
      @media (min-width:700px) {
        display: none;

        img {}
      }

      @media (max-width:700px) {
        display: block;

        img {
          display: block !important;
        }
      }
    }

    img:not(.mobile-image img) {
      @media (max-width:700px) {
        display: none !important;
      }
    }
  }
}
