/*------------------------------------*\
    INTRO.CSS
\*------------------------------------*/

.ui-intro {
    position: relative;
    background: #fff;
    padding-bottom:3px;
    @include desktop {
      @include rem(padding, 40px);
      &:before {
        content: '';
        position: absolute;
        @include rem(( 
          top: 30px,
          right: 30px,
          left: 30px,
          
        ));
        height: calc(100% - 60px);
        border: 1px solid $color-primary;
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        @include rem(height, 800px);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%);
        z-index: 2;
      }
    }
    .row {
      position: relative;
      z-index: 4;
      pointer-events: none;
      margin-left: 0;
      margin-right: 0;
      & > * {
        pointer-events:auto;

      }
    }
    .ui-breadcrumbs {
        margin-bottom: rem(60px);
        padding: 0;
        text-align: center;
    }
    .text--lead {
      padding: 0;
      margin: 0 auto rem(40px);
      border: none;
    }
}

.intro {
  padding-top: rem(60px);
  @media (max-width:700px) {
    padding-top: rem(20px);
  }
  h4 {
      margin-top: 0;
  }
  p {
      color: #666666;
      font-family: $font-crimson;
      @include font-size(22px);
  }
}
