.bottom-bar.mobile {
  display: none;

  @media (max-width:700px) {
    display: block;
    background-color: #000;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;

    .inner-wrapper {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;


      .element {
        cursor: pointer;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        &:first-of-type svg {
          fill: #fff;

          &:focus, &:hover {
            fill: #ba9e66;
          }
        }

        &:nth-child(2) svg {
          &:focus, &:hover {
            fill: none;
          }
        }

        svg {
          max-width: 20px;
          max-height: 20px;
          transition: .1s;
        }

        span {
          display: block;
          margin-top: 4px;
          font-family: 'Graphik';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 13px;
          color: #FFFFFF;
        }

        &:focus, &:hover {

          span,
          svg,
          svg path {
            fill: #ba9e66;
            transition: .1s;
            circle{
              fill: transparent;
            }
          }
        }

        &.expandable {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .popup,
          + .popup {
            position: absolute;
            left: 0;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px #0000001a;
            z-index: 20;
            top: 0;
            transition: .2s;
            height: 0px;
            overflow: hidden;
            visibility: visible;
            display: block !important;

            .close {
              // pointer-events: none;
              position: absolute;
              right: 15px;
              top: 15px;
              background: none;
              border: none;
              cursor:pointer;
              z-index:20;
              height: 15px;
    width: 15px;
              &:before,
              &:after {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 16px;
                height: 2px;
                background: #000;
                transform: rotate(-45deg);
              }

              &:after {
                transform: rotate(45deg);
              }
            }

            .popup-element {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 16px 20px;

              &:first-of-type {
                border-bottom: 1px solid #E6E6E6;
              }

              .image-wrapper {
                height: 64px;
                width: 64px;
              }

              .text-wrapper {

                .label {
                  font-family: 'Graphik';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #666666;
                  margin-bottom: 8px;
                }

                .link {
                  font-family: 'Graphik';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 15px;
                  display: flex;
                  flex-direction: row;

                  span {
                    color: #000000;
                    margin-top: 0;
                  }

                  .arrow{
                    padding-left: 5px;
                    padding-bottom: 4px;
                  }

                  .text {
                    border-bottom: 1px solid #BA9E66;
                    padding-bottom: 3px;
                  }
                }
              }
            }
          }

          &.open {
            .popup,
            + .popup {
              top: -193px;
              transition: top .2s;
              transition: .2s;
              height: 193px;
            }
            svg path{
              fill: #BA9E66;
            }
          }
        }
      }
    }
  }
}
