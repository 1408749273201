.view.view-widget-visite {
  @include rem(margin-bottom, 30px);
  .container {
    padding: 0;
  }
  .ui-billet{
    .text-top{
      max-width: 230px;
      width: 215px;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
    }
    .bottom{
      display: flex;
      flex-direction: column;
      align-items: inherit;
      justify-content: initial;
      .btn-group{
        margin:0;
        // width: calc(100% - 270px);
      }
      .text-bottom{
        text-align: left;
        max-width: 215px;
        margin-top: 10px;
        p{
          font-size: 14px;
          line-height: 20px;
          color:#9b9b9b;
        }
      }
    }
  }
  .light{
    .btn-group{
      justify-content: flex-start;
      .btn--primary{
        &:hover{
          text-decoration: underline !important;
        }
      }
    }
  }
}

.view-widget-billet{
  .select-a11y{
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 240px;
    margin-left: auto;
  }

  .billet{
    padding: 0 !important;
    label{
      display: none;
    }
  }
  .light{
    .col-md-9{
      .row{
        margin: 0;
      }
    }
    .btn-group{
      justify-content: flex-start;
      .btn--primary{
        &:hover{
          text-decoration: underline !important;
        }
      }
    }
  }
}

