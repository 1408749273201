// GENERAL FOR ALL MANUEL DES VENTES PAGES

.manuel-des-ventes {

  // HEADER
  header#header {
    @media (max-width:900px) {
      display: flex;
    }

    .left {
      @media (max-width:900px) {
        display: none;
      }

      a {
        font-size: 15px;
        line-height: 17px;
        color: #fff;
        background: url(../images/arrow-right-white.svg) no-repeat center left 0px;
        padding-left: 20px;

        &:focus,
        &:hover {
          color: #ba9e66;
          filter: invert(60%) sepia(62%) saturate(262%) hue-rotate(2deg) brightness(95%) contrast(87%);
          transition: all .3s;
        }
      }
    }

    .right {
      @media (max-width:900px) {

        &>a,
        button#open-form {
          display: none;
        }

        .mobile-menu div#block-lang_dropdown-language {
          display: block;
          position: absolute;
          right: 20px;
          border: 1px solid white;

          form {
            display: block;

            .form-type-select {
              position: relative;

              &::after {
                content: none;
                position: absolute;
                width: 5px;
                height: 5px;
                left: 30px;
                top: 6px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
              }
            }

            select {
              width: 165px;
              opacity: 1;
              background: transparent;
              color: #fff;
              border: none;
              text-transform: uppercase;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              option {
                color: #000;
              }
            }
          }
        }
      }

      #open-form {
        @media (min-width:720px) {
          margin: 0;
          top: 0;
          position: relative;
        }
      }

      #block-views-publics-block {
        display: none;
      }

      &>a {
        padding-right: 20px;
        color: #FFFFFF;
        font-size: 15px;
        line-height: 17px;
        background: url(../images/externalink.svg) no-repeat center right 30px;
        padding-right: 50px;
        transition: all .3s;

        &:focus,
        &:hover {
          color: #ba9e66;
          background: url(../images/externalink-gold.svg) no-repeat center right 30px;
          transition: all .3s;
        }
      }
    }

    #logo {
      display: flex;
      align-items: center;
      left: calc(50% - 36px);

      @media (max-width:900px) {
        width: 250px;
        left: calc(50% - 20px);
      }

      span {
        font-family: 'Apollon';
        font-weight: normal;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        display: block;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #ccc;

        @media (max-widtH:900px) {
          font-size: 16px;
          line-height: 18px;
          padding-right: 10px;
        }
      }

      img {
        margin-bottom: auto;
        width: 120px;
      }
    }

    // MOBILE MENU
    .mobile-menu {
      display: none;

      @media (max-width:900px) {
        display: flex;
        flex-direction: column;

        &>.container {
          display: none;

          ul {
            margin: 0;
            padding-left: 0;
            list-style: none;
            // overflow-y: scroll;
            // max-height: 450px;

            li {
              list-style: none;
              max-height: 70px;
              transition: .2s;
              padding-right: 0;
              padding-top: 0;

              a {
                font-size: 16px;
                line-height: 26px;
                color: #333333;
                display: block;
                padding: 20px;
                border-bottom: 1px solid #E6E6E6;
              }

              &.expanded {
                &>a {
                  &:after {
                    top: 38px;
                    right: 28px;
                  }

                  &:before {
                    content: none;
                  }
                }

                // second level menu ==========

                ul {
                  position: relative;
                  top: 0;
                  left: 0;
                  transform: none;
                  opacity: 0;
                  visibility: visible;
                  box-shadow: none;
                  border-bottom: 1px solid #E6E6E6;
                  transition: .1s;
                  pointer-events: none;
                  padding-bottom: 0;

                  li {
                    a {
                      font-size: 16px;
                      line-height: 26px;
                      color: #333333;
                      padding-top: 0;
                      padding-bottom: 16px;
                      border-bottom: none;
                      padding-left: 47px;

                      &:focus,
                      &:hover {
                        background: transparent;
                      }
                    }
                  }
                }

                &.open {
                  max-height: 400px;
                  height: 100%;
                  transition: .2s;
                  border-bottom: none;

                  &>a:after {
                    top: 27px;
                    transform: rotate(180deg);
                  }

                  &>a {
                    border-bottom: none;
                    font-weight: 500;
                  }

                  ul {
                    opacity: 1;
                    transition: .1s;
                    pointer-events: all;
                  }
                }
              }

              &.last a {
                border-bottom: none;
              }
            }
          }
        }

        button#menu-toggle {
          position: absolute;
          right: 20px;
          margin-right: 0;
          margin-top: 0;
          top: 26px;
        }

        svg g {
          fill: #fff;
        }

        &.open>.container {
          display: block;
          position: absolute;
          left: 0;
          width: 100vw;
          max-width: 100vw;
          height: 100vh;
          height: calc(100vh - 70px);
          top: 70px;
          background: #fff;
          overflow-y: scroll;
          padding: 0;

          ul.menu {
            display: block !important;
          }
        }
      }

      .menu-bottom {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 520px);
        background: #000;
        padding: 24px 20px;
        text-align: left;
        min-height: 220px;

        a {
          color: #fff;
          display: block;
          margin-bottom: 20px;
          margin-left: 0 !important;
          // text-decoration: underline;
          // font-size: 14px !important;
          font-size: 16px;
          line-height: 26px;
          text-decoration: none;
          background-image: url(../images/externalink.svg) no-repeat center right 30px;


          &:last-of-type {
            margin-top: auto;
            margin-bottom: 0;
            padding-bottom: 20px;

            @media (max-width:900px) {
              margin-top: auto;
              margin-bottom: 0;
              padding-bottom: 0;
            }

            &:before {
              content: '←';
              margin-right: 5px;
            }
          }
        }

        .form-item-lang-dropdown-select {
          display: flex;
          align-items: center;
          margin-top: 0;

          label {
            display: block;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
          }

          button {
            border: 0;
            color: #fff;
            text-transform: uppercase;
          }

          .select-a11y-button:focus {
            outline-offset: -2px;
          }

          .select-a11y-button__icon:before {
            border: 4px solid transparent;
            border-top-color: #fff;
          }

          .select-a11y-suggestion {
            text-transform: uppercase;
          }
        }
      }

      &.open {

        svg g {
          polygon {
            transition: .2s;

            &.line-1 {
              transition: .2s;
              transform: translate(1px, 1px) rotate(45deg);
            }

            &.line-2 {
              transition: .2s;
              opacity: 0;
            }

            &.line-3 {
              transition: .2s;
              transform: translate(-13px, 8px) rotate(-45deg);
            }
          }
        }
      }
    }
  }

  // MAIN
  #main {

    // GENERAL
    @media (max-width:1100px) {
      margin-top: 70px;
    }

    .page-nav {
      @media (max-width:900px) {
        padding: 15px 15px 0 15px;
        background: #FFF;
        border-bottom: 1px solid #e6e6e6;
        display: block;

        .container {
          padding: 0px;
        }
      }

      .menu {
        @media (max-width:900px) {
          display: flex;
          width: 100%;
          overflow: auto;
          white-space: nowrap;
          flex-wrap: unset;
        }

        li {
          @media (max-width:900px) {
            margin-right: 25px;

            a {
              font-size: 14px;
              padding-bottom: 15px;
              line-height: 20px;
              padding: 0 0 15px 0;
              text-decoration: none;

              &.active-trail {
                border-bottom: 1px solid #ba9e66;
              }
            }
          }

          .menu {
            position: absolute;
            left: 0;
            background-color: #f1efea;
            margin-left: 50%;
            width: 100vw;
            transform: translateX(-50%);
            height: 60px;
            top: 60px;
            z-index: 1;

            @media (max-width:900px) {
              display: flex;
              top: 36px;
              padding: 15px 15px 0 15px;
              height: 51px;

              li {
                a {
                  padding: 0 0 15px 0 !important;
                }
              }
            }

            li {
              a {
                color: #000;
                line-height: unset;
                padding: 20px 0 20px 0;

                &.active {}
              }
            }
          }
        }
      }
    }

    .big-header {
      @media (max-width:900px) {
        min-height: 300px !important;

        .text--center {
          margin: 0;
        }

        h1 {
          font-size: 30px;
          text-align: center;
          line-height: 45px;
          margin: 0;
          color: #FFF;

          .small {
            font-size: 24px;
            margin: 0;
            color: #FFF;
          }
        }
      }
    }


    .smartbanner {
      display: none;
    }

    // VIEW 3 BLOCS
    .view-manuel-des-ventes {
      &.trois-blocs {
        &>.container {
          @media (max-width:900px) {
            padding: 10px 20px 10px 20px;

            .view-header {
              h2 {
                margin-bottom: 20px;
              }
            }
          }
        }

        .row {
          @media (max-width:900px) {
            .outer {
              padding-top: 0px;
            }

            img {
              gap: 20px 0;
            }
          }

          &>div {
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

            @media (max-width:900px) {
              margin-bottom: 15px;
            }

            .text-wrapper {
              padding: 30px 25px;
              background: #f1efea;

              @media (max-width:900px) {
                padding: 25px;

                .accroche {
                  display: none;
                }
              }

              h4 {
                margin-top: 0;
                margin-bottom: 15px;
              }

              a {
                margin-top: 35px;
                margin-bottom: 10px;
                display: block;
                background-image: url(../images/externalink-gold.svg);
                background-repeat: no-repeat;
                background-position: right;
                display: inline-block;
                padding-right: 20px;
                font-size: 14px;
                color: #ba9e66;
                border-bottom: solid 1px;

                @media (max-width:900px) {
                  margin: 0;

                  span {
                    font-size: 14px;
                    line-height: 24px;
                    text-underline-offset: 6px;
                    text-align: inherit;
                    position: relative;
                    display: inline-block;

                    // &:after {
                    //   content: '→';
                    //   position: relative;
                    //   top: -2px;
                    //   background: 0 0;
                    //   display: inline-block;
                    //   overflow-y: hidden;
                    //   text-decoration: none;
                    //   transform: unset !important;
                    //   max-width: 20px;
                    //   max-height: 14px;
                    //   left: 5px;
                    //   transition: .5s;
                    //   transform-origin: center top;
                    // }
                  }
                }

                &:hover {
                  filter: brightness(0);
                }
              }
            }

            .image-wrapper {
              @media (max-width:1100px) {
                img {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
        }

        .view-footer {
          @media (max-width:1100px) {
            margin-top: 20px;

            a {
              font-size: 14px;
            }
          }

        }
      }

      &.view-display-id-mediatheque_page {
        .view-filters {}

        .mosaique-guerre {
          background: transparent;
          padding: 0;

          .grid-container {
            .item {
              .cover {
                .title {}

                a {}
              }

              &:hover {
                .cover {}
              }
            }
          }
        }
      }

      &.view-display-id-news_block {
        .container {
          @media (max-width:900px) {
            padding: 40px 20px;
          }
        }

        .view-header {
          margin-bottom: 30px;

          @media (max-width:900px) {
            margin-bottom: 25px;
          }
        }

        .view-content {
          .row {
            .outer {
              @media (max-width:900px) {
                padding-top: 20px;
              }

              .cat {
                @media (max-width:900px) {
                  top: 0;
                }
              }
            }
          }
        }

        .view-footer {
          @media (max-width:900px) {
            margin-top: 0;
          }

          a {
            @media (max-width:900px) {
              font-size: 14px;
            }
          }
        }
      }

      .outer img {
        margin-bottom: 20px;
      }
    }

    // MDV DETAIL
    .node-mdv-detail {
      .main-content {
        // padding-top: 110px;

        .text--center {
          text-align: center;

          .btn {
            @media (max-width:900px) {
              font-size: 14px;
            }
          }
        }

        .view-billets-tarifs-mdv {
          .view-header {
            margin-top: 40px;
          }

          .view-filters {
            margin-bottom: 0px;
          }

          .view-content {
            margin-top: 40px;
          }
        }

        @media (max-width:900px) {
          padding-top: 0px;

          .view-billets-tarifs-mdv {
            .container {
              padding: 0 20px;

              .view-header {
                margin-bottom: 20px;
              }

              .view-filters {
                margin-bottom: 20px;

                .views-exposed-widgets {
                  padding: 0 20px 20px 20px;

                  .form-item {
                    margin-bottom: 10px;
                  }

                  .form-radios {
                    display: flex;
                    flex-wrap: wrap;

                    .form-item {
                      width: 50%;
                    }
                  }
                }
              }

              .view-content {
                margin-top: 0;
              }
            }
          }
        }

        .page-nav {
          @media (max-width:900px) {
            margin-bottom: 70px;
          }
        }

        // Horaires & accordions (same as main Info pratiques)
        .bottom-info {
          margin-top: 50px;

          .field-name-field-titre {
            font-family: "Apollon";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            color: #000000;
            margin: 0 0 25px 0;
          }

          .field-name-field-informations-principales {
            &.horaires {
              .field-item {
                min-width: 160px;
                border-left: 1px solid #e6e6e6;
                padding-left: 16px;
                box-sizing: border-box;

                &:first-of-type {
                  border-left: 0;
                  padding-left: 0;
                }

                &:last-of-type {
                  max-width: unset;
                }

                @media (max-width: 700px) {
                  padding-left: 0;
                  border-left: 0;
                  max-width: 50%;
                }
              }
            }

            .field-items {
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-bottom: 35px;

              @media (max-width: 700px) {
                margin-bottom: 20px;
              }

              .field-item {
                &:last-of-type {
                  max-width: 50%;

                  @media (max-width: 700px) {
                    max-width: unset;
                  }
                }

                p,
                li {
                  color: #666666;
                  font-size: 15px;
                  line-height: 24px;
                  margin: 0 0 15px 0;
                }
              }
            }
          }

          .accordion {
            margin-bottom: 30px;

            h4 {
              margin: 0;

              .accordion-trigger {
                background: 0 0;
                color: #000;
                display: block;
                padding: 25px 100px 25px 0px;
                position: relative;
                text-align: left;
                font-family: "Crimson Text";
                font-size: 20px;
                line-height: 24px;
                width: 100%;
                // outline: 0;
                border-left: none;
                border-right: none;
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #e6e6e6;
                position: relative;

                &[aria-expanded="true"] {
                  border-bottom: none;
                }

                &:after {
                  content: "";
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 4px 0 4px;
                  border-color: #666 transparent transparent transparent;
                  position: absolute;
                  top: 45%;
                  right: 19px;
                }

                .check-icon {
                  width: 24px;
                  position: absolute;
                  left: 0;
                }

                &[aria-expanded="true"] {
                  &:after {
                    transform: rotate(180deg);
                  }
                }
              }
            }

            .accordion-panel {

              p,
              li {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #666666;
              }

              p,
              ul {
                margin-bottom: 15px;
              }
            }

            // tabs

            [role="tablist"] {
              // min-width: 550px;
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              margin-top: 10px;

              @media (max-width: 700px) {
                gap: 10px;
              }
            }

            [role="tab"],
            [role="tab"]:focus,
            [role="tab"]:hover {
              position: relative;
              z-index: 2;
              padding: 8px 16px;
              overflow: visible;
              outline: 0;
              font-weight: 700;
              border-radius: 24px;
              border: 1px solid #f1efea;
              background: transparent;
            }

            [role="tab"][aria-selected="true"] {
              background: #f1efea;
            }

            [role="tab"] span.focus {
              display: inline-block;
              margin: 0;
              padding: 0;
              font-family: "Graphik";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              color: #000000;
            }

            [role="tab"]:hover {
              background: #f1efea;
            }

            /* span.focus,
            [role="tab"]:focus span.focus,
            [role="tab"]:active span.focus {
            } */

            [role="tabpanel"] {
              min-height: 10em;
              overflow: auto;
              display: flex;
              gap: 40px;
              margin-top: 30px;
              padding-bottom: 20px;
              border-bottom: 1px solid #e6e6e6;

              @media (max-width: 900px) {
                flex-direction: column;
                gap: 20px;
              }

              .col {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #666666;
                min-width: calc(50% - 20px);
              }
            }

            [role="tabpanel"].is-hidden {
              display: none;
            }

            [role="tabpanel"] p,
            [role="tabpanel"] ul {
              margin: 0 0 15px 0;
              color: #666666;
              font-size: 15px;
              line-height: 24px;

              a {
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            [role="tabpanel"] p {
              strong {
                &.bold--title {
                  font-size: 17px;
                  color: #000000;
                }
              }
            }

            [role="tabpanel"] ul {
              padding: 0 0 0 15px;
            }
          }
        }
      }
    }

    // NEWS
    .actus-mdv {
      @media (max-width: 900px) {
        .page-nav {
          .menu {
            row-gap: unset;
            flex-wrap: unset;
          }
        }
      }

      .view-manuel-des-ventes {
        &.view-display-id-news_une {
          @media (max-width: 900px) {
            .animated {
              margin-bottom: 10px;

              .col-md-5 {
                margin-top: 20px;

                p {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }

          }
        }

        &.view-display-id-news_hub {
          @media (max-width: 900px) {
            .container {
              padding: 0 20px;

              .item {
                margin-bottom: 30px;

                p {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }

    // ONLY PRIVATISATIONS
    .privatisations-mdv {
      .content {
        margin-top: 100px;

        @media (max-width:900px) {
          margin-top: 70px;
        }
      }
    }

    // ONLY CONTACT
    .contact-mdv {
      .main-content {
        padding-top: 10px;
      }
    }

    // ONLY HUB HOME
    .hub-mdv {
      .view-home {
        &.view-display-id-block_hub_lieux {
          margin-top: 35px;

          @media (max-width: 900px) {
            margin-top: 10px;
          }

          .view-header {
            h3 {
              &.title {
                @media (max-width: 900px) {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }

            // CALENDAR ICON
            .datepickerbox {
              .icon-calendair:before {
                content: "";
                background-image: url(../../images/clock_min.svg);
                height: 16px;
                width: 16px;
                display: inline-block;
                position: relative;
                top: -2px;
              }

              .datepicker {
                @media (max-width: 900px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }



      // INTRO + SVG MAP
      .intro-section {
        @include rem(padding, 20px 0 0);

        @include desktop {
          @include rem(padding, 40px 0 20px);
        }

        @media (max-width: 900px) {
          .container {
            padding: 0;
          }
        }

        .intro {
          padding-top: 0;

          @include desktop {
            padding: 0 40px 0 0;
          }

          h3.title {
            @include rem(margin, 0 0 20px);
            @include font-size-adapt(20px, 24px);
            @include rem(line-height, 26px);

            @include desktop {
              @include rem(margin, 0 0 20px);
            }
          }

          p {
            margin: 0;
            font-family: $base-font-family;
            @include font-size-adapt(16px, 18px);
            @include line-height-adapt(26px);

            @media (max-width: 900px) {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .btn {
            @include rem(margin, 40px 0 0);
            @include rem(padding, 20px 15px);
            font-size: 16px;

            @media (max-width: 900px) {
              padding: 20px 10px;
              font-size: 14px;
              @include rem(margin, 20px 0 0);
            }
          }
        }

        .map {
          padding: 0;

          svg {
            display: none;

            @include tablet {
              display: block;
              margin: 0;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    // ONLY INFO PRATIQUES
    .infos-mdv {
      .article-header {
        @media (max-width:900px) {
          .attendance {
            bottom: 20px !important;
          }
        }
      }

      .main-content {
        @media (max-width:900px) {
          margin-top: 0;

          .page-nav-bottom {
            display: none;
          }
        }
      }
    }

  }

  // MEDIATHEQUE SLIDER

  .view-id-manuel_des_ventes.view-display-id-mediatheque_block {
    &>.container {
      @media (min-width:900px) {
        margin-left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        max-width: unset;
        margin-top: 0px;
        padding-bottom: 0;
      }

      @media (max-width:900px) {
        padding: 10px 20px;
      }
    }

    .view-footer {
      margin-top: 30px;

      @media (max-width:900px) {
        margin-top: 20px;
      }
    }

    .view-content {
      @media (min-width:900px) {
        // margin-left: 50%;
        // width: 100vw;
        // transform: translateX(-50%);
      }

      @media (max-width:900px) {
        margin: 0;
        max-width: 100%;
      }

      .slick-slide {
        @media (min-width:900px) {
          // width: 544px;
          // height: 410px;
          margin: 0 16px;
        }

        @media (max-width:900px) {
          // height: 300px;
          max-width: calc(100vw - 40px);
          width: 100%;

          height: fit-content;

          div {
            height: 100%;
            width: 100%;
          }
        }

        img {
          filter: brightness(0.8);

          @media (min-width:1440px) {
            // height: 400px !important;
          }

          @media (max-width:900px) {
            // max-height: 300px;
            max-width: 100%;
            // height: 100% !important;
            width: 100%;
          }
        }

        .photo {
          position: relative;
          cursor: pointer;

          .cover {
            position: absolute;
            display: none;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            padding: 24px;
            color: white;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;

            a {
              font-family: 'Graphik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #ffffff;
              border-bottom: 1px solid white;
              padding-bottom: 5px;
              margin-top: 10px;
            }

            .title {
              font-family: 'Apollon';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: #ffffff;
              margin-top: 5px;
            }
          }
        }

        .photo:hover .cover {
          display: flex;
        }

      }

      button {
        border: 1px solid #fff;
        width: 48px;
        height: 48px;
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        background: url(../images/arrow-right-white.svg) no-repeat center;
        right: auto;
        font-size: 0;
        left: 40px;

        &:hover {
          background-color: #000;
          border-color: #000;
        }

        @media (max-width:900px) {
          width: 40px;
          height: 40px;
          left: 0px;
        }

        &.next-button {
          right: 40px;
          left: auto;
          transform: rotate(180deg) translateY(50%);

          @media (max-width:900px) {
            right: 0px;
          }
        }
      }
    }
  }

}

// MEDIATHEQUE PAGE & SLIDER + POPUP
.node-type-mediatheque,
.node-type-mdv-detail {
  .guerre-visuels-popup {
    .mfp-content {
      background-color: #FFFFFF !important;

      .popup-content {
        &.mdv {
          .text {
            .tags {
              display: flex;
              margin-bottom: 30px;

              .tag {
                display: flex;
                height: 24px;
                justify-content: center;
                align-items: center;
                margin: 0 15px 0 0;
                padding: 12px 8px 11px 8px;
                color: #000;
                text-transform: unset;
                border-radius: 35px;
                background: #F1EFEA;
                font-size: 12px;
              }
            }

            .title {
              margin-bottom: 15px;
            }

            .subtitle {
              margin-bottom: 15px;

              p,
              li {
                font-size: 14px;
                line-height: 22px;
              }

              p {
                margin: 0;
              }
            }

            .credits,
            .size {
              margin-bottom: 15px;
              font-size: 14px;
              line-height: 22px;
            }

            .size {
              color: #666;
            }

            .download-wrapper {
              div {
                display: flex;
                font-size: 14px;
                line-height: 22px;
              }

              p {}
            }
          }
        }
      }

      .slick-prev {
        background: url(../images/mpf-arrow-left.svg) no-repeat center center !important;
        border: none !important;

        i {
          display: none;
        }
      }

      .slick-next {
        background: url(../images/mpf-arrow-right.svg) no-repeat center center !important;
        border: none !important;

        i {
          display: none;
        }

        &:hover:after,
        &:focus:after {
          transform: rotate(180deg);
        }
      }

      .slick-arrow:focus,
      .slick-arrow:hover {
        background: url(../images/arrow-right-white.svg) no-repeat center !important;

        &:before {
          content: '';
          position: absolute;
          left: 1px;
          top: 1px;
          background-color: #ba9e66;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          z-index: -1;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background: url(../images/arrow-right-white.svg) no-repeat center !important;
          width: 100%;
          height: 100%;
        }
      }

      .mfp-close {
        background-image: url(../images/mpf-close.svg) no-repeat center center !important;
        color: transparent !important;
      }
    }
  }

  .big-photo-container {
    background: #FFF;

    .close-photo-button {
      background: url(../images/mpf-close.svg) no-repeat center center !important;
      color: transparent !important;
      top: calc(50% - 50px);
      margin: 0px;
    }
  }
}

.node-type-mdv-detail,
.node-type-mediatheque,
.privatisations-mdv {
  .big-header:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .4;
  }

  .mosaique-guerre .grid-container {
    max-width: 1100px;

    .photo,
    .sizer {
      width: 334px;
      max-width: 334px;
      margin-bottom: 40px;
    }
  }

  .guerre-visuels-popup-slider .slick-slide {
    .video-container {
      width: 686px;
      height: 420px;

      @media (max-width:900px) {
        width: auto;
        max-height: 250px;
      }

      &+.text {
        width: auto;
      }
    }

    .guerre-visuels-popup-slider .slick-slide img {
      width: 504px
    }

    @media (min-width:900px) {
      .text {
        padding-left: 50px;
      }
    }
  }

  .mfp-content .slick-popup button.slick-arrow.slick-prev,
  .mfp-content .slick-popup button.slick-arrow.slick-next {
    @media (max-width:900px) {
      top: 35px !important;
      right: 90px !important;
    }
  }

  .mfp-wrap {
    @media (max-width:900px) {
      top: 0 !important;
      position: fixed !important;
      outline: 0 !important;
    }
  }
}

.node-type-mdv-detail {
  .content {
    margin-top: 50px;
  }

  .guerre-visuels-popup-slider .slick-slide {
    .video-container {
      position: relative;

      img {
        width: 100%
      }

      .icon-youtube {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 60px;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.view-manuel-des-ventes,
.view-billets-tarifs-mdv {
  border-bottom: none;

  &.view-display-id-lieux {
    .container {
      padding-top: 0;

      .view-header {
        margin-top: 0;

        h2 {
          margin-bottom: 0;
          padding-top: 50px;

          @media (max-width: 900px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .view-header {
    padding: 0;
  }

  .view-filters {
    margin-bottom: 40px;

    .views-exposed-form {
      background-color: transparent !important;
      padding: 0 !important;
      max-height: unset !important;

      .views-exposed-widgets {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        padding: 30px 0;

        @media (max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          margin-bottom: 0;
          padding: 30px 20px;
        }

        .views-exposed-widgets-label {
          margin-right: 30px;
          font-size: 14px;
          font-weight: 500;
        }

        .views-exposed-widget {
          min-width: 214px;

          @media (max-width: 900px) {
            min-width: 100%;
          }

          &>label {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip-path: inset(100%);
            clip: rect(0 0 0 0);
            white-space: nowrap;
            border: 0;
          }

          &.views-widget-filter-field_media_reutilisation_value {
            display: block;
            position: relative;
            padding: 10px 20px;
            font-size: 15px;
            line-height: 2;
            color: #000;
            border: 1px solid #e6e6e6;
            margin: 0;

            label {
              font-size: 16px;
              color: #000;
              margin-left: 10px;

              &:before {
                top: 55%;
              }
            }
          }
        }
      }
    }
  }

  .view-empty {
    text-align: center;
    display: block;
    margin: 30px 0;

    p {
      font-size: 14px;
    }
  }
}


// fix for button inside the popin to be active on input check

.guerre-visuels-popup-slider {
  .slick-slide {
    .text {
      input {
        cursor: pointer;
      }

      label {
        pointer-events: none;

        a {
          pointer-events: all;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      .download-wrapper {
        .btn {
          opacity: .4;
          pointer-events: none;
        }

        p {
          cursor: not-allowed;
        }

        &.active {
          p {
            cursor: initial;
          }

          .btn {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  &>div {
    @media (max-width: 900px) {
      width: 100%;

      img {
        width: 100%;
        padding: 0;
      }
    }
  }

  .video-container {
    @media (min-width: 1200px) {
      width: 516px;
      height: 320px;
    }

    @media (min-width: 900px) {
      width: 416px;
      height: 260px;
    }

    .fluid-width-video-wrapper {
      padding-top: 0 !important;
      height: 100%;

      iframe {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  }
}
