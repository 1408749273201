.view.view-alerte {
  margin: 0;
  padding: 0;

  @media (max-width:700px) {
    border-top: 1px solid #ba9e66;
    border-bottom: 1px solid #ba9e66;
  }



  .views-row {
    background: #F1EFEA;
    //margin-bottom: 20px;
    border-bottom: 1px solid #ba9e66;

    @media (max-width:700px) {
      margin-bottom: 0;
    }
  }

  .container {
    @include rem(max-width, 820px);
    @include rem(padding, 30px 20px);

    @media (max-width:700px) {
      @include rem(padding, 20px);
    }

    p {
      margin: 0;
      @include font-size(16px);
      @include line-height-adapt(26px);

      @media (max-width:700px) {
        font-size: 14px;
        line-height: 24px;
      }

      a {
        text-decoration: underline;
      }
    }

    ul,
    ol {
      margin-left: 0;
    }
  }

  &.view-display-id-general_rouge {
    position: fixed;
    z-index: 200;
    width: 100vw;
    top: 0;
    transition: .2s;

    &.active {
      display: block !important;
    }

    @media (max-width:700px) {
      top: auto;
      bottom: 53px;
      border-bottom: none;
    }

    &>.container {
      max-width: 100vw;
      padding: 0;
      background: #D0021B;

      .views-row {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        max-width: calc(100vw - 140px);
        background: #d0021b;
        margin: 0 auto;
        justify-content: space-between;
        margin-right: 80px;
        border-color: #d0021b;
        // @media (max-width:1440px) {
        //   max-width: calc(100vw - 110px);
        //   margin-right: 40px;
        // }

        @media screen and (min-width: 700px) and (max-width: 900px) {
          max-width: 100%;
          margin-left: 30px;
          margin-right: 20px;
        }

        @media (max-width:700px) {
          flex-direction: column;
          max-width: 100%;
          gap: 10px;
          margin-right: 20px;
          padding-bottom: 20px;
        }
      }

      .views-field-field-lien {
        max-width: 200px;

        @media (max-width:700px) {
          max-width: 100%;
          margin-left: 20px;
        }
      }

      .views-field-field-fc-alerte-message {
        @media screen and (min-width: 700px) and (max-width: 900px) {
          max-width: 70%;
        }
      }

      .views-field {
        background: #D0021B;

        .field-content.container {
          margin: 0 auto;
          color: #fff;
          position: unset;
          max-width: unset;
          padding: 16px 0;

          @media (max-width:700px) {
            max-width: 100vw;
            flex-wrap: wrap;
            gap: 0;
            padding-right: 20px;
            padding-left: 20px;
            max-height: 55vh;
            overflow-y: scroll;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;

            @media (max-width:700px) {
              max-width: 100vw;
            }
          }

          button {
            position: absolute;
            top: 18px;
            right: 30px;
            width: 20px;
            height: 20px;
            background: 0 0;
            border: none;

            @media (max-width:700px) {
              top: 20px;
              right: 10px;
            }

            &:before,
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 24px;
              height: 2px;
              margin-top: -1px;
              margin-left: -12px;
              background: #fff;

              @media (max-width:700px) {
                width: 16px;
              }
            }

            &:before {
              transform: rotate(-45deg);
            }

            &:after {
              transform: rotate(45deg);
            }
          }

        }

        a {
          color: #fff !important;
          text-decoration: underline #FFF !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 20px;
          margin-top: 20px;
          display: block;
          margin-right: 20px;

          &:after {
            content: '→';
            position: absolute;
            width: 10px;
            height: 10px;
            margin-left: 5px;
          }

          @media (max-width:700px) {
            max-width: calc(100vw - 40px);
            white-space: break-spaces;
            margin-bottom: 0;
          }

          &:hover {
            // color: #ba9e66 !important;
          }
        }
      }
    }

    &:not(.active) {
      top: -420px;
      transition: .2s;

      @media (max-width:700px) {
        // bottom: -1000px;
        // top: auto; 
        display: none !important;
      }
    }
  }
}

@media (min-width:700px) {

  body:has(.view.view-alerte.view-display-id-general_rouge):not(.closed) {
    // padding-top: 58px;
    transition: padding-top .2s;

    header#header {
      // top: 60px;
    }
  }

  body:has(.view.view-alerte.view-display-id-general_rouge.closed) {
    padding-top: 0;
    transition: padding-top .2s;

    header#header {
      top: 0;
    }
  }

  body.logged-in {
    .view.view-alerte.view-display-id-general_rouge.active {
      top: 26px;
    }

    &:has(.view.view-alerte.view-display-id-general_rouge).active {

      header#header {
        // top: 70px;

        &.fixed {
          // top: 86px;
        }

      }
    }

    // &:has(.view.view-alerte.view-display-id-general_rouge:not(.closed)) {

    //   #navigation.fixed,
    //   header#header {
    //     top: 28px !important;
    //   }
    // }

    // &:not(.node-type-home) {
    //   &:has(.view.view-alerte.view-display-id-general_rouge):not(.closed) {
    //     header#header {
    //       top: 87px;
    //     }

    //     #navigation.fixed {
    //       top: 87px;
    //     }
    //   }
    // }
  }

  .node-type-home {
    .view.view-alerte:not(.view-display-id-general_rouge) {
      margin-top: -2px;
    }
  }

  body {
    &:has(.view.view-alerte.view-display-id-general_rouge).active {
      #navigation.fixed {
        top: 10px;
      }
    }
  }
}

body:not(.node-type-home) #navigation:not(.fixed) {
  padding-top: 10px !important;
}

body {
  @media (max-width:700px) {
    padding-top: 0 !important;
  }
}

header#header {
  @media (max-width:700px) {
    top: 0 !important;
  }
}
