/*------------------------------------*\
    BREADCRUMBS.CSS
\*------------------------------------*/

.main-content {
  position: relative;

  &.with-nav {
    @include desktop {
      @include rem(padding-top, 71px);

    }
  }

  .page-nav:not(.no-sticky) {
    @include desktop {
      position: absolute;
      top: -2px;
      left: 0;
      width: 100%;
    }

    // .share-print-size {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   white-space: nowrap;
    // }
  }
}

.page-nav.fixed {
  @include desktop {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 149;
  }
}

.share-print-size {
  display: none;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  @include clearfix;

  @include desktop {
    display: block;
  }

  li {
    float: left;
    margin-bottom: 0 !important;

    &.resizer {
      position: relative;

      span {
        cursor: pointer;
      }

      ul {
        padding-left: 0 !important;

        li {
          float: none;

          &:last-child {
            @include rem(margin-top, -2px);
          }
        }
      }
    }

    &:hover>ul {
      opacity: 1;
      pointer-events: auto;
    }

    ul {
      margin: 0 !important;
      padding: 0 !important;
      position: absolute;
      top: calc(100% - 1px);
      left: 1px;
      width: 100%;
      list-style: none;
      z-index: 9;
      opacity: 0;
      pointer-events: none;
      text-align: right;
      transition: all 0.3s;

      li {
        a {
          &.facebook:after {
            background: #3B5998;
          }

          &.twitter:after {
            background: #1DA1F2;
          }

          &.google:after {
            background: #DD4E42;
          }

          i {
            transition: none;
          }

          &:focus i,
          &:hover i {
            color: #fff !important;
          }
        }
      }

      li {
        span {
          &.smaller {
            i {
              transform: translateX(-50%) translateY(-50%) scale(0.8);
            }
          }

          &.bigger {
            i {
              transform: translateX(-50%) translateY(-50%) scale(1.2);
            }
          }
        }
      }
    }
  }

  span,
  a {
    position: relative;
    display: block;
    @include rem((margin-right: -1px,
        width: 50px,
        height: 50px));
    background: #fff;
    text-decoration: none;
    z-index: 2;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #CCCCCC;
      background: none;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: #ccc;
      opacity: 0;
      @include transition(all 0.2s ease-in-out);
      z-index: -1;
    }

    &:focus,
    &:hover {
      i {
        color: #fff;
      }

      &:after {
        opacity: 1;
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #666666;
      @include font-size(18px);
      line-height: 1;
      text-decoration: none;
      transform: translateX(-50%) translateY(-50%);
      @include transition(all 0.2s ease-in-out);
    }
  }

}

.breadcrumbs,
.page-nav {
  top: 0;
  @include rem(padding, 15px 0 20px);
  border-bottom: 1px solid #E6E6E6;
  background: #fff;

  // z-index: 111;
  .nav-logo {
    display: none;
    position: absolute;
    left: 0;
    @include rem(bottom, -10px);
    @include rem(width, 115px);
    height: 100%;
    overflow: hidden;

    @include desktop {
      display: block;
    }
  }

  .logo-page {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(150%);
  }

  .ui-breadcrumbs {
    margin: 0;
    padding: 0;
  }

  .share-print-size {
    display: none;

    @include desktop {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .item-list,
  .btn,
  .menu-block-wrapper {
    display: block;

    @include tablet-port {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .item-list {
    flex: 1 1 auto;
  }

  ul.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: block;
      padding: 0;

      @include tablet-port {
        display: inline-block;
        margin: 0;
        @include rem(margin-right, 32px);
      }

      &:last-child {
        @include desktop {
          margin-right: 0;
        }
      }

      &.active-trail {
        a {
          color: $color-primary;
        }
      }

      &.calendar {
        width: auto;
      }

      a {
        display: block;
        @include rem(padding, 10px 0);
        color: #666666;

        &:focus,
        &:hover {
          color: $color-primary;
        }

        &:focus {
          outline-color: #000;
        }
      }

      // &.calendar {
      // display: none;
      // }
      &.don {
        border: 1px solid #ba9e66;
        padding: 0 10px;
        @media (max-width:700px) {
          border: none;
          padding: 0;
        }
        a{
          @media (max-width:700px) {
            border: 1px solid #ba9e66;
            padding: 2px 5px !important;
            width: -moz-max-content;
            width: max-content !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

.breadcrumbs {
  display: none;

  &>.container {
    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1300px;
    }

    @media (min-width: 1100px) {
      padding-left: 20px;
    }
  }

  @include desktop {
    display: block;
  }
}

.page-nav {
  top: 0;
  @include rem(padding, 20px 0);
  text-align: center;

  @include tablet-port {
    @include rem(padding, 10px 0);
    text-align: left;
  }

  &>.container {
    @include tablet-port {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .menu-block-wrapper {
    flex: 1 1 auto;
  }

  ul.menu {
    li {
      a {
        color: #504F4F;

        @include desktop {
          font-size: 16px;
          line-height: 20px;
        }

        @include tablet-port {
          padding: 0;
          @include rem(line-height, 50px);
        }

        &.active {
          font-weight: 500;
        }
      }

      // &.last.agenda {
      // display: none;
      // }
    }
  }

  .btn {
    @include rem(margin-top, 30px);

    @include tablet-port {
      margin-top: 0;
    }
  }

  .agenda {
    display: inline-block;
    width: auto;
    @include rem(padding, 15px 15px 15px 40px);
    color: #fff;
    min-height: 0;

    @include desktop {
      color: $color-primary;
    }

    i {
      @include rem(left, 15px);
    }

    span {
      display: inline-block;
      vertical-align: middle;

    }

    &:before {
      border: 1px solid $color-primary;
    }

    &:after {
      background: $color-primary;
    }
  }
}

.ui-breadcrumbs {
  display: none;
  list-style: none;
  margin-left: 0;

  @include desktop {
    display: block;
  }

  >li {
    display: inline-block;
    margin: 0;

    p {
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    i {
      transform: none;
      color: #0F0F0F;
      vertical-align: unset;

      &:before {
        content: "→";
        margin: 0 10px;
      }
    }

    a {
      margin: 0;
      color: #0F0F0F;
      font-size: 14px;
      line-height: 16px;
      -webkit-text-decoration: underline #ba9e66 !important;
      text-decoration: underline #ba9e66 !important;
      text-underline-offset: 4px;

      &:focus,
      &:hover {
        color: #ba9e66;
      }
    }

    a,
    span {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      @include rem(padding, 5px 0);
      padding: 0;
      color: #0F0F0F;
      font-size: 14px;
      line-height: 16px;
      border: none;
    }

    &:last-child {
      span {
        border-bottom: none;
      }
    }
  }

}

.node-type-visite-section {
  &.i18n-en {
    .page-nav {
      ul.menu {
        li {
          @include tablet-port {
            @include rem(margin-right, 25px);
          }
        }
      }
    }
  }
}
