.ml-show{
	display: none;
}

.home-ml{
  .view-home{
    .view.view-display-id-block_hub_lieux{
      div[class^='col-'] {
        @include rem(margin-bottom, 0);
      }
    }
  }
  .ml-content{
    @include rem(margin-top, 50px);
  }
}

.ml-content{
	.ml-show{
		display: block;
	}
	.ml-hide{
		display: none;
	}
  
  .view-visite-faq{
    background: #f1efea;
    @include rem(margin-top, 70px);
    @include rem(padding-top, 70px);
    .view-header{
      text-align: center;
      h2{
      @include rem(margin-bottom, 30px);
      }
      p{
      @include rem(margin-bottom, 30px);
       a{
        @include font-size-rem-adapt(20px);
       }
       &:last-child{
        @include rem(margin-bottom, 0);
       }
      }
    }
    .view-content{
      .accordion__header{
        background: #FFFFFF;
        &:hover{
          background: #FFFFFF;
          color: #333;
        }
      }
    }
    .view-footer{
      text-align: left;
    }
  }
  
  .view.view-push-abonnement{
    .view-content{
      .text{
        @include rem(padding, 60px 20px);
        text-align: center;
        @include desktop {
          text-align: left;
        }
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
        .app-banner{
          a{
            &.googlestore{
              img{
                width: 180px;
                margin-left: 0;
              }
            }
          }
        }
      }
      .image{
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include rem(padding, 60px 0 0 0);
        @include desktop {
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
    }
  }
  .view.view-id-push_image .outer{
    background: none;
    text-align: center;
    .inner {
      @include rem(padding, 30px 30px 45px);
      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }
      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;
        .ext {
          display: none;
        }
        &:before {
          background: none;
        }
      }
    }
  }
  .view-multilingue.view-display-id-carte{
		border-bottom: 1px solid #e6e6e6;
    .container{
      padding-bottom: 0;
      .views-column-first{
        svg{
          width: 100%;
          &:hover{
            path{
              fill: unset;
              &.nofill{
                fill: #ba9e66;
              }
              &.whitefill{
                fill: #FFFFFF;
              }
            }
          }
        } 
      }
    }
  }
  .billet-infos{
    .bloc-infos{
      h4{
       @include rem(margin, 0 0 20px 0);
       color: #ba9e66;
      }
      ul{
       @include rem(padding, 0 0 0 12px);
       @include rem(margin, 0);
        li {
          @include font-size-rem-adapt(14px);
          @include line-height-adapt(20px);
          @include rem(margin, 0 0 10px 0);
          color: #666;
        }
      }
      p{
        @include font-size-rem-adapt(14px);
        @include line-height-adapt(20px);
        color: #666;
      }
    }
    .bloc-billet{
      text-align: right;
      margin-bottom: 40px;
      @include desktop {
        margin-bottom: 0;
      }
      .btn {
        &:before {
          background: #ba9e66; 
        }
      }
      .inner{
        .row{
          .col-xs-12.col-md-3{
            @media (max-width:1100px){
            display:none;
            }
          } 
          .col-md-9{
            @media (max-width:1100px){
            flex-basis: 100%;
            max-width: 100%;
            }
          }
        }
      }
    }
  }
  .top-footer{
    .footer-title{
      color: #333;
    }
  
    .view{
      background-color: transparent !important;
      @include rem(margin, 0 0 40px 0);
      .container{
        border-top: none !important;
        border-bottom: 1px solid #e6e6e6;
        padding: 0;
        @include desktop {
          @include rem(padding, 0 0 60px 0);
        }
        .view-header{
          h5{
          
          }
        }
      }
      &.view-instagram{
        @media (max-width:1100px){
          padding: 0 1.875rem !important;
        }
        .instagram_gallery{
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin: 0 -10px;
          a{
            flex: 0 0 auto;
            max-width: 25%;
            display: block;
            padding: 0 10px;
            img{
              margin: 0 !important;
              width: 100% !important;
              float: none !important;
            }
          }
          
        }
        .view-header{
          padding: 0 !important;
        }        
        .view-footer{
          margin-top: 30px;
        }
      }
      &.view-home{
        &.view-display-id-block_hub_lieux_ml_footer{
          margin: 0;
          .container{
            border: none;
            padding: 0;
            @include desktop {
              .views-row-first{
                margin-bottom: 40px;
              }
            }
            h4{
              font-family: Graphik,serif;
              text-transform: unset;
              font-weight: 500;
              margin-top: 0;
              margin-bottom: 3px;
              color: #333;
              font-size: 16px;
            }
            .hours{
              display: block;
              font-size: 15px;
            }
            .attendance{
              margin-top: 15px;
              color: #959595;
              font-size: 14px;
            }
          }
        }
      }
    }
    .horaires-colon-wrapper{
      @include desktop {
        border-right: 1px solid #d8d8d8;
      }
    }
    .icon-colon-wrapper{
      @include desktop {
        border-right: 1px solid #d8d8d8;
      }
      .icon-colon{
        position: relative;
        p{
          font-size: 15px;
        }
        i{
          left: 0 !important;
        }
      }
    }
    .acces-rapide-wrapper{
      @include desktop {
        border-right: 1px solid #d8d8d8;
      }
      .field-item{
        margin-bottom: 5px;
        a{
          color: #666;
          text-decoration: none;
          @include font-size(15px, 14px);
          line-height: 20px;
          &:hover{
            color: #ba9e66;
          }
        }
      }
      a{
        color: #666;
        text-decoration: none;
        @include font-size(15px, 14px);
        line-height: 20px;
        &:hover{
          color: #ba9e66;
        }
      }
    }
		.social-wrapper {
      padding-right: 0px;
      .social-icons {
        display: block;
        @include rem(margin-top, 20px);
        @include rem(margin-bottom, 10px);
        text-align: center;
        @include desktop {
          margin-top: 0;
          text-align: left;
        }
        a {
          display: inline-block;
          @include rem(margin-right, 10px);
          color: #666666;
          @include font-size(16px, 14px);
          line-height: 1;
          vertical-align: middle;
          .ext {
            display: none;
          }
          i {
            transition: all 0.3s;
            position: unset !important;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
		}
  }
  .bottom-footer{
    #logo-footer{
      padding: 0;
      position: relative;
      bottom: 45px;
    }
  }
	.top-info{
    p.icon {
      position: relative;
      @include rem(padding-left, 40px !important);
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include rem((
          width: 26px,
          height: 26px
        ));
        border-radius: 50%;
        z-index: 1;
      }
      &:before {
        position: absolute;
        @include rem((
          top: 7px,
          left: 7px,
          width: 12px,
          height: 12px
        ));
        @include font-size(12px);
        line-height: 1;
        color: #fff;
        z-index: 2;
      }
      &.icon-checked {
        &:after {
          background: #48D668;
        }
      }
      &.icon-fountain {
        &:after {
          background: #7293CB;
        }
      }
      & + ul {
        @include rem(margin-top, -20px);
        @include rem(padding-left, 60px);
      }
    }
    p, ul {
      @include rem(margin, 0 0 25px);
      &:last-child {
        margin: 0;
      }
    }
    p, li {
      color: #666;
      @include font-size(17px);
      @include rem(line-height, 21px);
    }
    ul {
      @include rem(padding-left, 20px);
    }
	}
  
  .view-domaine{
    .row{
      .col-md-6:not(.image){
        // .title{
        //   a{
        //     pointer-events: none;
        //   }
        // }
        .subtitle{
          cursor: auto;
        }
        p{
          a{
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .theme-video {
    .row{
      padding:0 40px;
      justify-content:center;
      div{
        &:only-child{
          h4{
            text-align: center;
          }
        }
      }
      @media(max-width:1100px){
        padding:0 60px;
      }
      .outer {
        width: 100%;

        .popup-youtube {
          position: relative;
          display: block;
          border: none !important;
          text-decoration: none !important;
          @media(max-width:720px) {
            text-align:center;
          } 
          &:focus, &:hover {
            .image:after {
              border-color: transparent transparent transparent $color-primary;
            }
            h4 {
              color: $color-primary;
            }
          }
          .image {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(#000, 0.6);
              z-index: 1;
              pointer-events: none;
            }
          
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 14px 0 14px 25px;
              border-color: transparent transparent transparent #fafafa;
              transform: translateY(-50%) translateX(-50%);
              pointer-events: none;
              z-index: 2;
              transition: all 0.3s;
            }
          }
        }
      
        h4 {
          color: #fff;
          white-space: normal;
          text-decoration: none;
          transition: all 0.3s;
        }
      }
    }
  }

	.view-domaine.view-display-id-block_lieux_principaux{
		.view-content{
			.even,
			.odd{
				width: 100vw;
				position: relative;
				margin-left: -50vw;
				left: 50%;
				.desc{
					margin: 0;
					font-family: Graphik,serif;
					@include font-size(16px);
					@include rem(line-height, 24px);
					color: #666;
				}
			}
			.odd{
				.row{
					flex-direction: row;
				}
			}
			.even{
				.row{
					flex-direction: row-reverse;
					.image{
						img{
							right: unset;
							@include rem(left, 20px);
						}
					}
				}
			}
		}
  }
}

footer#page-footer div.top-footer .col .view .row [class^=col-]{
  @media (max-width:1100px){
    margin-bottom: 2.5rem;
  }
}

.node-type-hub-domaine,
.node-type-hub-dates,
.node-type-page-simple,
.node-type-visite-section{
	.ml-content{
		.row.billet-infos{
      @include rem(margin-top, 70px);
		}
		.view-multilingue.view-display-id-carte {
				border-top: 1px solid #e6e6e6;
				border-bottom: none;
		}
		.view-push-image.view-display-id-block {
			margin-bottom: 0;
		}
  }
}

.node-type-visite-section{
	.ml-content{
		.view.view-push-abonnement{
      @include rem(margin-top, 0);
      border-top: 1px solid #d8d8d8;
		}
  }
}

footer#page-footer .ml-content div.top-footer .col{
  &.col-md-3,
  &.acces-rapide-wrapper{
    flex-basis: 21%;
    max-width: 21%;
  }
  &:last-child {
    flex-basis: 16%;
    max-width: 16%;
  }
  @media (max-width:716px){
    &.col.col-md-3,
    &.acces-rapide-wrapper{
      flex-basis: 100%;
      max-width: 100%;
    }
  
    &:last-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.ml-content{
  .view.view-billets-tarifs .ticket .billet p.tooltip{
    height: auto;
    @media (max-width:960px){
      line-height: 1;
    }
  }
  @media (max-width:960px){
    .view.view-billets-tarifs .ticket p a:not(.tooltip):not(.btn) {
      word-break: break-word;
    }
  }
  .view.view-billets-tarifs .ticket .billet .text-bottom {
    margin-top: 10px;
    p{
      @media (max-width:720px){
        width:100%;
        &:nth-child(2){
          width:auto;
        }
      }
    }
  }

  .view-references{
    background: #edeae2;
    margin: 0 auto;
    margin-top: 70px;

    @media (max-width:720px){
      margin-top:30px;

      .row .col:first-of-type{
        margin-bottom:20px;
      }
    }
    
     .ui-header{
       @media (max-width:900px){
        padding-left: 10px;
        padding-right: 10px;
       }
      &:before{
         content: '';
         position:absolute;
         left:0;
         top:0;
         width:100%;
         height:100%;
         background-color: rgba(#000, 0.6);
       }
      h3{
         position:relative;
       }
      .btn{
         max-width:50%;
       }
     }
  }

}

.node-type-evenement{
  .ml-content{
    .view-instagram{
      background: #f1efea;
      @media (max-width:1100px){
        padding: 0 1.875rem !important;
      }
      .instagram_gallery{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 -10px;
        a{
          flex: 0 0 auto;
          max-width: 25%;
          display: block;
          padding: 0 10px;
          img{
            margin: 0 !important;
            width: 100% !important;
            float: none !important;
          }
        }
        
      }
      .view-header{
        padding: 0 !important;
        h5{
          margin: 0 0 1.875rem;
          color: #666;
        }
      }        
      .view-footer{
        margin-top: 30px;
      }
    }
  }
}

.i18n-es{
  header#header{
    
     .left{
      @media (max-width:1100px){
        margin-top: 15px;
      }
     }

     .right{

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 10px;

        @media (max-width:1100px){

          .soutenir{
            margin-right: 0px;
          }
        }

      .block {
        display: block;
        vertical-align: middle;
        margin-bottom: 3px;

        @media (max-width:1100px){
          margin-bottom:0;
        }
      }
    } 
    &.fixed{
      .block {
        @media (max-width:720px){
          margin-top: 30px;
          margin-right: -20px;
        }
      }
    }
  }

  .node-evenement{
    .page-nav{
      ul.menu{
        margin-left: 190px;
        text-align: left;

        @media (max-width:1100px){
          max-width: 100%;
          margin: 0 auto;
          margin-left: 0;
          padding-left: 5px;

          li{
            margin-right: 1.5rem;
            a{
              text-align: center;
            }
          }
        }

        @media (max-width:720px){
          margin-left: 0;
          padding-left: 0;
          text-align:center;

          li{
            margin-right: 0;
          }
        }

      }
    }
  }

  &.node-type-hub-dates{
    .ml-content .view-references {
      margin-top: 0px;
    } 
  }
}

