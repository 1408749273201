/*------------------------------------*\
	SLIDER.CSS
\*------------------------------------*/

/*!
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.slider {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	@include rem(margin-bottom);
}


.slider.slider-horizontal {
	width: 210px;
	height: 20px;
}
.slider.slider-horizontal .slider-track {
	height: 10px;
	width: 100%;
	margin-top: -5px;
	top: 50%;
	left: 0;
}
.slider.slider-horizontal .slider-selection {
	height: 100%;
	top: 0;
	bottom: 0;
}
.slider.slider-horizontal .slider-handle {
	margin-left: -10px;
	margin-top: -5px;
}
.slider.slider-horizontal .slider-handle.triangle {
	border-width: 0 10px 10px 10px;
	width: 0;
	height: 0;
	border-bottom-color: #0480be;
	margin-top: 0;
}
.slider.slider-vertical {
	height: 210px;
	width: 20px;
}
.slider.slider-vertical .slider-track {
	width: 10px;
	height: 100%;
	margin-left: -5px;
	left: 50%;
	top: 0;
}
.slider.slider-vertical .slider-selection {
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
}
.slider.slider-vertical .slider-handle {
	margin-left: -5px;
	margin-top: -10px;
}
.slider.slider-vertical .slider-handle.triangle {
	border-width: 10px 0 10px 10px;
	width: 1px;
	height: 1px;
	border-left-color: #0480be;
	margin-left: 0;
}
.slider input {
	display: none;
}
.slider .tooltip-inner {
	white-space: nowrap;
}

/**
 * Slider Track
 */
.slider-track {
	position: absolute;
	cursor: pointer;
	background-color: $color-light-grey;
	border-radius: $base-radius;
}

/**
 * Slider Selection
 */

.slider-selection {
	position: absolute;
	background-color: $color-primary;
	border-radius: $base-radius;
	
}


/**
 * Slider Handle
 */

.slider-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	border: 5px $color-dark solid;
	background: $color-primary;
}

.slider-handle.round {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.slider-handle.triangle {
	background: transparent none;
}

/**
 * Hide slider handle
 */

.slider-handle.hide{
	display: none;
}


/**
 * Slider tooltip
 */

.slider .tooltip{

	opacity: 0;

	&.in{
		opacity: 1;
	}
}