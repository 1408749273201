// Header

.ui-header {
  position: relative;
  @include rem(padding, 50px 0);
  @include rem(min-height, 400px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  @include desktop {
    height: auto;
  }

  * {
    color: #fff;
  }

  canvas {
    display: none !important;
  }

  .videocontent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 56.25%;
    height: 0;
    z-index: 1;

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .text--center {
    position: relative;
    z-index: 2;

    .cookieConsentPopin {
      @media(min-width:990px) {
        // display:flex;
        // gap:50px;
        // align-items:flex-start;
        padding: 15px;

        p {
          font-size: 14px;
          margin: 0 0 10px 0;
          text-align: center;
        }

        a {
          margin: 0;
          font-size: 14px;
        }

        button {
          margin: 0 15px 0 0;
        }
      }
    }
  }

  p.subtitle {
    margin: 0;
    @include rem(margin-bottom, 15px);
    color: $color-primary;
    @include font-size-rem(20px);
    line-height: 1;
    text-transform: uppercase;
  }

  p.tags {
    margin: 0;
    color: $color-primary;
    @include font-size-rem(15px);
    text-transform: uppercase;
  }

  h3 {
    @include rem(margin, 0 0 20px);
    @include font-size-rem-adapt(25px, 36px);
    line-height: 1;

    @include tablet {
      @include rem(margin, 0 0 7px);
    }
  }

  p {
    @include rem(margin, 0 0 30px);
    @include font-size-rem(19px);
    line-height: 1.5;
  }

  a {
    @include rem(margin, 20px 20px 0 0);

    &:last-child {
      margin-right: 0;
    }
  }

  .btn-group {
    margin-bottom: 0 !important;
  }

  .btn.popup-youtube {
    position: relative;
    color: #000;
    @include rem(padding-left, 40px);

    color: #fff !important;

    &:focus,
    &:hover {
      color: #000 !important;

      span {
        border-color: transparent transparent transparent #000;
      }
    }


    span {
      position: absolute;
      top: 48%;
      @include rem(left, 15px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 8px;
      border-color: transparent transparent transparent #000;
      transform: translateY(-50%);
      transition: all 0.5s ease-in-out;

      @include desktop {
        border-color: transparent transparent transparent #fff;
      }
    }
  }

  .credit {
    display: none;
    position: absolute;
    margin: 0;
    @include rem((right: 40px,
        bottom: 20px));
    @include font-size-rem(13px);
    line-height: 1;
    opacity: 0;
    transition: all 0.3s;

    @include desktop {
      display: block;
    }
  }

  &:focus,
  &:hover {
    .credit {
      opacity: 1;
    }
  }

  &.no-subtitle {
    p.tags {
      @include rem(margin, 0 0 10px !important);
    }

    .btn-space {
      @include rem(margin-top, 20px);
    }
  }
}

.home-header {
  position: relative;
  @include rem(padding, 180px 30px 20px 30px);
  background: none;
  background-size: cover;
  background-position: center center;
  text-align: center;

  @include tablet {
    text-align: center;
    background: #000 !important;
  }

  @include desktop {
    @include rem(padding, 100px 0 75px);
  }

  @media (max-width:700px) {
    padding-top: 60px;
    padding-bottom: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include rem(height, 460px);
    //opacity: 0.5;
    //background-image: linear-gradient(-180deg, #000000 0%, rgba(0,0,0,0.00) 100%);
    z-index: 2;

    @include tablet {
      display: none;
      @include rem(height, 130px);
    }
  }

  &>div:first-child {
    background-size: cover !important;
    background-position: center center !important;
    z-index: 1 !important;

    video {
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    @include tablet {
      background: none !important;
    }
  }

  #logo-home {
    position: relative;
    display: block;
    z-index: 2;
    margin: 0 auto;
    width: 100%;

    @include desktop {
      @include rem(max-width, 500px);
      @include rem(margin-bottom, 90px);

      .mobile {
        display: none;
      }
    }

    margin-bottom:60px;

    @media (max-width:1100px) {
      @include rem(max-width, 178px);

      .desk {
        display: none;
      }
    }

    @media (max-width:700px) {
      padding-bottom: 80px;
      margin-bottom: 0;
      @include rem(max-width, 178px);

    }

    img {
      display: block;
      @include rem(max-width, 100%);
      height: auto;
      margin: 0 auto;

      @include tablet {
        @include rem(max-width, 100%);
      }
    }
  }

  h1 {
    @include rem((margin: 0 auto 30px,
        max-width: 620px));
    max-width: rem(800px);
    @include font-size-rem-adapt(33px, 48px);

    // text-shadow: 0px 0px 5px #000;
    .small {
      margin-bottom: rem(20px);
      color: $color-primary;
      font-family: $font-crimson;
      @include font-size-rem(30px);
      line-height: 1;
    }
  }

  .buttons {
    .btn:first-child {
      color: #000;

      &:focus,
      &:hover {
        color: #fff;
      }

      &:before {
        border-color: #fff;
        background: #fff;
      }

      &:after {
        border-color: #fff;
        background: #fff;

        @include desktop {
          color: #fff;
          border-color: #000;
          background: #000;
        }
      }
    }

    a {
      margin: 0 0 rem(10px);
      z-index: inherit;

      @include desktop {
        margin: 0 rem(20px);
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 0px);
    width: 100vw;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, .146096) 25%, rgba(0, 0, 0, .297356) 50%, rgba(0, 0, 0, .501838) 75%, rgba(0, 0, 0, .795956) 100%);

    @include desktop {
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.56%, rgba(0, 0, 0, .9) 124.71%);
      background: linear-gradient(0deg,rgba(15,15,15,.3) 30%,rgba(0,0,0,.35) 40%,rgba(15,15,15,.09) 100%);
    }
  }
}

.page-header {
  @include rem(padding, 50px 30px 50px);
  background-size: cover;
  text-align: center;

  @include desktop {
    @include rem(padding, 120px 0);
  }

  h2,
  h3 {
    @include rem(margin, 0 auto 30px);
    max-width: rem(850px);
    @include font-size-rem(42px);
    line-height: 1;

    // text-shadow: 0px 0px 5px #000;
    .small {
      display: block;
      @include rem(margin-bottom, 10px);
      @include font-size-rem(36px);
      line-height: 1;
      text-transform: uppercase;
    }
  }
}



.article-header {
  @include rem(padding, 50px 30px 50px);
  background-size: cover;
  @include rem(min-height, 250px);

  @include desktop {
    @include rem(padding, 30px 0);
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @include rem(height, 250px);
  }

  /*&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, 0.3);
	}*/
  h1,
  h2,
  h3 {
    margin: 0 auto;
    max-width: rem(1110px);
    @include font-size-rem-adapt(26px, 64px);
    @include line-height-adapt(45px, 80px);
    text-align: center;

    // text-shadow: 0px 0px 5px #000;
    .small {
      display: block;
      font-family: $font-crimson;
      @include font-size-rem(32px);
      line-height: rem(38px);
      text-transform: none;
    }

    .date {
      display: block;
      @include rem(margin-bottom, 20px);
      font-family: $base-font-family;
      @include font-size-rem(15px);
      line-height: rem(15px);
      text-transform: uppercase;
    }
  }

  &.actualites-header {
    @include rem(min-height, 250px);
    background-size: cover;

    @include desktop {
      @include rem(padding, 30px 0);
    }
  }
}

.node-home {
  .page-header {
    padding: rem(170px) 0;
    background-size: cover;
    text-align: center;

    h2 {
      margin: rem(30px) auto;
      max-width: rem(550px);
      @include font-size-rem(42px);
      line-height: rem(52px);

      // text-shadow: 0px 0px 5px #000;
      .small {
        margin-bottom: rem(15px);
        color: $color-primary;
        font-family: $base-font-family;
        @include font-size-rem(20px);
        text-transform: uppercase;
      }
    }

    a.btn--light {
      border-color: rgba(#fff, 0.3);
    }
  }
}

.lieu-header {
  position: relative;
  @include rem(padding, 50px 30px 50px);
  z-index: -1;

  @include desktop {
    @include rem(padding, 200px 0 530px);
    @include rem(height, 960px);
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @include rem(height, 960px !important);
  }

  &:before {
    display: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.6);

    @include desktop {
      display: block;
      @include rem(height, 550px);
      background: linear-gradient(-180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
      z-index: 1;
    }
  }

  h1 {
    margin: 0 auto;
    @include rem(max-width, 800px);
    @include font-size-rem-adapt(32px, 64px);

    // text-shadow: 0px 0px 5px #000;
    .small {
      font-family: $font-crimson;
      @include font-size-rem-adapt(18px, 32px);
      line-height: 1.2;
      text-transform: none;

      @media (max-width:700px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}

.small-header {
  @include rem(padding, 50px 30px 50px);
  @include rem(min-height, 250px);

  @include desktop {
    @include rem(padding, 130px 0 100px);
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @include rem(height, 250px);
  }

  h1,
  h2,
  h3 {
    @include font-size-rem-adapt(36px, 64px);
    @include line-height-adapt(45px, 60px);
    text-align: center;
    // text-shadow: 0px 0px 5px #000;
  }
}

.big-header {
  @include rem(padding, 0px 30px 0px);
  @include rem(min-height, 400px);

  @include desktop {
    @include rem(padding, 30px 0 0px);
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @include rem(height, 400px);
  }
}

.header-404 {
  background-image: url('../images/404.jpg');

  h1 {
    margin: 0 auto;
    @include rem(max-width, 600px);
    @include font-size-rem(30px);
    @include line-height-adapt(36px);
    // text-shadow: 0px 0px 5px #000;
  }
}

.search-header {
  background-image: url('../images/header.jpg');
}

.gradient-header {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    @include rem(height, 80px);
    opacity: 0;
    background: linear-gradient(to top, rgba(34, 34, 34, 1) 0%, rgba(255, 255, 255, 0) 100%);
    transition: all 0.3s;
  }

  &:hover:before {
    opacity: 1;
  }
}

.position-unset {
  position: unset !important;
}
