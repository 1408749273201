/* SEARCH */

#open-form {
  @include rem((width: 20px,
      height: 20px,
    ));
  color: #fff;
  line-height: 1;
  border: none;
  background: none;
}


.logged-in .search-form-full {
  .toggle-search-form {
    @include rem(top, 50px);
  }
}

.search-form-full {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, .95);
  z-index: 500;

  i {
    display: none;
  }

  .toggle-search-form {
    position: absolute;
    @include rem((top: 35px,
        right: 30px,
        width: 20px,
        height: 20px));
    background: none;
    border: none;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      @include rem((top: 50%,
          left: 50%,
          width: 24px,
          height: 2px,
          margin-top: -1px,
          margin-left: -12px));
      background: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .form-item {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    .form-text {
      display: block;
      float: none;
      width: calc(90% - 90px);
      margin: 0 auto;
      font-family: $base-font-family;
      color: rgba(#FFFFFF, 1);
      @include rem(padding, 10px 0);
      @include font-size-adapt(30px, 58px);
      line-height: 1;
      background: none;
      border: 0;
      border-bottom: 1px solid rgba(#E6E6E6, 0.2);

      &:focus {
        outline: 2px solid #fff;
      }
    }
  }
}

.gss {
  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    @include rem((width: 30px));
    background: none;
    border: none;
  }

  .form-actions {
    margin: 0;
  }
}

#page-footer .gss,
#sidebar .gss {
  position: relative;
  @include rem(margin-bottom, 10px);
  background: #D8D8D8;

  .form-item {
    margin: 0;

    input {
      display: block;
      width: 100%;
      color: #000;
      @include rem(padding, 15px 60px 15px 15px);
      @include font-size(15px);
      line-height: 1;
      background: transparent;
      border: none;
      outline: none;
    }
  }

  i {
    position: absolute;
    top: 50%;
    color: rgba(#000, 0.3);
    @include rem((width: 20px,
        height: 20px,
        right: 20px));
    @include font-size(20px);
    line-height: 1;
    transform: translateY(-50%);
    z-index: 5;
  }
}


.page-search {
  .nav-bar {
    h3 {
      display: block;
      font-family: $base-font-family;
      @include font-size-adapt(16px);
      @include rem(margin-bottom, 20px);
      line-height: 1;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      color: #666666;

      @include desktop {
        display: inline-block;
        margin-bottom: 0;
      }

      @media (max-width:700px) {
        text-align: left;
        margin-bottom: 15px;
      }
    }

    .filters-list {
      display: block;
      margin: 0;
      list-style: none;
      line-height: 1;

      @include desktop {
        display: inline-block;
        @include rem(margin, 0 0 0 30px);
      }

      li {
        display: block;
        @include rem(margin, 10px 0);
        line-height: 1;
        text-align: center;

        @include desktop {
          display: inline-block;
          text-align: left;
          @include rem(margin, 0 10px 0 0);
        }

        @media (max-width:700px) {
          text-align: left;
          text-decoration: underline;
          text-decoration-color: #ba9e66;
          text-underline-offset: 3px;
        }

        &:hover,
        &.selected {
          a {
            color: #000;
          }
        }

        &:last-child {
          margin: 0;
        }

        a {
          color: #666666;
          line-height: 1;
        }
      }
    }

    .gss {
      position: relative;
      display: inline-block;
      @include rem(max-width, 550px);
      @include rem(margin-bottom, 10px);
      width: 100%;
      background: #fff;
      vertical-align: middle;
      border: 1px solid #E6E6E6;

      @include desktop {
        margin-bottom: 0;
        @include rem(margin-right, 10px);
      }

      label {
        display: none;
      }

      .form-item {
        margin: 0;

        input {
          display: block;
          width: 100%;
          color: #000;
          @include rem(padding, 20px 60px 20px 20px);
          @include font-size(15px);
          @include line-height-adapt(18px);
          background: transparent;
          border: none;
          outline: none;
        }
      }

      button {
        @include rem(width, 60px);
      }

      i {
        position: absolute;
        top: 50%;
        color: rgba(#000, 0.3);
        @include rem((width: 20px,
            height: 20px,
            right: 20px));
        @include font-size(20px);
        line-height: 1;
        transform: translateY(-50%);
        z-index: 5;
      }
    }

    .selectric-wrapper {
      display: block;

      @include desktop {
        display: inline-block;
      }

      .selectric {
        width: 100%;

        @include desktop {
          width: auto;
        }
      }
    }

    .form-links-as-radios {
      display: inline-block;
      vertical-align: middle;
    }
  }
}


h4.results {
  @include rem(margin, 40px 0);
  color: $color-primary;
  @include font-size-adapt(24px, 30px);
}

.google-search-results {
  .gss-result {
    padding: 0;
    @include rem(padding-bottom, 40px);
    @include rem(margin-bottom, 40px);
    border-bottom: 1px solid #E6E6E6;

    h3.gss-title {
      @include rem(margin, 0 0 20px);
      @include font-size-adapt(20px, 24px);
    }

    a {
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .gss-thumbnail-image {
      @include rem(margin-bottom, 10px);

      @include desktop {
        display: inline-block;
        max-width: 30%;
        vertical-align: top;
        margin-bottom: 0;

        &+.gss-search-snippet-info {
          display: inline-block;
          max-width: 69%;
          @include rem(padding-left, 20px);
          vertical-align: top;
        }
      }
    }

    .gss-search-snippet-info {
      p {
        @include rem(margin, 0 0 10px !important);

        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }
}




.page-search .nav-bar h3 {
  display: block;
  font-family: Graphik, serif;
  font-size: 16px;
  font-size: 1em;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  line-height: 1;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  color: #666;

  @media (max-width:700px) {
    text-align: left;
  }

  @media all and (min-width: 1100px) {
    .page-search .nav-bar .filters-list li {
      text-align: left;
      margin-bottom: 15px;
    }
  }
}

@media all and (min-width: 1100px) {
  .page-search .nav-bar h3 {
    display: inline-block;
    margin-bottom: 0
  }
}

.page-search .nav-bar .filters-list {
  display: block;
  margin: 0;
  list-style: none;
  line-height: 1
}

@media all and (min-width: 1100px) {
  .page-search .nav-bar .filters-list {
    display: inline-block;
    margin: 0 0 0 30px;
    margin: 0 0 0 1.875rem
  }
}

.page-search .nav-bar .filters-list li {
  display: block;
  margin: 10px 0;
  margin: .625rem 0;
  line-height: 1;
  text-align: center;

  @media (max-width:700px) {
    text-align: left;
    text-decoration: underline;
    text-decoration-color: #ba9e66;
    text-underline-offset: 3px;
  }
}

@media all and (min-width: 1100px) {
  .page-search .nav-bar .filters-list li {
    display: inline-block;
    text-align: left;
    margin: 0 10px 0 0;
    margin: 0 .625rem 0 0
  }
}

.page-search .nav-bar .filters-list li.selected a,
.page-search .nav-bar .filters-list li.selected label,
.page-search .nav-bar .filters-list li:hover a,
.page-search .nav-bar .filters-list li:hover label {
  color: #000
}

.page-search .nav-bar .filters-list li:last-child {
  margin: 0
}

.page-search .nav-bar .filters-list li input {
  display: none
}

.page-search .nav-bar .filters-list li input:checked~label {
  font-weight: 500;
  color: #000
}

.page-search .nav-bar .filters-list li a,
.page-search .nav-bar .filters-list li label {
  font-weight: 400;
  color: #666;
  cursor: pointer;
  line-height: 1
}

.page-search .nav-bar .gss {
  position: relative;
  display: inline-block;
  max-width: 550px;
  max-width: 34.375rem;
  margin-bottom: 10px;
  margin-bottom: .625rem;
  width: 100%;
  background: #fff;
  vertical-align: middle;
  border: 1px solid #e6e6e6
}

@media all and (min-width: 1100px) {
  .page-search .nav-bar .gss {
    margin-bottom: 0;
    margin-right: 10px;
    margin-right: .625rem
  }
}

.page-search .nav-bar .gss label {
  display: none
}

.page-search .nav-bar .gss .form-item {
  margin: 0
}

.page-search .nav-bar .gss .form-item input {
  display: block;
  width: 100%;
  color: #000;
  padding: 20px 60px 20px 20px;
  padding: 1.25rem 3.75rem 1.25rem 1.25rem;
  font-size: 15px;
  font-size: .9375em;
  line-height: 18px;
  line-height: 1.125rem;
  background: 0 0;
  border: none;
  outline: 0
}

.page-search .nav-bar .gss button {
  width: 60px;
  width: 3.75rem
}

.page-search .nav-bar .gss i {
  position: absolute;
  top: 50%;
  color: rgba(0, 0, 0, .3);
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  right: 20px;
  right: 1.25rem;
  font-size: 20px;
  font-size: 1.25em;
  line-height: 1;
  transform: translateY(-50%);
  z-index: 5
}

.swiftype-widget .autocomplete {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background-color: #fff;
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
  position: absolute;
  border-radius: 3px;
  text-align: left
}

.swiftype-widget .autocomplete ul {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background-color: #fff;
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  text-align: left
}

.swiftype-widget .autocomplete li {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  padding: 10px 8px;
  font-size: 13px;
  list-style-type: none;
  background-image: none;
  margin: 0
}

.swiftype-widget .autocomplete li:first-child {
  border-top: 1px solid #fff;
  border-radius: 3px 3px 0 0
}

.swiftype-widget .autocomplete li:last-child {
  border-radius: 0 0 3px 3px
}

.swiftype-widget .autocomplete li.active {
  border-top: 1px solid #ba9e66;
  border-bottom: 1px solid #ba9e66;
  background-color: #ba9e66;
  box-shadow: 0 1px 0 #ba9e66 inset
}

.swiftype-widget .autocomplete li p {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0
}

.swiftype-widget .autocomplete li p.title {
  font-weight: 700;
  color: #333
}

.swiftype-widget .autocomplete li p.title em {
  color: #0b2644;
  font-style: normal;
  font-weight: 700
}

.swiftype-widget .autocomplete li.active p.title {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
  color: #fff
}

.swiftype-widget .autocomplete li.active p.title em {
  color: #fff;
  font-style: normal
}

.swiftype-widget .autocomplete li .sections {
  color: #999;
  font-size: 11px
}

.swiftype-widget .autocomplete li .sections em {
  color: #666;
  font-style: normal
}

.swiftype-widget .autocomplete li .sections .section {
  display: inline
}

.swiftype-widget .autocomplete li.active .sections {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
  color: #ba9e66
}

.swiftype-widget .autocomplete li.active .sections em {
  color: #ba9e66;
  font-style: normal
}

.swiftype-widget .autocomplete li.noResults {
  pointer-events: none
}


// floating label for search modal 

.search-form-full {
  .form-item {

    label {
      position: absolute;
      bottom: 20px;
      left: calc(5% + 45px);
      pointer-events: none;
      transition: 0.3s ease-out;
      font-size: 55px;
      color: #757575;
    }

    input {
      width: 100%;
      padding-top: 20px;
    }

    input.focused+label,
    &:focus-within label,
    input:focus+label {
      transform: translateY(-100%);
      bottom: 80px;
      font-size: 30px;
      color: #fff;
      opacity: .9;
      transition: 0.3s ease-out;
    }
  }
}

.page-search script+form#search-form {
  display: none;
}
