$white: #fff;
$brown: #BA9E66;

@keyframes fromBottom {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
}

.mfp-figure:after {
  display: none;
}

.node-long-read.node .main-content {
  @include rem(padding, 70px 0);
  @include tablet {
    padding: 4.375rem 0 0;
  }
  .full-width:last-child {
    margin-bottom: 0;
    .view-widget-cta.view {
      margin-bottom: 0;
    }
  }
  .share-print-size {
    position: absolute;
    list-style: none;
    @include rem((
      top: 30px,
      right: 30px
    ))
    li {
      float: none;
      position: relative;
      padding: 0 !important;
      @include rem(margin-top, -1px);
      &:before {
        display: none !important;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    & + h2 {
      @include rem(margin-top, 30px);
    }
  }
}
.node-long-read.node .text-content > h2 {
  @include tablet {
    @include rem(margin-top, 110px);
  }
}

.longread {
  position: relative;
  overflow: hidden;
  .longread__header {
    position: relative;
    color: $white;
    background-attachment: fixed;
    background-size: auto 100vh;
    background-position: bottom center;
    @include desktop {
      background-size: cover;
      background-position: top center;
    }
    .container {
      @include rem(padding, 100px 30px 60px);
      background: #000;
      @include tablet {
        @include rem(padding, 150px 0 0);
        background: none;
      }
    }
    #overlay {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.8;
      z-index: 1;
      @include tablet {
        display: block;
      }
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }
  .longread__header__title {
    margin: 0;
    @include font-size-rem-adapt(18px, 40px);
    @include line-height-adapt(26px, 60px);
    & > span {
      display: block;
      overflow: hidden;
      span {
        display: inline-block;
        transform: translateY(100%);
        &.animated {
          opacity: 1;
        }
      }
    }
  }
  .longread__header__visite {
    @include rem(margin, 70px auto 120px);
    font-family: $font-crimson;
    @include font-size-rem-adapt(16px, 24px);
    @include line-height-adapt(24px, 36px);
    text-align: center;
    opacity: 0;
    @include tablet {
      @include rem(margin, 80px auto 220px);
    }
    span {
      display: block;
      font-family: $base-font-family;
      color: $brown;
    }
  }
  .longread__header__content {
    margin: 0 auto;
    @include rem(margin-bottom, 40px);
    @include font-size-rem-adapt(14px, 19px);
    @include line-height-adapt(23px, 32px);
    text-align: left;
    opacity: 0;
  }
  .longread__header__content--brown {
    color: $brown;
  }
}

.longreadVideo {
  position: relative;
  @include rem(height, 490px);
  @include tablet {
    height: 100vh;
  }
  img {
    width: 100%;
    height: auto;
  }
  .btn {
    position: absolute;
    width: auto;
    color: #fff;
    @include rem((left: 10px, right: 10px, bottom: 25px, padding-left: 75px));
    background: $white;
    @include tablet {
      position: absolute;
      left: auto;
      bottom: auto;
      @include rem((right: 90px, bottom: 65px));
    }
    @include desktop {
      color: #4A4A4A;
    }
    &:after {
      background: $brown;
    }
    &:focus, &:hover {
      color: $white;
      span {
        border-color: transparent transparent transparent $white;
      }
    }
    span {
      position: absolute;
      top: 50%;
      @include rem(left, 30px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 8px;
      border-color: transparent transparent transparent #fff;
      transform: translateY(-50%);
      transition: all 0.5s ease-in-out;
      &.ext {
        display: none !important;
      }
      @include desktop {
        border-color: transparent transparent transparent #4A4A4A;
      }
    }
  }
}

.longread-benjamin-franklin {
  .longreadArt {
    @include tablet {
      @include rem((margin-top: 30px, margin-bottom: 30px !important));
    }
    &.longreadArt--fullwidth {
      @include tablet {
        @include rem((margin-top: 50px, margin-bottom: 50px !important));
      }      
    }
  }
  .longreadArt:not(.longreadArt--fullwidth) {
    @include tablet {
      @include rem(padding, 60px 0);
    }
    .longreadArt__picture {
      display: block;
      @include rem(margin-bottom, 20px);
      @include tablet {
        margin: 0;
      }
    }
  }
  .longreadExposition {
    margin-top: -56px !important;
    @include tablet {
      margin-top: 0 !important;
      margin-bottom: 100px !important;
    }
  }
  .longreadArt--topoffset {
    @include rem((margin-top: 190px, margin-bottom: 50px !important));
    @include rem(padding, 60px 0 !important);
    transform: translateX(-100%);
    @include tablet {
      @include rem((margin-top: 190px, margin-bottom: 30px !important));
    }
    .longreadArt__picture {
      @include tablet {
        margin: 0;
        @include rem((margin-top: -180px !important, margin-right: 10px !important, margin-bottom: 0));
      }
    }
  }
  .view-widget-cta.view {
    margin: 0;
    @include rem(margin-bottom, 55px);
    @include tablet {
      @include rem(margin-bottom, -30px);
    }
    .longreadBanner {
      margin: 70px 0 0 !important;
    }
  }
}

.longread-hesther-thrale {
  .longread__header {
    background-position: bottom center;
  }
  .longreadArt {
    @include tablet {
      @include rem((margin-top: 50px, margin-bottom: 50px !important));
    }
    &.longreadArt--fullwidth {
      @include tablet {
        @include rem((margin-top: 50px, margin-bottom: 50px !important));
      }      
    }

  }
  .longreadArt:not(.longreadArt--fullwidth) {
    @include tablet {
      @include rem(padding, 30px 0);
    }
    .longreadArt__picture {
      display: block;
      @include rem(margin-bottom, 50px);
      @include tablet {
        margin: 0;
      }
    }
  }
  .longreadArt--topoffset {
    @include rem((margin-top: 190px, margin-bottom: 50px !important));
    @include rem(padding, 60px 0 !important);
    transform: translateX(-100%);
    @include tablet {
      @include rem((margin-top: 190px, margin-bottom: 50px !important));
    }
    .longreadArt__picture {
      @include tablet {
        margin: 0;
        @include rem((margin-top: -180px !important, margin-right: 10px !important, margin-bottom: 0));
      }
    }
    .image-container {
      .longreadArt__picture {
        margin: 0 !important;
      }
    }
  }
  .longreadExposition {
    @include rem(margin-top, 50px !important);
  }
  .view-widget-collections {
    @include rem(margin, 50px 0);
  }
  .collections-wrapper {
    .ui-grid {
      display: block;
    }
  }
}
.view-widget-cta.view {
 margin-top:0;
}
.view-widget-cta.view .full-width {
  padding-top: 60px;
  background:white; 
}

.longreadArt {
  @include rem((margin-top: 50px, margin-bottom: 50px !important));
  @include rem(padding, 0 0 50px);
  color: $white;
  background: #000;
  @include tablet {
    @include rem((margin-top: 100px, margin-bottom: 100px !important));
    @include rem(padding, 130px 0);
  }
  .longreadArt__picture--mirror {
    display: flex;
    justify-content: flex-end;
    @include ie {
      @include rem(margin-left, -500px);
    }
    div {
      display: none;
      flex: 0 0 auto;
      position: relative;
      &:first-child {
        display: block;
        width: 100%;
        @include rem(margin-bottom, 20px);
        @include tablet {
          @include rem((margin: 0 30px, width: 225px, height: 325px));
        }
      }
      &:nth-child(2n) {
        picture img {
          left: 57%;
          transform: translate(-50%, -50%) rotateY( -165deg );
          @include ie {
            left: 0;
            transform: rotateY( -165deg );
          }
        }
      }
      @include tablet {
        display: block;
        @include rem((margin: 0 30px, width: 225px, height: 325px));
        perspective: 200px;
        perspective-origin: center;
        @include ie {
          flex: 1 0 auto;
          height: auto;
        }
      }

      picture {
        @include rem((margin-top: -35px, margin-bottom: 50px));
        @include tablet {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          margin: 0;
          transform-style: preserve-3d;
          @include ie {
            position: relative;
          }
        }
        img {
          @include tablet {
            position: absolute;
            top: 50%;
            left: 43%;
            width: auto;
            height: 280px !important;
            transform: translate(-50%, -50%) rotateY( -15deg );
            transform-origin: center center;
            @include ie {
              top: 0;
              left: 0;
              width: 100%;
              height: auto !important;
              transform: none;
            }
          }
        }
      }
    }
  }
  .longreadArt__title {
    position: relative;
    @include rem(margin, 0 0 10px 0);
    @include font-size-rem-adapt(16px, 19px);
    @include line-height-adapt(25px, 30px);
    &:before {
      display: none;
      position: absolute;
      content: '';
      @include rem((top: 15px, left: -30px, width: 15px, height: 1px));
      transform: translateY(-50%);
      background: $white;
      @include tablet {
        display: block;
      }
    }
    @include tablet {
      @include rem(margin, 0 0 20px);
    }
  }
  .longreadArt__source {
    @include rem(margin, 0 0 10px 0);
    @include font-size-rem-adapt(12px, 14px);
    @include line-height-adapt(12px, 30px);
    color: #979797;
    @include tablet {
      @include rem(margin, 0 0 20px);
    }
  }
  .longreadArt__desc {
    @include rem(margin, 30px 0 0 0);
    @include font-size-rem-adapt(14px, 17px);
    @include line-height-adapt(23px, 30px);
    color: rgba($white, 0.7);
    @include tablet {
      margin: 0;
    }
  }
}

.longreadArt--fullwidth {
  padding: 0;
  background: none;
  .longreadArt__picture {
    img {
      width: 100%;
      height: auto;
    }
  }
  .container {
    background: #000;
    transform: translateX(-100%);
    @include tablet {
      @include rem(margin-top, -90px);
      background: transparent;
    }
  }
  .row {
    position: relative;
    &:before {
      @include tablet {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        height: 100%;
        width: 100vw;
        background: #000;
      }
    }
  }
  .col {
    position: relative;
    @include rem(padding, 50px 30px);
    background: #000;
    @include tablet {
      @include rem(padding, 50px 50px 40px 0);
    }
    p {
      opacity: 0;
      a {
        color: #fff !important;
        font-weight: 400 !important;
      }
    }
  }
}

.longreadArt--topoffset {
  @include rem((margin-top: 190px, margin-bottom: 50px !important));
  @include rem(padding, 90px 0);
  transform: translateX(-100%);
  @include tablet {
    @include rem((margin-top: 190px, margin-bottom: 100px !important));
  }
  .container {
    opacity: 0;
  }
  .image-container {
    position: relative;
    @include tablet {
      margin: 0;
      @include rem((margin-top: -180px !important, margin-right: 10px !important, margin-bottom: 0));
    }
    .longreadArt__picture {
      margin: 0 !important;
    }
    .hover-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      transition: all 0.5s;
      &:focus, &:hover {
        opacity: 1;
      }
    }
  }
  .longreadArt__picture {
    display: block;
    @include rem(margin, -180px -30px 50px);
    @include tablet {
      margin: 0;
      @include rem((margin-top: -220px, margin-right: 10px, margin-bottom: 0));
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.longreadProduct {
  @include rem(margin, 50px 0);
  text-align: center;
  @include desktop {
    margin: 0 auto;
    position: absolute;
    left: 100%;
    @include rem(width, 255px);
    transform: translateX(-50%);
  }
  .longreadProduct__header {
    margin: 0;
    font-family: $base-font-family;
    @include font-size-rem-adapt(16px);
    color: #4A4A4A;
    text-transform: uppercase;
  }
  .longreadProduct__picture {
    display: block;
    @include rem((max-width: 160px, margin: 10px auto 20px));
    img {
      width: 100%;
      height: auto;
    }
  }
  .longreadProduct__title {
    margin: 0;
    font-family: $base-font-family;
    @include font-size-rem-adapt(16px);
    @include line-height-adapt(24px);
    color: #4A4A4A;
  }
  .longreadProduct__price {
    @include rem((margin: 10px auto 20px));
    font-family: $base-font-family;
    @include font-size-rem-adapt(22px);
    @include line-height-adapt(22px);
    color: $brown;
  }
}

.longreadSideart {
  @include rem(margin, 50px 0);
  @include tablet {
    position: absolute;
    left: 100%;
    margin: 0 auto;
    @include rem(width, 255px);
    transform: translateX(-50%);
  }
  .longreadSideart__picture {
    display: block;
    width: 100%;
    @include rem((margin: 0 0 40px, padding: 0 30px));
    @include tablet {
      padding: 0;
      @include rem((max-width: 210px));
    }
    a {
      display: block;
      border: none !important;
    }
    img {
      width: 100%;
      height: auto;
      transition: all 0.3s;
      filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.23));
      &:focus, &:hover {
        filter: drop-shadow(2px 16px 30px rgba(0,0,0,0.23));

      }
    }
  }
  .longreadSideart__title {
    position: relative;
    @include rem(margin, 0 30px 15px);
    font-family: $base-font-family;
    @include font-size-rem-adapt(19px);
    @include line-height-adapt(28px);
    color: #4A4A4A;
    text-transform: none;
    @include tablet {
      @include rem(margin, 0 0 15px);
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      @include rem((top: 14px, width: 12px, height: 1px, margin-right: 15px));
      background: #4A4A4A;
    }
  }
  .longreadSideart__credits {
    @include rem(margin, 0 30px 0);
    font-family: $base-font-family;
    @include font-size-rem-adapt(14px);
    @include line-height-adapt(19px);
    color: #A8A8A8;
    @include tablet {
      margin: 0;
    }
  }
}

.longreadBanner {
  position: relative;
  @include rem((margin-top: 50px, margin-bottom: 50px !important));
  @include rem(padding, 75px 0);
  color: $white;
  text-align: center;
  background-image: url(../images/longread-header.jpg);
  background-size: cover;
  background-position: top center;
  @include tablet {
    @include rem((margin-top: 100px, margin-bottom: 100px !important));
    @include rem(padding, 115px 0);
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.65;
    z-index: 1;
  }
  .longreadBanner__inner {
    position: relative;
    margin: 0 auto;
    @include rem(padding, 0 30px);
    @include rem(max-width, 730px);
    z-index: 2;
  }
  .longreadBanner__autor {
    margin: 0;
    color: $brown;
    @include font-size-rem-adapt(16px, 28px);
    @include line-height-adapt(26px, 35px);
    &:after {
      content: ' -';
    }
  }
  .longreadBanner__title {
    margin: 0;
    color: $white;
    @include font-size-rem-adapt(18px, 48px);
    @include line-height-adapt(26px, 55px);
    &:before {
      content: '« ';
    }
    &:after {
      content: ' »';
    }
  }
}

.longreadExposition {
  @include rem((margin-top: 50px, margin-bottom: 50px !important));
  @include rem(padding, 50px 0);
  color: $white;
  background: #000;
  @include tablet {
    @include rem((margin-top: 0!important, margin-bottom: 0!important));
    @include rem(padding, 70px 0);
  }
  p {
    margin: 0;
    @include font-size-rem-adapt(14px, 19px);
    @include line-height-adapt(23px, 35px);
    &:last-child {
      @include rem(margin, 45px 0 0);
    }
  }
  h2, h3, h4 {
    @include rem(margin, 50px auto 30px);
    font-family: $heading-font-family;
    color: $white;
    @include font-size-rem-adapt(20px, 24px);
    @include line-height-adapt(26px, 35px);
    @include tablet {
      @include rem(margin, 70px auto 50px);
    }
  }
  a.btn {
    font-family: $base-font-family;
  }
  a:not(.btn) {
    color: $brown;
  }
}

.view-display-id-visiteur {
  @include rem(margin-bottom, 100px !important);
  & > .container {
    padding: 0 !important;
  }
  .row [class^=col-] {
    @include rem(margin-bottom, 20px);
  }
  .outer {
    position: relative;
    height: auto !important;
    margin: 0;
    cursor: pointer;
    @include tablet {
      margin: 0;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 66%;
    }
    .ui-header {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      padding: 0;
      min-height: 0;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        top: 60%;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-image: linear-gradient(rgba(0,0,0,0.00) 0%, #000000 100%);
        z-index: 1;
      }
      a {
        text-decoration: none;
      }
      .container {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        @include rem(padding, 20px 70px 20px 30px);
        z-index: 2;
        @include tablet {
          @include rem(padding, 25px 55px);
        }
        h3 {
          margin: 0;
          @include font-size-rem-adapt(18px, 24px);
          @include line-height-adapt(22px, 35px);
        }
        p {
          margin: 0;
          color: $brown;
          font-family: $font-crimson;
          @include font-size-rem-adapt(16px, 22px);
          @include line-height-adapt(26px);         
        }
      }
    }
  }
}

.node-long-read .view-widget-cta .ui-header {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @include rem(height, 400px !important);
  }
}

.paragraph--comment {
  position: relative;
  @include rem(margin-bottom, 30px);
  p {
    margin: 0;
  }
  .text {
    @include rem(margin-bottom, 20px);
    @include tablet {
      margin: 0;
      @include rem(padding-right, 100px);
      border-right: 1px dashed $brown;
    }
    p {
      @include font-size-rem-adapt(14px, 19px);
      @include line-height-adapt(24px, 32px);   
    }
  }
  .golden--text {
    color: $brown;
    @include font-size-rem-adapt(14px, 16px);
    @include line-height-adapt(24px, 25px);
    text-transform: uppercase;
    @include tablet {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      @include rem(width, 190px);
    }
    p {
      width: 100%;
      @include tablet {
        position: absolute;
        left: 0;
        bottom: 0;
        @include rem(padding-left, 10px);
      }
    }
  }
}