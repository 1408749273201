.view.view-widget-dates.theme-people {
  .slick.unslick {
    justify-content: center;
    display: flex;
  }
  .slick {
    figure {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      max-width: 340px;
      height: 100%;
      align-items: flex-start;
      @include rem(margin, 0 10px);
      overflow: hidden;
      background: none;
      @include desktop {
        @include rem(margin, 0 20px);
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        transform: translateY(100%);
        background: $color-primary;
        @include transition(all 0.4s ease-in-out);
        z-index: -1;
      }

      &:focus, &:hover {
        &:after {
          transform: translateX(0);
        }
        & > a {
          &:before {
            opacity: 0.6;
          }
        }
        figcaption {
          h4,
          h5,
          p {
            color: #fff;
          }
        }
      }

      & > a {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0;
          transition: all 0.3s;
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      a {
        flex: 1 100%;
      }

      figcaption {
        flex: 1 100%;
        padding: em(30) em(20);
        h4,
        h5 {
          color: #333333;
          margin: 0;
          @include font-size(24px);
          @include transition(all 0.1s ease-in-out);

          a {
            color: inherit;
            transition: none;
            border: none;
          }
        }
        p.small {
          margin-top: 0;
          @include rem(margin-bottom, 15px);
        }
        p {
          @include transition(all 0.1s ease-in-out);
        }
      }
    }
  }
}
