.view-accessibilite {
  ul {
    list-style: none;
    // margin: 0;
    li {
      display: inline-block;
      @include rem(margin, 0 0 0 10px);
      vertical-align: middle;
      i {
        display: inline-block;
        color: #7F7F7F;
        @include font-size(20px);
        line-height: 1;
        cursor: help;
      }
    }
  }
}
