.node-hub-domaine {
  .intro-section {
    @include rem(padding, 40px 0 40px);
    border-bottom: 1px solid #e6e6e6;
    @include desktop {
      @include rem(padding, 80px 0 80px);
    }
    .intro {
      padding-top: 0;
      @include desktop {
        padding: 0 40px 0 0;
      }
      h3.title {
        @include rem(margin, 0 0 10px);
        @include font-size-adapt(20px, 24px);
        @include rem(line-height, 26px);
        @include desktop {
          @include rem(margin, 0 0 20px);
        }
      }
      p {
        margin: 0;
        font-family: $base-font-family;
        @include font-size-adapt(16px, 18px);
        @include line-height-adapt(24px);
      }
      .btn {
        @include rem(margin, 40px 0 0);
        @include rem(padding, 20px 15px);
      }
    }
    .map{
      padding: 0;
      svg {
      display: none;
        @include tablet {
          display: block;
          margin: 0;
          width: 100%;
          height: auto;
        }
      }
    }

  }
  .full-width > .view-domaine {
    padding-bottom: 0 !important;
    @include desktop {
      padding: 0 !important;
    }
    .view-domaine {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
  .view-push-image {
    @include rem(margin-bottom, 70px);
  }
}
