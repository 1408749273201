.view.view-networks,
.view.view-tweets {
  position: relative;
  padding-bottom: rem(20px);

  @include desktop {
    @include rem(padding-bottom, 20px);
  }

  @media (max-width:700px) {
    padding-bottom: 20px !important;
  }

  .view-header {
    a {
      display: block;
      margin-bottom: rem(20px);
      color: $color-primary;
      @include font-size(18px);
    }
  }

  .view-content {

    font-family: $font-crimson;
    @include font-size-adapt(20px, 26px);
    line-height: 1;
    @include rem(padding, 20px);
    background: #F6F6F6;

    @media (max-width:700px) {
      border: 1px solid #D6D4CF;
      margin-bottom: 10px;
      background: transparent;
    }

    tbody {
      border: none;
    }

    tr.even,
    tr.odd {
      border: none;
      background: none;
    }

    table {
      border: none;
    }

    table tr {
      padding: 0;

      td {
        padding: 0;
      }
    }

    p {
      margin: 0;
    }

    a {
      color: #333;
      text-decoration: underline;
    }
  }

  .view-footer {
    margin: 0;
    text-align: left;

    .link {
      @include rem(margin-top, 10px);

      i {
        @include rem(margin-right, 10px);
      }

      i,
      a {
        display: inline-block;
        vertical-align: middle;
        transition: all 0.3s;
      }

      &:focus, &:hover {

        i,
        a {
          color: $color-primary !important;
        }
      }
    }
  }
}

.view.view-tweets {
  @include rem(padding-bottom, 40px);

  @include desktop {
    @include rem(padding-bottom, 20px);
  }
}

.social-network-block {
  @media (max-width:700px) {
    .view.view-tweets .view-content {
      background: transparent;
      border: 1px solid #D6D4CF;
    }
  }
}
