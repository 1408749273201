.view-decouvrir {
  figure.brown-figure {
    position: relative;

    &:focus-within,
    &:hover {
      &>a:before {
        opacity: 1;
      }
    }

    &>a {
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        opacity: 0;
        transition: all 0.3s;
        z-index: 2;
      }
    }
  }
}
