.node-hub-domaine .view-domaine {
  @include rem(padding, 40px 0);
  border-bottom: 1px solid #e6e6e6;

  @include desktop {
    @include rem(padding, 60px 0 80px);
  }

  @media (max-width:700px) {
    border-bottom: none;
    @include rem(padding, 20px 0 0 0);
  }

  >.container {
    @media (max-width:700px) {
      @include rem(padding, 0 20px);
    }

    .container {
      @media (max-width:700px) {
        @include rem(padding, 0);
      }
    }
  }

  .view-display-id-block_lieux_sous_lieux {
    @media (max-width:700px) {
      .container {
        padding: 0;
      }
    }
  }

  &:nth-child(4) {
    .intro-section {
      .image {

        a:focus,
        a:focus-within {
          img {
            outline: 2px solid #000;
            outline-offset: 13px;
          }
        }

        img {
          @include desktop {
            @include rem(height, 190px !important);
          }
        }
      }
    }
  }

  .intro-section {
    padding: 0;
    border-bottom: 0;

    @media (max-width:700px) {
      padding: 0 20px;
    }

    .col-md-6 {
      @media (max-width:700px) {
        margin: 10px 0 0 0;
        padding: 0;
      }
    }

    h4.title {
      @include rem(margin, 0 0 5px 0);
      color: $color-primary;
      @include font-size-adapt(24px, 42px);

      &:hover {
        text-decoration: underline !important;
      }
    }

    p.subtitle {
      margin: 0;
      font-family: $heading-font-family;
      color: #666;
      cursor: pointer;
      @include rem(max-width, 450px);
      @include font-size-adapt(14px, 20px);
    }

    .image {
      position: relative;
      @include rem(margin, 30px 0 40px);

      @include desktop {
        margin: 0;
        @include rem(height, 245px !important);
      }

      img {
        width: 100%;

        @include desktop {
          position: absolute;
          top: 50%;
          right: 0;
          width: auto;
          @include rem(height, 245px !important);
          transform: translateY(-50%);
        }
      }
    }
  }

  .view.view-domaine {
    padding: 0;
    border-bottom: 0;

    .container {
      @include rem(padding, 0 0 40px);

      @include desktop {
        @include rem(padding, 60px 0 40px);
      }

      @media (max-width:700px) {
        @include rem(padding, 0 0 20px 0);
      }
    }
  }
}

.view.view-display-id-block_lieux_second {
  padding: 0;

  .container {
    @include rem(padding, 20px 0);
    max-width: 100%;

    @media (max-width:700px) {
      padding: 0;
    }
  }

  .row {
    @include desktop {
      border-bottom: 1px solid #E6E6E6;
    }

    &:last-child {
      border-bottom: none;
    }

    [class^=col-] {
      border-bottom: 1px solid #E6E6E6;

      @include desktop {
        border: none;
      }
    }

    .views-column-first .outer {
      @include desktop {
        border-right: 1px solid #E6E6E6;
      }
    }

    .views-row-last .views-column-first {
      border: none;
    }

    .outer {
      height: auto;
      cursor: pointer;
    }

    figure {
      @include rem(padding, 40px);
      height: auto;

      img {
        width: auto;
        height: auto;
        @include rem((max-width: 380px,
            max-height: 180px));
      }

      figcaption {
        padding-bottom: 0;
        margin: 0 auto;
        @include rem(max-width, 360px);

        h5 {
          margin: 0;
          color: #333;
          @include font-size-adapt(22px, 24px);
          line-height: 1;
        }

        p {
          margin: 0;
          font-family: $font-crimson;
          @include font-size(20px, 24px);
          line-height: 1;
        }
      }
    }
  }
}
