.node.node-hub-avantages {
  .breadcrumbs {
    border-bottom: none;
  }

  .text--lead {
    @include font-size-rem-adapt(22px);
    @include line-height-adapt(32px);
    border: none;
    @media (max-width:700px) {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 18px !important;
      line-height: 26px !important;
      text-align: left !important;
    }
  }


  .top-info {
    margin: 0;
    @include rem(padding, 60px 0 180px);
    background: url('../images/avantages.jpg') no-repeat center bottom #F1EFE9;

    @media (max-width:700px) {
      @include rem(padding, 30px 0 180px);
    }

    h2.title {
      @include rem(margin-bottom, 45px);
      @media (max-width:700px) {
        margin-bottom: 20px;
        text-align: left !important;
      }
    }

    .col-md-5 {
      display: flex;

      @include tablet {
        flex-basis: 41.6666666667%;
        max-width: 41.6666666667%;
      }
    }

    .inner {
      height: 100%;
      @include rem(padding, 40px);
      background: #fff;
      @media (max-width:700px) {
        padding: 30px 20px;
      }

      h2 {
        @include rem(margin-bottom, 20px);
        font-family: $base-font-family;
        text-transform: none;
        @media (max-width:700px) {
          @include rem(margin-bottom, 18px);
          font-size: 18px;
        }
      }

      p {
        @include rem(margin-bottom, 40px);
        color: #000;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(28px);
        @media (max-width:700px) {
          @include font-size-rem-adapt(14px);
          @include line-height-adapt(26px);
        }

        &:last-child {
          margin: 0;
        }
      }

      ul {
        li {
          margin: 0;
          color: #000;
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(28px);
          @media (max-width:700px) {
            @include font-size-rem-adapt(14px);
            @include line-height-adapt(26px);
          }
        }
      }
    }
  }


  .view-espace-abonnes.view-id-espace_abonnes.view-display-id-avantages_domaine {
    background: #000;

    .container {
      @include rem(padding, 40px 0 32px);
    } 

    .tabs-slider {
      max-width:1100px;
      margin:0 auto;
    }

    .view-header {
      @include rem(padding, 30px 30px 60px);

      @media (max-width:700px) {
        padding-top: 0;
        padding-bottom: 20px;
      }

      h2.title {
        color: #fff;

        @media (max-width:700px) {
          margin-bottom: 10px;
          text-align: left !important;
        }
      }

      p {
        color: $color-primary;
        @media (max-width:700px) {
          text-align: left !important;
        }
      }
    }

    .tabs-nav {
      @include rem(margin-bottom, 40px);
      text-align: center;

      @media (max-width:700px) {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 10px;
      }

      a {
        @include rem(margin, 0 20px);
        @include rem(padding-bottom, 10px);
        display: inline-block;
        color: #fff;
        @include font-size-rem-adapt(20px);
        line-height: 1;

        @media (max-width:700px) {
          font-size: 14px;
          padding-bottom: 1px;
          border-width: 1px;
          margin-left: 0;
          margin-right: 0;
        }

        &.active,
        &:focus, &:hover {
          @include rem(padding-bottom, 8px);
          border-bottom: 2px solid #fff;

          @media (max-width:700px) {
            padding-bottom: 1px;
            border-width: 1px;
          }
        }
      }
    }

    .slick-track {
      display: block;
    }

    .slick-slide > div {
      width:100%;
    }

    .tab-content {
      background-color: #000;
      background-repeat: no-repeat;


      .container {
        position: relative;
        @include rem(padding, 20px 30px 32px);
        background-size: cover;

        .bg {
          position: absolute;
          top: 0;
          left: 50%;
          width: 100vw;
          height: 102%;
          transform: translateX(-50%);

          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 60%;
            background-image: linear-gradient(#000000 3%, rgba(0, 0, 0, 0.00) 83%);
            transform: rotate(180deg);
            z-index: 2;
          }

          img {
            display: block;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
          }
        }

      }
      .col-xs-12.col-md-7.col-md-offset-1{
        margin: 0 !important;
        position: relative;
        left: -10px;
      }
      .outer {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          @include rem((left: -20px, top: 20px, right: -20px, bottom: 10px));
          background: #eeeeee;
          z-index: 1;
        }

        .inner {
          position: relative;
          @include rem(padding, 30px);
          background: #fff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
          overflow: hidden;
          z-index: 2;

          @include tablet {
            @include rem(padding, 30px 100px 60px 100px);
          }

          &:before {
            content: '';
            position: absolute;
            @include rem((left: 15px, top: 15px, right: 15px, bottom: 15px));
            border: 1px solid $color-primary;
            z-index: -1;
          }

          .avantage--menu {
            @include rem(margin-bottom, 25px);

            &:last-child {
              margin-bottom: 0;
            }
          }

          p {
            margin: 0;
            color: $color-primary;
            @include font-size-rem-adapt(16px);
            @include line-height-adapt(29px);
          }

          a {
            @include rem(padding-bottom, 1px);
            color: #4A4A4A;
            @include font-size-rem-adapt(20px);
            @include line-height-adapt(29px);

            &:focus, &:hover {
              padding-bottom: 0;
              border-bottom: 1px solid #4A4A4A;
            }
          }
        }
      }
    }
  }

  .view-espace-abonnes.view-display-id-avantages_partenaires {
    .container {
      @include rem(padding-bottom, 40px);
      @media (max-width:700px) {
        padding: 20px;
      }
    }
    .view-header{
      @media (max-width:700px) {
        padding: 0 0 20px 0;
        h2{
          text-align: left !important;
        }
      }
    }
    .view-content {
      .col-xs-12 {
        @include tablet {
          margin-bottom: 2.5rem;
        }
      }

      .outer {
        position: relative;

        img {
          width: 100%;
        }

        p {
          margin: 0;
          @include rem(padding, 20px 15px);
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          color: #fff;
          font-family: $heading-font-family;
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(20px);
          z-index: 2;
        }
      }
    }
  }

  .view-espace-abonnes.view-display-id-autres_cycles {
    .container {
      @include rem(padding-top, 50px);
      @include rem(padding-bottom, 100px);
    }

    .view-header {
      @include rem(padding-bottom, 50px);
    }

    .view-content {
      .outer {
        margin-bottom: 1.25rem;

        img {
          width: 100%;
        }

        a {
          display: block;
          color: #333333;
          @include rem(margin-bottom, 15px);
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(22px);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .view-espace-abonnes.view-id-espace_abonnes.view-display-id-autres_cycles_avantages {
    .container {
      @include rem(padding-bottom, 100px);
    }

    .view-content {
      .outer {
        margin-bottom: 1.25rem;

        img {
          width: 100%;
        }

        a {
          display: block;
          color: #333333;
          @include rem(margin-bottom, 15px);
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(22px);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .view.view-push-abonnement {
    background: #f1efea;

    .view-content {
      .text {
        @include rem(padding, 60px 20px);

        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);
        }

        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }

        .btn {
          color: #fff;

          &:before {
            background: $color-primary;
          }

          .ext {
            display: none;
          }
        }
      }

      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;

        @include desktop {
          margin: 0;
        }
      }
    }
  }
}

.node-type-hub-avantages {
  .mfp-bg {
    background: #000000;
  }

  .mfp-bg,
  .mfp-zoom-in.mfp-removing .mfp-container,
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0.47;
  }

  .white-popup {
    .mfp-close {
      color: #fff;
      position: absolute;
    }
  }
}


.inlinepopup {

  .row {
    margin: 0;
  }

  .text {
    display: flex;
    padding: 0;

    div {
      @include rem(padding, 70px 40px 30px 65px);
      background: #fff;
    }

    h2 {
      @include rem(margin, 0 0 45px);
    }

    p {
      color: #4A4A4A;
      @include rem(margin, 0 0 45px);
      @include font-size-rem-adapt(16px);
      @include line-height-adapt(20px);

      a {
        text-decoration: underline;
      }
    }

    ul {
      color: #4A4A4A;
      @include rem(margin, 0 0 45px);
      @include rem(padding, 0 0 0 15px);
      @include font-size-rem-adapt(16px);
      @include line-height-adapt(20px);
    }

    .btn.ext {
      @include rem(margin-top, 65px);
      color: #fff;
      background: $color-primary;

      &:before {
        border-color: $color-primary
      }
    }
  }

  .image {
    padding: 0;
    display: flex;
    align-items: center;
    background: #000002;

    img {
      width: 100%;
      height: auto;
    }

    p {
      color: #fff;
      @include rem(padding, 15px 30px);
      @include font-size-rem-adapt(12px);
      @include line-height-adapt(18px);
    }
  }
}
