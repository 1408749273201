/*------------------------------------*\
    BILLETS.CSS
\*------------------------------------*/
.ui-billet {
  margin: 0 0 rem(40px);
  text-align: left;

  .row {
    @include rem((margin-left: -10px, margin-right: -10px));

    [class^="col-"] {
      @include rem((padding-left: 10px, padding-right: 10px));
    }
  }

  .inner {
    position: relative;
    @include rem(padding, 30px 20px);
    border: 1px solid #E6E6E6;
  }

  .icon {
    position: absolute;
    top: rem(40px);
    left: rem(25px);
  }

  img {
    display: none;
    width: 100%;
    height: auto;

    @include desktop {
      display: block;
    }
  }

  h4 {
    margin: 0 0 rem(20px);
    font-family: $base-font-family;
    @include font-size(24px);
    text-transform: uppercase;
  }

  p {
    @include rem(margin, 0 0 15px);
    color: #333;
    line-height: 20px;
    font-size: 14px;
  }

  ul {
    margin-left: 0;
    padding-left: 20px;
  }

  li {
    line-height: 20px;
    font-size: 14px;
  }

  div.middle {
    @include rem(margin, 0 0 20px);

    p {
      margin: 0 20px 0 0;
      display: inline-block;
      vertical-align: middle;
    }

    .view-accessibilite {
      display: inline-block;
      vertical-align: middle;

      ul {
        padding: 0;
        margin: 0;
      }
    }
  }

  .bottom {
    @include clearfix;

    .btn-group {
      // display: inline-block;
      // vertical-align: middle;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: initial;
      @include rem(margin-bottom, 5px);

      @include desktop {
        @include rem(max-width, 250px);
        margin-bottom: 0;
        @include rem(margin-right, 15px);
      }

      p {
        width: 100%;
      }

      .btn {
        width: auto;
      }
      .tooltip {
        margin-bottom:0;
      }

      .btn.price {
        @media(max-width:999px) {
        font-size: 1em;
        }
      }
    }

    .text-bottom {
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      @include desktop {
        width: calc(100% - 270px);
      }

      p {
        margin: 0;
        @include font-size(12px);
        line-height: 1.8;
      }
    }
  }

  .view-accessibilite {
    display: flex;
    align-items: initial;
    justify-content: flex-end;
    margin: 0;
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 0 0 !important;

    .btn {
      margin: 0;
    }

    .price {
      @include rem((padding-left: 20px,
          padding-right: 20px));

      &:focus,
      &:hover {
        color: $color-primary;

        &:after {
          display: none;
        }
      }
    }

    .btn.btn--sm {
      flex: 0 0 auto;
      width: auto;

      &+.btn {
        flex: 0 1 auto;
        width: auto;
      }
    }

    p.btn {
      vertical-align: top;
      margin: 0 -1px 0 0;
      @include rem(padding, 8px 20px);
      color: $color-primary;
      @include font-size(24px, false);

      &:after {
        display: none !important;
      }

      &:focus,
      &:hover {
        color: $color-primary !important;
      }
    }

    .text-bottom {
      float: left;
    }
  }

  p.tooltip {
    position: relative;
    display: block;
    color: inherit;
    min-height: 20px;
    height: auto;
    @include font-size(12px);
    @include rem(margin-bottom, 10px);
    @include rem(padding-right, 30px);
    padding-bottom: 0;
    background: none;
    text-align: right;

    &:after {
      display: block;
      content: '?';
      @include font-size(16px);
      @include rem((width: 20px,
          height: 20px,
        ));
      cursor: pointer;
      line-height: 20px;
      text-align: center;
      background: #f1efea;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      @include rem(margin-top, -10px);
    }
  }
}

.view.view-widget-visite {
  .ui-visite {
    .bottom {
      .text-bottom {
        max-width: unset !important;
        width: unset !important;
      }
    }
  }
}