html.iphone, html.iphone body.page-grands-formats-poum-tiya {
  height: 100%;
}

.node-poum-tiya {
  .ui-header.lieu-header {
    position: relative;
    background-image: url('../images/poum/bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-color: #F1EFEA;
    background-attachment: fixed;
    overflow: hidden;
    @include tablet {
      background-position: top center;
      background-size: auto auto;
    }
    &:before {
      display: none;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.4;
    }
    h1 .small {
      @include rem(margin-top, 25px);
    }
  }
  .ui-intro {
    position: relative;
    overflow: hidden;
    z-index: 2;
    @include desktop {
      @include rem(padding, 100px 30px 0);
      @include rem(margin, -395px 0 0 -20px);
    }
    &:after {
      @include rem(height, 600px);
    }
    .text--lead {
      margin: 0;
      @include rem(padding-bottom, 30px);
      border-bottom: 1px solid #E6E6E6;
    }
  }

  .poum-block {
    @include rem(margin-bottom, 100px);
    @include rem(padding, 40px 20px);
    border: 1px solid #E6E6E6;
    @include tablet {
      display: flex;
    }
    .thumbnail {
      width: 100%;
      @include rem(margin-bottom, 40px);
      @include tablet {
        margin: 0;
        @include rem(width, 225px);
      }
      img {
        width: 100%;
      }
      p {
        display: none;
        @include rem(margin, 30px 0 0 0);
        color: #979797;
        @include font-size-rem-adapt(12px, 14px);
        @include line-height-adapt(12px, 30px);
        @include tablet {
          @include rem(margin, 20px 0 0 0);
        }
      }
    }
    .image {
      position: relative;
      width: 100%;
      background: #E6E6E6;
      &:before {
        content: '';
        padding-top: 100%;
        display: block;
        height: 0;
      }
    }
    .text {
      @include tablet {
        @include rem(max-width, 650px);
        @include rem(padding, 0 40px);
      }
      &.open {
        .inner {
          &:before {
            opacity: 0;
          }
        }
        .btn {
          span:before {
            content: 'Fermer'
          }
        }
        .link {
          display: inline-block !important;
          opacity: 1 !important;
        }
      }
      .inner {
        position: relative;
        @include rem(height, 210px);
        overflow: hidden;
        transition: all 1s;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 50%;
          background-image: linear-gradient(rgba(255,255,255,0.00) 0%, #FFFFFF 80%);
          transition: all 0.4s;
        }
      }
      .btn {
        span:before {
          content: 'Lire la suite'
        }
      }
      .links {
        @include tablet {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        .link {
          display: none;
          @include rem(padding-top, 20px);
          @include rem(padding-bottom, 5px);
          border-bottom: 1px solid $color-primary;
          opacity: 0;
          transition: all 0.4s;
          @include tablet {
            display: inline-block;
            padding-top: 0;
          }
        }
      }
      .tag {
        @include rem(margin, 0 0 15px);
        color: $color-primary;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(16px);
      }
      p:not(.tag) {
        font-family: $font-crimson;
        @include font-size-rem-adapt(16px, 21px);
        @include line-height-adapt(24px, 30px);
      }
    }
  }

  .chapter {
    position: relative;
    @include rem(padding, 100px 0 600px);
    overflow: hidden;
    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity 0.8s;
        &[data-src] {
          opacity: 0;
        }
      }
    }
    p {
      @include rem(margin, 0 0 30px);
      color: #333333;
      @include font-size-rem-adapt(16px, 19px);
      @include line-height-adapt(24px, 28px);
    }
    .fin {
      color: #4A4A4A;
      @include font-size-rem-adapt(34px);
      @include line-height-adapt(43px);
    }
  }

  .chapter-1 {
    padding-bottom: 55%;
    @include desktop {
      padding-bottom: 46%;
    }
  }

  .chapter-2 {
    padding-bottom: 60%;
    @include tablet {
      padding-bottom: 65%;
    }
    @include desktop {
      padding-bottom: 52%;
    }
  }

  .chapter-3 {
    @include rem(padding, 80px 0 400px);
    padding-bottom: 45%;
    @include tablet {
      @include rem(padding, 80px 0 400px);
    }
    .bg {
      img {
        width: auto;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
  }

  .chapter-4 {
    @include rem(padding, 100px 0 470px);
    padding-bottom: 70%;
    @include tablet {
      padding-bottom: 75%;
    }
    @include desktop {
      padding-bottom: 35%;
    }
  }

  .chapter-5 {
    @include rem(padding, 100px 0 520px);
    padding-bottom: 36%;
  }

  .chapter-6 {
    @include rem(padding, 70px 0 590px);
    padding-bottom: 45%;
  }

  .chapter-7 {
    @include rem(padding, 70px 0 700px);
    padding-bottom: 50%;
  }

  .chapter-8 {
    @include rem(padding, 70px 0 450px);
    padding-bottom: 34%;
  }

  .chapter-9 {
    @include rem(padding, 80px 0 400px);
    padding-bottom: 45%;
    @include tablet {
      padding-bottom: 45%;
    }
    @include desktop {
      padding-bottom: 25%;
    }
    .bg {
      img {
        width: auto;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .chapter-10 {
    @include rem(padding, 80px 0 0);
    .bg {
      position: relative;
      left: auto;
      @include rem(padding, 0 20px);
      img {
        position: relative;
        left: auto;
        display: block;
        margin: 50px auto 0;
        width: auto;
      }
    }
  }

  .chapter-11 {
    @include rem(padding, 70px 0 550px);
    padding-bottom: 42%;
  }

  .chapter-12 {
    @include rem(padding, 70px 0 800px);
    padding-bottom: 58%;
    @include tablet {
      padding-bottom: 65%;
    }
    @include desktop {
      padding-bottom: 58%;
    }
  }

  .chapter-13 {
    @include rem(padding, 70px 0 650px);
    padding-bottom: 48%;
  }

  .chapter-14 {
    @include rem(padding, 70px 0 550px);
    padding-bottom: 70%;
    @include tablet {
      padding-bottom: 75%;
    }
    @include desktop {
      padding-bottom: 40%;
    }
  }

  .chapter-15 {
    @include rem(padding, 70px 0 550px);
    padding-bottom: 40%;
  }

  .chapter-16 {
    @include rem(padding, 100px 0 550px);
    padding-bottom: 44%;
  }

  .chapter-17 {
    @include rem(padding, 100px 0 500px);
    padding-bottom: 44%;
  }

  .chapter-18 {
    @include rem(padding, 100px 0 500px);
    padding-bottom: 34%;
  }

  .chapter-19 {
    @include rem(padding, 100px 0 750px);
    padding-bottom: 55%;
    @include tablet {
      padding-bottom: 70%;
    }
    @include desktop {
      padding-bottom: 55%;
    }
  }

  .chapter-20 {
    @include rem(padding, 80px 0 400px);
    padding-bottom: 44%;
  }

  .chapter-21 {
    @include rem(padding, 100px 0 230px);
    padding-bottom: 20%;
  }

  .chapter-footer {
    @include rem(margin, 30px auto);
    p {
      font-family: $font-crimson;
      @include font-size-rem-adapt(22px);
      @include line-height-adapt(26px);
    }
  }

  .poum-footer {
    @include rem(padding, 50px 0 40px);
    text-align: center;
    background: #F1EFEA;
    h3 {
      display: block;
      @include rem(max-width, 720px);
      @include rem(margin, 0 auto 75px);
      @include font-size-rem-adapt(26px);
      @include line-height-adapt(32px);
    }
    .inner {
      @include rem(padding, 50px 40px);
      border: 1px solid #C5C5C5;
      p {
        @include rem(margin, 0 0 20px);
        color: #666666;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(24px);
        &.title {
          @include rem(margin, 0 0 10px);
          color: #333333;
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(26px);
          text-transform: uppercase;
        }
      }
      .btn {
        @include rem(max-width, 210px);
        width: 100%;
      }
    }
  }

  .view-widget-cta.view.view-display-id-full .ui-header, .view-widget-cta.view.view-display-id-moy2 .ui-header {
    &:before {
      opacity: 0.5;
    }
    &:hover:before  {
      opacity: 1;
    }
  }
}
