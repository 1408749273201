.admin-menu {
  #admin-menu {
    background: #BA9E66;
  }

  #header {
    @include desktop {
      @include rem(top, 28px);
    }
  }

  .page-nav.fixed {
    top: 29px;
  }

  #sidebar {

    .scrollbar,
    .simplebar-content {
      @include rem(padding-top, 55px);
    }
  }
}

#main {
  @include rem(margin-top, 124px);

  @include desktop {
    @include rem(margin-top, 70px);
  }
}

.admin-menu.front {
  #main {
    margin-top: 0;
  }

  #page {
    padding-top: 0;
  }
}

header#header .selectric-wrapper .selectric-items {
  border: none;
  height: auto !important;
}

.front {
  header#header {
    background: none;
    @include rem(padding, 20px 30px);

    @include desktop {
      @include rem(padding, 20px);
    }

    #logo {
      display: none;
    }

    #navigation-fixed {
      display: none;
    }

    .menu-button {
      margin-right: 20px;
      margin-top: 0;

      svg {
        display: block;

        g {
          fill: #000;
        }
      }

      span {
        display: none;
      }

      @include desktop {
        @include rem(margin-right, 25px);
      }
    }



    .social-icons {
      display: inline-block;

      a {
        @include rem(margin-right, 16px);

        i {
          color: #000000;
          display: block;
          @include rem(font-size, 20px);

          &:before {
            display: block;
          }
        }
      }
    }

    &.fixed {
      @include rem(padding, 0 30px);
      background: #000;
      transform: translateY(-100%);

      .menu-button {
        color: #FFF;

        svg {
          display: inline-block;

          g {
            fill: #FFF;
          }
        }

        span {
          display: none;
        }

        @include desktop {
          @include rem(margin-right, 35px);
        }
      }

      #navigation-fixed {
        display: none;

        @include desktop {
          display: inline-block;
        }
      }

      #logo {
        display: block;
        // @include desktop {
        //   top: 150%;
        // }
      }

      .social-icons {
        display: none;
      }

      .selectric-wrapper {
        .selectric {
          color: #FFF;

          &:after {
            border-top-color: #FFF;
          }
        }
      }

      .right {
        a {
          transition: color .3s !important;
        }

        .bilette {
          color: #FFF;

          &:focus,
          &:hover {
            color: #ba9e66 !important;
          }
        }

        .boutique {
          color: #FFF;

          &:focus,
          &:hover {
            color: #ba9e66 !important;

            i {
              color: #ba9e66 !important;
            }
          }
        }

        .soutenir {
          color: #FFF;

          &:focus,
          &:hover {
            color: #ba9e66 !important;
            outline-color: #fff;
          }
        }

        #open-form {
          color: #FFF;

          &:focus,
          &:hover {
            color: #ba9e66;
          }

          @media (max-width:700px) {
            display: none;
          }
        }
      }

    }

    .selectric-wrapper {
      background: transparent;

      .selectric {
        background: transparent;
        color: #000;

        &:after {
          border-top-color: #000;
        }
      }

      &.selectric-lang-dropdown-select-element {
        .selectric {
          text-transform: uppercase;
        }

        .selectric-items {
          text-transform: uppercase;
          min-width: 75px;
          text-align: center;
        }
      }
    }

    .right {
      a {
        transition: color 0s, background .3s;
      }

      .bilette {
        color: #000;

        @include tablet {
          // @include rem(padding, 5px);
        }

        @include desktop {
          @include rem(padding, 9px 15px 9px 35px);
        }

        i {
          left: 10px;
        }

        &:focus,
        &:hover {
          color: #FFF !important;
          background: #000;
        }
      }

      .boutique {
        color: #000;

        @include tablet {
          @include rem(padding, 5px);
          @include rem(margin, 25px 0 0 0);
        }

        @include desktop {
          @include rem(padding, 9px 15px 9px 15px);
          @include rem(margin, 0);
        }

        i {
          left: 10px;
        }

        &:focus,
        &:hover {
          color: #FFF !important;
          background: #000;

          i {
            color: #FFF !important;
          }
        }
      }

      .soutenir {
        color: #000;

        @include tablet {
          @include rem(padding, 5px);
          @include rem(margin, 25px 18px 0 0);
        }

        @include desktop {
          @include rem(padding, 9px 15px 9px 15px);
          @include rem(margin, 0);
        }

        &:focus,
        &:hover {
          color: #FFF !important;
          background: #000;
        }
      }

      #open-form {
        color: #000;

        @include tablet {
          @include rem(margin, 25px 0 0 0);
        }

        @include desktop {
          @include rem(margin, 0);
        }
      }
    }
  }

  .page-nav {
    @include rem(padding, 20px);
    background: #000;

    @include desktop {
      padding: 0;
    }

    ul.menu {
      li {
        margin-bottom: 5px;

        @include desktop {
          margin-bottom: 0;
        }

        a {
          padding: 0;
          color: #fff;
          font-family: $heading-font-family;
          @include font-size-adapt(20px, 26px);
          @include line-height-adapt(30px, 32px);

          @include desktop {
            @include rem(padding, 20px 0 16px);
            @include font-size-adapt(24px, 26px);
            @include line-height-adapt(26px, 30px);
            border-bottom: 4px solid transparent;

            &:focus,
            &:hover {
              color: $color-primary;
              border-bottom: 4px solid $color-primary;
            }

            &:focus {
              border-bottom: 0;
              outline-color: #fff;
            }

            span {
              display: block;
            }
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  #main {
    margin-top: 0;
  }

  .main-content {
    background: #fff;

    @include desktop {
      @include rem(padding-top, 80px);
    }
  }
}

#site-map {

  text-align: center;
  @include rem(margin, 50px 0);

  .site-map-menus {
    display: inline-block;
  }

  h3,
  .view .view-header h3 {
    @include rem(font-size, 24px);
    text-align: left !important;
  }

  h4 {
    text-align: left;
  }

  .col {
    display: inline-block;
    @include rem(max-width, 540px);
    width: 100%;
    @include rem(margin-bottom, 40px);
  }

  ul {
    margin: 0;

    li {
      text-align: left;
      list-style-image: none;
      list-style: none;

      ul {
        @include rem(padding-left, 10px);
      }

      a {
        color: #666666;
        @include font-size-adapt(15px, 17px);

        span.arrow {
          display: none;
        }

        &:focus,
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

.page-403 {
  #main {
    text-align: center;
    padding: 100px;
  }
}

@media screen and (max-width: 1100px) {
  body.admin-menu.adminimal-menu:before {
    display: none;
  }
}
