.node {
  .top-info {
    @include rem(margin, 30px -40px);

    @include desktop {
      @include rem(margin, 60px -40px 50px);
    }

    .col-left,
    .col-right {
      @include rem(padding, 10px 40px);
    }

    h2 {
      @include rem(margin, 0 0 20px);
      @include font-size-adapt(20px, 24px);
      line-height: 1;
    }

    p,
    li {
      color: #666666;
      @include font-size-adapt(14px, 16px);
      @include rem(line-height, 1.4);
    }

    p,
    li {
      font-size: 16px;
      line-height: 1.4;
    }

    p.text--gold {
      color: $color-primary;
      font-family: $font-crimson;
      @include font-size-adapt(32px);
      @include line-height-adapt(36px);

      a {
        font-size: inherit;
        top: auto;
        font-family: inherit;
      }
    }

    p {
      @include rem(margin, 0 0 20px);

      &:last-child {
        margin: 0;
      }
    }

    .attendance {
      cursor: help;
      @include rem((margin-left: 12px,
          padding-left: 48px));

      &:before,
      &:after {
        @include rem(top, 15px);
      }

      a {
        position: relative;
        display: inline-block;
        font-family: $base-font-family;
        @include font-size-rem(14px);
        @include rem(top, -10px);
        @include rem(padding-bottom, 5px);
        color: #666666;
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }

    ul {
      @include rem(margin, 0 0 20px 0);
      @include rem(padding, 0 0 0 20px);

      @include desktop {
        @include rem(margin, 0 0 35px 0);
      }

      li {
        @include rem(margin-bottom, 20px);

        &:last-child {
          margin: 0;
        }

        a:not(.tooltip):not(.btn) {
          color: $color-primary;
          border-bottom: solid 1px;
        }
      }
    }

    a.btn {
      @include rem(margin-bottom, 20px);

      @include desktop {
        margin-bottom: 0;
        @include rem(margin-right, 20px);
      }

      &:last-child {
        margin: 0 !important;
      }
    }

    .col-left {
      position: relative;
      @include rem((margin-bottom: 40px,
          padding-bottom: 40px));

      @media (max-width:700px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        @include rem((left: 30px,
            right: 30px,
            height: 1px));
        background: #E6E6E6;
      }

      @include tablet {
        margin-bottom: 0;
        padding-bottom: 0;

        &:before {
          display: none;
        }
      }

      p {
        @include desktop {
          @include rem(padding-left, 20px);
        }
      }
    }

    .col-right {
      position: relative;

      @include desktop {
        border-left: 1px solid #E6E6E6;
      }

      .share-print-size {
        position: absolute;
        top: 0;
        @include rem(right, 40px);
        padding: 0 !important;

        a {
          border-bottom: none !important;
        }
      }

      h2 {
        @include desktop {
          @include rem(padding-right, 160px);
        }
      }
    }

    a:not(.tooltip):not(.btn):not(.underline) {
      color: $color-primary;
      border-bottom: solid 1px;

      &:focus,
      &:hover {
        color: #000;
      }
    }

    .view-colonne {
      &>.container {
        padding: 0;
      }

      [class^=col-] {
        @include rem(margin-bottom, 30px);

        @media (max-width:700px) {
          padding: 0;
          margin-bottom: 20px;
        }

        &:last-child {
          margin: 0;

          @media (max-width:700px) {
            padding: 0;
          }
        }
      }

      h4.info-title {
        @include rem(margin, 0 0 10px);
        font-family: $base-font-family;
        text-transform: uppercase;
      }

      .info-content {
        p {
          color: #000;
          @include font-size(14px);
          @include rem(line-height, 20px);

          &:last-child {
            margin: 0;
          }
        }

        a:not(.btn) {
          display: inline-block;
          @include rem(padding-bottom, 5px);
          color: #666666;
          line-height: 1;
          border-bottom: 1px solid #d8d8d8;
          text-decoration: none;

          &:focus,
          &:hover {
            color: $color-primary !important;
          }
        }
      }
    }
  }

  .text-content {
    .full-width {
      @include rem(padding-bottom, 30px);
      margin-bottom: 0;
      background: white;
    }
  }

  .text-content {
    &>h2 {
      width: 100%;
      @include rem((padding: 15px 0 15px 0,
          margin: 0 0 20px));
      @include font-size-adapt(18px, 26px);
      line-height: 1.1;
      border-bottom: 1px solid #f1efea;

      @include desktop {
        @include rem(margin-bottom, 30px);
      }
    }

    &>h3 {
      font-family: $base-font-family;
      color: $color-primary;
      @include rem(margin, 0 0 30px);
      @include font-size-adapt(14px, 19px);
      @include line-height-adapt(24px, 28px);
      text-transform: none;
    }

    &>h4 {
      color: #000;
      font-family: $base-font-family;
      @include font-size-adapt(14px, 16px);
      line-height: 1.8;
      font-weight: 500;
      text-transform: none;
    }

    &>p,
    .view-widget-svg p {
      @include rem(margin, 0 0 30px);
      @include font-size-adapt(14px, 19px);
      @include line-height-adapt(24px, 32px);

      &.text--lead {
        margin-top: rem(50px);
        @include font-size-adapt(16px, 24px);
        line-height: 1.4;
      }
    }

    .text--citation {
      color: $color-primary;
      @include font-size(21px);
      @include rem(line-height, 30px);
    }

    .text--auteur {
      @include font-size(16px);
      text-transform: uppercase;

      &:before {
        content: '— ';
      }
    }

    a:not(.btn):not(.tooltip) {
      color: $color-primary;
      border-bottom: solid 1px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    &>ul,
    &>ol {
      padding: 0;
      @include rem(margin, 0 0 30px);
      list-style: none;

      li {
        color: #333;
        @include font-size-rem-adapt(14px, 19px);
        @include line-height-adapt(24px, 32px);
        @include rem(padding, 0 0 0 30px);
        @include rem(margin-bottom, 10px);

        &:before {
          float: left;
          display: inline-block;
          content: '•';
          @include rem(margin-left, -30px);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul,
      ol {
        margin-left: 0 !important;
        padding-left: 0 !important;
        list-style: none !important;

        li {
          margin: 0;
          @include rem(padding, 0 0 0 30px);
          border: none;
        }
      }
    }

    &>ol {
      counter-reset: counter;

      li {
        position: relative;
        @include rem(padding, 10px 0 10px 30px);
        border-bottom: 1px solid #e6e6e6;
      }

      li:before {
        content: counter(counter);
        counter-increment: counter;
      }

      ol {
        counter-reset: counterchild;

        li:before {
          content: counter(counterchild);
          counter-increment: counterchild;
        }
      }
    }

    .btn-group {
      @include rem(margin-bottom, 30px);
    }

    #reservation-iframe {
      position: relative;
      left: 50%;
      @include rem(margin-left, -50%);
      width: 100%;
      @include rem(max-width, 820px);
      @include rem(height, 510px);

      @include desktop {
        @include rem(width, 820px);
        @include rem(height, 900px);
      }
    }
  }

  .social-icons {
    @include rem(padding, 35px 0 45px);

    h4 {
      @include font-size-adapt(16px, 18px);
      @include line-height-adapt(22px);
      @include rem(margin, 0 0 10px);
    }

    a {
      display: inline-block;
      @include rem(margin, 5px 10px 0);
      color: rgba(#666666, 0.55);
      @include font-size-adapt(16px, 18px);
      line-height: 1;

      .ext {
        display: none;
      }

      i {
        line-height: 1;
        transition: all 0.3s;
      }
    }
  }

  &.node-actualite {
    .with-don {
      @include tablet {
        &.with-don {
          flex-basis: 83.3333333333%;
          max-width: 83.3333333333%;
        }
      }

      @include desktop {
        &.with-don {
          display: flex;
          flex-basis: 83.3333333333%;
          max-width: 83.3333333333%;
          justify-content: space-between;
        }
      }

      .text--lead {
        flex: 1;

        @include tablet {
          flex-basis: 100%;
          max-width: 100%;
        }

        @include desktop {
          flex-basis: 70%;
          max-width: 70%;
        }
      }

      .view-don-ligne {
        margin-bottom: rem(25px);
        padding-bottom: rem(25px);
        border-bottom: 1px solid #e6e6e6;

        @include desktop {
          margin-top: rem(30px);
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        p {
          color: #959595;
          @include font-size(13px);
        }

        .file-link-wrapper {
          @include font-size(14px);
          display: flex;

          @include tablet {}

          @include desktop {
            justify-content: space-between;
          }

          .file-link {
            color: unset;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}

.links {
  &.text--center {
    position: fixed;
    left: 0;
    background-color: #ba9e66;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;

    h4 {
      margin: 0;

      ul {
        li {
          display: flex;
          padding: 10px;

          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
