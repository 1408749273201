.node.node-hub-espace-enseignants,
.node.node-hub-relais {
  .top-info {
    @include rem(margin-bottom, 60px);

    .col-left {
      p {
        @include rem(margin-bottom, 30px);
        padding-left: 0;

        &:last-child {
          margin: 0;
        }

        &.text--gold {
          color: $color-primary;
          font-family: $font-crimson;
          @include font-size-adapt(24px);
          @include line-height-adapt(30px);
          @media (max-width:700px) {
            font-size: 18px!important;
            line-height: 26px!important;
          }
        }
      }
    }
  }

  .view.view-billets-tarifs {
    &>.container {
      padding-top: 0;
    }

    .nav-bar .form-type-bef-link {
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .field-collection-view {
    @include rem(margin-bottom, 90px);

    .inner {
      h4 {
        @include rem(margin, 0 0 20px);
        font-family: $base-font-family;
        @include font-size-adapt(24px);
        @include line-height-adapt(24px);
        text-transform: none;
      }

      a {
        @include rem(margin, 0 0 10px);
        display: inline-block;
        @include rem(padding-bottom, 5px);
        color: #0F0F0F;
        @include font-size(16px);
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  .view.view-display-id-avantages {
    background: url('../images/abonner-bg.jpg') no-repeat center bottom #BA9E66;

    &>.container {
      @include desktop {
        @include rem(padding, 39px 0 77px);
      }
    }

    .view-header {
      @include rem(padding-bottom, 30px);

      h3.title {
        color: #fff;
        text-align: left !important;
      }
    }

    .view-content {
      .col-xs-12 {
        @include rem(margin-bottom, 40px);

        @include desktop {
          margin-bottom: 0;
        }
      }

      .outer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @include rem(padding, 30px 20px 80px);
        background: #fff;
        text-align: center;
        @media (max-width:700px) {
          text-align: left;
          padding: 30px 20px 60px;
        }

        h4 {
          flex: 1 0 100%;
          @include rem(margin, 0 0 20px);
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(32px);
        }

        p {
          @include rem(margin, 0 0 10px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(29px);
          @media (max-width:700px) {
            font-size: 14px;
            line-height: 22px;
          }
        }

        a {
          position: absolute;
          @include rem((left: 20px, right: 20px, bottom: 30px));
          @include font-size-rem-adapt(20px);
          line-height: 1;
          text-decoration: underline;
          @media (max-width:700px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .view.view-push-abonnement {
    margin: 0;
    background: #f1efea;

    .view-content {
      .text {
        @include rem(padding, 60px 20px);

        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);
        }

        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }

        .btn {
          color: #fff;

          &:before {
            background: $color-primary;
          }

          .ext {
            display: none;
          }
        }
      }

      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;

        @include desktop {
          margin: 0;
        }

        img {
          margin: 0;
        }
      }
    }
  }
  .text--center{
    &.space{
      margin: 50px 0;
    }
  }
}

.node.node-hub-relais,
.node.devenir-relais {
  .view.view-id-push_image .outer {
    background: none;
    text-align: center;

    .inner {
      @include rem(padding, 30px 30px 45px);

      @media (max-width:700px) {
        text-align: left;
        padding-bottom: 0;
        padding-left: 0;
      }

      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }

      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        color: #333333;

        @media (max-width:700px) {
          margin-left: 0;
        }

        .ext {
          display: none;
        }

        &:before {
          background: none;
        }
      }
    }
  }

  .video-wrapper {
    @include rem(margin, 50px 0 0 0);
  }
}

.node.relais-evenements-handicap,
.node.relais-evenements-publics {
  .view-hub-contenus {
    .view-header {
      text-align: center;
      @media (max-width:700px) {
        text-align: left;
      }
    }
  }

  .view-push-calendrier {
    display: none;
  }
}
