// Footer

#logo-footer {
  display: block;
  @include rem(padding, 40px 0 35px);

  img {
    display: block;
    @include rem(width, 260px);
    margin: 0 auto;
    height: auto;
  }
}

#go-top,
.scroll-to-top {
  display: block;
  position: relative;
  @include rem((margin: 20px 0 0 30px,
      width: 80px,
      height: 80px,
    ));
  background: #D8D8D8;
  border: none;
  outline-color: #000;
  transition: all 0.3s;

  @include desktop {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    @include font-size(23px);
    line-height: 1;
    @include rem((width: 23px,
        height: 23px));
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
  }

  &:focus,
  &:hover {
    color: #fff;
    background: $color-primary;
  }
}

footer#page-footer {
  position: relative;
  z-index: 90;

  .view {
    padding: 0;
    background: #fff;

    .view-header {
      padding-top: rem(60px);
    }

    .container {
      padding: 0 0 rem(60px) 0;
    }

    &.dark {
      .container {
        padding: 0 0 rem(80px);
      }
    }

    &:not(.dark) {
      .container {
        border-top: 1px solid #E6E6E6;
      }
    }
  }

  div.top-footer {
    position: relative;
    @include rem(padding, 40px 0 0);
    color: #666666;
    background-color: $bg-primary;

    @include desktop {
      @include rem(padding, 60px 0 40px 0);
    }

    h5 {
      margin: 0 0 rem(30px);
    }

    .col {
      position: relative;
      @include rem(margin-bottom, 40px);

      &:last-child {
        margin-bottom: 0;
      }

      i {
        position: absolute;
        top: 0;
        @include rem((top: 3px,
            left: 20px));
      }

      p {
        margin-top: 0;
        @include rem(margin-bottom, 15px);
        @include rem(line-height, 24px);
        @include rem(padding-left, 30px);
        color: $color-secondary;

        &:last-child {
          margin: 0;
        }

        a:not(.btn) {
          display: inline-block;

          @include rem(padding-bottom, 5px);
          color: #666666;
          @include font-size(16px);
          line-height: 1;
          text-decoration: none !important;
          border-bottom: 1px solid #d8d8d8;

          &:focus,
          &:hover {
            color: $color-primary;
          }
        }

        a.btn {
          display: block;
          @include rem(margin-left, -30px);
          @include rem(padding, 20px 15px);

          @include desktop {
            margin-left: 0;
          }
        }
      }

      .share-print-size {
        @include rem(margin-left, 30px);

        a {
          background: transparent;
        }

        a:after {
          background: transparent;
        }

        a i {
          position: relative;
          display: block;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          transform: none;

          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  div.bottom-footer {
    background-color: #000000;

    .row {
      position: relative;
      @include rem(padding, 60px 0 0);

      @include desktop {
        @include rem(padding, 60px 0);
      }

      &+.row {
        background-color: #0F0F0F;

        .field {
          .field-label {
            display: none;
          }
        }
      }
    }

    .field {
      @include font-size(15px);
      line-height: rem(30px);
      @include rem(margin-bottom, 20px);

      @include desktop {
        margin: 0;
      }

      .field-label {
        margin-top: 0;
        margin-bottom: rem(10px);
        color: #fff;
        font-weight: 500;
      }

      a,
      button {
        color: #959595;
        text-decoration: none;
        border: none;
        background: none;
        padding: 0;
        font-size: 14px;
        display: block;
        line-height: 18px;
        margin: 10px 0;

        &:focus,
        &:hover {
          color: $color-primary;
        }
      }
    }

    .social-icons {
      display: block;
      @include rem(margin-top, 20px);
      @include rem(margin-bottom, 10px);
      text-align: center;

      @include desktop {
        margin-top: 0;
        text-align: left;
      }

      a {
        display: inline-block;
        @include rem(margin-right, 10px);
        color: #666666;
        @include font-size(16px, 14px);
        line-height: 1;
        vertical-align: middle;

        .ext {
          display: none;
        }

        i {
          transition: all 0.3s;
        }

        &:last-child {
          margin: 0;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .gss {
      @include rem(margin-bottom, 50px);
      background: #333333;

      @include desktop {
        margin: 0;
        @include rem(margin-bottom, 30px);
        background: rgba(#fff, 0.8);
      }

      .form-item {
        input {
          color: #CCCCCC;

          @include desktop {
            color: #000;
          }
        }
      }

      i {
        color: rgba(#fff, 0.38);

        @include desktop {
          color: rgba(#000, 0.38);
        }
      }
    }
  }
}

.app-banner {
  text-align: center;

  @include desktop {
    text-align: left;
  }

  a {
    display: inline-block;
    margin: 0 5px;

    @include desktop {
      margin: 0;
    }

    &.appstore {
      img {
        width: 109px;
        margin-right: 16px;
      }

      @include desktop {
        img {
          width: 139px;
        }
      }
    }

    &.googlestore {
      img {
        width: 150px;
        margin-left: -10px;
      }

      @include desktop {
        img {
          width: 170px;
          margin-left: -10px;
        }
      }
    }
  }
}

footer .top-footer a:focus {
  outline: 2px solid #000;
}

footer a:focus {
  outline: 2px solid #fff;
}



.footer-nl-block {
  background: #F1EFEA;
  color: black;
  margin-bottom: 30px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-left: 20px !important;

  @media(max-width:1300px) {
    margin: 0 20px 20px !important;
  }

  h2 {
    font-family: "Crimson Text";
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    text-transform: none;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0 !important;

    @media(max-width:990px) {
      font-size: 18px;
      white-space: nowrap;
      gap: 5px;
 
    } 

    svg {
      min-width: 30px;
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom:0;
    @media(max-width:990px) {
    margin-bottom: -10px;
    }
  }

  a {
    background: #000 !important;
    color: #fff !important;
    font-size: 16px !important;
    line-height: 64px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all .3s;
    @media(max-width:990px) {
      font-size: 14px !important;
      gap:5px;
      svg {min-width:30px;}
    }
    svg path {
      transition: all .3s;
    }

    &:hover {
      color: black !important;

      svg path {
        fill: black;
      }
    }

    &:after {
      background: #ba9e66 !important;
    }
  }
}

footer#page-footer div.bottom-footer .field.button-wrapper {
  @media(max-width:990px) and (min-width:720px) {
    padding-left:0;
  } 
} 

footer#page-footer div.bottom-footer .row.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

footer#page-footer div.bottom-footer .row.flex-center {
  display: flex;
  align-items: center;
}

footer#page-footer div.bottom-footer .row.md-nomargin {
  @media(max-width:1300px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}