.view.view-visite-parcours {
  .view-empty {
    @include rem(margin-bottom, 40px);
    p {
      margin: 0;
    }
  }
  .nav-bar:not(.nav-bar--inverted) {

    @include desktop {
      padding: 0;
    }
    .views-exposed-widgets {
      &:before, &:after {
        display: none;
      }
    }
    .form-type-bef-link {
      @include rem(margin-right, 35px);
      a {
        display: block;
        color: #959595 !important;
        @include rem(padding, 28px 0 24px);
        @include rem(line-height, 28px);
        text-decoration: none !important;
        border-bottom: 4px solid transparent;
        &.active {
          color: #000 !important;
          border-bottom-color: #000;
        }
      }
    }
  }
  .views-exposed-form {
    .trigger {
      position: relative;
      display: block;
      width: 100%;
      background: #fff;
      @include rem((
        height: 62px,
        padding: 20px,
        margin-bottom: 30px
      ))
      border: 1px solid #e6e6e6;
      vertical-align: middle;
      @include desktop {
        display: inline-block;
        margin-bottom: 0;
        @include rem((
          margin-right: 20px,
          width: 190px
        ));
      }
      i {
        position: relative;
        color: $color-primary;
        z-index: 4;
      }
      .bef-datepicker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        @include rem(padding, 0 0 0 50px);
        @include font-size(17px);
        line-height: 1.4;
        color: #666;
        border: 0;
      }
    }
    .form-radios {
      display: block;

      .form-type-radio {
        display: block;
        margin: 0;
        @include rem(margin-bottom, 20px);
        &:last-child {
          margin-bottom: 0;
        }
        @include desktop {
          display: inline-block;
        }
        @media (max-width:700px){
          text-align: left;
        }
      }
      @include desktop {
        display: inline-block;
        vertical-align: middle;
        .form-type-radio {
          margin-bottom: 0;
          @include rem(margin-right, 20px);

        }
      }

    }
  }
  .visit-description {
    @include rem(padding, 35px 0);
    .row {
      display: none;
      &.visible {
        display: flex;
      }
    }
    p.icon {
      position: relative;
      @include rem(padding-left, 40px);
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include rem((
          width: 26px,
          height: 26px
        ));
        border-radius: 50%;
        z-index: 1;
      }
      &:before {
        position: absolute;
        @include rem((
          top: 7px,
          left: 7px,
          width: 12px,
          height: 12px
        ));
        @include font-size(12px);
        line-height: 1;
        color: #fff;
        z-index: 2;
      }
      &.icon-checked {
        &:after {
          background: #48D668;
        }
      }
      &.icon-fountain {
        &:after {
          background: #7293CB;
        }
      }
      & + ul {
        @include rem(margin-top, -20px);
        @include rem(padding-left, 60px);
      }
    }
    p, ul {
      @include rem(margin, 0 0 25px);
      &:last-child {
        margin: 0;
      }
    }
    p, li {
      color: #666;
      @include font-size(17px);
      @include rem(line-height, 21px);
    }
    ul {
      @include rem(padding-left, 20px);
    }
  }
  .ticket {
    @include rem(margin-bottom, 40px);
    @include rem(padding, 35px 25px);
    border: 1px solid #e6e6e6;
    .row {
      @include rem((
        margin-left: -10px,
        margin-right: -10px
      ))
      @include desktop {
        @include rem(min-height, 220px);
      }
      [class^="col-"] {
        @include rem((
          margin-bottom: 20px,
          padding-left: 10px,
          padding-right: 10px
        ))
      }
    }
    &.withimage {
      .billet_content {

        @include desktop {
          @include rem(padding-left, 170px);
        }
        img {
          display: none;
          @include desktop {
            display: block;
          }
        }
      }
    }
    .billet_content {
      position: relative;
      @include desktop {
        @include rem(min-height, 150px);
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    h4 {
      @include rem(margin, 0 0 10px);
      color: #262626;
      font-family: $base-font-family;
      @include font-size-adapt(20px, 24px);
      text-transform: none;
      @include desktop {
        @include rem(margin, 0 0 5px);
      }
    }
    p {
      @include rem(margin-bottom, 10px);
      @include font-size-adapt(14px, 16px);
      line-height: 1.8;
      &:last-child {
        margin: 0;
      }
      @include desktop {
        margin: 0;
      }
      a:not(.tooltip):not(.btn) {
        color: $color-primary;
        border-bottom: solid 1px;
      }
    }
    .billet {
      @include rem(margin-bottom, 20px);
      &.right {
        text-align: right;
        .btn-group {
          .selectric-wrapper {
            float: left;
            width: 100%;
            @include rem(margin-bottom, -1px);
            @include desktop {
              margin: 0;
              @include rem(max-width, 180px);
              @include rem(margin-right, -1px);
            }
            .selectric {
              width: 100%;
              color: $color-primary;
              border-color: $color-primary;

              &:after {
                border-top-color: $color-primary;
              }
            }
          }
          & > .btn {
            @include rem(min-height, 60px);
          }
        }
      }
      &:last-child {
        margin: 0;
      }
      p {
        position: relative;
        margin: 0;
        @include font-size(19px);
        line-height: 1.8;
      }
      p.tooltip {
        position: relative;
        display: inline-block;
        color: inherit;
        @include rem(padding-right, 30px);
        padding-bottom: 0;
        background: none;
        &:after {
          display: inline-block;
          content: '?';
          @include font-size(13px);
          @include rem((
            margin-left: 10px,
            width: 20px,
            height: 20px,
            line-height: 20px
          ));
          text-align: center;
          background: #f1efea;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          @include rem(margin-top, -10px);
        }
      }
      .btn-group {
        margin: 0;
        span.price {
          width: 40%;
          margin: 0;
          @include rem(min-height, 59px);
          padding-left: 0;
          padding-right: 0;
          @include font-size(24px);
          @include rem(padding, 15px 10px 14px);
          @include desktop {
            @include rem(width, 92px);
          }
        }
        a.btn {
          width: 60%;
          margin: 0;
          @include desktop {
            width: auto;
          }
        }
      }
    }
  }
  @media (max-width:700px) {
    .slick--optionset--services .slick__arrow {
      display: block;

      button {
        top: 110px;
      }
    }
  }
}
