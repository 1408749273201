.view.view-widget-tldr {

  & > .container {
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    @include rem(padding, 50px 0);
  }
  p.title {
    @include rem(margin, 0 0 25px);
    color: $color-primary;
    @include font-size(18px);
    text-transform: uppercase;
  }
  .outer {
    display: flex;
    justify-content: space-between;
  }
  .inner {
    p {
      margin: 0;
      @include rem(max-width, 480px);
      font-family: $font-crimson;
      color: #333333;
      @include font-size(26px);
      @include rem(line-height, 36px);
      &:before {
        content: '« ';
      }
      &:after {
        content: ' »';
      }
    }
  }
  .share-buttons {
    @include clearfix;
    a {
      position: relative;
      display: block;
      float: left;
      @include rem((
        margin-right: -1px,
        width: 64px,
        height: 64px
      ));
      text-decoration: none;
      overflow: hidden;
      z-index: 2;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #CCCCCC;
        background: none;
        z-index: -1;
      }
      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        transform: translateY(100%);
        @include transition(all 0.4s ease-in-out);
        z-index: -1;
      }
      &.twitter-share-button {
        &:after {
          background: #00ABF1;
        }
      }
      &.facebook {
        &:after {
          background: #3B5998;
        }
      }
      &.google {
        &:after {
          background: #EA4335;
        }
      }
      &:focus, &:hover {
        i {
          color: #fff !important;
        }
        &:after {
          transform: translateY(0);
        }
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #666666;
        @include font-size(18px);
        line-height: 1;
        text-decoration: none;
        transform: translateX(-50%) translateY(-50%);
        @include transition(all 0.4s ease-in-out);
      }
    }
  }
}
