.view-breves {

    &.ui-calendair {
        margin: 0 auto;
        border-top: 0;
        border-bottom: 1px solid #e6e6e6;
        .container {
          @include rem(padding, 40px 0);
          @include rem(max-width, 1000px);
          @include desktop {
              @include rem(padding, 40px 90px);
          }
        }
        .view-grouping .view-grouping-header {
          display: none;
          @include rem(top, -120px);
          left: auto;
          right: 0;
          color: rgba(#666666, 0.5);
          @include desktop {
            display: block;
          }
        }
        .view-grouping-content {
          @include rem(padding, 0 30px);
          @include desktop {
            padding: 0;
          }
          h3 {
            @include rem(margin-bottom, 5px);
            line-height: 1;
            &:first-child {
              margin-top: 0;
            }
            @include desktop {
              @include rem(margin-bottom, 15px);
            }
          }
        }
        .view-content {
            position: relative;
            .views-row {
              @include desktop {
                @include rem(padding, 15px 20px 15px 100px);
              }
              &.views-row-last {
                border: none;
              }
            }
        }
        .view-footer {
          @include padding(0 30px);
          @include desktop {
            margin: 0;
            padding: 0;
          }
        }
    }
}
