// html {
//   scroll-behavior: smooth;
// }

.node-type-murmures-media,
.node-type-hub-murmures {

  #main {
    @media (max-width:1100px) {
      margin-top: 70px;
    }
  }

  header#header {
    nav.menu {
      @media (max-width:1100px) {
        top: 25px;
      }
    }

    .left a {
      color: #fff;
      transition: .3s;

      @media (max-width:700px) {
        color: transparent;
      }

      &:before {
        content: '';
        width: 50px;
        height: 20px;
        padding-right: 40px;
        background: url(../../src/images/back-arrow.svg);
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        position: relative;
        left: 0;
        transition: .3s;
      }

      &:focus,
      &:hover {
        transition: .3s;
        color: #ba9e66;

        &:before {
          left: -5px;
          transition: .3s;
        }
      }
    }
  }

  .view.view-murmures {
    background: none;

    .views-exposed-widget.views-widget-filter-combine {
      @media (max-width: 700px) {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }

    .view-empty {
      padding: 30px 0;
      padding-bottom: 100px;
    }

    .nav-bar {
      border-bottom: none;
    }

    .lo-circular {
      position: absolute;
      left: -65px;
      top: 32px;
    }

    .pager--infinite-scroll li {
      animation: lo-circ-rotate 1s infinite linear;
      box-sizing: border-box;
      background: 0 0;
      border: 2.5px solid #f1efea;
      border-color: #ba9e66 rgba(51, 51, 51, .3) rgba(51, 51, 51, .3);
      border-radius: 10px;
      height: 20px;
      width: 20px;
      list-style: none;
    }

    .views-exposed-widgets.container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width:1100px) {
        justify-content: center;
        flex-wrap: wrap;

        .views-exposed-widget:nth-child(1) {
          margin-right: auto;
          max-width: 100%;
          overflow-x: scroll;
          overflow-scrolling: auto;
          -webkit-overflow-scrolling: auto;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 2px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(255, 255, 255, .5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
          }

          .form-radios {
            width: 500px;
            overflow-x: auto;
            overflow-y: hidden;
          }
        }
      }

    }

    .views-submit-button {
      display: block;
      position: absolute;
      right: 0;
      background-size: 16px;
      width: 20px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
      background-color: transparent;
      background-image: url(../../src/images/search.svg);
      bottom: 5px;
      cursor: pointer;

      @media (max-width:1100px) {
        right: 30px;
      }

      @media (max-width:700px) {
        right: 28px;
      }

      input {
        border: none !important;
        background: none !important;
        outline: none !important;
        width: 100%;
        heigth: 100%;

        &::placeholder {
          color: #666666;
        }
      }
    }

    .views-widget {

      .form-type-textfield {
        margin-top: 28px;

        input {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #000;
          outline: none !important;
          color: #333;
          padding-bottom: 10px;
          font-size: 14px;
        }
      }

      .form-radios {
        .form-item {
          display: inline-block;
          margin-right: 2.1875rem;
          padding-left: 0;
          margin-right: 0;
          line-height: 28px;
          line-height: 1.75rem;
          border-bottom: 4px solid transparent;
          cursor: pointer;
          transition: all .3s;

          input {
            width: 100%;

            &:checked+label {
              color: #000;
              border-bottom: 1px solid;
            }
          }

          label {
            font-size: 14px;
            padding: 30px 0 26px;
            padding: 1.875rem 0 1.625rem;
            color: #333;
            cursor: pointer;
            transition: all .3s;
            display: inline-block;
            margin-right: 25px;
            padding-bottom: 5px;

            @media (max-width:640px) {
              margin-right: 15px;
            }

            &:after,
            &:before {
              display: none;
            }
          }
        }
      }
    }

    .view-content {
      // height: 900px;
      padding-top: 0;
      margin-top: -40px;
      max-width: 1140px;

      @media (max-width:640px) {
        margin-top: -20px;
      }

      a {
        display: inline-block;
        position: relative;
        margin: 20px;
        max-width: calc(100% /3 - 50px);

        @media (max-width:1030px) {
          margin: 10px;
        }

        @media (max-width:890px) {
          margin: 5px;
        }

        @media (max-width:640px) {
          margin: 0;
          max-width: 100%;
          width: 100%;
          margin-bottom: 20px;

          img {
            width: 100%;
          }
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
          max-width: calc(100% /3 - 20px);
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: rgba(0, 0, 0, .4);
          transition: .3s;
        }

        &:focus,
        &:hover {
          &:after {
            opacity: 1;
            transition: .3s;
          }
        }

        &.media-image {

          &:after {
            background-image: url(../../src/images/media-image.svg);
            background-position: center;
            background-size: 25px;
            background-repeat: no-repeat;
          }
        }

        &.media-text {
          &:after {
            background-image: url(../../src/images/media-text.svg);
            background-position: center;
            background-size: 30px;
            background-repeat: no-repeat;
          }

          .media-title {
            position: absolute;
            top: 0;
            color: #ba9e66;
            font-family: "Crimson Text", serif;
            font-weight: 400;
            left: 0;
            padding: 40px;
            font-size: 30px;
            line-height: 36px;

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
              padding: 20px;
            }
          }
        }

        &.media-video {
          &:after {
            background-image: url(../../src/images/media-video.svg);
            background-position: center;
            background-size: 25px;
            background-repeat: no-repeat;
          }
        }
      }

    }

  }

  footer#page-footer {
    div.top-footer {
      margin-top: -16px;
      padding: 40px 0;
    }

    div.bottom-footer .container {
      display: flex;
      padding: 60px 0;
      padding-bottom: 70px;
      justify-content: space-between;
      max-width: 740px;
      margin: 0 auto;

      @media (max-width:800px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
      }

      #logo-footer {
        padding-top: 0px;

        @media (max-width:800px) {
          padding: 40px 0 40px;
        }

        @media (max-width:800px) {
          img {
            margin-bottom: 0px;
          }
        }
      }

      img {
        max-height: 80px;

        @media (max-width:800px) {
          margin-bottom: 0px;
        }
      }

      .logo-left {
        padding: 10px;
        background: #fff;
      }

      .logo-right {
        width: 160px;
        background: none;

        @media (max-width:800px) {
          width: auto;
        }
      }
    }
  }

  .article-header {
    height: 800px;
    background-size: 400px 500px;
    background-repeat: no-repeat;
    background-position: right 100px top 100px;

    @media (max-width:1100px) {
      margin-top: -54px;
    }

    @media (max-width:900px) {
      height: auto;
    }

    .container {
      display: flex;
      padding-top: 50px;

      @media (max-width:900px) {
        flex-direction: column-reverse;
        align-items: flex-end;
        padding-top: 50px;
      }

      @media (max-width:700px) {
        padding: 0;
      }

      .text--left {
        width: 60%;

        @media (max-width:900px) {
          width: 100%;
          padding-top: 50px;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
          padding-top: 40px;
        }

        .mega {
          text-align: left;
          font-size: 50px;
          width: 50%;
          line-height: 1.3;

          @media (max-width:700px) {
            font-size: 35px;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
            font-size: 46px;
          }

        }

        .chapo {
          padding: 50px 0;

          @media (max-width:700px) {
            padding-top: 20px;
            padding-bottom: 100px;
          }

          @media (max-width:640px) {
            padding-top: 20px;
            padding-bottom: 30px;
          }

          @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
            padding-top: 10px;
            padding-bottom: 20px;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
            padding: 30px 0;
          }

          p {
            max-width: 600px;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 28px;

            @media (max-width:700px) {
              max-width: 100%;
              font-size: 16px;
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
              font-size: 15px;
              line-height: 21px;
              max-width: 65%;
            }

            @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
              font-size: 15px;
              line-height: 24px;
            }

            a {
              color: #fff;
              text-decoration: underline;

              &:focus,
              &:hover {
                color: #000;
              }
            }
          }
        }

        .link {
          display: inline-block;
          position: relative;
          cursor: pointer;
          // margin-top: 20px;
          top: 110px;

          @media (max-width:800px) {
            margin-top: 0;
            top: 0;
          }

          @media (max-width:700px) {
            display: block;
          }

          &:after {
            content: '';
            background-image: url(../../src/images/arrow-down.svg);
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            right: 30px;
            top: 20px;
            width: 20px;
            height: 20px;
            z-index: 200;
          }

          a {
            margin-top: 0;
            padding-right: 55px;
            color: #fff;
            text-decoration: underline;
            scroll-behavior: smooth;

            &:before {
              border-color: #fff;
            }
          }
        }
      }

      .media--right {
        width: 40%;
        display: flex;
        position: relative;
        right: -50px;
        top: 50px;

        @media (max-width:900px) {
          width: 70%;
        }

        @media (max-width:700px) {
          display: none;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
          width: 65%;
          top: 30px;
          right: -55px;
        }

        div {
          padding: 35px;
          background-repeat: no-repeat;
          overflow: hidden;
          background-color: #DC5B45;

          &.media-text {
            .media-title {
              position: absolute;
              top: 60px;
              color: #BA9E66;
              padding: 15px 20px;
              max-width: 208px;
              font-family: "Crimson Text", serif;
              font-size: 32px;
              font-weight: 400;
              line-height: 34px;
              background-color: transparent;
              left: 34px;
              max-height: 283px;

              @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                max-width: 208px;
                left: 34px;
                padding: 25px 20px;
                max-height: 283px;
                font-size: 32px;
                line-height: 36px
              }
            }
          }

          &.media-first {
            width: 50%;
            padding: 50px;
            background-image: url(../../src/images/cadre-first.svg);
            width: 276px;
            height: 375px;
            padding: 60px 34px;
            padding-bottom: 32px;

            img {
              height: 100%;
            }
          }

          &.media-second {
            margin-right: -50px;
            position: absolute;
            right: -50%;
            top: -50px;
            background-image: url(../../src/images/cadre-second.svg);
            padding: 25px 28px;
            padding-bottom: 25px;
            padding-bottom: 27px;
            width: 290px;
            height: 220px;

            @media (max-width:900px) {
              margin-right: 50px;
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
              margin-right: 70px;
            }

          }

          &.media-third {
            width: 100%;
            margin-right: -50px;
            background-image: url(../../src/images/cadre-third.svg);
            position: absolute;
            bottom: -60px;
            right: -220px;
            height: 311px;
            width: 586px;
            padding: 38px 30px 28px 25px;

            @media (max-width:900px) {
              bottom: -140px;
            }
          }

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
          }
        }
      }
    }
  }

}

.node-type-hub-murmures {
  .article-header {
    height: 100vh;
    justify-content: flex-start;

    @media (max-width:640px) {
      padding: 20px 40px 40px 40px;
    }

    @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
      padding: 10px 30px 0px 30px;
    }

    .container {
      padding: 50px 0 0 0;

      @media (max-width:640px) {
        padding: 20px 0 0 0;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        padding: 0;
      }
    }
  }

  footer#page-footer {
    div.top-footer {
      margin-top: -16px;
      padding: 40px 0;

      #go-top {
        top: -80px;
        background: #fff;
        right: 0;
        left: auto;
        margin: 0;
        position: absolute;

        &:focus,
        &:hover {
          color: #ba9e66;
        }

        @media (max-width:640px) {
          display: none;
        }
      }
    }
  }

}

.node-type-murmures-media {

  &.role-authenticated-user {
    #main {
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        margin-top: 40px;
      }
    }
  }

  .mfp-iframe-holder .mfp-content {
    max-width: 1200px;

    img {
      max-height: 675px;
      width: 100%;
    }
  }

  .main-content {
    .content-top.container {
      padding: 50px 0;

      a {
        color: #333;
        transition: .3s;

        &:before {
          content: '';
          position: relative;
          left: 0px;
          width: 50px;
          height: 20px;
          padding-right: 40px;
          background: url(../../src/images/back-arrow_grey.svg);
          background-position: center;
          background-size: 20px;
          background-repeat: no-repeat;
          transition: .2s;
        }

        &:focus,
        &:hover {
          transition: .3s;
          text-decoration: underline;

          &:before {
            left: -5px;
            transition: .2s;
          }
        }
      }
    }

    .content-bottom {
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        padding-bottom: 50px;
      }
    }

    .content {
      display: flex;

      @media (max-width:640px) {
        flex-wrap: wrap;
        padding: 0 20px;
      }

      &>div {
        width: 50%;

        @media (max-width:640px) {
          width: 100%;
        }

        &.text-wrapper {
          margin-left: 20px;

          @media (max-width:640px) {
            margin-left: 0;
            margin-top: 20px;
          }

          h1 {
            border-bottom: 1px solid;
            font-size: 35px;
            display: inline-block;
            padding-bottom: 10px;
            margin-bottom: 30px;

            @media (max-width:640px) {
              font-size: 30px;
            }
          }

          .media-info p {
            margin-bottom: 0;
            margin-top: 5px;

            span {
              font-weight: 500;
            }
          }

          .media-desc {
            margin-top: 20px;

            p {
              line-height: 22px;
              margin: 0;
              font-size: 14px;
            }
          }
        }

        &.media-wrapper {
          margin-right: 20px;
          position: relative;

          @media (max-width:700px) {
            margin-right: 0;
          }

          img {
            width: 100%;
          }

          .enhance {
            bottom: 0px;
            right: 0;
            position: absolute;
            background: rgba(0, 0, 0, .7);
            transition: .3s;

            &:focus,
            &:hover {
              background: #ba9e66;
              transition: .3s;
            }

            a {
              color: #fff;
              padding: 8px 15px;
              padding-left: 40px;
              display: inline-block;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                background-image: url(../../src/images/search_white.svg);
                background-repeat: no-repeat;
                background-position: center;
                left: 7px;
                top: 1px;
              }
            }
          }

          .media-texte {
            position: relative;

            .enhance {
              a:before {
                background-image: url(../../src/images/media-text.svg);
                background-size: 20px;
                top: 2px;
              }
            }

            .media-title {
              position: absolute;
              color: #ba9e66;
              font-family: "Crimson Text", serif;
              font-size: 48px;
              font-weight: 400;
              line-height: 58px;
              height: 100%;
              top: 0;
              left: 0;
              padding: 60px;

              @media (max-width:640px) {
                font-size: 42px;
                padding: 60px;
                line-height: 50px;
              }

              @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                padding: 30px;
              }
            }
          }

          .media-video {
            position: relative;

            .enhance {
              a:before {
                background-image: url(../../src/images/media-video.svg);
                background-size: 13px;
                top: 2px;
              }
            }
          }

          .media-image {
            position: relative;

            .enhance {
              bottom: 0px;
            }
          }
        }
      }
    }

    .view-murmures {


      .container {
        display: flex;
        margin-top: 100px;
        padding: 0 0 60px 0;

        @media (max-width:640px) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
          padding: 30px;
          margin-top: 50px;
        }

        .view-header {
          max-width: 160px;
          margin-right: 50px;
          border-bottom: 1px solid #333;
          padding-bottom: 0;
          max-height: 110px;

          @media (max-width:640px) {
            max-width: 50%;
            width: 100%;
            margin-right: 10px;
            max-height: 118px;
            margin-top: -20px;
          }

          h2 {
            text-transform: none;
            font-size: 30px;
            line-height: 40px;
            font-family: "Crimson Text", serif;
          }
        }

        .view-content {
          display: flex;

          @media (max-width:640px) {
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            width: 50%;

            .views-row {
              @media (max-width:640px) {
                width: 100%;

                &:last-of-type {
                  position: absolute;
                  max-width: 100%;
                  max-height: 100%;
                  bottom: 0;
                  left: calc(-100% - 10px);
                }

                &:nth-child(2) {
                  margin-top: 20px;
                }
              }
            }
          }

          a {
            max-width: 100%;
            overflow: hidden;

            &.media-text .media-title {
              font-size: 24px;
              line-height: 1.2;
              padding: 20px 30px;

              @media (max-width:640px) {
                padding: 20px;
              }

              @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                font-size: 20px;
                line-height: 22px;
                padding: 10px 15px;
              }
            }
          }
        }
      }
    }
  }

  footer#page-footer {
    div.top-footer {
      margin-top: -16px;
      padding: 40px 0;

      #go-top {
        top: -80px;
        background: #fff;
        right: 0;
        left: auto;
        margin: 0;
        position: absolute;

        &:focus,
        &:hover {
          color: #ba9e66;
        }

        @media (max-width:640px) {
          display: none;
        }
      }
    }
  }
}

/* Magnific Popup CSS */

.image-popup-vertical-fit {
  display: block;

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;

    transform: translateX(-50px);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateX(50px);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }

  }
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;

  &:focus {
    outline: 2px solid;
    // width: auto!important;
  }
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 980px;
  min-height: 80vh;
}

.youtube-iframe-popup .mfp-iframe-holder .mfp-content {
  min-height: 100px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;

  .video-title {
    color: white;
    position: absolute;
    bottom: calc(100% + 20px);
  }
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
  border: none;
}

.mfp-iframe-scaler .mfp-title {
  position: absolute;
  font-family: Graphik, serif;
  margin-top: 20px;
  font-size: 12px;
  bottom: -25px;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 0px 0 5px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
  font-family: 'Apollon', Arial, sans-serif;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
 
  .mfp-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

// list effects

.node-type-hub-murmures .view-filters .views-exposed-widget {
  margin-left: 0;
}

.node-type-hub-murmures .view-filters .views-exposed-widget.views-widget-filter-field_murmures_categorie_tid_selective,
.node-type-hub-murmures .view-filters .views-exposed-widget.views-widget-filter-combine {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  margin: 0;

  @media (max-width:700px) {
    flex-basis: 100%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    flex-basis: 100%;
  }
}

.node-type-hub-murmures .view-filters .views-exposed-widget.views-widget-filter-combine .views-widget {
  text-align: right;
}

.node-type-hub-murmures .view-filters .views-exposed-widget.views-widget-filter-combine .views-widget input {
  padding-right: 50px;

  /* do not group these rules */
  &::-webkit-input-placeholder {
    color: #333;
  }

  &:-moz-placeholder {
    /* FF 4-18 */
    color: #333;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* FF 19+ */
    color: #333;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #333;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }

  &::placeholder {
    /* modern browser */
    color: #333;
  }


  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
  }
}

.node-type-hub-murmures .view-filters .views-widget-filter-combine {
  margin-right: 0;
}

.node-type-hub-murmures .view.view-murmures .view-content {
  a {
    opacity: 0;
    overflow: hidden;
    -webkit-animation: fadeIn 0.5s 1;
    animation: fadeIn 0.5s 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  a:nth-child(5n+1) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  a:nth-child(5n+2) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }

  a:nth-child(5n+3) {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }

  a:nth-child(5n+4) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
  }

  a:nth-child(5n+5) {
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
  }
}

/* Animation steps */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

.node-type-murmures-media .main-content .view-murmures .container .view-content a:hover img,

.node-type-hub-murmures .view.view-murmures .view-content a:hover img {
  transform: scale(1.1);
  transition: transform 1s;
  animation: fadeIn 1s 1;
}

.node-type-murmures-media .main-content .view-murmures .container .view-content a img,
.node-type-hub-murmures .view.view-murmures .view-content a img {
  animation: fadeIn 1s 1;
  transition: transform 1s;
}
