/*------------------------------------*\
    PAGINATION.CSS
\*------------------------------------*/
/**
 * Variables
 */
$pagination-hover-background: $color-light-grey;
$pagination-active-background: $color-primary;
/**
 * Pagination
 */

.item-list {
  text-align: center;
}

.item-list .pager,
.ui-pagination {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include rem((margin-top: 50px, padding: 49px 0));
  list-style: none;
  margin-left: 0;
  @include clearfix;

  @include desktop {
    @include rem((margin-top: 50px, padding: 0));
  }

  @media (max-widtH:700px) {
    justify-content: flex-start;
  }

  .ajax-progress-throbber {
    display: none;
  }

  >li {
    margin: 0;
    padding: 0;
    background: #fff;
    @include rem(margin-right, -1px);

    @include desktop {
      flex: 1 1 auto;
    }

    @include tablet {
      @include rem(max-width, 62px);
    }

    &:last-child {
      margin-right: 0;
    }

    &.pager-current {
      font-weight: 400;

      a,
      span {
        color: $color-primary;
      }
    }

    &.pager-first,
    &.pager-last {
      display: none;
    }

    &.pager-next,
    &.pager-previous {

      @include desktop {
        flex: 0 0 100%;
      }

      @include tablet {
        flex: 1 1 auto;
        position: static;
        display: block;
        width: auto;
        margin: 0 !important;
        @include rem(max-width, 150px);
        @include rem(margin-right, -1px !important);
      }
    }

    // &.pager-previous {
    //   @include rem(margin-bottom, -1px);
    // }

    // &.pager-next {
    //   @include rem(margin-top, -1px);
    // }

    i {
      display: inline-block;
      vertical-align: middle;
      margin: 0 rem(10px);
    }

    a,
    span {
      color: #333;
      display: block;
      padding: 0 rem(20px);
      min-width: rem(50px);
      min-height: 0;
      height: rem(50px);
      line-height: rem(50px);
      text-align: center;
      border: 1px solid #CCCCCC;
      background: #fff;
      vertical-align: top;

      @include desktop {
        min-width: rem(62px);
        height: rem(62px);
        line-height: rem(62px);
      }

      &:after,
      &:before {
        display: none;
      }
    }

    a {
      transition: all 0.3s;

      &:focus, &:hover {
        color: #fff;
        background: #000;
        background-color: #000;
      }
    }
  }
}
