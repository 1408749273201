.view-dates-personnages.view-display-id-hub_personnages {
  color: #fff;
  background: #000 !important;

  .views-exposed-form {
    @include tablet {
      @include rem(margin-left, 30px);
    }
  }

  .d-flex {
    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  .d-flex__space {
    justify-content: space-between;
  }

  .view-top {
    @include tablet {
      @include rem(margin-bottom, 100px);
    }
  }

  .view-filters {
    margin-bottom: 0;
  }

  .view-header {
    @include tablet {
      padding: 0;
    }
  }

  .views-exposed-widgets {
    @include tablet {
      display: flex;
      margin: 0;
    }

    .views-exposed-widget {
      @include rem(margin-bottom, 20px);

      @include tablet {
        @include rem(margin, 0 40px 0 0);
      }
    }
  }

  .visiteurs-person__figure {
    .visiteurs-person__role {
      opacity: 0.7;
    }

    .visiteurs-person__deco--lines {
      z-index: 4;
    }

    .visiteurs-person__date {
      max-width: 100%;
      width: 100%;

      @media (max-width:700px) {
        width: 100%;
        padding: 0;
        white-space: unset;
      }
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 105%;
      height: 105%;
      background-image: linear-gradient(0deg, #000000 3%, rgba(0, 0, 0, 0.00) 100%);
    }
  }
}

.view-id-dates_personnages.view-display-id-explorer {
  @include rem(padding, 0 0 90px 0);
  background: #F1EFEA;

  @include tablet {
    @include rem(padding, 90px 0);
  }

  &>.container {
    padding: 0;
  }

  .ui-header {
    @include rem(margin-bottom, 40px);
    text-align: center;
    cursor: pointer;
    overflow: hidden;

    &:focus, &:hover {
      .bg {
        transform: scale((1.1));
      }
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all 0.5s;
      z-index: 1;
    }

    &>div {
      position: relative;
      @include rem(padding, 0 30px);
      z-index: 3;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(0deg, #000000 2%, rgba(0, 0, 0, 0.00) 100%);
      z-index: 2;
    }

    .btn {
      @include rem(width, 173px);
    }
  }

  .row-top {
    .ui-header {
      @include rem(height, 450px);
    }
  }

  .row-bottom {
    .ui-header {
      @include rem(height, 350px);
    }
  }

  .view-footer {
    @include rem(margin-top, 10px);
    @include rem(padding, 0 30px);
  }
}

.view-dates-personnages.view-display-id-dates {
  &>.container {
    padding: 0;
    max-width: 100%;
  }

  .date-wrapper {
    background: #F1EFEA;

    .container {
      position: relative;
      @include rem(padding, 50px 30px);

      @include tablet {
        @include rem(padding, 45px 0 60px 110px);
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          @include rem(left, 55px);
          @include rem(width, 1px);
          height: 100%;
          background: #999999;
        }
      }
    }

    &:first-child {
      .container {
        @include tablet {
          @include rem(padding, 140px 0 60px 110px);
        }
      }
    }

    &:last-child {
      .container {
        &:before {
          @include tablet {
            height: auto;
            @include rem(bottom, 60px);
          }
        }
      }
    }

    &:nth-child(2) {
      background: #EDEAE2;

      .see-children .button,
      .date-block p.subtitle:before {
        background: #EDEAE2;
      }
    }

    &:nth-child(3) {
      background: #E9E5DB;

      .see-children .button,
      .date-block p.subtitle:before {
        background: #E9E5DB;
      }
    }

    &:nth-child(4) {
      background: #E5E0D3;

      .see-children .button,
      .date-block p.subtitle:before {
        background: #E5E0D3;
      }
    }

    &:nth-child(5) {
      background: #E1DBCC;

      .see-children .button,
      .date-block p.subtitle:before {
        background: #E1DBCC;
      }
    }
  }

  .see-children {
    position: relative;
    display: flex;
    align-content: flex-start;
    @include line-height-adapt(55px);

    .button {
      display: block;
      position: relative;
      @include rem(margin-right, 20px);
      @include rem(width, 55px);
      @include rem(height, 55px);
      border: 1px solid #999999;
      border-radius: 50%;
      text-align: center;
      color: #999999;
      font-family: $heading-font-family;
      @include font-size-adapt(50px);
      line-height: 1;
      background: #F1EFEA;

      @include tablet {
        position: absolute;
        top: 50%;
        @include rem(left, -54px);
        transform: translate(-50%, -50%);
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '+';
      }
    }

    .less {
      display: none;
    }
  }

  .dates-row.visible {
    .see-children {
      .button {
        &:before {
          top: 58%;
          content: '-';
        }
      }

      .less {
        display: inline;
      }

      .more {
        display: none;
      }
    }
  }

  .date-block {
    p.subtitle {
      position: relative;
      margin: 0;
      font-family: $heading-font-family;
      color: $color-primary;
      @include font-size-adapt(24px, 28px);
      @include line-height-adapt(30px, 35px);

      &:before {
        @include tablet {
          content: '';
          @include rem(width, 16px);
          @include rem(height, 16px);
          border: 1px solid #999999;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          @include rem(left, -54px);
          transform: translate(-50%, -50%);
          background: #F1EFEA;
        }
      }
    }

    h2 {
      @include rem(margin, 0 0 45px);
      color: #4A4A4A;
      font-family: $font-crimson;
      @include font-size-adapt(42px, 62px);
      @include line-height-adapt(46px, 66px);
      text-transform: none;

      sup {
        top: -1em;
        font-size: 0.5em;
      }
    }

    .item-list {
      text-align: left;

      ul {
        list-style: none;

        li {
          &:before {
            content: '- ';
          }

          &:last-child {
            margin: 0;
          }

          @include rem(margin, 0 0 15px);
          color: #666666;
          @include font-size-adapt(15px);
          @include line-height-adapt(15px);
        }
      }
    }

    .date-block-text {
      order: 2;

      @include tablet {
        order: 1;
      }
    }

    .date-block-chapo {
      @include font-size-adapt(16px);
      @include line-height-adapt(28px);
      padding-right: 20px;
      color: #666;
    }

    .date-block-image {
      margin-bottom: 0;
      margin-top: 0;
      @include rem(padding-bottom, 30px);
      order: 1;

      @include tablet {
        order: 2;
        margin: 0;
        @include rem(padding-top, 35px);
      }
    }
  }

  .date-block-children {
    &.text-only {
      @include rem(padding, 40px 0 40px 0);
      color: #666;
    }

    display: none;
    @include rem(padding-top, 60px);

    @include tablet {
      @include rem(padding, 140px 0 40px 40px);
    }

    .date-block {
      @include rem(margin-bottom, 40px);

      @include tablet {
        @include rem(margin-bottom, 60px);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    p.grey-text {
      padding: 0;
      color: #666666;
      @include rem(margin, 0 0 10px);
      @include font-size-adapt(15px);
      @include line-height-adapt(15px);

    }

    h3 {
      @include rem(margin, 0 0 10px);
      color: #333333;
      @include font-size-adapt(24px);
      @include line-height-adapt(26px);
    }

    p {
      @include font-size-adapt(16px);
      @include line-height-adapt(28px);
    }
  }
}
