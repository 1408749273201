html body div {

  .select-a11y {
    position: relative
  }

  .select-a11y.is-open .select-a11y__overlay {
    display: block
  }

  .select-a11y input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    display: none !important
  }

  .select-a11y-button {
    width: 100%;
    text-align: left;
    background-color: #f1efea;
    position: relative;

  }

  .select-a11y-button[aria-expanded=true] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
  }

  .select-a11y-button[aria-expanded=true] .select-a11y-button__icon {
    transform: rotate(180deg);
    top: 42%;
    right: 13px;
  }

  .select-a11y-button__clear {
    appearance: none;
    background: 0 0;
    border: 0;
    margin: 0;
    padding: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem
  }

  .select-a11y-button__clear:before {
    content: "✕"
  }

  .select-a11y-button.select-a11y-button--no-selected-option+.select-a11y-button__clear {
    display: none
  }

  .select-a11y-button__text {
    padding-right: 2.5rem
  }

  .select-a11y-button__icon {
    line-height: 0;
    position: absolute;
    top: 50%;
    right: .5em
  }

  .select-a11y-button__icon:before {
    content: "";
    top: 50%;
    right: 0;
    border: 3px solid transparent;
    border-top-color: #333;
    transform: translateY(-50%);
    display: block;
    position: absolute;
    width: 0;
    height: 0;
  }

  .select-a11y__selected-item {
    border: 1px solid #c3c4cc;
    padding: .2em .1em .2em .5em
  }

  .select-a11y-delete__icon:before {
    content: "✕";
    color: red;
    line-height: 1
  }

  .select-a11y-delete {
    background-color: #0000;
    border: 1px solid #0000
  }

  .select-a11y-delete:focus {
    color: red;
    outline: 1px solid red
  }

  .select-a11y-delete:hover {
    color: red;
    border-color: red
  }

  .select-a11y__hidden {
    display: none !important
  }

  .select-a11y__overlay {
    z-index: 10;
    background: #fff;
    border: none;
    // box-shadow: 0 0 2px #333;
    position: absolute;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  }

  .select-a11y-suggestions {
    background-color: #fff;
    //border: 1px solid #595b6a;
    border: 1px solid #e6e6e6;

    overflow-y: auto;
    display: block;
  }

  .select-a11y-suggestion {
    color: #595b6a;
    cursor: pointer;
    text-align: left;
    align-items: center;
    padding: .25rem .5rem .25rem 1.5rem;
    display: flex;

    &:focus {
      outline: 2px solid #000;
    }
  }

  .select-a11y-suggestion:focus,
  .select-a11y-suggestion:hover {
    color: #333;
    background-color: #f1efea
  }

  .select-a11y-suggestion[aria-selected=true] {
    color: #333;
    background-color: #f1efea;
    position: relative
  }

  .select-a11y-suggestion__image {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    object-position: center;
    margin-right: .5rem
  }

  .select-a11y__no-suggestion {
    color: #595b6a;
    text-align: left;
    margin: 0;
    padding: 0 .5em
  }

  .select-a11y__selected-list {
    margin: 0;
    padding: .5em 0
  }

  .select-a11y__selected-list li {
    margin: 0 .3em .3em 0
  }

  .select-a11y__selected-list li:before {
    content: ""
  }

  .select-a11y__selected-list li span {
    display: inline-block
  }
}


.select-a11y {
  .select-a11y-suggestions__list {
    display: flex;
    flex-direction: column;

    .select-a11y-suggestion {
      padding: 23px 15px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .select-a11y-button {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-size: 15px;
    line-height: 2;
    text-transform: none;
    border: none;
    background: 0 0;
    color: #000;
    border: 1px solid #e6e6e6;


    .select-a11y-button__icon:before {
      font-size: 9px;
    }

    .select-a11y-button__text {
      padding-right: 0;
    }

    +div input {
      display: none;
    }
  }
}
