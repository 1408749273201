.view.view-display-id-svg_texte {
  & > .container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .row {
    align-items: center;
  }
}

.view.view-display-id-svg_texte_multiple {
  & > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .row [class^=col-] {
    margin: 0;
  }
  .svg-wrapper svg {
    max-width: 100%;
    margin: 0 auto;
    @include desktop {
      max-width: none;
    }
  }
  @include desktop {
    .full-width:first-child {
      .svg-wrapper {
        position: absolute;
        display: block;
        top: 0;
      }
    }
    .full-width:last-child {
      .svg-wrapper, .svg-wrapper.fixed {
        position: absolute !important;
        top: 0 !important;
      }
    }
    .svg-wrapper {
      display: none;
      position: fixed;
      @include rem(top, 200px);
      background: #fff;
      &.fixed {
        @include rem(top, 200px !important);
        display: block !important;
        position: fixed !important;
      }
    }
  }
}