* {

  &:focus,
  &:focus-visible {
    // border: 1px solid #000!important;
    outline-offset: 3px;
    outline: 2px solid;
    // outline-color: revert;
  }
}

#nav-toggle:focus {
  outline-color: #fff;
}

.skip-link {
  position: absolute;
  z-index: 920;
  list-style: none;
  padding: 0;
  outline: transparent !important;

  @media (max-width: 700px) {
    display: none;
  }

  li {
    background: #ba9e66;
    color: #000;

    a {
      text-decoration: none;
      display: block;
      font-size: 18px;
      padding: 10px 20px;
      position: absolute;
      top: -420px;
      left: 0;
      z-index: 10;
      background: #ba9e66;
      color: #000;
      white-space: nowrap;

      &:focus {
        top: 0;
      }
    }
  }
}

.page-nav ul.menu li,
.search-form-full .toggle-search-form,
.search-form-full .form-item .form-text {
  outline-color: #fff !important;
}

header#header .right #open-form i {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-form-full .form-actions {
  button {
    top: calc(50vh);
    right: calc(5% + 45px);
    color: #fff;
    height: 44px;
    width: 40px;
    border-bottom: 0;


    &:focus {
      outline: 2px solid #fff;
    }

    i {
      display: block;

      &:before {
        font-size: 25px;
      }
    }
  }
}

.a11y {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.a11y-focusable :focus {
  clip: auto !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

#content {
  scroll-margin-top: 70px;
}

.view.ui-news {
  .row .outer {
    cursor: pointer;

    img {
      transition: .1s;
      filter: brightness(1);
    }

    &:focus-within img,
    &:focus img,
    &:hover img {
      filter: brightness(0.5);
      transition: .3s;
    }

    &:focus-within h4 {
      outline: 2px solid #000;
      outline-offset: 3px;
    }

    h4 {

      a {

        &:focus {
          outline: 2px solid #000;
          outline-offset: 3px;
        }

      }
    }
  }

  &.view-display-id-block_actus {
    p.tags {
      margin-top: 20px;
    }
  }
}

#sidebar {
  transform: translateX(-100%);
  transition: ease-out .3s;
  display: block;

  &.open {
    transition: ease-out .3s;
    transform: translateX(0);
  }
}

#navigation {
  scroll-margin-top: 70px;
}

#tarteaucitronRoot a:focus,
#tarteaucitronRoot .tarteaucitronDeny:focus,
#tarteaucitronRoot .tarteaucitronAllow:focus {
  outline: 2px solid #000;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden
}

.form-type-select,
.form-item-jump {
  label {
    display: none;
  }
}

// specific selects scss

div#block-views-publics-block {
  .select-a11y__overlay {
    width: 240px;
    max-height: unset;
    overflow: auto;
  }

  .select-a11y-button {
    border: none;
  }
}

html body .block-lang_dropdown {
  .select-a11y-button {
    text-transform: uppercase;
    border: none;
    padding-top: 0px;
    padding-bottom: 1px;
  }

  .select-a11y__overlay {
    width: 74px;
    text-transform: uppercase;

    .select-a11y-suggestions__list .select-a11y-suggestion {
      width: 100%;
      justify-content: center;
    }
  }
}

header .block-lang_dropdown .select-a11y-button,
header div#block-views-publics-block .select-a11y-button {
  color: #fff !important;

  .select-a11y-button__icon:before {
    border-top-color: #fff;
  }
}

.node-type-home header:not(.fixed) .block-lang_dropdown .select-a11y-button,
.node-type-home header:not(.fixed) div#block-views-publics-block .select-a11y-button {
  color: #000 !important;

  .select-a11y-button__icon:before {
    border-top-color: #000;
  }
}

#sidebar {
  .select-wrapper {
    form {
      &.language {
        .select-a11y-button {
          text-transform: uppercase;
          border: 1px solid #e6e6e6;
          background-repeat: no-repeat;
          background-position: center left 18px;
          background-size: 20px;
          padding-left: 40px;
          padding-right: 60px;
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 17px;
        }

        .select-a11y-suggestions {
          text-transform: uppercase;
        }
      }

      &:not(.language) {
        .select-a11y-button {
          border: 1px solid #e6e6e6;
          background-image: url(/sites/all/themes/versailles/images/vousetes.svg);
          background-repeat: no-repeat;
          background-position: center left 20px;
          padding-left: 40px;
          padding-right: 60px;
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 17px;
          color: #666;
        }

        .select-a11y__overlay {
          bottom: calc(100% + 10px);
          width: 240px;
          left: 0;
          right: auto;
        }
      }
    }
  }
}

figure.brown-figure:focus-within {
  background: #ba9e66;
}

.btn:focus {
  outline: 2px solid #000;
}

.node-home .home-header .buttons .btn,
.view-push-image.view-display-id-block_full .view-content .ui-header .btn-group a {
  &:focus {
    outline-color: #fff !important;
  }
}

.form-item.webform-component.webform-component-file:focus-within {
  outline: 2px solid #000;
  outline-offset: 3px;
}

.view-dates-personnages {
  .view-filters {
    label {
      display: none;
    }

    button {
      background: #fff;
      width: 240px;
      font-size: 16px;
    }
  }
}

.quote-wrapper {
  margin: 60px auto;
  max-width: 570px;
  text-align: center;

  blockquote {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;

    p.quote-text {
      font-size: 22px;
    }
  }

  figcaption.quote-author {
    color: #ba9e66;
    text-transform: uppercase;
    font-size: 18px;
  }

  p.quote-text {
    margin: 0;
    font-family: "Apollon, Arial, sans-serif";
    line-height: 1.4;
  }

}

.share-print-size {
  button {
    background: transparent;
    border: 1px solid #ccc !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    transition: .3s;

    &:hover {
      background: #000;
      transition: .3s;

      i {
        color: #fff;
      }
    }

    &:focus+ul {
      display: block !important;
      opacity: 1;
    }
  }

  li {
    ul:focus-within {
      display: block !important;
      opacity: 1;
    }

    &:focus-within {
      ul {
        display: block !important;
        opacity: 1;
      }
    }
  }

  .share-wrapper+li {
    button {
      border-left: 0;
      border-right: 0;
    }
  }

  li ul {
    top: calc(100% - -1px);
    left: 1px;
  }
}

.select-a11y-suggestion {

  &:focus,
  &:focus-visible {
    outline-offset: -2px;
  }
}

.view-id-hub_visiter.view-display-id-view_grid_list .outer:focus-within {
  outline: 2px solid #000;
  outline-offset: 2px;
}

body.admin-menu.adminimal-menu:before {
  display: none;
}
