.view-archives {
  margin: 0 auto;
  @include rem((
    max-width: 900px
  ));
  .accordion__header {
    position: relative;
    margin: 0;
    @include rem(padding, 30px 0);
    font-family: $heading-font-family;
    @include font-size(42px);
    font-weight: 400;
    line-height: 0.6;
    border: none;
    border-bottom: 1px solid #cccccc;
    transition: all 0.3s;
    text-transform: lowercase;
    &:after {
      position: absolute;
      top: 50%;
      @include rem(right, 20px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 3.5px 0 3.5px;
      border-color: #000 transparent transparent transparent;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
    &.active {
      border-bottom-color: transparent;
      &:before {
        display: none;
      }
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .accordion__content {
    padding: 0;
    @include rem(margin-bottom, 50px);
    border: none;
    .archive {
      @include rem(padding, 20px 0);
      border-bottom: 1px solid #cccccc;
      .left {
        display: inline-block;
        @include rem(width, 180px);
        @include rem(padding-right, 20px);
        vertical-align: top;
        p {
          margin: 0;
          color: #666666;
          @include font-size(15px);
          text-transform: uppercase;
          span:last-child {
            display: block;
            @include rem(margin-top, 10px);
            line-height: 1;
          }
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
      .right {
        display: inline-block;
        vertical-align: top;
        color: #333333;
        font-family: $font-crimson;
        @include font-size(18px);
        a {
          color: #333333 !important;
          text-decoration: none !important;
        }
      }
    }
  }
}
