/*------------------------------------*\
    LINKS.CSS
\*------------------------------------*/

a {
  //@include link-colors($link-color, $link-hover-color, $link-visited-color);
  text-decoration: none;

  @if $link-underline {
    border-bottom: solid 1px;
  }

  @if $link-underline-on-hover {

    &:hover {
      border-bottom: none;
    }
  }

  &:not(.btn) {
    .ext {
      display: none;
    }
  }
}

a.underline {
  display: inline-block;
  @include rem(padding-bottom, 5px);
  color: #666666;
  @include font-size(16px);
  line-height: 1;
  border-bottom: 1px solid #d8d8d8;

  &:focus,
  &:hover {
    color: $color-primary !important;
    border-bottom: 1px solid $color-primary;
  }

  &:focus {
    outline-color: #000;
  }
}

.map-link {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
