.view.view-chapelle.view-display-id-images_home {
  background: url('../images/chapelle.jpg') no-repeat center top #000;
  background-size: 100% auto;
  & > .container {
    @include rem(padding, 70px 30px 60px);
    @include tablet {
      @include rem(padding, 100px 0);
    }
  }
  .view-header {
    padding: 0;
    @include rem(margin-bottom, 100px);
    h2 {
      position: relative;
      display: block;
      @include font-size-rem(24px);
      line-height: 1;
      color: #fff;
      @include tablet {
        display: inline-block;
        @include rem(padding-left, 30px);
        &:before {
          content: '';
          position: absolute;
          left: 0;
          
          @include rem((
            top: 3px,
            width: 16px,
            height: 16px
          ));
          border: 2px solid #fff;
          border-radius: 50%;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          @include rem((
            top: 7px,
            width: 8px,
            height: 8px,
            left: 4px
          ));
          background: #FF0505;
          border-radius: 50%;
        }
      }
    }
  }
  .view-content {
    .main-row {
      text-align: center;
      p.date {
        color: #B9B9B9;
        @include font-size-rem(14px);
        line-height: 1;
      }
      h4 {
        margin: 0;
        color: #FFFFFF;
        @include font-size-rem(22px);
        line-height: 1;
      }
    }
    .pop-in {
      display: none;
    }
  }
}

.view.view-chapelle.view-display-id-faq_home, .view.view-chapelle.view-display-id-faq_liste {
  background: #F1EFEA;
  & > .container {
    @include rem(padding, 50px 30px 60px);
    @include rem(max-width, 910px);
    @include tablet {
      @include rem(padding, 70px 30px 60px);
    }
  }
  .view-header {
    @include rem(padding, 0 0 24px);
    h2 {
      @include rem(margin-bottom, 15px);
      @include font-size-rem(24px);
    }
    p {
      @include rem(margin-bottom, 15px);
      @include font-size-rem(19px);
      a {
        font-family: $font-crimson;
        @include font-size-rem(24px);
      }
      &:last-child {
        color: #666666;
        @include rem(margin-top, 45px);
        @include font-size-rem(16px);
      }
    }
  }
  .view-content {
    & > div {
      @include rem(margin-bottom, 20px);
      background: #fff;
    }
    .accordion__header:hover, .tab__handle--mobile:hover {
      color: inherit;
      background: inherit;
    }
    .accordion__content {
      border-top: none;
      p, li {
        color: #666666;
        @include font-size-rem(17px);
        @include line-height-adapt(20px);
      }
    }
  }
  .view-footer {
    @include rem(margin-top, 40px);
    text-align: left;
  }
}

.view.view-chapelle.view-display-id-images_liste {
  background: #000;
  & > .container {
    padding: 0;
  }
  .nav-bar {
    padding: 0;
    border: none;
  }
  .views-exposed-widgets {
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .lo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .views-exposed-widget {
      margin-top: 1.5rem;
      @include tablet {
        margin: 0 0 0 2rem;
        flex: 1 0 auto;
      }
      &:first-child {
        margin: 0 !important;
      }
      .form-type-bef-link {
        margin: 0;
        margin-top: 0;
        vertical-align: middle;
        @include tablet {
          margin-left: 1rem;
        }
        &:first-child {
          margin: 0 !important;
        }
        a {
          color: #fff;
          &.active {
            color: $color-primary;
          }
        }
      }
      .form-item-sort-bef-combine {

        @include tablet {
          display: flex;
          justify-content: flex-end;
        }
        label {
          display: none;
        }
        .selectric {
          color: #fff;
          background: transparent;
          border-color: #CCCCCC;
          &:after {
            border-top-color: #fff;
          }
        }
      }
    }
  }
  .view-content {
    @include rem(padding, 0 30px);
    @include tablet {
      @include rem(padding, 0);
    }
    & > [class^="col-"] {
      @include rem(padding, 0 20px);
    }
    .ui-header {
      @include rem(padding, 30px 0 70px);
      @include rem(margin, 0 -30px 80px);
      @include tablet {
        @include rem(margin, 0 auto 80px);
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        z-index: 1;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0.5;
        background: rgba(0,0,0,1);
        background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 22%, rgba(#000, 1) 71%);
      }
      .container {
        z-index: 3;
      }
      .subtitle {
        display: block;
        @include rem(margin-bottom, 70px);
        color: #B9B9B9;
        @include font-size-rem(14px);
        @include line-height-adapt(20px);
      }
      .uppertitle {
        display: block;
        font-family: $font-crimson;
        @include rem(margin-bottom, 20px);
        @include font-size-rem(20px);
        @include line-height-adapt(22px);
      }
      h3 {
        @include rem(margin, 0 auto 30px);
        @include rem(max-width, 400px);
        @include line-height-adapt(37px, 52px);
      }
    }
    .main-row {
      text-align: center;
      p.date {
        color: #B9B9B9;
        @include font-size-rem(14px);
        line-height: 1;
      }
      h4 {
        margin-bottom: 0;
        color: #FFFFFF;
        @include font-size-rem(22px);
        line-height: 1;
        @include tablet {
          @include rem(margin-bottom, 80px);
        }
      }
    }
    .pop-in {
      display: none;
    }
  }
}

.view.view-display-id-techniques {
  background: #F1EFEA !important;
  .view-header {
    @include rem(padding, 0 0 70px);
    h2 {
      color: $color-primary;
      text-align: center;
    }
  }
  .col-lg-5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .row-wrapper {
    @include rem(margin-bottom, 100px);
    &:last-child {
      margin-bottom: 0;
    }
    h3 {
      @include rem(margin, 0 0 20px);
      @include  font-size-rem-adapt(20px, 24px);
      @include tablet {
        @include rem(margin, 0 0 40px);
      }
    }
  }
  .has-quote, .no-quote {
    .text-wrapper {
      width: 100%;
      @include rem(padding-top, 30px);
      color: #666666;
      @include font-size-rem-adapt(17px);
      @include line-height-adapt(24px);
      ul {
        @include rem(margin, 0 0 10px);
        @include rem(padding, 0 0 0 15px);
      }
      p {
        @include rem(margin, 0 0 10px);
        &::last-child {
          margin: 0;
        }
      }
    }

    .btn {
      @include rem(margin-top, 30px);
      a {
        color: inherit;
      }
    }
  }
  .has-quote {
    .text-wrapper {
      padding-top: 0;
      &:before {
        content: '“';
        font-family: TimesNewRoman, 'Times New Roman',Times, Baskerville, Georgia, serif;
        @include  font-size-rem-adapt(59px);
        line-height: 1;
        color: $color-primary;
        display: block;
        @include rem(margin-bottom, 10px);
      }
    }
    .auteur {
      @include rem(margin-top, 20px);
      @include font-size-rem-adapt(14px);
      @include line-height-adapt(24px);
    }
  }
}

.view.view-display-id-autres_episodes {
  position: relative;
  @include rem(height, 500px);
  z-index: 2;
  background: transparent;
  & > .container {
    padding: 0;
  }
  .view-header {
    position: absolute;
    @include rem(top, 60px);
    width: 100%;
    text-align: center;
    h2 {
      color: #fff;
      font-family: $base-font-family;
      @include font-size-rem-adapt(20px);
      @include rem(padding, 0 40px);
      line-height: 1;
      text-transform: uppercase;
    }
  }
  .slick-track {
    position: static;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .slick-slider {
    @include rem(max-width, 700px);
  }
  button.slick-prev, button.slick-next {
    position: absolute;
    top: 0;
    display: block;
    opacity: 0;
    width: 100vw;
    height: 100%;
  }
  button.slick-prev {
    left: auto;
    right: 100%;
  }
  button.slick-next {
    left: 100%;
    right: auto;
  }
  .slick-track .slick-slide {
    &.slick-active {
      .line {
        span {
          background: #ba9e66;
        }
      }
      .title-link-wrapper {
        transform: scale(1);
      }
    }
  }
  .slick-track .slick-slide {
    @include rem(height, 500px);
    align-items: center;
    justify-content: center;
    & > div {
      width: 100%;
      text-align: center;
      position: relative;
    }
    .image {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translateX(-50%);
      z-index: -1;
    }
    .uppertitle {
      position: relative;
      display: block;
      font-family: $font-crimson;
      @include rem(margin-bottom, 10px);
      @include font-size-rem-adapt(20px);
      @include line-height-adapt(32px);
    }
    .line {
      position: relative;
      width: 100%;
      @include rem(height, 22px);
      @include rem(margin-bottom, 25px);
      span {
        display: block;
        margin: 0 auto;
        @include rem((
          width: 22px,
          height: 22px
        ));
        border: 1px solid #fff;
        border-radius: 50%;
      }
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include rem(height, 1px);
        background: #979797;
      }
      &:before {
        left: 0;
        width: calc(50% - 12px);
      }
      &:after {
        right: 0;
        width: calc(50% - 12px);
      }
    }
    .title-link-wrapper {
      padding-bottom: 2px;
      transform: scale(0.7);
      transition: all 0.5s ease-in-out .2s;
      h3 {
        @include rem(margin-botto, 25px);
        @include rem(height, 110px);
        color: #fff;
        @include font-size-rem-adapt(20px, 42px);
        @include line-height-adapt(32px, 52px);
      }
    }
  }
}

.view.view-display-id-autres_episodes_image {
  background: #000 !important;
  overflow: hidden;
  & > .container {
    padding: 0;
  }
  .slick-slider {
    @include rem(max-width, 700px);
  }
  .slick-track .slick-slide {
    &.slick-middle {
      z-index: 2;
    }
  }
  .slick-track .slick-slide {
    position: relative;
    @include rem(height, 500px);
    &.slick-active {
      z-index: 2;
    }
    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out;
      z-index: -1;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        z-index: 2;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: none !important;
        width: auto !important;
        height: 100% !important;
        transform: translate(-50%, -50%);
        @include tablet {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  .changing {
    .image {
      width: 80% !important;
      height: 50% !important;
      @include tablet {
        height: 100% !important;
      }
    }
  }
}

.episode-related {
  position: relative;
  .full-width:last-child {
    position: absolute;
    top: 0;
    height: 100%;
  }
}

.view-display-id-metiers_home {
  text-align: center;
  .view-header {
    .text--lead {
      @include font-size-rem-adapt(20px);
      line-height: 1;
      padding: 0;
      border: none;
    }
  }
  .job {
    margin: 0 auto;
    @include rem(margin-bottom, 30px);
    width: 100%;
    max-width: 100%;
    color: #fff;
    text-align: center;
    align-items: center!important;
    @include tablet {
      @include rem(margin-bottom, 40px);
      display: block;
    }
    .image {
      position: relative;
      display: block;
      margin: 0 auto;
      @include rem((
        height: 55px,
        width: 55px
      ));
      border: 1px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transition: all 0.4s ease;
        transform: translate(-50%, -50%) scale(1);
        &:focus, &:hover {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    p {
      @include rem(margin, 15px 10px 0);
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}