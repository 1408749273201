.node-type-hub-galerie {
  background: #4f2c74;

  .main-content {
    background: #cecac0;
    padding-bottom: 70px;

    &>.container {
      max-width: 1140px;

      @media (max-width: 700px) {
        padding: 0 20px;
      }
    }
  }

  .smartbanner.smartbanner--ios.js_smartbanner {
    display: none;
  }

  button#go-top {
    top: -80px;
    background: #fff;

    &:focus, &:hover {
      background: #ba9e66;
    }
  }

  .view-galerie {
    background: #cecac0;

    &>.container {
      padding-top: 0;
    }

    .view-empty {
      color: #4f2c74;
      max-width: 1100px;
      margin: 0 auto;
      padding-top: 40px;
      font-size: 16px;

      a {
        color: #4f2c74;
        text-decoration: underline;

        &:focus, &:hover {
          color: #000;
        }
      }
    }

    .view-filters {
      margin-bottom: 0;

      .form-radios .form-item:first-of-type {
        padding-left: 0;
      }

      .nav-bar {
        border-bottom-color: #ba9e66;
        padding-top: 18px;
        padding-bottom: 10px;
        -ms-overflow-style: none;

        @media (min-width: 900px) {
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        @media (max-width: 1100px) {
          max-width: 100vw;
          overflow: auto;
        }

        @media (max-width: 700px) {
          padding-top: 70px;
          padding-bottom: 0;
          overflow: unset;
        }

        .lo.lo-circular {
          margin-right: -40px;

          @media (max-width: 700px) {
            display: none !important;
          }
        }

        .views-exposed-widgets {
          display: flex;

          @media (max-width: 1100px) {
            min-width: 1080px;
          }

          @media (max-width: 700px) {
            min-width: 100%;
            padding: 10px 20px;
            padding-bottom: 0;

            .views-widget {
              overflow-x: auto;
              max-width: calc(100vw - 20px);
              overflow-y: visible;

              .form-radios {
                min-width: 650px;
                overflow-y: visible;
                height: 36px;
              }
            }
          }

          .views-widget-filter-combine {
            margin-left: auto;
            margin-right: 0;

            @media (max-width: 700px) {
              position: absolute;
              left: 20px;
              top: -55px;
            }

            input {
              background: transparent;
              border: none;
              border-bottom: 1px solid #4f2c74;
              background: url(../images/recherche-purple.svg) no-repeat bottom 9px right 1px;
              background-size: 18px;
              min-width: 340px;
              color: #4f2c74;
              height: 30px;
              margin-top: -10px;

              @media (max-width: 700px) {
                background: url(../images/recherche-purple.svg) no-repeat bottom 6px right 1px;
                width: calc(100vw - 40px);
                margin-top: 5px;
              }

              &::placeholder {
                color: #4f2c74;
                opacity: 1;
              }
            }
          }
        }

        input:checked+label {
          text-decoration: underline;
          text-decoration-color: #4f2c74;
          text-underline-offset: 15px;
          text-decoration-thickness: 4px;
          font-weight: 500;
        }

        label {
          cursor: pointer;
          font-size: 16px;
          line-height: 18px;
          transition: 0.1s;
          color: #4f2c74;

          &:focus, &:hover {
            opacity: 0.7;
            transition: 0.1s;
          }

          &::before,
          &::after {
            display: none;
          }
        }

        .views-submit-button {
          display: block;

          input {
            margin: 0;
            margin-top: 0;
            background-color: transparent;
            border: none;
            font-size: 0;
            width: 20px;
            padding: 0;
            cursor: pointer;
            height: 20px;
            position: relative;
            top: -5px;
            right: 20px;
          }
        }
      }
    }

    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 32px;
      padding-left: 20px;
      padding-right: 20px;
      overflow: hidden;
      position: relative;

      @media (max-width: 700px) {
        border-color: transparent;
        padding: 20px 0;
      }

      &:after {
        content: '';
        width: calc(100% / 3 - 25px);
      }

      .views-row.media-item {
        width: calc(100% / 3 - 25px);
        margin-bottom: 32px;
        overflow: hidden;
        transition: 0.3s;
        position: relative;

        @media (max-width: 700px) {
          margin-bottom: 20px;
        }

        &:after {
          background-position: center;
          background-size: 25px;
          background-repeat: no-repeat;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: 0.3s;
        }

        &:hover:after {
          opacity: 1;
          transition: 0.3s;
        }

        &.media-text {
          &:after {
            background-image: url(../../src/images/document.svg);
          }
        }

        &.media-image,
        &.media-images {
          &:after {
            background-image: url(../../src/images/image.svg);
          }
        }

        &.media-video {
          &:after {
            background-image: url(../../src/images/play.svg);
          }
        }

        @media (max-width: 900px) {
          width: calc(100% / 2 - 30px);
        }

        @media (max-width: 700px) {
          width: 100%;
        }

        img {
          width: 100%;
          display: block;
          transition: 0.3s;
        }

        .media-title {
          display: none;
          opacity: 0;
        }

        &:focus, &:hover {
          transition: 0.3s;

          img {
            transition: 0.3s;
            transform: scale(1.1);
            filter: brightness(0.7);
          }

          .media-title {
            display: block;
            opacity: 1;
            position: absolute;
            bottom: 5px;
            left: 5px;
            font-size: 13px;
            text-shadow: 9px 5px 20px black;
          }
        }
      }
    }

    .pager-load-more {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 20px;
      padding-top: 0;

      >li {
        width: 200px;
        max-width: 200px;
      }

      a.btn {
        background: #cecac0;
        color: #000;
        border-color: #000;
        transition: 0.2s;
        min-width: 100%;
        display: block;

        &:focus, &:hover {
          transition: 0.2s;
          //opacity: .7;
          background: white;
        }
      }

      &.pager-load-more-empty {
        display: none;
      }
    }

    .pager-show-more {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 20px;
      padding-top: 0;

      >li {
        width: 200px;
        max-width: 200px;
      }

      a.btn {
        background: #cecac0;
        color: #000;
        border-color: #000;
        transition: 0.2s;
        min-width: 100%;
        display: block;

        &:focus, &:hover {
          transition: 0.2s;
          opacity: 0.7;
        }
      }

      &.pager-show-more-empty {
        display: none;
      }
    }
  }

  .image-container {
    transition: 2s;
    border-bottom: 1px solid #ba9e66;
    max-height: 840px;
    overflow-y: hidden;

    &>.wrapper {
      padding-top: 60px;

      @media (max-width: 700px) {
        padding-top: 0;
      }

      &>.container {
        transform: translateY(300px);
        opacity: 0;
        padding-top: 50px;
        z-index: 15;
      }
    }

    @media (max-width: 700px) {
      padding-top: 0;
    }

    h1 {
      margin-top: 26px;
      position: relative;
      z-index: 15;

      @media (max-width: 700px) {
        margin-top: 0;
      }
    }

    span.small {
      font-family: 'Apollon';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      color: #ffffff;
      text-align: center;
      display: block;
      position: relative;
      z-index: 15;
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 2s;
      width: 100%;
      margin: 50px auto;
      position: absolute;
      left: 0;
      top: 170px;

      .border-wrapper {
        position: relative;

        &.left {
          left: 50px;
          z-index: 10;
        }

        &.right {
          left: -50px;
        }

        &:before {
          content: '';
          position: absolute;
          width: 160%;
          height: 160%;
          top: -30%;
          left: -30%;
          background: #66363e;
          opacity: 0.5;
          border-radius: 50%;
          z-index: -1;
        }
      }

      @media (max-width: 700px) {
        &+.container {
          padding: 50px 10px 0;

          .chapo p {
            font-size: 14px;
            margin-bottom: 0;
            text-align: center;
          }
        }
      }
    }

    .link {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      padding-bottom: 50px;

      @media (max-width: 700px) {
        padding-bottom: 0;
      }

      .btn {
        color: #fff;
        border: 1px solid #cccccc;
        padding-right: 50px;

        &:before {
          background: url(../images/arrow-slider-white.svg) no-repeat left 20px center;
          transform: rotate(-90deg);
          width: 60px;
          right: 0;
          left: auto;
          border: none;
          z-index: 3;
        }

        @media (max-width: 700px) {
          &:after {
            display: none;
          }
        }
      }
    }

    .chapo {
      max-width: 530px;
      margin: 0 auto;
      margin-left: auto !important;
      margin-bottom: 40px;

      p {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    &.show {
      transition: 2s;

      .flex-wrapper {
        justify-content: space-between;
        transition: 2s;
        max-width: 100vw;
      }

      img {
        animation: biggerToLeft 2s;
        animation-fill-mode: forwards;
      }

      .chapo {
        display: block;
        opacity: 1;
        transition: opacity 2s;
      }
    }
  }
}

@keyframes biggerToLeft {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

// html {
//   scroll-behavior: smooth;
// }

.node-type-galerie-media {
  #main {
    @media (max-width: 700px) {
      margin-top: 70px;
    }
  }

  .mfp-image-holder .mfp-content img {
    max-height: 80vh !important;
  }

  .node-galerie-media {
    background: #cecac0;

    .mfp-iframe-holder .mfp-content {
      max-width: 1200px;
    }

    .content-top {
      padding-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 700px) {
        padding-top: 20px;
        padding-left: 0;
      }

      a {
        font-size: 15px;
        line-height: 17px;
        color: #000;
        background: url(../images/arrow-slider-black.svg) no-repeat left 28px center;
        padding-left: 50px;

        &:focus, &:hover {
          color: #ba9e66;
          transition: 0.2s;
          background-image: url(../images/arrow-slider-gold.svg);
          background-position: left 25px center;
        }
      }

      .share-print-size {
        span {
          background: transparent;

          &:focus, &:hover {
            opacity: 0.6;

            &:after {
              opacity: 0;
            }
          }

          &:before {
            border-color: #000;
          }

          i.icon-font {
            color: #000;
          }
        }
      }
    }

    .content.container {
      display: flex;
      width: 100%;
      max-width: 1050px;
      padding-top: 50px;
      padding-bottom: 80px;

      @media (max-width: 900px) {
        flex-direction: column;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }

      &+.prefooter-bottom {
        background: #4f2c74;
        width: auto;
        margin-left: 0;
        left: 0;

        @media (max-width: 1060px) {
          padding-left: 20px;
          padding-right: 20px;
        }

        a {
          width: 100%;
          text-align: left;
          max-width: 1050px;
          margin-bottom: 0;
        }
      }

      &>div {
        &.media-wrapper {
          max-width: calc(100% - 440px);
          width: 100%;

          @media (max-width: 900px) {
            max-width: 100%;
          }

          .media-carrousel .arrows {
            right: 45px;
            top: calc(100% - 40px);

            @media (max-width: 900px) {
              right: 0;
            }

            button.slick-arrow {
              height: 48px;
              width: 48px;

              .icon-arrow-long:before {
                font-size: 13px;
              }

              &:hover .icon-arrow-long:before {
                color: #ba9e66;
              }
            }
          }

          &>div.media-carrousel .image-thumb,
          &>div:not(.media-carrousel) {
            position: relative;
            border: 2px solid rgba(186, 158, 102, 0.5);

            @media (max-width: 900px) {
              border: none;
            }

            &:before {
              content: '';
              position: absolute;
              width: calc(100% + 18px);
              height: calc(100% + 18px);
              top: -9px;
              left: -9px;
              border: 8px solid #66363e;
              opacity: 0.5;
              z-index: -3;

              @media (max-width: 900px) {
                display: none;
              }
            }
          }

          .media-texte {
            padding: 0;

            .media-title {
              display: none;
            }
          }

          .media-video {
            .enhance a {
              height: 47px;
              padding-top: 13px;

              &:before {
                background-image: url(../images/play.svg);
                background-repeat: no-repeat;
                background-position: center;
                top: 8px;
              }
            }
          }

          img {
            width: 100%;
          }

          .enhance {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 20px;
            background: rgba(0, 0, 0, 0.7);
            transition: 0.3s;

            &:focus, &:hover {
              background: #ba9e66;
              transition: 0.3s;
            }

            a {
              color: #fff;
              padding: 8px 15px;
              padding-left: 40px;
              display: inline-block;
              position: relative;
              font-family: 'Crimson Text';
              height: 47px;

              &:not(.ext) {
                padding-top: 13px;
              }

              &.iframe-popup{
                padding-top: 8px!important;
              }

              &:before {
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                background-image: url(../images/document.svg);
                background-repeat: no-repeat;
                background-position: center;
                left: 7px;
                top: 8px;
                background-size: 17px;
              }
            }
          }

          .media-texte {
            height: 100%;
            background: #fff;
            color: #ba9e66;
            font-family: 'Crimson Text';
            font-size: 29px;
            font-weight: 400;
            line-height: 31px;
            padding: 40px;
            position: relative;
            padding: 0 !important;
            max-height: 457px;

            .media-title {
              color: #000;
            }
          }
        }

        &.text-wrapper {
          max-width: 420px;
          margin-left: 20px;
          color: #000;

          @media (max-width: 900px) {
            max-width: 100%;
            margin-left: 0;
          }

          h1 {
            font-size: 1.96em;
            line-height: 45px;
            padding-bottom: 16px;
            border-bottom: 1px solid #ba9e66;
            margin-bottom: 40px;

            @media (max-width: 700px) {
              font-size: 22px;
              line-height: 28px;
              padding-bottom: 13px;
            }
          }

          .media-info {
            p {
              font-size: 16px;
              line-height: 28px;
              margin-bottom: 3px;
              margin-top: 0;

              @media (max-width: 700px) {
                font-size: 14px;
                line-height: 22px;
              }

              span {
                font-weight: 500;
              }
            }
          }

          .media-desc {
            p {
              margin-top: 24px;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .content-bottom {
    background: #4f2c74;

    .view {
      background: transparent;
      display: flex;
      max-width: 1050px;
      margin: 0 auto;
      padding-bottom: 30px;
      padding-top: 50px;

      @media (max-width: 1060px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media (max-width: 700px) {
        flex-direction: column;
        max-width: 100vw;
        padding-left: 0;
        padding-right: 0;
      }

      .view-header {
        max-width: 160px;
        margin-right: 50px;
        border-bottom: 1px solid #fff;
        padding-bottom: 0;
        max-height: 110px;

        @media (max-width: 700px) {
          max-width: calc(100% - 40px);
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 20px;
          padding-bottom: 10px;
          width: 100%;
          max-height: 118px;
          margin-top: -20px;
        }

        h2 {
          text-transform: none;
          font-size: 30px;
          line-height: 40px;
          font-family: 'Crimson Text', serif;
          color: #fff;
        }
      }

      .view-content {
        display: flex;

        @media (max-width: 700px) {
          flex-direction: column;
          padding-left: 20px;
          padding-right: 20px;
        }

        &>.views-row {
          max-width: 286px;
          width: 100%;
          padding: 20px;
          padding-top: 0;

          @media (max-width: 700px) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            &:last-of-type {
              margin-top: 20px;
            }

            &:nth-child(2) {
              margin-top: 20px;
            }
          }
        }

        a {
          max-width: 100%;
          overflow: hidden;
          display: inline-block;
          position: relative;

          @media (max-width: 700px) {
            width: 100%;
          }

          &:after {
            background-image: url(../images/media-image.svg);
            background-position: center;
            background-size: 25px;
            background-repeat: no-repeat;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: 0.3s;
          }

          &:hover:after {
            opacity: 1;
            transition: 0.3s;
          }

          &.media-text {
            &:after {
              background-image: url(../../src/images/document.svg);
            }
          }

          &.media-image,
          &.media-images {
            &:after {
              background-image: url(../../src/images/image.svg);
            }
          }

          &.media-video {
            &:after {
              background-image: url(../../src/images/play.svg);
            }
          }

          img {
            width: 100%;
          }

          .media-title {
            display: none;
          }

          &.media-text .media-title {
            font-size: 24px;
            line-height: 1.2;
            padding: 20px 30px;

            @media (max-width: 640px) {
              padding: 20px;
            }

            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
              font-size: 20px;
              line-height: 22px;
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
}

.node-type-hub-galerie,
.node-type-galerie-media {
  #header {
    &.banner-active {
      top: 0;

      &+#main {}
    }

    .left a {
      font-size: 15px;
      line-height: 17px;
      color: #fff;
      background: url(../images/arrow-slider-white.svg) no-repeat left 25px center;
      padding-left: 50px;

      &:focus, &:hover {
        color: #ba9e66;
        transition: 0.2s;
        background: url(../images/arrow-slider-gold.svg) no-repeat left 20px center;
      }

      @media (max-width: 700px) {
        color: transparent;
        font-size: 8px;
        background-position: left center;
      }
    }

    nav.menu {
      background: transparent;

      @media (max-width: 1100px) {
        position: relative;
      }
    }

    &.fixed nav.menu {
      @media (max-width: 1100px) {
        display: block;
      }
    }
  }

  footer {
    .top-footer {
      @media (max-width: 700px) {
        padding-bottom: 100px !important;
      }
    }

    button#go-top {
      @media (max-width: 700px) {
        position: absolute;
        bottom: 0;
        right: calc(100vw - 110px);
        top: auto;
        z-index: 20;
      }
    }

    .bottom-footer .container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 0;

      @media (max-width: 800px) {
        flex-wrap: wrap;
      }

      #logo-footer {
        margin: 0 70px;

        @media (max-width: 800px) {
          width: 100%;
          padding-top: 0;
          margin-top: 0;
          padding-bottom: 20px;
        }
      }

      .logo-left {
        @media (max-width: 800px) {
          order: 1;
          margin-right: 20px;
        }

        img {
          background: #fff;
          width: 162px;
        }
      }

      .logo-right {
        @media (max-width: 800px) {
          order: 2;
          margin-left: 20px;
        }

        img {
          max-height: 80px;
        }
      }
    }
  }
}

html body.node-type-hub-galerie.admin-menu {
  margin-top: 0 !important;
}

.node-type-galerie-media {
  .smartbanner {
    display: none !important;
  }

  .prefooter-bottom.text--center {
    background: #232323;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;

    a {
      font-size: 17px;
      line-height: 19px;
      color: #fff;
      display: inline-block;
      margin: 20px 0;
      padding-right: 30px;
      padding-left: 30px;
      background: url(../images/arrow-right-white.svg) no-repeat top 2px left 0;

      &:focus, &:hover {
        text-decoration: underline;
        // color: #ba9e66;
        // background: url(../images/arrow-right.svg) no-repeat top 2px left 0;
      }
    }
  }
}

body .share-print-size li.resizer ul li:last-child {
  margin-top: -1px;
}

.node-type-hub-galerie .smartbanner.smartbanner--android {
  display: none !important;
}

.node-type-galerie-media .node-galerie-media .content.container>div.media-wrapper>div.media-carrousel .image-thumb:before {
  display: none;
}

.view-id-galerie.view-display-id-block {
  .views-widget-filter-field_galerie_categorie_tid_selective {
    .form-item-categorie {
      span {
        font-family: $font-crimson;
        @include font-size(14px);
        line-height: 1.2;
        position: absolute;
        background: #e6e6e6;
        color: #333333;
        padding: 5px 10px;
        width: 200px;
        margin-top: 20px;
        margin-left: -100px;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: transform 0.3s, opacity 0.6s, margin-left 0.2s,
          margin-top 0.2s;
      }

      span:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 50%;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid #e6e6e6;
      }

      &:hover span {
        visibility: visible;
        opacity: 1;
        z-index: 999;
        filter: alpha(opacity=100);
        top: 30px;
        left: 50%;
        margin-top: 0;
      }
    }
  }
}
