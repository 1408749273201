.view-partenaires-medias.view {
  @include rem(margin, 0 0 30px);

  .container {
    @include rem(padding, 0 30px);

    @include tablet {
      padding: 0;
    }

    .view-content {
      max-width: 720px;
      margin: 0 auto;

       .row [class^=col-] {
        min-width: 230px;
      }
    }
  }

  .outer {
    position: relative;
    margin: 0;
    border: 1px solid #D8D8D8;
    height: auto !important;

    @include tablet {
      @include rem(margin-bottom, 40px);
    }

    a {
      display: block;
      @include rem(padding, 20px);
      border: none;

      img {
        position: relative;
        display: block;
        margin: 0 auto;
        width: auto !important;
        @include rem(height, 90px !important);
        z-index: 2;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        opacity: 0;
        transition: all 0.3s;
      }

      &:focus, &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}
