$white: #fff;
$brown: #BA9E66;


.node-type-long-read.section-voyage-hiver,
.node-type-long-read.page-long-read-voyage-d-hiver,
.node-type-long-read.page-grands-formats-voyage-d-hiver,
.page-grands-formats-voyage-d-hiver {
  #header a:not(#logo) {
    @include rem(padding, 4px 0);
    @include font-size-rem-adapt(12px, 16px);
    line-height: 1;
    white-space: nowrap;
    border-bottom: 1px solid $brown;
    &:focus, &:hover {
      color: $white;
      border-bottom-color: $white;
    }
  }
  #main {
    margin-top: 0;
  }

  footer#page-footer div.top-footer {
    .col {
      @include tablet {
        @include rem(padding-right, 45px);
        & + .col {
          @include rem((padding-right: 20px, padding-left: 45px));
        }
      }
      .icon {
        position: absolute;
        top: 3px;
        top: .1875rem;
        @include rem(left, 20px);
        @include tablet {
          @include rem(left, -12px);
        }
      }
      p {
        @include tablet {
          padding-left: 0;
        }
      }
      ul {
        margin-left: 0;
      }
    }
  }
  .field-collection-container {
    .ui-header {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background: rgba(#000, 0.5);
      }
      .container {
        position: relative;
        z-index: 2;
        .tags {
          @include rem(margin-bottom, 5px);
        }
      }
    }
  }
}

.node.node-long-read.voyage-hiver {
  min-height: 100vh;
  .text-content > p {
    @include font-size-rem-adapt(16px);
    @include line-height-adapt(28px);
  }
  .main-content {
    @include rem(padding, 50px 20px);
    @include tablet {
      @include rem(padding, 70px 0);
    }
    h2 {
      @include rem(margin, 0 auto 45px);
      padding: 0;
      border: none;
    }
    a:not(.btn):not(.tooltip) {
      color: #000;
      font-weight: 500;
      text-decoration: underline;
      border: none;
    }
    a.link:not(.btn):not(.tooltip) {
      display: inline-block;
      color: #4A4A4A;
      font-weight: 400;
      @include font-size-rem-adapt(17px);
      @include line-height-adapt(22px);
      text-decoration: none;
      border-bottom: 1px solid #4A4A4A;
      i {
        display: inline-block;
        @include rem(margin-left, 3px);
        transform: rotate(-90deg);
      }
    }
  }
}


@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    40% { transform: rotate(0deg); }
    60% { transform: rotate(360deg); }
    100% { transform: rotate(360deg); }
}

@keyframes top-left {
    0% { transform: translate(30px, 25px) }
    20% { transform: translate(30px, 25px) }
    40% { transform: translate(0, 0) }
    60% { transform: translate(0, 0) }
    80% { transform: translate(30px, 25px) }
    100% { transform: translate(30px, 25px) }
}

@keyframes top-right {
    0% { transform: translate(-30px, 25px) scaleX(-1) }
    20% { transform: translate(-30px, 25px) scaleX(-1) }
    40% { transform: translate(0, 0) scaleX(-1) }
    60% { transform: translate(0, 0) scaleX(-1) }
    80% { transform: translate(-30px, 25px) scaleX(-1) }
    100% { transform: translate(-30px, 25px) scaleX(-1) }
}

@keyframes bottom-left {
    0% { transform: translate(30px, -25px) scaley(-1) }
    20% { transform: translate(30px, -25px) scaley(-1) }
    40% { transform: translate(0, 0) scaley(-1) }
    60% { transform: translate(0, 0) scaley(-1) }
    80% { transform: translate(30px, -25px) scaley(-1) }
    100% { transform: translate(30px, -25px) scaley(-1) }
}

@keyframes bottom-right {
    0% { transform: translate(-30px, -25px) scaley(-1) scalex(-1) }
    20% { transform: translate(-30px, -25px) scaley(-1) scalex(-1) }
    40% { transform: translate(0, 0) scaley(-1) scalex(-1) }
    60% { transform: translate(0, 0) scaley(-1) scalex(-1) }
    80% { transform: translate(-30px, -25px) scaley(-1) scalex(-1) }
    100% { transform: translate(-30px, -25px) scaley(-1) scalex(-1) }
}

.voyage-loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 90;
  .voyage-loader__content {
    position: relative;
    @include rem(width, 410px);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%;
    &:before {
      content: '';
      display: block;
      padding-top: 86.59%;
      height: 0;
    }
    img {
      position: absolute;
      &.top-left {
        max-width: 30%;
        top: 0;
        left: 0;
        animation-name: top-left;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        @include ie {
          @include rem(( width: 123px, height: 108px))
        }
      }
      &.top-right {
        max-width: 30%;
        top: 0;
        right: 0;
        animation-name: top-right;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        @include ie {
          @include rem(( width: 123px, height: 108px))
        }
      }
      &.center {
        max-width: 50%;
        position: relative;
        animation-name: rotate;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        @include ie {
          @include rem(( width: 205px, height: 175px))
        }
      }
      &.bottom-left {
        max-width: 30%;
        left: 0;
        bottom: 0;
        animation-name: bottom-left;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        @include ie {
          @include rem(( width: 123px, height: 108px))
        }
      }
      &.bottom-right {
        max-width: 30%;
        right: 0;
        bottom: 0;
        animation-name: bottom-right;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        @include ie {
          @include rem(( width: 123px, height: 108px))
        }
      }
    }
  }
}

.voyage-header {
  @include rem(padding, 130px 0 30px);
  @include tablet {
    @include rem(padding, 230px 0 200px);
  }
  h1 {
    @include rem(margin, 0 0 10px);
    @include font-size-rem-adapt(36px, 64px);
    @include line-height-adapt(50px);
    @include tablet {
      @include rem(margin, 0 0 30px);
    }
  }
  h3 {
    margin: 0;
    font-family: $base-font-family;
    @include font-size-rem-adapt(25px, 30px);
    @include line-height-adapt(30px);
    text-transform: none;
    @include tablet {
      @include rem(margin, 0 0 40px);
    }
  }
  p {
    @include rem(margin, 0 0 50px);
    @include tablet {
      @include rem(margin, 0 0 80px)
    }
  }
  a.ext {
    .ext {
      display: none;
    }
  }

  .btn--light {
    color: #fff !important;
    border-color: rgba(#fff, .3);
    &:after {
      background: none;
    }
    @include desktop {
      border: none;
      &:focus, &:hover {
        color: #000 !important;
      }
      &:after {
        background: #fff;
      }
    }
  }
}

.voyage-hiver.node .voyage-enter {
  @include rem(padding-bottom, 110px);
  .main-content {
    @include tablet {
      padding-bottom: 0 !important;
    }
  }
  .h2--big {
    @include rem(margin, 40px auto 20px !important);
    padding: 0;
    border: 0;
    @include font-size-rem-adapt(24px, 42px);
    @include line-height-adapt(30px, 52px);
  }
  .show-slider + a.download {
    display: block;
    color: #666666 !important;
    @include rem(padding, 6px 0);
    @include rem(margin-top, 15px);
    @include font-size-rem-adapt(16px);
    line-height: 1;
    font-weight: 500;
  }
  .static__map {
    position: relative;
    display: none;
    @include tablet {
      display: block;
    }
    & + .text-content {
      @include rem(padding, 60px 20px 0);
      background: url(../images/map-mobile.jpg) no-repeat center top;
      @include tablet {
        @include rem(padding, 0 30px);
        background: none;
      }
    }
    img {
      width: 100%;
      height: auto;
      opacity: 0.5;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: auto;
      z-index: 2;
      overflow: visible;
      transform: translate(-50%, -50%);
    }
  }
  .sponsors {
    text-align: left;
    h2 {
      @include rem(margin-top, 90px);
    }
    img {
      display: block;
      margin: 0 auto 50px;
      @include desktop {
        margin: 0;
      }
    }
    p {
      @include rem(margin, 0 0 20px);
      @include font-size-rem-adapt(16px);
      @include line-height-adapt(22px, 32px);
      text-align: center;
      &:last-child {
        margin: 0;
      }
      @include desktop {
        text-align: left;
      }
    }
    a.app {
      display: block;
      @include rem(padding, 10px 20px);
      text-align: center;
      @include desktop {
        display: inline-block;
        @include rem(padding, 0 20px);
      }
      img {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

.voyage-enter {
  .voyage-header {
    video {
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      object-fit: cover;
      transform: translateX(-50%) translateY(-50%);
      &::-webkit-media-controls-start-playback-button {
        display: none!important;
        -webkit-appearance: none;
      }
    }
  }
}

// *::-webkit-media-controls-play-button {
//   display: none!important;
//   opacity: 0 !important;
//   -webkit-appearance: none;
// }

// *::-webkit-media-controls-start-playback-button {
//   display: none!important;
//   opacity: 0 !important;
//   -webkit-appearance: none;
// }

.voyage-content {
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  .voyage-content__map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    svg {
      position: relative;
      @include rem(margin-top, 60px);
      max-width: none;
      width: 200%;
      height: auto;
      z-index: 1;
      @include tablet-port {
        width: 75%;
      }
      * {
        stroke-opacity: 0.5;
      }
      .bg {
        fill-opacity: 0;
        transition: fill-opacity 1s;
      }
    }
  }
  .voyage-slider {
    position: relative;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: auto;
    @include tablet-port {
      overflow: hidden;
    }
  }
  .voyage-slider__slider {
    width: 100%;
    @include rem(padding, 70px 0);
    z-index: 3;
    @include tablet-port {
      position: absolute;
      top: 0;
      left: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      padding: 0;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.8;
      background-image: linear-gradient(#FFFFFF 0%, rgba(255,255,255, 0.4) 100%);
      z-index: 2;
      @include tablet-port {
        display: block;
        width: 50%;
        background-color: rgba(255,255,255,0);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%);
        background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%);
        transform: translateX(-50%);
      }
    }
    &:after {
      content: '';
      display: none;
      width: 75%;
      position: absolute;
      top: 0;
      left: 25%;
      bottom: 0;
      background: $white;
      z-index: 2;
      @include tablet-port {
        display: block;
      }
    }
    .slick-list {
      position: relative;
      height: 100%;
      z-index: 3;
      .slick-track {
        height: 100%;
        align-items: center;
      }
    }
    button.slick-arrow.slick-prev {
      display: none !important;
    }
    button.slick-arrow.slick-next {
      top: auto;
      bottom: 0;
      left: 50%;
      width: auto;
      height: auto;
      @include rem(padding-bottom, 40px);
      color: $brown;
      @include font-size-rem-adapt(17px);
      @include line-height-adapt(17px);
      text-transform: uppercase;
      background: none;
      border: none;
      transition: all 0.3s;
      transform: translateX(-50%);
      z-index: 4;
      @include tablet-port {
        left: 0;
      }
      &:after {
        position: absolute;
        left: 50%;
        content: '';
        @include rem((top: 25px, width: 1px, height: 50px));
        background: $brown;
      }
      &:focus, &:hover {
        transform: translateX(-50%) translateY(-20px) !important;
      }
      .text {
        display: block;
        opacity: 0;
        //transition: all 0.3s;
      }
    }
    .slick-dots {
      display: none;
      position: absolute;
      top: 50%;
      margin: 0;
      padding: 0;
      @include rem((right: 40px, width: 20px));
      opacity: 0;
      transform: translateY(-50%);
      z-index: 4;
      @include tablet-port {
        display: block;
        opacity: 1;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background: #C2C2C2;
        transform: translateX(-50%);
      }
      li {
        position: relative;
        @include rem((margin: 0 0 10px, width: 20px, height: 20px));
        background: $white;
        &:last-child {
          margin: 0;
        }
        &:focus, &:hover {
          button {
            transform: scale(1.2);
            span {
              opacity: 1;
              transform: translateX(0) translateY(-50%);
            }
          }
        }
        &.slick-active {
          button {
            border-color: $brown;
            &:before {
              background: $brown;
            }
          }
        }
        button {
          position: relative;
          width: 100%;
          height: 100%;
          display: block;
          background: #fff;
          border: 1px solid #c2c2c2;
          border-radius: 50%;
          background: none;
          outline: none;
          transition: all 0.3s;
          span {
            position: absolute;
            top: 50%;
            right: 100%;
            opacity: 0;
            @include rem(margin-right, 10px);
            color: #4A4A4A;
            font-family: $heading-font-family;
            @include font-size-rem-adapt(14px);
            @include line-height-adapt(16px);
            text-align: right;
            transform: translateX(10%) translateY(-50%);
            transition: 0.5s all;
          }
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            @include rem((width: 8px, height: 8px));
            background: #c2c2c2;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  .voyage-slider__slide {
    position: relative;
    display: block;
    @include rem(padding, 50px 30px);
    text-align: center;
    @include tablet-port {
      @include rem(padding, 0 115px);
      text-align: left;
    }
    .inner {
      position: relative;
      z-index: 2;
      &.fadeInAnimations {
        .voyage-slider__title span {
          animation-name: slideInUp;
        }
        .voyage-slider__subtitle, .voyage-slider__name, .load-content  {
          animation-name: fadeIn;
        }
      }
      &.fadeOutAnimations {
        .voyage-slider__title span {
          animation-name: slideOutUp;
        }
        .voyage-slider__subtitle {
          animation-name: fadeOut;
        }
      }
    }
    .load-content {
      color: #fff;
      background: #000;
      &:after {
        background: $brown;
      }
      @include desktop {
        opacity: 0;
        animation-duration: .63s;
        animation-delay: .67s;
        animation-fill-mode: both;
      }
    }
  }

  .voyage-slider__title {
    @include rem(margin, 0 0 10px);
    color: $brown;
    @include font-size-rem-adapt(36px, 42px);
    @include line-height-adapt(52px);
    overflow: hidden;
    span {
      display: block;
      @include desktop {
        animation-duration: 0.67s;
        animation-fill-mode: both;
        transform: translateY(100%);
      }
    }
  }
  .voyage-slider__subtitle {
    @include rem(margin, 0 0 20px);
    color: #333333;
    @include font-size-rem-adapt(16px, 20px);
    @include line-height-adapt(29px);
    @include desktop {
      opacity: 0;
      animation-duration: 0.33s;
      animation-delay: 0.67s;
      animation-fill-mode: both;
    }
  }
  .voyage-slider__name {
    position: relative;
    color: rgba(#4A4A4A, 0.6);
    @include rem((margin: 0 0 30px));
    text-transform: uppercase;
    @include desktop {
      opacity: 0;
      animation-duration: 0.3s;
      animation-delay: 1s;
      animation-fill-mode: both;
    }
  }
  .load-content {
    @include rem((margin: 30px 0 0));
  }
}

.voyage-wrapper {
  display: none;
  position: relative;
  //height: 100vh;
  overflow: auto;
  z-index: 3;
  .main-content {
    padding: 0 !important;
  }
  .voyage-single > .inner {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      @include rem((top: 0, height: 729px));
      background-image: linear-gradient(-1deg, #FFFFFF 12%, rgba(255,255,255,0.00) 100%);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      background: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      z-index: 2;
    }
  }
  .voyage-single__header {
    position: relative;
    width: 100%;
    @include rem(padding, 140px 30px 0);
    text-align: center;
    z-index: 3;
    @include tablet {
      @include rem(padding, 320px 30px 0);
    }
    h2.title {
      @include rem(margin, 0 auto 30px auto);
      color: $brown;
      @include font-size-rem-adapt(36px, 64px);
      @include line-height-adapt(44px, 50px);
      animation-name: slideInUp;
      animation-duration: 0.67s;
      animation-fill-mode: both;
      transform: translateY(100%);
    }
    h3 {
      @include rem(margin, 0 auto 35px);
      @include font-size-rem-adapt(20px);
      @include line-height-adapt(25px);
      animation-name: fadeIn;
      animation-duration: 0.33s;
      animation-delay: 0.67s;
      animation-fill-mode: both;
      @include tablet {
        @include rem(margin, 0 auto 105px);
      }
    }
    p.name {
      @include rem(margin, 0 0 10px);
      color: $brown;
      @include font-size-rem-adapt(18px, 22px);
      @include line-height-adapt(20px);
      text-transform: uppercase;
      animation-name: fadeIn;
      animation-duration: 0.33s;
      animation-delay: 0.67s;
      animation-fill-mode: both;
      &:last-child {
        margin: 0;
      }
    }
  }

  .voyage-single__content {
    position: relative;
    @include rem(padding-top, 100px);
    margin-bottom: 100vh;
    background: $white;
    z-index: 3;
    @include tablet {
      @include rem(padding-top, 180px);
    }
    .audio-embed {
      audio {
        display: none;
      }
    }
    .longreadArt {
      @include rem(margin-top, 40px);
      @include rem(margin-bottom, 60px !important);
      &.longreadArt--fullwidth {
        .col {
          @include rem(padding, 50px 50px 40px 20px);
          @include tablet {
            padding-left: 0;
            @include rem(padding, 50px 50px 50px 20px);
          }
        }
        .longreadArt__title {
          @include rem(margin, 0 0 15px 0);
          @include tablet {
            @include rem(margin, 0 0 20px);
          }
        }
        .longreadArt__source {
          @include line-height-adapt(19px);
          @include rem(margin, 0 0 30px 0);
          @include tablet {
            @include rem(margin, 0 0 20px);
          }
        }
        .longreadArt__desc:last-child {
          margin: 0 !important;
        }
      }
    }
    .longreadExposition {
      @include rem(padding, 90px 0);
      .longreadExposition__title {
        margin-top: 0;
      }
      a:not(.btn) {
        color: $brown !important;
      }
    }
    .voyage-single__intro {
      position: relative;
      @include rem((margin: 50px auto, padding-bottom: 40px));
      color: $brown;
      font-family: $font-crimson;
      @include font-size-rem-adapt(20px, 24px);
      @include line-height-adapt(30px, 32px);
      text-align: center;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        display: block;
        width: 100%;
        height: 1px;
        @include rem(max-width, 495px);
        background: #DEDEDE;
        transform: translateX(-50%);
      }
    }
    h3.subtitle {
      display: block;
      @include rem(max-width, 720px);
      @include rem(margin, 0 auto 40px);
      @include font-size-rem-adapt(20px, 24px);
      @include line-height-adapt(26px, 32px);
      text-align: center;
    }
    h2 {
      @include rem(margin, 0 0 30px);
      @include font-size-rem-adapt(24px);
      @include line-height-adapt(32px);
    }
    p {
      @include rem(margin, 0 0 30px);
      @include font-size-rem-adapt(16px);
      @include line-height-adapt(28px);
    }
    a {
      color: #000;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.voyage-player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include rem((margin: 0 auto, max-width: 720px, padding: 20px 25px));
  background: #F1EFEA;
  .voyage-player__control {
    position: relative;
    @include rem((margin-right: 20px, width: 14px, height: 14px));
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: none;
      &.voyage-player__play {
        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 12px;
          border-color: transparent transparent transparent $brown;
          transform: translate(-50%, -50%);
        }
      }
      &.voyage-player__pause {
        display: none;
        &:before, &:after {
          position: absolute;
          top: 0;
          content: '';
          display: block;
          @include rem(width, 4px);
          height: 100%;
          background: $brown;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
  .voyage-player__progress {
    position: relative;
    flex: 1 0 auto;
    @include rem(height, 3px);
    cursor: pointer;
    background: #fff;
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $brown;
    }
  }
  .voyage-player__time {
    @include rem((margin-left: 20px, width: 40px));
    @include font-size-rem-adapt(14px);
    text-align: right;
    line-height: 1;
  }
}

.voyage-video {
  @include rem(margin, 35px auto 60px);
  a {
    position: relative;
    display: block;
    @include rem(margin, 0 -30px);
    overflow: hidden;
    @include tablet {
      margin: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.7);
      z-index: 1;
    }
    img {
      width: 100%;
      height: auto;
    }
    .circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      @include rem((width: 90px, height: 90px));
      border: 1px solid $brown;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 0 12px 18px;
        border-color: transparent transparent transparent $brown;
        transform: translate(-50%, -50%);
      }
    }
  }
  .description {
    @include rem(margin, 10px 0 0 0);
    color: rgba(#4A4A4A, 0.7);
    @include font-size-rem-adapt(14px);
    @include line-height-adapt(28px);
  }
}
