.view.view-services {
  .view-empty {
      text-align: center;
      @include rem(padding, 50px 0);
      margin-bottom: 2.5rem;
  }
  .nav-bar {
    @include rem(padding, 30px 0);
    @include desktop {
      padding: 0;
    }
    @media (max-width:700px) {
      // select{
      //   display:none !important;
      // }

    }
    .views-exposed-widgets {
      &:before, &:after {
        display: none;
      }
      label,
      .select-a11y,
      .form-select{
        display:none !important;
      }
    }
    .form-type-bef-link {
      @include rem(margin-right, 35px);
      a {
        display: block;
        color: #959595 !important;
        @include rem(padding, 28px 0 24px);
        @include rem(line-height, 28px);
        text-decoration: none !important;
        border-bottom: 4px solid transparent;
        &.active {
          color: #000 !important;
          border-bottom-color: #000;
        }
      }
    }
  }
}
