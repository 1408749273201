.node-type-hub-chapelle, .node-type-chapelle-direct {
  .mfp-bg {
    background: rgba(#000, 0.9);
    opacity: 1;
  }
  .mfp-close {
    @include rem((
      top: 50px,
      right: 50px
    ))
  }
  .mfp-content {
    color: #fff;
    @include rem(padding, 0 45px);
    @include rem(max-width, 900px);
    margin: 0 auto;
    h4 {
      @include rem(margin, 0 0 30px);
      @include font-size-rem-adapt(24px);
    }
    img {
      @include rem(margin, 0 0 30px);
    }
    p {
      @include rem(margin, 0 0 5px);
      @include font-size-rem(14px);
      @include line-height-adapt(19px);
    }
    p.credits {
      margin: 0;
      color: #979797;
    }
  }
}
.node-hub-chapelle {
  .links {
    display: none;
  }
  .article-header {
    @include rem(height, 360px);
    @include tablet {
      @include rem(height, 500px);
    }
    h1 {
      @include font-size-rem-adapt(28px, 64px);
      @include line-height-adapt(35px, 80px);
    }
  }

  .chapelle-header {
    @include rem(max-width, 915px);
    @include rem(margin, 75px auto 20px);
    text-align: center;
    h3 {
      @include font-size-rem(24px);
      @include rem(padding, 0 20px);
    }
    .text--lead {
      color: #666666;
      border: none;
      padding: 0;
      @include font-size-rem-adapt(22px, 24px);
      @include line-height-adapt(30px);
    }
  }

  .intro-wrapper {
    margin: 0 auto;
    @include rem(margin-bottom, 40px);
    @include rem(max-width, 640px);
    @include tablet {
      @include rem(margin-bottom, 150px);
    }
    .row {
      justify-content: center;
      align-items: center;
    }
    h3 {
      @include rem(margin, 40px auto 15px);
      @include font-size-rem(24px);
      @include line-height-adapt(34px);
      @include tablet {
        margin-top: 0;
      }
    }
    p {
      margin: 0;
      @include font-size-rem-adapt(14px, 19px);
      @include line-height-adapt(28px);
    }
    svg {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 300px;
        height: 268px;
      }
    }
  }

  .video-wrapper {
    @include rem(padding, 60px 0);
    text-align: center;
    @include tablet {
      @include rem(padding, 90px 0 70px);
    }
    h2 {
      margin: 0;
      @include font-size-rem(24px);
    }
    .voyage-video {
      @include rem(margin, 40px 30px);
      @include rem(max-width, 900px);
      @include tablet {
        @include rem(margin, 90px auto 50px);
      }
      a:before {
        background: rgba(#000, 0.5);
      }
    }
    span.ext {
      display: none;
      @include tablet {
        display: inline-block
      }
    }
  }

  .view-wrapper {
    background: url('../images/chapelle.jpg') no-repeat center top #000;
    background-size: 100% auto;
    .view {
      background: none;

    }
    .view-display-id-episode_home .ui-header {
      @include rem(padding, 30px 0 70px);
      @include rem(margin, 0 -30px 0);
      @include rem(margin-bottom, 80px);
      @include tablet {
        @include rem(margin, 0 0 80px);
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        z-index: 1;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0.5;
        background: rgba(0,0,0,1);
        background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 22%, rgba(#000, 1) 71%);
      }
      .container {
        z-index: 3;
      }
      .subtitle {
        display: block;
        @include rem(margin-bottom, 70px);
        color: #B9B9B9;
        @include font-size-rem(14px);
        @include line-height-adapt(20px);
      }
      .uppertitle {
        display: block;
        font-family: $font-crimson;
        @include rem(margin-bottom, 20px);
        @include font-size-rem(20px);
        @include line-height-adapt(22px);
      }
      h3 {
        @include rem(margin, 0 auto 30px);
        @include rem(max-width, 400px);
        @include line-height-adapt(37px, 52px);
      }
    }
  }
}