.node.node-page-simple {
  .text--lead {
    margin-bottom: 0;
    @media (max-width:700px) {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 18px !important;
      line-height: 26px !important;
      text-align: left !important;
    }
  }
  .view.view-widget-cta {
    margin: 0;
  }
  .text-content .full-width {
    margin-bottom: 0;
  }
  .text-content {
    @include rem((
      margin-top: 20px,
      margin-bottom: 20px
    ));
    @include desktop {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    & > h3 {
      @include font-size(20px, 24px);
      @include line-height-adapt(24px, 28px);
    }
  }
}

#contact-iframe {
  width: 100%;
  height: 2500px;
  border: none;
  outline: none;
  overflow: hidden;
  @include desktop {
    height: 1600px;
  }
}

#reservation-iframe {
  width: 100%;
  height: 2500px;
  border: none;
  outline: none;
  overflow: hidden;
  @include desktop {
    height: 1600px;
  }
}