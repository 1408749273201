.node.node-enseignants-modalites {
  .breadcrumbs {
    @include rem((
      padding: 25px 0 0,
      margin: 0 0 30px
    ));
    border-bottom: none;
  }
  .intro {
    @include rem(margin-bottom, 80px);
    @include desktop {
      padding-top: 0;
    }
    h2 {
      margin-top: 0;
    }
    p, li {
      margin-top: 0;
      @include rem(margin-bottom, 20px);
      font-family: $base-font-family !important;
      @include font-size-adapt(17px);
      @include line-height-adapt(26px);
    }
    ul {
      margin: 0;
      @include rem(padding, 0 0 0 15px);
      li {
        color: #666;
        @include rem(padding-left, 15px);
      }
    }
    a {
      text-decoration: underline;
    }
    .text--vigipirate {
      @include rem(margin-top, 80px);
    }
  }
  .text--gold {
    color: $color-primary;
  }
  .nav-bar.with-icons {
    padding: 0;
    .form-radios {

      @include desktop {
        display: flex;
        flex-wrap: wrap;
      }
      .form-type-radio {
        display: block;
        margin: 0;
        padding: 0;
        @include rem(margin-right, 45px);
        box-shadow: none;
        &:first-child label {
          padding-left: 0;
        }
        &:last-child {
          margin: 0;
        }
        input:checked + label {
          color: #000;
          @include rem(border-bottom, 26px);
          border-bottom: 4px solid #000;
        }
        label {
          position: relative;
          display: flex;
          color: #666666;
          @include rem(padding, 25px 0 25px 30px);
          cursor: pointer;
          &:before, &:after {
            display: none;
          }
          img {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include rem((
              max-width: 25px,
              max-height: 25px
            ));
          }
        }
      }
    }
  }
  .visit-description {
    @include rem(padding, 60px 0);
    .row {
      .col {
        @include rem(margin-bottom, 40px);
      }
      &:last-child .col:last-child {
        margin-bottom: 0;
      }
    }
    p.icon {
      position: relative;
      @include rem(padding-left, 40px);
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include rem((
          width: 26px,
          height: 26px
        ));
        border-radius: 50%;
        z-index: 1;
      }
      &:before {
        position: absolute;
        @include rem((
          top: 7px,
          left: 7px,
          width: 12px,
          height: 12px
        ));
        @include font-size(12px);
        line-height: 1;
        color: #fff;
        z-index: 2;
      }
      &.icon-checked {
        &:after {
          background: #48D668;
        }
      }
      &.icon-fountain {
        &:after {
          background: #7293CB;
        }
      }
      & + ul {
        @include rem(margin-top, -20px);
        @include rem(padding-left, 60px);
      }
    }
    p, ul {
      @include rem(margin, 0 0 25px);
      &:last-child {
        margin: 0;
      }
    }
    p, li {
      color: #666;
      @include font-size(17px);
      @include rem(line-height, 21px);
    }
    a {
      display: inline-block;
      @include rem(margin-top, 20px);
      text-decoration: underline;
    }
    ul {
      @include rem(padding-left, 20px);
    }
  }
  .view.view-push-abonnement {
    background: #f1efea;
    .view-content {
      .text {
        @include rem(padding, 60px 20px);
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
      }
      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include desktop {
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
    }
  }
}