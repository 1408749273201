/*
Theme Name: Drupal 7 Gulp Starter Theme
Theme URI: https://github.com/lasseyls/Drupal-7-Starter-Theme
Description: Drupal 7 Gulp Starter Theme for use as a starting template for building custom themes.
Author: Lasse Moos
Author URI: http://www.yourlocalstudio.dk
License: GNU General Public License v2.0
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Version: 1.0
*/

// Reset, Variables, Mixins, Etc
// DO NOT EDIT ORDER OF FILES
@import "partials/_accesibility.scss";
@import "partials/_helpers.scss";
@import "partials/_mediaqueries.scss";
@import "partials/_mixins.scss";
@import "partials/_normalize.scss";
@import "partials/_optimisations.scss";
@import "partials/_print.scss";
@import "partials/_select.scss";
@import "partials/_variables.scss";

// bower:scss
@import "../../bower_components/sass-mediaqueries/_media-queries.scss";
@import "../../bower_components/sass-rem/_rem.scss";
@import "../../bower_components/bourbon/app/assets/stylesheets/_bourbon.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

// Globals
@import "layout/_footer.scss";
@import "layout/_forms.scss";
@import "layout/_global.scss";
@import "layout/_header.scss";
@import "layout/_search.scss";
@import "layout/_sidebar.scss";
@import "components/_accordion.scss";
@import "components/_alerts.scss";
@import "components/_anchornav.scss";
@import "components/_anecdote.scss";
@import "components/_autocomplete.scss";
@import "components/_billets.scss";
@import "components/_bottom-bar.scss";
@import "components/_breadcrumbs.scss";
@import "components/_carousel.scss";
@import "components/_components.scss";
@import "components/_date.scss";
@import "components/_datepicker.scss";
@import "components/_discover.scss";
@import "components/_dropdown.scss";
@import "components/_dropzone.scss";
@import "components/_events.scss";
@import "components/_header.scss";
@import "components/_history.scss";
@import "components/_instargram.scss";
@import "components/_intro.scss";
@import "components/_media.scss";
@import "components/_modules.scss";
@import "components/_navbar.scss";
@import "components/_navigation.scss";
@import "components/_news.scss";
@import "components/_pagination.scss";
@import "components/_panel.scss";
@import "components/_places.scss";
@import "components/_progress.scss";
@import "components/_search.scss";
@import "components/_select.scss";
@import "components/_showcase.scss";
@import "components/_slider.scss";
@import "components/_tabs.scss";
@import "components/_tags.scss";
@import "components/_tooltip.scss";
@import "components/_videoswidget.scss";
@import "components/_visuelswidget.scss";
@import "components/_wizard.scss";
@import "elements/_buttons.scss";
@import "elements/_elements.scss";
@import "elements/_flexboxgrid.scss";
@import "elements/_forms.scss";
@import "elements/_icons.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_modal.scss";
@import "elements/_rules.scss";
@import "elements/_table.scss";
@import "elements/_typography.scss";


// Page and Site Styles
@import "views/_view--parcours-conseils.scss";
@import "views/_view-accessibilite.scss";
@import "views/_view-agenda.scss";
@import "views/_view-alerte.scss";
@import "views/_view-archives.scss";
@import "views/_view-billet.scss";
@import "views/_view-billets-tarifs.scss";
@import "views/_view-boutique.scss";
@import "views/_view-breves.scss";
@import "views/_view-carnets.scss";
@import "views/_view-categories.scss";
@import "views/_view-chapelle.scss";
@import "views/_view-date-agenda.scss";
@import "views/_view-dates-personnages.scss";
@import "views/_view-decouvrir.scss";
@import "views/_view-diaporama.scss";
@import "views/_view-domaine.scss";
@import "views/_view-home.scss";
@import "views/_view-hub-lieu.scss";
@import "views/_view-hub-ressources.scss";
@import "views/_view-hub-visiter.scss";
@import "views/_view-hubs.scss";
@import "views/_view-instagram.scss";
@import "views/_view-menu.scss";
@import "views/_view-networks.scss";
@import "views/_view-partners.scss";
@import "views/_view-programmation-abonnes.scss";
@import "views/_view-push-abonnement.scss";
@import "views/_view-push-calendrier.scss";
@import "views/_view-push-image.scss";
@import "views/_view-services.scss";
@import "views/_view-videos.scss";
@import "views/_view-visite-faq.scss";
@import "views/_view-visite-parcours.scss";
@import "views/_view-widget-cta.scss";
@import "views/_view-widget-dates.scss";
@import "views/_view-widget-onglets.scss";
@import "views/_view-widget-reference.scss";
@import "views/_view-widget-svg.scss";
@import "views/_view-widget-tldr.scss";
@import "views/_view-widget-visite.scss";
@import "views/_views.scss";
@import "blocks/_blocks.scss";
@import "posts/_posts.scss";
@import "pages/_page.scss";
@import "nodes/_famille.scss";
@import "nodes/_galerie-virtuelle.scss";
@import "nodes/_mdv.scss";
@import "nodes/_ml.scss";
@import "nodes/_murmures.scss";
@import "nodes/_node-abonnes.scss";
@import "nodes/_node-agenda.scss";
@import "nodes/_node-chapelle-direct.scss";
@import "nodes/_node-chapelle-episode.scss";
@import "nodes/_node-chapelle-main-faq.scss";
@import "nodes/_node-chapelle-metier.scss";
@import "nodes/_node-chapelle-metiers-liste.scss";
@import "nodes/_node-collections.scss";
@import "nodes/_node-cycle-abonne.scss";
@import "nodes/_node-date-agenda.scss";
@import "nodes/_node-enseignants-modalites.scss";
@import "nodes/_node-enseignants-visites-activites.scss";
@import "nodes/_node-enseignants-visites.scss";
@import "nodes/_node-epoque.scss";
@import "nodes/_node-hameau.scss";
@import "nodes/_node-hiver.scss";
@import "nodes/_node-home.scss";
@import "nodes/_node-hub-actualites.scss";
@import "nodes/_node-hub-avantages.scss";
@import "nodes/_node-hub-chapelle.scss";
@import "nodes/_node-hub-contenus.scss";
@import "nodes/_node-hub-decouvrir.scss";
@import "nodes/_node-hub-domaine.scss";
@import "nodes/_node-hub-espace-abonnes.scss";
@import "nodes/_node-hub-espace-enseignants.scss";
@import "nodes/_node-hub-lieu.scss";
@import "nodes/_node-lieu.scss";
@import "nodes/_node-long-read-equestre.scss";
@import "nodes/_node-long-read-notre.scss";
@import "nodes/_node-long-read-seconde-guerre.scss";
@import "nodes/_node-long-read.scss";
@import "nodes/_node-page-simple.scss";
@import "nodes/_node-parcours.scss";
@import "nodes/_node-personnage.scss";
@import "nodes/_node-poum-tiya.scss";
@import "nodes/_node-programmation-dediee.scss";
@import "nodes/_node-ressource.scss";
@import "nodes/_node-sous-espace-dedie.scss";
@import "nodes/_node-type-long-read-chapelle.scss";
@import "nodes/_node-visite-section.scss";
@import "nodes/_node-visiteurs.scss";
@import "nodes/_node.scss";
@import "nodes/_presse.scss";
@import "nodes/_ressource-pedagogique.scss";




.cookieConsentPopin {
    background: rgba(241, 239, 234, 0.8);
    backdrop-filter: blur(10px);
    
    position: absolute;
    padding: 20px;
    cursor: default;
    font-size: 15px;
    height: auto;
    width: 100%;
    z-index: 5;
    bottom: 0;
    left: 0;
  
    @media (max-width: 999px) {
      padding: 15px;
    }
  
    p {
      margin: 0 0 15px 0;
      line-height: 1.5;
      text-align: left;
      color: #666;
      font-size: 14px;
  
      @media (max-width: 999px) {
        font-size: 12px;
        margin: 0 0 8px 0;
        line-height: 1.2;
      }
    }
  
    a {
      display: inline;
      margin-left: 20px;
      color: #666;
      display: inline-block;
      text-align: left;
      border-bottom: 1px solid #ba9e66 !important;
      font-size: 14px;
      pointer-events: auto;
      @media (max-width: 999px) {
        font-size: 12px;
        margin-left: 15px;
      }
    }
  
    button {
      background-color: #ba9e66;
      border: 2px solid #ba9e66;
      transition: all 0.25s;
      pointer-events: auto;
      padding: 8px 16px;
      cursor: pointer;
      color: #fff;
  
      @media (max-width: 999px) {
        padding: 6px 12px;
      }
  
      &:focus, &:hover {
        background-color: #fff;
        border: 2px solid #ba9e66;
        color: #ba9e66;
      }
    } 
  }

  .tac_sketchfab,
    .tac_genially {
    max-width:100%;
  }

  .disabled-asset {
    font-style: italic;
    background: #ba9e66;
    padding: 20px;
    text-align: center;
    font-size: 18px;
  }


iframe {
  border: none;
}
