.view-hub-contenus.view-display-id-block {
  .outer {
    text-align: center;
    p.text--gold {
      @include rem(margin, 25px auto 0);
      color: $color-primary;
      @include font-size-adapt(14px);
      @include line-height-adapt(24px);
    }
    h4 {
      @include rem(margin, 10px auto 50px);
    }
  }
}

.view.view-programmation-abonnes {
  & > .container {
    padding-top: 0;
  }
  .nav-bar {
    @include rem(padding, 0 0 40px 0);
    @include desktop {
      padding: 0;
    }
    & + .container {
      padding: 0;
    }
    .views-exposed-widgets {
      @include desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &:before, &:after {
        display: none;
      }
    }
    .views-widget-edit-tid {
      @include rem(margin-bottom, 20px);
      @include desktop {
        margin: 0;
      }
    }
    .form-type-bef-link {
      @include rem(margin-right, 30px);
      a {
        display: block;
        color: #959595 !important;
        @include rem(padding, 28px 0 24px);
        @include rem(line-height, 28px);
        text-decoration: none !important;
        border-bottom: 4px solid transparent;
        &.active {
          color: #000 !important;
          border-bottom-color: #000;
        }
      }
    }
    .views-widget-filter-date_filter {
      margin: 0;
      .form-item {
        margin: 0 !important;
      }
      .form-item-date-filter-value-date {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          @include rem(right, 20px);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 6px 0 6px;
          border-color: #E6E6E6 transparent transparent transparent;
          transform: translateY(-50%);
        }
      }
      input {
        margin: 0;
        @include rem(max-width, 150px);
        @include rem(padding, 20px 30px);
        border: 1px solid #CCCCCC;
      }
    }
  }
  .visit-description {
    .row {
      display: none;
      @include rem((
        margin-left: -10px,
        margin-right: -10px
      ));
      @include rem(padding, 40px 0);
      &.visible {
        display: flex;
      }
      & > [class^="col-"] {
        position: relative;
        @include rem((
          padding-left: 10px,
          padding-right: 10px
        ))
      }
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    p {
      margin: 0;
      @include font-size-adapt(17px);
      @include line-height-adapt(21px);
      &:last-child {
        @include rem(margin-bottom, 25px);
      }
    }
    .auteur-wrapper {
      @include rem(margin-top, 20px);
      color: #979797;
      @include font-size-adapt(14px);
      @include line-height-adapt(19px);
      @include desktop {
        margin: 0;
        position: absolute;
        @include rem(left, 10px);
        bottom: 0;
      }
      a {
        display: inline-block;
        @include rem(padding-bottom, 5px);
        color: #333333;
        @include font-size(14px);
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  .row [class^=col-] {
    @include rem(margin-bottom, 20px);
    @include desktop {
      margin-bottom: 0;
    }
  }

  .ticket {
    @include rem(margin-bottom, 20px);
    @include rem(padding, 30px 20px 0);
    border: 1px solid #E6E6E6;
    @include desktop {
      @include rem(margin-bottom, 40px);
      @include rem(padding, 50px 50px 0);
    }
    h4 {
      font-family: $base-font-family;
      margin: 0;
      @include rem(margin-bottom, 10px);
      @include font-size-adapt(20px, 24px);
      line-height: 1;
      text-transform: none;
    }
    p, li {
      @include rem(margin, 0 0 10px);
      color: #666666;
      @include font-size-adapt(13px, 16px);
      @include line-height-adapt(20px, 28px);
    }
    ul {
      @include rem(padding, 0 0 0 20px);
      @include rem(margin, 0 0 10px);
      li {
        @include desktop {
          margin: 0;
        }
      }
    }
    p.tooltip {
      position: relative;
      display: block;
      color: inherit;
      @include font-size(12px);
      @include rem(margin-bottom, 10px);
      @include rem(padding-right, 30px);
      padding-bottom: 0;
      background: none;
      &:after {
        display: block;
        content: '?';
        padding: 0;
        @include font-size(16px);
        @include rem((
          width: 20px,
          height: 20px
        ));
        line-height: 20px;
        text-align: center;
        background: #f1efea;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        @include rem(margin-top, -10px);
      }
    }
    a:not(.tooltip):not(.btn) {
      color: $color-primary;
      border-bottom: solid 1px;
    }
    .billet {
      text-align: right;
    }
    .btn-group {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      position: relative;
      @include desktop {
        display: inline-block;
      }
      @include rem(padding-top, 65px);
      .btn {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 0;
        @include desktop {
          display: inline-block;
          width: auto;
          vertical-align: top;
        }
        &.price {
          @include desktop {
            @include rem(margin-right, -5px);
          }
          &:after {
            display: none !important;
          }
        }
      }
      .selectric-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        @include tablet {
          @include rem(min-width, 249px);
        }
        .selectric {
          width: 100%;
          color: $color-primary;
          border-color: $color-primary;
          text-transform: uppercase;
          &:after {
            border-top-color: $color-primary;
          }
        }
        .selectric-items {
          padding: 0;
          width: auto;
          @include rem((
            left: 0,
            right: 0
          ));
        }
      }
      & > .btn {
        float: none;
      }
    }
    .links {
      @include rem(padding, 20px 0);
      border-top: 1px solid #E6E6E6;
      @include font-size-adapt(12px, 16px);
      @include desktop {
        @include rem(padding, 25px 0);
      }
      p {
        margin: 0;
        display: inline;
      }
      div {
        display: inline;
      }
    }
    .view-accessibilite ul {
      text-align: left;
      padding: 0;
      margin: 0;
      @include desktop {
        text-align: right;
        @include rem(margin-bottom, 20px);
      }
    }
    p.text-bottom {
      @include rem(margin-top, 10px);
      @include font-size-adapt(12px, 14px);
      @include line-height-adapt(20px);
      text-align: left;
      a {
        font-size: inherit;
      }
      @include desktop {
        text-align: right;
      }
    }
  }
}

.view.view-display-id-block_billets {
  .row [class^=col-] {
    margin: 0;
  }
  .ticket {
    padding: 0;
    background: #F1EFEA;
    border: none;
    .image {
      position: relative;
      @include desktop {
        display: flex;
      }
      img {
        width: 100%;
        height: auto;
      }
    }

    .block-content {
      position: relative;
      @include rem(padding, 25px 40px 30px);
      @include desktop {
        @include rem(padding, 25px 20px 100px);
      }
      h4 {
        font-family: $heading-font-family;
        @include font-size-adapt(18px, 20px);
        @include line-height-adapt(26px);
      }
      .btn-group {
        display: block;
        @include rem(margin-top, 30px);
        text-align: left;
        padding: 0;
        @include desktop {
          position: absolute;
          margin: 0;
          @include rem((
            left: 20px,
            right: 20px,
            bottom: 25px
          ));
        }
        .btn {
          float: none;
          display: block;
          vertical-align: middle;
          width: 100%;
          @include rem(margin-bottom, 10px);
          @include desktop {
            display: inline-block;
            width: auto;
            margin-bottom: 0;
            @include rem(margin-right, 20px);
          }
        }
        .text {
          display: inline-block;
          vertical-align: middle;
          p {
            margin: 0;
            color: #666666;
            @include font-size(14px);
            @include line-height-adapt(18px);
          }
        }
      }
    }
  }
}
