/*------------------------------------*\
    BUTTONS.CSS
\*------------------------------------*/


@mixin buildButton($background, $border, $color: $button-default-color, $background-hover: #000, $color-hover: #fff, $hover: false) {
  position: relative;

  background: transparent;
  border: none !important;
  @include transition(all 0.4s ease-in-out);
  overflow: hidden;
  z-index: 2;

  @if $hover {
    color: $color-hover !important;

    color: $color !important;
  }

  @else {
    color: $color;
  }

  i,
  .icon,
  .fa {
    color: $color !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgba($border, 1) !important;
    background: $background;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: $background-hover;
    transform-origin: center top;
    @include transition(transform 0.4s ease-in-out);
    transition-delay: 0;
    z-index: -1;

    @if $hover {
      transform: scaleY(1) !important;

      transform: scaleY(0) !important;
    }

    @else {
      transform: scaleY(0) !important;
    }

  }

  &:focus,
  &:hover {
    color: $color-hover !important;

    &:after {
      transform-origin: center bottom;
      transform: scaleY(1) !important;
    }
  }
}


@mixin buttonGroupBorder($bg, $border) {

  @if $bg==$border {

    border-left-color: darken($bg, 10%);
  }

}

/**
 * Base button extend
 */



.btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  @include font-size-adapt(16px, 16px);
  line-height: 1.2;
  @include rem(padding, 20px 30px);
  @include rem(min-height, 60px);
  color: #fff;
  text-align: center;
  border-radius: $button-border-radius;
  text-decoration: none !important;
  @include transition(all 0.4s ease-in-out);
  z-index: 2;

  @include tablet {
    display: inline-block;
    width: auto;
  }

  @include desktop {
    color: $button-default-color;
  }

  color: $button-default-color;

  &[class^="push-"],
  &[class*="push-"] {
    @include rem(padding-left, 50px);
  }

  i,
  .fa {
    position: absolute;
    top: 50%;
    @include rem(left, 20px);
    @include font-size(16px);
    line-height: 1;
    transform: translateY(-50%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgba(lighten($button-default-color, 20%), 0.2);
    background: $button-default-background;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transform: scaleY(1);
    background: #000;
    transform-origin: center top;
    @include transition(transform 0.4s ease-in-out);
    transition-delay: 0;
    z-index: -1;

    transform: scaleY(0);
  }

  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;

    &:after {
      transform-origin: center bottom;
      transform: scaleY(1) !important;
    }
  }

  &:focus,
  &:hover {
    span.ext {
      transform: translateX(5px);
    }
  }

  /* Disabled */

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: lighten($button-default-background, 1%);
    border-color: lighten($button-default-background, 1%);
    color: lighten($button-default-color, 20%);
  }

  /* Caret */

  .caret {
    margin-left: 0.3em;
  }

}

/**
 * Adjacent buttons
 */

// .btn + .btn, .btn-group + .btn-group{
//     @include rem(margin-left, 0.5);
// }


/**
 * Button Primary
 */

.btn--primary {

  @include buildButton($button-primary-background, $button-primary-border, #000, #000, #fff, false);

}

/**
 * Button Secondary
 */

.btn--secondary {

  @include buildButton($button-secondary-background, $button-secondary-border, $button-secondary-color, $color-primary, #000, true);
}

/**
 * Button Secondary
 */

.btn--brown {

  @include buildButton($button-brown-background, $button-brown-border, $button-brown-color, #000, #fff, false);

}

/**
 * Button Secondary
 */

// .btn--warning{
//
//     @include buildButton($button-warning-background, $button-warning-border, $button-warning-color, $button-warning-background, $button-warning-color);
// }

/**
 * Dark button
 */

// .btn--dark{
//
//     @include buildButton($button-dark-background, $button-dark-border);
// }

/**
 * Button light
 */

.btn--light {

  @include buildButton($button-light-background, $button-light-border, $button-light-color, #fff, #000, true);

}

.btn--tertiary {
  @include buildButton('transparent', #CCCCCC, #000, #000, #ffff, false);
}


/**
 * Button unstyled
 */

.btn--unstyled,
.btn--unstyled:hover {
  background: transparent;
  border-color: transparent !important;
}

/**
 * Toggle button
 */

.btn--toggle {
  font-size: 30px;
  float: right;
  margin: 0;
  padding: 0 15px;

  .fa {
    margin: 0;
  }
}


/**
 * Button group
 */

.btn-group {
  position: relative;
  display: block;
  margin: 0;
  vertical-align: middle;
  @include clearfix;

  @include tablet {
    display: inline-block;
  }

  &.btn-space {
    >.btn {
      @include rem(margin-right, 40px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  >.btn {
    @include rem(margin, 0 auto 10px);

    @include tablet {
      float: left;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: -1px;
    }
  }

  >.btn:not(:first-child):not(:last-child):not(.btn--dropdown) {
    border-radius: 0;
  }


  >.btn:first-child {
    margin-left: 0;

    &:not(:last-child):not(.btn--dropdown) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  >.btn:last-child:not(:first-child),
  >.btn--dropdown:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }


  /**
     * Primary button
     */

  .btn--primary {
    @include buttonGroupBorder($button-primary-background, $button-primary-border);

  }

  /**
     * Secondary button
     */

  .btn--secondary {
    @include buttonGroupBorder($button-secondary-background, $button-secondary-border);

  }

  /**
     * Warning button
     */

  .btn--warning {
    @include buttonGroupBorder($button-warning-background, $button-warning-border);

  }

  /**
     * Warning button
     */

  .btn--dark {
    @include buttonGroupBorder($button-dark-background, $button-dark-border);

  }
}


/**
     * Dropdown button
     */

.btn--dropdown {
  padding-left: 0.8em;
  padding-right: 0.8em;

  .caret {
    margin: 0;
  }

}



/**
 * Button Sizes
 */

.btn--xsm {
  line-height: em(50);
}

.btn--sm {
  @include rem(min-width, 60px);
  width: auto;

  i,
  .fa {
    margin: 0;
  }
}

.btn--lg {
  width: auto;
  @include rem(min-width, 230px);
  max-width: none;
  @include rem(line-height, 25px);
  @include rem(padding, 20px 20px);

  @include desktop {
    @include rem(line-height, 110px);
    @include rem(padding, 0px 20px);
  }
}

/**
     * Hard Button
     */

.btn--hard {
  border-radius: 0;
}

/**
     * Soft button
     */

.btn--soft {
  border-radius: 20%;
}


/**
     * Button round
     */

.btn--round {
  border-radius: 20%;
  padding-left: 0.7em;
  padding-right: 0.7em;
}


/**
     * Fixed width
     */

.btn--fix {
  min-width: 100px;
  text-align: center;
}




/**
 * Button with icons aligned to the left
 */

.btn--icon-left {

  position: relative;

  .icon,
  .fa {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}


/**
 * Button with icons aligned to the right
 */

.btn--icon-right {

  position: relative;

  .icon,
  .fa {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn.price {
  @include rem(padding, 15px 30px);
  @include font-size(24px);

  &:hover,
  &:active {
    color: $color-primary !important;

    &:after {
      transform: translateY(100%);
    }
  }
}


/**
 * Adjust CSS for external links.
 */
span.ext {
  display: inline-block;
  padding: 0;
  background: none;
  width: auto;
  height: auto;
  transition: transform 0.2s;
}

.btn span.ext:before {
  content: '\00a0→';
}

.link-secondary {
  text-decoration: underline #ba9e66 !important;
  text-underline-offset: 6px;
  border: none !important;
  color: #333 !important;

  &:after {
    content: '→';
    position: relative;
    top: 1px;
    background: 0 0;
    display: inline-block;
    text-decoration: none;
    transform: unset !important;
    left: 7px;
    transition: .5s;
    max-height: 19px;
  }

  &:focus,
  &:hover {
    color: #ba9e66 !important;

    &:after {
      left: 10px;
    }
  }
}

.btn--secondary {
  color: #fff !important;

  &:after {
    transform: scaleY(0) !important;
  }
}

.btn:focus {
  color: #fff;
  &:after {
    transform-origin: center bottom;
    transform: scaleY(1) !important;
  }
}


.link-media-remote.cookie-consent-waiting {
  pointer-events:none;
}

.link-media-remote.cookie-consent-ok {
  pointer-events: auto !important;
  iframe {
    pointer-events: auto !important;
  }
}