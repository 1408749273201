/*------------------------------------*\
		HISTORY.CSS
\*------------------------------------*/

.ui-history {
	position: relative;
	@include rem(padding, 0 20px);
	@include desktop {
		@include rem(padding, 40px 0);
	}
	canvas {
		display: none !important;
	}
	.content-nav {
		display: none;
		position: relative;
		margin: em(40) 0;
		padding: 0;
		list-style: none;
		z-index: 10;
		@include tablet {
			display: block;
			position: absolute;
			top: 0;
			@include rem(width, 150px);
		}
		@include desktop {
			@include rem(width, 200px);
		}
		&.fixed {
			@include tablet {
				position: fixed;
				@include rem(top, 80px);
			}
			@include desktop {
				@include rem(top, 120px);
			}
		}
		li {
			position: relative;
			margin: 0;
			margin-bottom: rem(-5px);
			padding: 0 0 em(30) em(20);
			border-left: 1px solid #D8D8D8;
			cursor: pointer;
			&:last-child {
				border-left: none;
				padding: 0 0 0 em(20);
			}
			&:before {
				position: absolute;
				top: 0;
				left: - em(7);
				content: '';
				display: block;
				width: em(14);
				height: em(14);
				background: #fff;
				border: 1px solid #d8d8d8;
				border-radius: 50%;
				z-index: 5;
			}
			.progress {
				display: block;
				position: absolute;
				top: 0;
				left: -1px;
				width: 1px;
				background: $color-primary;
				z-index: 4;

			}
			p {
				margin: 0;
				display: block;
				color: #333333;
				font-family: $heading-font-family;
				@include font-size(16px);
				line-height: 1.2;
				span {
					display: block;
					margin-bottom: em(5);
					color: #666666;
					font-family: $base-font-family;
					@include font-size(15px);
					line-height: 1;
				}
			}
			&.active, &.done {
				&:before {
					border-color: $color-primary;
				}
				p {
					color: $color-primary;
					span {
						color: inherit;
					}
				}
			}
		}
	}
	.history-content {
		position: relative;
		overflow: hidden;

		.chapter {
			position: relative;
			@include rem(margin-bottom, 40px);
			overflow: hidden;
			@include tablet {
				@include rem(margin-bottom, 60px);
			}
      @media (max-width:700px) {
        @include rem(margin-bottom, 0);
      }
			&.fade-bg:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 80%,rgba(255,255,255,1) 100%);
				z-index: 5;
				pointer-events: none;
			}
			&.extended {
				&:before {
					display: none !important;
				}
			}
			&:last-child {
				margin: 0;
			}
		}
		h2 {
			@include line-height-adapt(25px, 42px);
		}
		p:not(.text--lead) {
			@include font-size-adapt(14px, 19px);
			@include line-height-adapt(24px, 28px)
		}
		a:not(.tooltip):not(.btn) {
			border-bottom: solid 1px;
		}
	}
	.chapter {
		position: relative;
	}
	.read-more {
		position: relative;
		z-index: 10;
	}
}

.view.ui-decouvrir {
	position: relative;
	background: #F1EFEA;
	.date-start, .date-end {
		position: absolute;
		top: rem(20px);
		left: 0;
		color: #666666;
		font-family: $heading-font-family;
		font-size: rem(480px);
		line-height: 1;
		z-index: 0;
		opacity: 0.03;
		pointer-events: none;
	}
	.date-end {
		top: auto;
		left: auto;
		right: 0;
		bottom: rem(60px);
	}
	.view-header {
		position: relative;
		@include rem(margin-bottom, 0);
		text-align: center;
		z-index: 1;
		@include tablet {
			@include rem(margin-bottom, 60px);
		}
		h3.title {
			@include rem(margin-bottom, 15px);
		}
		p {
			color: #666;
      font-family: $font-crimson;
      @include font-size-adapt(18px, 24px);
			line-height: 1.5;
		}
	}
	.view-footer {
		position: relative;
		z-index: 1;
	}
	.view-content {
		position: relative;
		z-index: 2;
		@include clearfix;
		.views-row {
			position: relative;
			text-align: center;
			@include rem(padding, 35px 0 20px);
			@include tablet {
				@include rem((
					padding: 0 50px,
					margin-top: -100px,
					margin-right: 1px
				));
				width: 50%;
				float: right;
				clear: both;
				text-align: left;
			}
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				@include rem(padding, 35px 0 40px);
				@include tablet {
					@include rem(padding, 0 50px);
				}
				&:after {
					display: none;
				}
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 50%;
				width: 1px;
				height: 100%;
				background: #D8D8D8;
				z-index: -1;
				transform: translateX(-50%);
				@include tablet {
					@include rem(left, 0px);
					height: calc(100% - 100px);
				}
			}
			&:before {
				content: '';
				display: block;
				width: rem(13px);
				height: rem(13px);
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 50%;
				border: 1px solid #D8D8D8;
				background: #F1EFEA;
				transform: translateX(-50%);
				z-index: 2;
				@include tablet {
					@include rem(left, 0);
				}
			}
			&:nth-child(2n) {
				@include tablet {
					float: left;
					text-align: right;
					margin-right: 0;
					border-left: none;
					&:after {
						left: auto;
						@include rem(right, 0.5px);
					}
					&:before {
						left: auto;
						right: 0;
						transform: translateX(50%);
					}
					h4 {
						margin-left: auto;
						a span {
							transform: translateX(100%);
						}
					}
				}
			}
			.item {
				& > a {
					display: block;
					position: relative;
					overflow: hidden;
					&:hover:before {
						opacity: 1;
					}
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: rgba(#000, 0.6);
						opacity: 0;
						transition: all 0.3s;
						z-index: 2;
					}
				}
			}
			p.date {
				margin-top: 0;
				@include rem(margin-bottom, 5px);
				color: #666666;
				@include font-size(15px);
				line-height: 1;
				@include tablet {
					@include rem(margin-bottom, 10px);
				}
			}
			h4 {
				display: block;
				@include rem(margin, 0 0 10px);
				@include font-size-adapt(20px, 24px);
				line-height: 1.3;
				overflow: hidden;
				@include tablet {
					@include rem(margin, 0 0 20px);
				}
				a {
					position: relative;
					display: block;
					color: #333;
					&:hover span {
						transform: translateX(0%);
					}
					span {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						color: transparent;
						overflow: hidden;
						transform: translateX(-100%);
						transition: all 0.5s;
						&:before {
							display: inline;
							content: attr(data-hover);
							background-image: linear-gradient(to right, #333 0%, #333 100%);
							background-repeat: repeat-x;
							background-position: 0 95%;
							background-size: 100% 1px;
						}
					}
				}
			}
			img {
				width: 100%;
				height: auto;
				box-shadow: 0 0 0 rgba(0,0,0,0.12), 0 0 0 rgba(0,0,0,0.24);
				transition: all 0.3s cubic-bezier(.25,.8,.25,1);
				&:focus, &:hover {
					box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
				}
			}
		}
	}
}
