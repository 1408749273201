/*------------------------------------*\
		SHOWCASE.CSS
\*------------------------------------*/

.filters {
  @include rem(margin, 40px 0);
  text-align: center;

  button {
    margin: 0 rem(10px);
    color: #666666;
    background: none;
    border: none;
    outline: none;
    border-color: transparent;
    transition: all 0.3s ease;

    &.active {
      color: #fff;
      border-bottom: 1px solid #fff;

    }
  }
}

.ui-grid {

  .sizer,
  .item {
    float: left;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }

  .item {
    &>* {
      opacity: 0;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  .item.animated {
    opacity: 0;
  }

  figure {
    margin: 0;

    img {
      cursor: pointer;
    }
  }

  @include tablet {

    .sizer,
    .item {
      width: calc(50% - 20px);
      margin-bottom: 40px;
    }
  }

  @include media-query(desktop) {

    .sizer,
    .item {
      width: calc(33.33333% - 30px);

    }
  }
}

.view.view-display-id-block_home {

  .ui-grid .item,
  .ui-grid .sizer {
    width: 100%;

    @include tablet {
      width: calc(25% - 40px);
    }

    @include desktop {
      width: calc(16.6666% - 40px);
    }

    figure {
      figcaption {
        display: none;
      }
    }
  }

  .ui-grid .item:nth-child(2) {
    @include desktop {
      width: calc(33.33333% - 40px);
    }
  }
}

.ui-diaporama {
  .ui-grid {
    @include tablet {

      .sizer,
      .item {
        margin: 0 0 20px 0;
        width: 45%;
      }

      figure.description figcaption {
        padding-left: 0;
      }
    }
  }
}

.ui-showcase.view.dark {
  @include desktop {
    background: url('../images/collections.jpg') no-repeat left bottom #000 !important;
    background-size: 100% auto !important;
  }
}

.view-display-id-node.ui-showcase.view.dark {
  background: #000 !important;

  .collections-wrapper .ui-grid {
    display: block !important;
  }
}

.ui-showcase {
  .view-header {
    position: relative;
    text-align: center;
    z-index: 1;

    h3.title {
      @include rem(margin-bottom, 15px);
    }

    p {
      color: #666;
      font-family: $font-crimson;
      @include font-size-adapt(16px, 24px);
      line-height: 1.5;
    }
  }

  h4 {
    color: #fff;
  }

  p {
    margin-bottom: rem(40px);
    font-family: $font-crimson;
    @include font-size-adapt(24px, 24px);
    color: $color-secondary;
  }

  .slick-slide>div {
    width: 100%;
  }

  .ui-grid {
    figure {
      position: relative;
      margin: 0;
      overflow: hidden;
 
      img {
        display: block;
      }

      &:focus-within,
      &:focus,
      &:hover {
        figcaption {
          opacity: 1;

          .inner {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      figcaption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.7);
        opacity: 0;
        cursor: pointer;
        transition: all 0.3s ease;

        .inner {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: rem(20px);
          text-align: left;
          z-index: 2;
          opacity: 0;
          cursor: pointer;
          transform: translateY(20px);
          transition: all 0.3s ease 0.3s;

          p.author {
            margin: 0;
            color: #8C8D93;
            font-family: $font-crimson;
            @include font-size(20px);
            line-height: 1.3;
          }

          h5 {
            margin: rem(10px) 0 rem(20px);
            color: #fff;
            @include font-size(16px);
          }

          a {
            display: inline-block;
            color: #fff !important;
            padding: 0;
            border-bottom: 1px solid #666666;
            text-decoration: none !important;
            cursor: pointer;

            &:after {
              content: ' →';
              @include font-size(12px);
            }
          }
        }
      }
    }
  }
}


.collections-wrapper {
  position: relative;

  .ui-grid {
    position: relative;
    display: none;
    z-index: 1;

    @include tablet-port {
      display: block;
      z-index: 3;
    }
  }

  .view-footer {
    @include rem(margin-top, 60px);

    @include tablet-port {
      margin-top: 0;
    }

    a {
      line-height: 2;
      padding-top: 14px;
    }
  }
}

.focus-hide {
  visibility: hidden;
}

.carousel-popup {

  @include tablet-port {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  .close {
    position: absolute;
    top: rem(50px);
    right: rem(40px);
    width: 32px;
    height: 32px;
    z-index: 50;
    background: rgba(#000, 0);
    border: none;
    outline: none;

    @media(max-width:990px) {
      top: 0;
      right: 25px;
    }

    &:focus {
      outline: 1px solid white;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  a {
    display: inline-block;
    color: #fff !important;
    padding: 0;
    border-bottom: 1px solid #666666;
    text-decoration: none !important;
    cursor: pointer;

    &:after {
      content: ' →';
      @include font-size(12px);
    }
  }

  .slick-slider {
    @include tablet-port {
      @include rem(padding, 0 160px);
    }

    button.slick-arrow.slick-prev {

      top: auto;
      left: auto;
      right: 0;
      bottom: 0;
      transform: translateY(0);
      background: #000;

      &:focus,
      &:hover {
        background: #ba9e66;
      }

      &:focus {
        outline: 2px solid #fff;
      }

      @include tablet-port {
        display: block !important;
        top: 50%;
        @include rem(left, 40px);
        right: 0;
        bottom: auto;
        transform: translateY(-50%);
      }
    }

    button.slick-arrow.slick-next {

      top: auto;
      left: auto;
      right: 0;
      @include rem(bottom, 49px);
      transform: translateY(0);
      background: #000;

      &:focus,
      &:hover {
        background: #ba9e66;
      }

      &:focus {
        outline: 2px solid #fff;
      }

      @include tablet-port {
        display: block !important;
        top: 50%;
        @include rem(right, 40px);
        left: auto;
        bottom: auto;
        transform: translateY(-50%);
      }
    }
  }
}
