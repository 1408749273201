/*------------------------------------*\
    MEDIA.CSS
\*------------------------------------*/


iframe {
  max-width: 100%;
}

.ui-media {

    list-style: none;
    margin-left: 0;
    @include clearfix;

    > li{
        @include clearfix;
        @include rem(margin-bottom);
    }



    .media__content{
        overflow: auto;
    }

    figure {
        margin-bottom: 0;
    }

    /* Align Images, video */

    img, video, object{
        vertical-align: top;
    }
}


/**
 * Figure
 */

figure{
    margin: 0;
    text-align: center;
    background: #fff;
    img, video, object{
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
    figcaption {
        padding: em(20) 0;
        h5 {
            color: #333333;
            margin-top: 0;
            @include font-size-adapt(18px, 24px);
            a {
                color: inherit;
            }
        }
        p {
            color: #666666;
            @include font-size(15px);
        }
    }
    &.img-full-width {
      position: relative;
      z-index: 11;
        img {
          max-width: none;
          width: 100vw;
          position: relative;
          margin-left: -50vw;
          left: 50%;
        }
    }

    &.description {
        img {
            margin-bottom: rem(15px) !important;
        }
        figcaption {
            padding-top: 0;
            text-align: left;
            p {
                color: #333;
                margin: 0;
                @include font-size(14px);
                line-height: 1.4;
            }
            a {
                @include rem(margin-top, 5px);
                display: inline-block;
                padding: em(4) 0;
                color: #333333 !important;
                @include font-size(14px);
                line-height: 1.2;
                text-decoration: none !important;
                border-bottom: 1px solid #E6E6E6;
            }
        }
    }
    &.full-width {
        figcaption {
            @include outer-container();
            padding: 0 rem(20px);
        }
    }
    &.brown-figure {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background: #F1EFEA;
        transition: all 0.3s;
        &:focus, &:hover {
          background: $color-primary;
          & > a:before {
            opacity: 1;
          }
          p {
            color: #fff !important;
          }
          figcaption {
            a {
              color: #fff !important;
            }
          }
        }
        & > a {
          display: block;
          position: relative;
          @include tablet {
            flex: 0 0 auto;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.6);
            opacity: 0;
            transition: all .3s;
            z-index: 2;
          }
        }
        figcaption {
            @include rem(padding, 20px 20px);
            @include tablet {
              display: flex;
              width: 100%;
              height: 100%;
              @include rem(padding, 10px 0);
              @include rem(min-height, 100px);
              align-items: center;
            }
            h4 {
                @include rem(margin, 0 auto);
                @include font-size-adapt(18px, 22px);
                @include rem(max-width, 300px);
            }
            a {
                color: #333;
            }
            p {
              transition: all 0.3s;
            }
        }
    }
}

/**
 * Media item
 */

.media-item{
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-weight: 300;
    text-align: left;
    color: #fff;

    a{
        color: #fff;
        text-decoration: none;
    }

    h3{
        margin-bottom: 0.8em;
    }

    /* Caption */

    figcaption{

        position: absolute;
        left: rem(40px);
        right: rem(40px);
        bottom: rem(30px);
        padding: 0;
    }

    /* Image */

    img{
        vertical-align: top;
        margin-bottom: 0;
    }

    /* Remove margin in paragraphs */

    p:last-child{
        margin-bottom: 0;
    }
}

/**
* Media item big
*/

.media-item--big {
    figcaption {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        h3 {
            @include font-size($giga-size);
            margin-bottom: 0.5em;
        }
    }
}

/**
 * Media item overlay
 */

.media-item--overlay{

    .media-overlay{
        position: absolute;
        opacity: 0;
        @include transition(opacity 0.2s ease-in);
        left: 0;
        top: 0;
        bottom: auto;
        height: 100%;
        width: 100%;
        background: rgba($color-dark, 0.6);
    }

    figcaption{
        display: none;
        z-index: 1;
    }

    &:focus, &:hover {
        figcaption{
            display: block;
        }
        .media-overlay{
            opacity: 1;
        }
    }
}

/**
 * Bordered Media
 */

.media-item--bordered{
    border: 1px $base-ui-color solid;
    padding: 5px;
}
