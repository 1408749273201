.node-chapelle-episode {
  .view {
    background: transparent;
  }
  .lieu-header {
    @include desktop {
      padding: 0;
      @include rem(height, 740px);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.6);
      z-index: 1;
    }
    h1 {
      @include rem(max-width, 575px);
      @include  font-size-rem-adapt(36px, 42px);
      .uppertitle {
        display: block;
        margin-bottom: rem(20px);
        @include font-size-rem(30px);
        line-height: 1;
      }
      .subtitle {
        display: block;
        margin-top: rem(20px);
        margin-bottom: 0;
        font-family: $base-font-family;
        color: #B9B9B9;
        @include font-size-rem(20px);
      }
    }
  }
  .black-background {
    @include rem(margin-bottom, 90px);
    background: #000;
  }
  .episode-carrousel {
    .container {
      padding: 0;
    }
    .view-header {
      display: none;
    }
    .slick-slider {
      @include rem(max-width, 940px);
    }
    button.slick-arrow {
      @include rem((
        width: 70px,
        height: 70px
      ));
    }
    .slick-slider .slick-list {
      margin: 0;
      @include tablet {
        @include rem(margin, 0 -35px);
      }
    }
    .slick-track .slick-slide .slide__content {
      @include tablet {
        @include rem(padding, 0 35px);   
      }
      figcaption {
        padding-bottom: 0;
        p {
          @include  font-size-rem-adapt(18px);
        }
      }
    }
    .views-field {
      width: 100%;
    }
    .slick__arrow {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      @include rem(max-width, 1080px);
      transform: translate(-50%, -50%);
      @include tablet {
        display: block;
        pointer-events: none;
      }
      button.slick-arrow {
        pointer-events: all;
      }
      button.slick-arrow.slick-prev {
        left: 0 !important;
      }
      button.slick-arrow.slick-next {
        right: 0 !important;
      }
    }
  }
  .episode-media {
    @include rem(margin, 0 auto 84px);
    h2 {
      color: #fff;
      margin: 0;
      @include rem(padding, 20px 0);
      text-align: center;
      @include  font-size-rem-adapt(16px);
      @include desktop {
        display: none;
      }
    }
    .voyage-video {
      @include rem(max-width, 940px);
      @include rem(margin, 0 auto);
    }
  }
  .episode-chapo  {
    margin: 0 auto;
    @include rem(max-width, 790px);
    text-align: center;
    .text--center {
      width: 100%;
    }
    h2 {
      @include rem(margin-bottom, 24px);
      color: #fff;
      @include  font-size-rem-adapt(20px, 24px);
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .text--lead {
      border: none;
    }
  }
  .episode-figures {
    .container {
      padding: 0;
    }
    .view-content {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      @include rem(margin, 0 -20px);
      @include rem(padding-bottom, 20px);
      justify-content: center!important;
      @include desktop {
        @include rem(padding-bottom, 80px);
        border-bottom: 1px solid #4A4A4A;
      }
      .key-figure {
        @include rem(margin-bottom, 50px);
        @include rem(padding, 0 60px);
        width: 100%;
        max-width: 100%;
        color: #fff;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
        @include tablet {
          margin-bottom: 0;
          flex-basis: 0;
          flex-grow: 1;
          @include rem(padding, 0 20px);
        }
        @include desktop {
          max-width: 25%;
          flex-basis: 25%;
        }
        h4 {
          @include  font-size-rem-adapt(22px);
          @include rem(margin-bottom, 20px);
          @include desktop {
            @include rem(margin-bottom, 30px);
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .episode-jobs {
    @include rem(margin-top, 40px);
    @include rem(padding-bottom, 30px);
    @include tablet {
      padding-bottom: 0;
    }
    .container {
      padding: 0;
    }
    .view {
      .view-header {
        .text--lead {
          @include  font-size-rem-adapt(24px);
          text-align: center;
          margin: 0;
          padding: 0;
          border: none;
        }
      }
      .view-content {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center!important;
        .job {
          display: flex;
          @include rem(margin-bottom, 30px);
          @include rem(padding, 0 100px);
          width: 100%;
          max-width: 100%;
          color: #fff;
          text-align: center;
          align-items: center!important;
          @include tablet {
            display: block;
            margin-bottom: 0;
            flex-basis: 0;
            flex-grow: 1;
            @include rem(padding, 0 40px);
          }
          @include desktop {
            flex-basis: 280px;
            @include rem(max-width, 280px);
          }
          .image {
            position: relative;
            display: block;
            margin: 0 auto;
            @include rem((
              height: 55px,
              width: 55px
            ));
            border: 1px solid #fff;
            border-radius: 50%;
            overflow: hidden;
            @include tablet {
              @include rem((
                height: 170px,
                width: 170px
              ));
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              min-width: 100%;
              min-height: 100%;
              transition: all 0.4s ease;
              transform: translate(-50%, -50%) scale(1);
              &:focus, &:hover {
                transform: translate(-50%, -50%) scale(1.1);
              }
            }
          }
          p {
            @include rem(margin, 0 0 0 15px);
            color: #fff;
            @include tablet {
              @include rem(margin, 30px 10px);
            }
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .episode-content {
    @include rem(margin-bottom, 100px);
  }
}