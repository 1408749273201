.view.view-push-calendrier {
  p {
    margin: 0;
  }
  .text-gold {
    color: $color-primary;
    font-family: $font-crimson;
    @include font-size-adapt(24px);
    @include line-height-adapt(30px);
  }
  .col-xs-12 {
    &:nth-child(2) {
      p {
        margin: 0;
        @include rem(margin-top, 5px);
        position: relative;
        @include font-size-adapt(16px);
        @include line-height-adapt(25px);
        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          position: absolute;
          @include rem((
            top: 5px,
            bottom: 5px
          ));
          @include rem(left, -15px);
          background: #E6E6E6;
        }
      }
    }
    &:nth-child(3) {
      text-align: right;
      .btn {
        @include rem(margin-top, 5px);
      }
      .btn:before {
        border-color: $color-primary;
      }
    }
  }
}