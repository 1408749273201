/*------------------------------------*\
    ALERT.CSS
\*------------------------------------*/


.ui-alert{
    @include rem(margin, 30px 0 35px);
    @include rem(padding, 17px 20px);
    background: #f1efea;
    color: #0f0f0f;
    position: relative;
    @include desktop {
      @include rem(margin, 30px 0 50px);
    }
    p {
      margin: 0;
      @include font-size(16px);
      line-height: 1.6;
      a {
        color: inherit;
        border-bottom: solid 1px;
      }

      .text--red {
        color: #D0021B;
      }
    }

    /**
     * Alert close
     */

    .alert_close{

        &:after{
            position: absolute;
            content: "\f00d";
            font-family: FontAwesome;
            right: 10px;
            top: 5px;
            color: #fff;
            font-size: 16px;
        }

        &:hover:after{
            opacity: 0.8;
        }
    }
}


/**
 * Error alert
 */

.ui-alert--error{
    color: #D0021B;
}

/**
 * Info alert
 */

.ui-alert--info{
    background: #f1efea;
}

/**
 * Success alert
 */

.ui-alert--success{
    background: $color-primary;
}

#sliding-popup {
  position: fixed;
  background: #F1EFEA;
}

#sliding-popup .popup-content #popup-text {
  color: #1D1D1B;
  float: none;
  font-weight: 400;
  margin: 0 auto;
  @include rem(padding, 15px 60px);
  text-align: left;
  max-width: 100%;
  p {
    color: #1D1D1B;
    @include font-size(13px);
    font-weight: 400;
    margin: 0;
    a {
      color: #1D1D1B;
      border-bottom: solid 1px;
    }
  }
}

#sliding-popup .popup-content #popup-buttons {
  float: none;
  position: absolute;
  @include rem((
    width: 50px,
    height: 50px
  ));
  top: 0;
  right: 0;
  background: #D8D8D8;
  button.agree-button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: none;
    box-shadow: none;
    border: none;
    text-indent: -9999px;
    overflow: hidden;
    &:before, &:after {
      width: 1px;
      height: 10px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background: #000;
    }
    &:before {
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
    &:after {
      transform: translateY(-50%) translateX(-50%) rotate(-45deg);

    }
  }
}
