/*------------------------------------*\
    FORM ELEMENT STYLES
\*------------------------------------*/

/**
 * Forms
 */

form{
    @include rem(margin-bottom);
}

/**
 * Add border box for input search
 */

input[type="search"]{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

/**
 * Input Elements
 */

.selectric-hide-select.selectric-is-native select {
  width: 100% !important;
}
 select {
   display: block !important;
   opacity: 0;
   z-index: -1;
   visibility: visible !important;
 }

 input.datepicker, input.bef-datepicker {
   cursor: default;
   -webkit-appearance: none;
 }

.text-input,
textarea{
    border: 1px $input-border-color solid;
    padding: $input-padding;
    background: $input-background;
    transition: all 0.1s ease-in;
    vertical-align: middle;
    outline: none;
    width: 100%;
    border-radius: $input-border-radius;
    @include font-size(14px, false);

    &:focus{
        @extend %focus-style;
    }
}


/**
 * Disabled Input
 */

.text-input[disabled], textarea[disabled]{
    opacity: 0.5;
}

/**
 * Input inverted
 */

.input-inverted{
    background: transparent;
    border-color: #fff;
    color: #fff;
}

/**
 * Medium input
 */

.input-medium{
    padding: $input-padding-medium;
}

/**
 * Large input
 */

.input-large{
    padding: $input-padding-large;
    @include font-size(16px, false);
}

/**
 * Input with icons
 */

.form-control{
    position: relative;
    @include rem(margin-bottom, 0.75);

    .text-input{
        padding-right: 35px;
        margin-bottom: 0;
    }

    .fa{
        position: absolute;
        right: 10px;
        top: 50%;
        margin: 0;
        margin-top: -6px;
        height: 15px;
        color: $base-ui-color;
    }


    /* Icon on the left side */

    &.icon-left {

        .text-input{
            padding-left: 35px;
            padding-right: inherit;
        }

        .fa{
            left: 10px;
            right: auto;
        }
    }

    /* Button unstyled */

    .btn--unstyled{
        padding: 0;
        margin: 0;
        vertical-align: top;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
}


/**
 * Form control Inline
 */

.form-control--inline{



    .text-input{
        width: auto;
        padding: $input-padding;
        @include rem(margin-right,-0.2);
    }

    /* Button */

    .btn{
        margin-bottom: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


/**
 * Round text input
 */

.input-soft{
    border-radius: 200px;
}

/**
 * Margins for Select, File
 */

select,
input[type="file"],
input[type="color"],
input[type="range"],
.label-checkbox,
.label-radio,
textarea,
.text-input
{
    @include rem(margin-bottom, 0.75);
}


/**
 * Wide select
 */

.select-wide{
    width: 100%;
}

/**
 * Checkbox, Radio
 */

input[type="checkbox"],
input[type="radio"]{
    margin-right: 0.5em;
}

/**
 * Label
 */

label{
    @include rem(margin-bottom, 0.1);
    font-weight: 500;
}


/**
 * Checkbox Display
 */

.form-type-checkbox, .form-type-radio{
    display: inline-block;
    position: relative;
    font-weight: normal;
    @include rem(margin-right, 20px);
    @include rem(padding-left, 25px)
    input{
      position: absolute;
      top: 100%;
      left: 0;
      visibility: hidden;
      opacity: 0;
      &:checked + label {
        &:after {
          opacity: 1;
        }
      }
    }
    label {
      @include font-size(14px);
      line-height: 1.7;
      color: #666;
      font-weight: 400;
      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -12px;
        content: '';
        display: block;
        @include rem((
          width: 19px,
          height: 19px
        ));
        background: #fff;
        border: 1px solid #e6e6e6;
      }
      &:after {
        position: absolute;
        top: 50%;
        left: 6px;
        margin-top: -6px;
        content: '';
        display: block;
        opacity: 0;
        transition: all 0.3s;
        @include rem((
          width: 7px,
          height: 7px
        ));
        background: #ba9e66;
      }
    }
}

.form-type-radio{
    label {
      &:before {
        border-radius: 50%;
      }
      &:after {
        border-radius: 50%;
      }
    }
}

/**
 * Inline Input
 */

.input-inline{
    width: auto;
}



/**
 * Inline Label
 */

.label-inline{
    width: auto;
    display: inline-block;
    vertical-align: middle;
    @include rem(margin-right, 0.5);
}

/**
 * Fieldset
 */


fieldset{
    border-color: $input-border-color;
    padding: $grid-spacing/2;
}

/**
 * Error Input
 */

.input-error{
    border-color: $color-warning;
}

/**
 * Error Label
 */

.label-error{
    color: $color-warning;
    display: inline-block;
    @include rem(margin-bottom, 0.5);
    @include font-size($milli-size, false);
    font-weight: normal;
    margin-top: -0.5em;
    overflow-y: hidden;
}


/**
 * Input groups
 */

.input-group{

    @include rem(margin-bottom);

    .text-input, textarea{
        margin-bottom: 0;
        border-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;


        &:first-child{
            border-top-left-radius: $base-radius;
            border-top-right-radius: $base-radius;
            margin-bottom: -1px;
        }

        &:last-child{
            border-bottom-left-radius: $base-radius;
            border-bottom-right-radius: $base-radius;
        }

        &:focus{
            position: relative;
        }
    }
}


div.fancy-select {
    position: relative;
    display: inline-block;
    @include font-size(16px);
    line-height: 1;
    color: #fff;
    text-align: left;

    &.disabled {
        opacity: 0.5;
    }

    select:focus + div.trigger.open {
        box-shadow: none;
    }

    div.trigger {
        cursor: pointer;
        @include rem(padding, 21px 25px 21px 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        background: #fff;
        border: 1px solid #E6E6E6;
        color: #333;
        width: 100%;
        @include desktop {
          width: rem(240px);
        }
        i {
          display: inline-block;
          @include rem(margin-right, 20px);
          color: $color-primary;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top-color: #333;
            top: 50%;
            right: rem(15px);
            transform: translateY(-50%);
        }

        &.open {
            background: #fff;
            color: #333333;
            box-shadow: none;
            &:after {
                border-top-color: #333333;
            }
        }
    }

    ul.options {
        list-style: none;
        margin: 0;
        position: absolute;
        top: rem(50px);
        right: 0;
        visibility: hidden;
        opacity: 0;
        z-index: 50;
        padding: 0;
        max-height: rem(400px);
        overflow: auto;
        background: #fff;
        min-width: rem(240px);
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
        transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
        &.open {
            visibility: visible;
            @include rem(top, 60px);
            opacity: 1;
            transition: opacity 300ms ease-out, top 300ms ease-out;
        }
        &.overflowing {
          top: auto;
          @include rem(bottom, 50px);
          transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
          &.open {
            top: auto;
            @include rem(bottom, 60px);
          }
        }


        li {
            padding: rem(23px) rem(15px) rem(23px) rem(15px);
            color: #333;
            cursor: pointer;
            white-space: nowrap;
            border-bottom: 1px solid #E6E6E6;
            transition: all 150ms ease-out;
            &.selected {
                background: #F1EFEA;
            }
            &:focus, &:hover {
                background: #F1EFEA;
            }
        }
    }
}


$selectric-main-color:      #DDD !default;                                              // Color used in border
$selectric-secondary-color: #BBB !default;                                              // Color used in button
$selectric-text-color:      #444 !default;                                              // Color used in label
$selectric-bg-color:        #F8F8F8 !default;                                           // Background color
$selectric-height:          40px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height

.selectric-wrapper {
  position: relative;
  display: inline-block !important;
  @include font-size(16px);
  line-height: 1;
  color: #fff;
  text-align: left;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  cursor: pointer;
  @include rem(padding, 21px 25px 21px 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  background: #fff;
  border: 1px solid #E6E6E6;
  color: #333;
  width: 100%;
  @include desktop {
    width: rem(240px);
  }
  i {
    display: inline-block;
    @include rem(margin-right, 20px);
    color: $color-primary;
  }
  &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top-color: #333;
      top: 50%;
      right: rem(15px);
      transform: translateY(-50%);
  }

  &.open {
      background: #fff;
      color: #333333;
      box-shadow: none;
      &:after {
          border-top-color: #333333;
      }
  }
  .button {
    display: none;
  }
}


.selectric-open {
  z-index: 9999;
  select {
    visibility: visible;
  }
  .selectric-items {
    pointer-events: auto;
    visibility: visible;
    @include rem(top, 60px);
    opacity: 1;
    transition: opacity 300ms ease-out, top 300ms ease-out;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    right: 100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  list-style: none;
  margin: 0;
  position: absolute;
  top: rem(50px);
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 50;
  padding: 0;
  width: auto !important; 

  // max-height:350px;  
  // overflow: auto !important; 
  touch-action: none;
  background: #fff;
  min-width: rem(240px);
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
  pointer-events: none;
  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul {
    margin: 0 !important;
    padding: 0 !important;
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: rem(23px) rem(15px) rem(23px) rem(15px);
    color: #333;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: 1px solid #E6E6E6;
    transition: all 150ms ease-out;
    a {
      color: inherit;
      text-decoration: none;
    }
    &.selected {
        background: #F1EFEA;
    }
    &.highlighted {
        background: #F1EFEA;
    }
    &:focus, &:hover {
        background: #F1EFEA;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}

.webform-container-inline div, .webform-container-inline div.form-item {
   display: inline-block;
}

.webform-confirmation {
   font-size: 18px;
}

.selectric-open .selectric-items {
  overflow:hidden;
}
.selectric-items .selectric-scroll {
  width:100%;
}

.node-webform .webform-component--choix-1,
.node-webform .webform-component--choix-2,
.node-webform .webform-component--choix-3-,
.node-webform .webform-component--choix-3 {
  margin-bottom:10px;
  p{
    margin:0;
  }
}

.node-webform .webform-component--remarques-dates {
  margin-top:30px;
}
