/*------------------------------------*\
    TABLES.CSS
\*------------------------------------*/
@mixin table-hover {
  tr:hover > td {
    background: $color-light-grey;
  }
}
/**
 * Table
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
  /* Caption */
  caption {
    @include rem(margin-bottom, 20px);
    text-align: left;
    font-family: $heading-font-family;
    @include font-size-adapt(20px, 24px);
    font-weight: 400;
  }
  thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
  }
  td,
  th {
    padding: 0.5em 1em;
    border-left: 1px solid #cbcbcb;
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    &:first-child {
      border-left-width: 0;
    }
    p {
      margin: 0;
    }
  }
}
/**
 * Default table
 */
.table {
  td,
  th {
    border-bottom: 1px $base-ui-color solid;
  }
}
/**
 * Bordered table
 */
.table--bordered {
  thead th {
    font-family: $heading-font-family;
    background: $color-primary;
    border-color: $color-primary;
    color: #fff;
    font-weight: 700;

    &:first-child {
      border-top-left-radius: $base-radius;
    }

    &:last-child {
      border-top-right-radius: $base-radius;
    }
  }

  td,
  th {
    @include rem(padding, 20px);
    border: 1px $base-ui-color solid;
  }
}
/**
 * Simple table
 */
.table--simple {
  thead {
    text-align: left;
    font-family: $heading-font-family;
    @include font-size-adapt(20px, 24px);

    th {
      font-weight: 400;
    }
  }

  td,
  th {
    border-bottom: 1px solid $base-ui-color solid;
    @include rem(padding, 20px);
    line-height: 1.4em;
    overflow: visible;

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
}
/**
 * Zebra table
 */
.table--zebra {
  tr:nth-child(even) {
    background: lighten($color-primary, 40%);
  }
}
