.widget-multiple-video-player.widget-videos {
    width: 300%;
    margin-right: 0 !important;
    max-width: 942px !important;
    padding-left: 60px;
    .title h2{
        margin-bottom:40px;
    }
    @media (max-width: 1120px) {
        width: 100%;
        max-width: 760px !important;
        padding-right: 0;
    }
    @media (max-width: 900px) {
        margin-left: 0 !important;
        padding-left: 0;
    }
    .videos-wrapper {
        display: flex;
        justify-content: space-between;
        @media (max-width: 900px) {
            flex-direction: column-reverse;
        }
    }
    .videos-thumbnails {
        width: 215px;
        margin-right: 20px;
        max-height: 500px;
        min-height:500px;
        overflow: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
        @media (max-width: 900px) {
            display: flex;
            width: 100%;
            padding-top: 20px;
            margin-top: 20px;
            margin-right: 0;
            min-height:100px;
        }
        .simplebar-scroll-content {
            padding-right:10px;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
        }
        .video-small {
            margin-bottom: 20px;
            display: block;
            text-decoration: none;
            border: none !important;
            padding-left: 20px;
            position: relative;
            @media (max-width: 900px) {
                min-width: 160px;
                max-width: 160px;
                padding: 0;
                margin-right: 10px;
            }
            .image-thumb {
                position: relative;
                &:before {
                    content: "";
                    width: 40px;
                    height: 40px;
                    background: #ffffff;
                    border-radius: 60px;
                    position: absolute;
                    left: calc(50% - 20px);
                    top: calc(50% - 20px);
                    transition: all 0.3s ease;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 12px;
                    border-color: transparent transparent transparent #333;
                    position: absolute;
                    left: calc(50% - 5px);
                    top: calc(50% - 6px);
                }
            }
            .image-thumb .active-wrapper {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 102px;
                    width: 1px;
                    background: transparent;
                    transition: all 0.3s ease;
                    @media (max-width: 900px) {
                        height: 1px;
                        width: 100%;
                        left: 0;
                        top: 0;
                    }
                }
            }
            &.active {
                .video-title{
                    /* text-transform: uppercase; */
                }
                .image-thumb .active-wrapper {
                    &:before {
                        content: "";
                        position: absolute;
                        left: -20px;
                        top: 0;
                        height: 102px;
                        width: 1px;
                        background: #ba9e66;
                        @media (max-width: 900px) {
                            height: 1px;
                            width: 100%;
                            left: 0;
                            top: -10px;
                        }
                    }
                }
            }
            .video-title {
                font-size: 14px;
                line-height: 21px;
                font-family: "Crimson Text";
                color: #000;
                text-decoration: none;
                padding-top: 10px;
            }
            .video-desc {
                display: none;
            }
            &:focus, &:hover {
                .video-title {
                    color: #ba9e66;
                }
                .image-thumb:before {
                    width: 50px;
                    height: 50px;
                    left: calc(50% - 25px);
                    top: calc(50% - 25px);
                }
            }
        }
    }
    .videos-main {
        width: calc(100% - 225px);
        @media (max-width: 900px) {
            width: 100%;
        }
        img {
            width: 100%;
        }
        .video-title {
            font-size: 22px;
            line-height: 24px;
            font-family: "Crimson Text";
            color: #000;
            margin: 20px 0 0;
            text-transform: uppercase;
        }
        .video-desc {
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            padding-top: 20px;
        }
        .video-bottom {
            display: flex;

            justify-content: space-between;
        }
        .video-info {
            width: calc(100% - 100px);
        }

        .iframe-container {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
        }
        .iframe-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-buttons {
            width: 80px;
            display: flex;
            margin: 20px 0 0;
            .next-video-please,
            .prev-video-please {
                width: 40px;
                height: 40px;
                border: 1px solid #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url(../images/arrow-slider.svg) no-repeat center center;
                color: transparent;
            }
            .next-video-please {
                border-right: none;
                transform: rotate(180deg);
            }
        }
    }
}



.node-type-presse-communique .node .text-content .full-width {
    margin-left: calc(-50vw - 60px) !important;
    left: 50% !important;
    @media (max-width: 1100px) {
        transform: translateX(calc((51.3em - 100vw) / 2));
        margin-left: 0 !important;
        left: 0 !important;
    }
    @media (max-width: 900px) {
        transform: none !important;
        margin-left: -30px !important;
        margin-right: 0 !important;
    }
    @media (max-width: 700px) {
        margin-left: -20px !important;
        margin-right: 0 !important;
    }
}

.beige-bg .view {
    background: #f1efea;
}
 

.fluid-width-video-wrapper.cookie-consent-waiting {
    iframe {
        pointer-events: none !important;
    }
}

.view-widget-videos.view-display-id-block button.popup-youtube {text-align:left; background:transparent;}