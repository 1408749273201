.node.node-hub-espace-abonnes {
  .top-info {
    .col-left {
      p {
        @include rem(margin-bottom, 30px);
        padding-left: 0;
        &.text--gold {
          color: $color-primary;
          font-family: $font-crimson;
          @include font-size-adapt(30px);
          @include line-height-adapt(36px);
        }
      }
    }
  }
  .view.view-billets-tarifs {
    & > .container {
      padding-top: 0;
    }
    .nav-bar .form-type-bef-link {
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
  .field-collection-view {
    @include rem(margin-bottom, 90px);
    .inner {
      h4 {
        @include rem(margin, 0 0 20px);
        font-family: $base-font-family;
        @include font-size-adapt(24px);
        @include line-height-adapt(24px);
        text-transform: none;
      }
      a {
        @include rem(margin, 0 0 10px);
        display: inline-block;
        @include rem(padding-bottom, 5px);
        color: #0F0F0F;
        @include font-size(16px);
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
}
