.view.view-hub-lieu.view-display-id-block {
  padding: 0;
  border-bottom: 1px solid #E6E6E6;
  & > .container {
    padding: 0;
  }
  .row {
    @include rem(margin, 0);
    align-items: stretch;
    @include desktop {
      @include rem(margin, 0 -50px);
    }
    [class^="col-"] {
        @include rem(padding, 0 30px);
        @include desktop {
          @include rem(padding, 0 50px);
        }
        &:first-child {
          margin: 0;
          border-bottom: 1px solid #E6E6E6;
          @include desktop {
            border-bottom: 0;
            border-right: 1px solid #E6E6E6;
          }
        }
    }
  }
  .outer {
    height: 100%;
  }
  figure {
    @include rem(padding, 30px 0);
    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    figcaption {
      text-align: left;
      flex: 0 0 auto;
      flex-basis: 50%;
      padding: 0;
      h5 {
        @include rem(margin, 0 0 10px);
        color: $color-primary;
        @include font-size-adapt(26px, 36px);
        @include line-height-adapt(32px, 45px);
      }
      p {
        margin: 0;
        font-family: $heading-font-family;
        @include font-size-adapt(14px, 18px);
        @include line-height-adapt(18px, 22px);
      }
    }
    img {
      @include rem(margin-top, 30px);
      @include desktop {
        margin: 0;
      }
    }
  }
}
