/*
 * Mixin for easy media queries
 * @include bp(tablet) { SCSS here };
*/
@mixin desktop {
  @include mq($media-type: 'all', $min-width: $desktop-breakpoint-start) {
    @content;
  }
}

@mixin tablet {
  @include mq($media-type: 'all', $min-width: $tablet-breakpoint-start) {
    @content;
  }
  @include mq($media-type: 'print', $min-width: 0px) {
    @content;
  }
}

@mixin tablet-port {
  @include mq($media-type: 'all', $min-width: $tablet-portrait-start) {
    @content;
  }
  @include mq($media-type: 'print', $min-width: 0px) {
    @content;
  }
}

@mixin mobile-only {
  @include mq($media-type: 'all', $max-width: $tablet-breakpoint-start) {
    @content;
  }
}



@mixin outer-container($local-max-width: $pageWidth) {
  @include clearfix;
  max-width: $local-max-width;
  margin: {
    left: auto;
    right: auto;
  }
}


@mixin user-select($select) {
	-webkit-user-select: $select;
	-khtml-user-drag: $select;
	-khtml-user-select: $select;
	-moz-user-select: $select;
	@if $select == none { -moz-user-select: -moz-none; }
	-ms-user-select: $select;
	user-select: $select;
}

/*
 * Mixin for micro clearfix
 * @include microclearfix;
*/
@mixin microclearfix {
	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }
	*zoom: 1;
}

/*
 * Mixin for basic CSS triangles
 * @include triangle(up, #000, 50px)
*/
@mixin triangle($direction:up, $color:#000, $size:100px) {
	@if($direction == up) {
		border-color: transparent transparent $color;
		border-style: solid;
		border-width: 0 $size $size;
		height: 0;
		width: 0;
	}
	@if($direction == down) {
		border-color: $color transparent transparent transparent;
		border-style: solid;
		border-width: $size;
		height:0;
		width:0;
	}
	@if($direction == left) {
		border-color: transparent $color transparent transparent;
		border-style: solid;
		border-width: $size $size $size 0;
		height: 0;
		width: 0;
	}
	@if($direction == right) {
		border-color: transparent transparent transparent $color;
		border-style: solid;
		border-width: $size 0 $size $size;
		height:0;
		width:0;
	}
}

/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */

 @function str-replace($string, $search, $replace: "") {
 	$index: str-index($string, $search);

 	@if $index {
 		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
 	}

 	@return $string;
 }

 // =============================================================================
 // Font Face
 // =============================================================================

 @mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
 	$src: null;

 	$extmods: (
 		eot: "?",
 		svg: "#" + str-replace($name, " ", "_")
 	);

 	$formats: (
 		otf: "opentype",
 		ttf: "truetype"
 	);

 	@each $ext in $exts {
 		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
 		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
 		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
 	}

 	@font-face {
 		font-family: quote($name);
 		font-style: $style;
 		font-weight: $weight;
 		src: $src;
 	}
 }


 /*------------------------------------*\
 	MIXINS
 \*------------------------------------*/

 /**
  * Create a fully formed type style (sizing and vertical rhythm) by passing in a
  * single value, e.g.:
  *
    `@include font-size(10px);`
  *
  * Thanks to @redclov3r for the `line-height` Sass:
  * twitter.com/redclov3r/status/250301539321798657
  */
 @mixin font-size($font-size, $line-height:true){
     font-size:$font-size;
     font-size:($font-size / $base-font-size)*1em;
 }


 @mixin font-size-rem($font-size, $line-height:true){
     font-size:$font-size;
     font-size:($font-size / $base-font-size)*1rem;
 }


 /**
  * Font size mixin
  */

 @mixin font-size-adapt($mobile: false, $tablet: false, $desktop: false){

 		@if $mobile{

 				@include font-size($mobile, false);

 		}


 		@if $tablet{

 				@include media-query(tablet-desktop){
 						@include font-size($tablet, false);
 				}
 		}

 		@if $desktop{

 				@include media-query(desktop){
 						@include font-size($desktop, false);
 				}
 		}
 }

 @mixin font-size-rem-adapt($mobile: false, $tablet: false, $desktop: false){

     @if $mobile{

         @include font-size-rem($mobile, false);

     }


     @if $tablet{

         @include media-query(tablet-desktop){
             @include font-size-rem($tablet, false);
         }
     }

     @if $desktop{

         @include media-query(desktop){
             @include font-size-rem($desktop, false);
         }
     }
 }


 @mixin line-height-adapt($mobile: false, $tablet: false, $desktop: false){

		 @if $mobile{

				 @include rem('line-height', $mobile);

		 }


		 @if $tablet{

				 @include media-query(tablet-desktop){
						@include rem('line-height', $tablet);
				 }
		 }

		 @if $desktop{

				 @include media-query(desktop){
						 @include rem('line-height', $desktop);
				 }
		 }
 }

 /**
  * Media Query Mixin
  */

 @mixin media-query($media-query){

 		@if $media-query == "tablet-desktop"{

 				@media only screen and (min-width: $tablet-breakpoint-start) { @content; }

 		}

 		@if $media-query == tablet{

 				@media only screen and (min-width: $tablet-breakpoint-start) { @content; }

 		}

 		@if $media-query == desktop{

 				@media only screen and (min-width: $desktop-breakpoint-start) { @content; }

 		}

 		@if $media-query == phone{

 				@media only screen and (max-width: $tablet-breakpoint-start) { @content; }

 		}

 		@if $media-query == phone-landscape{

 				@media only screen and (min-width: $phone-breakpoint-end) and (orientation: landscape){
 						@content;
 				}
 		}

 		@if $media-query == tablet-landscape{

 				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape){
 						@content;
 				}
 		}

 		@if $media-query == widescreen{
 				@media only screen and (min-width: $widescreen-breakpoint-start){
 						@content;
 				}
 		}

 		@if $media-query == retina{
 				@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5){
 						@content;
 				}
 		}


 }


 /**
  * Box Shadow Base
  */

 @mixin base-box-shadow{

 	box-shadow: $base-box-shadow;
 }


 /**
  * Light Links
  */

 @mixin links-light {

 	a{
 		@include link-colors(#fff, #fff, #fff)
 	}
 }

 /**
  * Link Colors: Part of Compass
  */

 @mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
 	color: $normal;
 	@if $visited {
 	&:visited {
 		color: $visited; } }
 	@if $focus {
 	&:focus {
 		color: $focus; } }
 	@if $hover {
 	&:focus, &:hover {
 		color: $hover; } }
 	@if $active {
 	&:active {
 		color: $active; } } }


 /**
  * Focus Styles
  */

 %focus-style{
 	box-shadow :  0 1px 2px rgba(0,0,0,0.075) inset,
 									0 0 5px rgba(81,167,232,0.5);
 	border-color: #51A7E8;
 }


 /**
  * Convert numbers to words
  * 1 => 'one'
  */

 @function convert-number-to-word($num) {
 	$count:1;
 	@each $word in one,two,three,four,five,six,seven,eight,nine,ten,eleven,
 		twelve,thirteen,fourteen,fifteen,sixteen,seventeen,eighteen,nineteen,
 		twenty,twentyone,twentytwo,twentythree,twentyfour{
 		@if ($count == $num) { @return $word; }
 		$count: $count + 1;
 	}
 	@return "invalid";
 }
