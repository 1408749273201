.view.view-visite-menus {
  border-bottom: 1px solid #e6e6e6;
  .container {
    padding: 0;
  }
  ul {
    margin: 0;
    @include rem(padding, 30px 0);
    list-style: none;
    text-align: right;
    li {
      display: inline-block;
      a {
        color: #666;
        &.active {
          color: #000;
        }
      }
    }
  }
}
