.view.view-display-id-block_offres {
  background: url('../images/offersbg.jpg') no-repeat center bottom #f1efea;

  .container {
    @include rem(padding-bottom, 100px);
  }

  .view-footer {
    @include rem(margin-top, 40px);

    .container {
      padding: 0;
    }

    .view {
      background: none;
    }

    a {
      // @extend .btn;
      @include rem(margin-top, 50px);
    }
  }

  .row .col-md-6 {
    &:first-child {
      @include rem(margin-bottom, 40px);
      @media (max-width:700px) {
        @include rem(margin-top, 20px);
      }
      &+.col-md-6 {
        @include rem(margin-bottom, 40px);
      }
    }

    @include desktop {
      margin-bottom: 0 !important;
    }
  }

  .outer {
    position: relative;
    @include rem(margin-bottom, 20px);
    @include rem(padding, 10px);
    height: auto !important;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

    @include tablet {
      @include rem(padding, 10px 10px 120px);
      height: 100% !important;
    }

    @media (max-width:700px) {
      text-align: left;
      padding: 20px;
      margin: 0;

      .field-content.row {
        text-align: center;
      }
    }

    h4.title {
      @include rem(margin, 25px 0 15px);
    }

    p.label {
      @include rem(margin, 0 0 10px);
      @include font-size(14px);
      line-height: 1.4;
    }

    div.list {
      p {
        @include rem(margin, 0 0 5px);
        @include rem(padding, 5px 0);
        @include font-size(14px);
        line-height: 1.4;
      }

      a:not(.tooltip) {
        display: inline-block;
        color: inherit;
        margin: 0;
        border-bottom: 1px solid #cccccc;

        &:focus, &:hover {
          color: $color-primary;
          border-bottom: 1px solid $color-primary;
        }
      }
    }

    .row {
      width: 100%;
      @include rem(margin, 30px auto 0);

      @include tablet {
        position: absolute;
        width: auto;
        @include rem((left: 10px,
            right: 10px,
            bottom: 10px));
        margin: 0;
        border: none;
        flex-direction: column;
        align-items: center;

        [class^="col-"] {
          max-widtH:unset;

          p.price{
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 10px;
          }
        }
      }

      [class^="col-"] {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
      }

      p.price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        width: 100%;
        @include rem(padding, 0 10px);
        color: $color-primary;
        @include font-size(12px);
        line-height: 1;
        border: 1px solid $color-primary;

        @include desktop {
          border: none;
        }

        span {
          display: block;
          @include font-size-rem(24px);
        }
      }

      div.link {
        width: 100%;
        text-align: center;

        @include desktop {
          text-align: right;
        }

        a {
          display: block;
          width: 100%;

          @include desktop {
            @include rem(padding, 20px);
          }
        }
      }
    }
  }
}

.view.view-display-id-block_push {
  @include rem(margin, 0 -30px);

  @include desktop {
    margin: 0;
  }

  .views-column-first {
    @include desktop {
      -ms-flex-preferred-size: 66.6666666667%;
      flex-basis: 66.6666666667%;
      max-width: 66.6666666667%;
    }

    .ui-header {
      .container {
        @include rem((left: 50px,
            right: 50px,
            bottom: 50px))
      }
    }

    h3 {
      @include font-size-adapt(26px, 36px);
    }

    p {
      @include rem(margin-bottom, 20px);
      font-family: $font-crimson;
      color: $color-primary;
      @include font-size-adapt(22px, 28px);
      line-height: 1;
    }
  }

  .views-column-last {

    @include desktop {
      -ms-flex-preferred-size: 33.3333333333%;
      flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
    }

    h3 {
      @include font-size-adapt(22px, 24px);
      @include rem(line-height, 30px);
    }

    p {
      @include rem(margin-bottom, 20px);
      font-family: $font-crimson;
      @include font-size(18px);
      line-height: 1;
    }
  }

  .outer {
    padding: 0;
    height: 430px;

    .ui-header {
      cursor: pointer;
      height: 100%;
      text-align: left;
      background-size: cover;

      &:focus, &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: rgba(#000, 0.6);
        transition: all 0.3s;
        z-index: 1;
      }

      .container {
        position: absolute;
        z-index: 2;
        @include rem((left: 40px,
            right: 40px,
            bottom: 50px));
        width: auto;

        @include desktop {
          @include rem((left: 30px,
              right: 65px,
              bottom: 50px));
        }
      }

      h3 {
        p {
          margin: 0;
          line-height: 1;
        }
      }

      a {
        margin: 0;
        color: #fff;
      }
    }
  }
}
