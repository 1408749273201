.node-home {


  #ui-datepicker-div {
    @media (max-width:700px) {
      width: calc(100% - 43px);
    }

  }

  .ui-news .news {
    text-align: left;
  }

  .home-header {
    h1 {
      color: #fff;
      font-size: 64px;
      max-width: 880px;

      @media (max-width:700px) {
        font-family: 'Apollon';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        max-width: 270px;
      }
    }

    .buttons {
      .btn {
        padding-left: 45px;
        max-width: unset;
        font-size: 19px;
        padding-top: 22px;
        padding-bottom: 22px;

        // 2px solid #fff;
        @media (max-width:700px) {
          max-width: 220px;
          font-size: 16px;
          padding-top: 20px;
        }

        svg {
          position: absolute;
          left: 20px;
          bottom: 25px;
          transition: .1s;
        }

        &.first {
          svg {
            fill: #ba9e66;
          }

          &:focus,
          &:hover {
            svg {
              fill: #FFF;
              transition: .1s;

              &:hover path {
                fill: #FFF;
              }
            }
          }

          @media (max-width:1100px) {
            margin-right: 20px;

            &:after {
              transform: scaleY(0);
            }
          }

          @media (max-width:700px) {
            margin-right: 0;

            &:after {
              transform: scaleY(0);
            }
          }
        }

        &.second {
          svg path {
            transition: .2s;
          }

          &:focus,
          &:hover {
            border-color: #000;
            transition: border-color 0s;

            svg {
              fill: #FFF;
              transition: .1s;

              &:hover path {
                fill: #FFF;
                transition: .2s;
              }
            }
          }

          @media (max-width:1100px) {
            margin-left: 20px;
          }

          @media (max-width:700px) {
            margin-left: 0;
          }
        }
      }
    }
  }
}


// css for tickets to move

.ticket-popup-container,
.view-billets-tarifs .view-content .ticket {
  @media (max-width:700px) {

    .fixed-content {
      overflow-y: auto;
      margin-bottom: auto;
      overflow-x: hidden;
    }
  }
}


.tooltip-theme:before {
  @media (max-width:700px) {
    left: auto;
    right: 20px;
  }
}


.front .main-content.with-nav {
  @media (min-width:1100px) {
    padding-top: 60px;
  }
}

.view-collections {
  &.view-display-id-block_home {
    @media (max-width:700px) {
      >.container {
        padding: 40px 20px 20px 20px;
        padding-right: 0;

        .view-header {
          padding: 0 0 20px 0;
          padding-right: 20px;

          .container {
            padding: 0;

            .title {
              margin: 0;
              text-align: left !important;
              font-size: 24px;
              line-height: 30px;
            }

            p {
              display: none;
            }
          }
        }

        .theme-showcase .slick-track .slick-slide {
          max-width: 85vw;
          margin-right: 20px;
        }

        .collections-wrapper {
          .theme-showcase {
            .item {
              figure {
                figcaption {
                  h5 {
                    a {
                      &.ext {
                        text-decoration: none !important;

                        &:after {
                          content: none !important;
                        }
                      }
                    }
                  }

                  a {
                    &.ext {
                      border: none;
                      text-decoration: underline #ba9e66 !important;
                      text-underline-offset: 6px;

                      &:after {
                        content: '→';
                        position: relative;
                        top: 0;
                        background: 0 0;
                        display: inline-block;
                        overflow-y: hidden;
                        text-decoration: none;
                        transform: unset !important;
                        max-width: 20px;
                        max-height: 14px;
                        left: 5px;
                        transition: .5s;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .view-footer {
          margin: 0;
          padding-right: 20px;
          margin-top: 40px;

          .btn {
            background: transparent;
            color: #fff !important;
            border: 1px solid #ba9e66 !important;

            &:before,
            &:after {
              display: none;
            }

          }
        }
      }
    }
  }
}

.view-jo {
  background: #f1efea;

  .container {
    display: flex;
    max-width: 1400px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: flex-start;
    
    @media (max-width:1400px) {
      padding-right: 20px;
    }
    @media (max-width:700px) {
      flex-wrap: wrap;
      padding-right: 30px;
    }

    .view-header {
      width: 280px;
      color: #ba9e66;
      padding-bottom: 0;
      padding-right: 50px;

      h2 {
        font-size: 24px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 30px;

        @media (max-width:700px) {
          padding-bottom: 0;
        }
      }

      @media (max-width:700px) {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 0;
      }

      a {
        color: #000;
        border-bottom: 1px solid #ba9e66;
        display: inline;
        position: relative;
        word-wrap: break-word;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        line-height: 22px;
        font-size: 14px;

        @media (max-width:700px) {
          display: none;
        }

        &:hover {
          color: #ba9e66;
        }

        &:after {
          content: '→';
          // position: absolute;
          width: 10px;
          height: 10px;
          font-size: 13px;
          margin-left: 5px;
          transition: .2s;
          right: -15px;
        }
      }
    }

    .view-footer {
      display: none;

      @media (max-width:700px) {
        display: block;
        margin-top: 20px;
        text-align: left;

        a {
          color: #000;
          border-bottom: 1px solid #ba9e66;
          display: inline;
          position: relative;
          word-wrap: break-word;
          overflow-wrap: break-word;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          line-height: 22px;
          font-size: 14px;

          &:hover {
            color: #ba9e66;
          }

          &:after {
            content: '→';
            position: absolute;
            width: 10px;
            height: 10px;
            font-size: 13px;
            margin-left: 5px;
            transition: .2s;
            right: -15px;
          }
        }
      }
    }

    .view-content {
      max-width: calc(100% - 300px);

      @media (max-width:700px) {
        max-width: 100%;
      }

      .slick-track {
        padding: 10px 0;
      }

      .slick-slide {
        max-width: 350px;
        background: #fff;
        margin-right: 15px;
        padding: 24px;
        position: relative;
        transition: .1s;
        width: 100%;

        @media (max-width:700px) {
          max-width: calc(100vw - 40px);
          width: 100%;
        }

        &>div {
          width:100%;
        }

        .text-wrapper {
          width:100%;
        }

        &:hover {
          box-shadow: 2px 2px 2px -2px #666;
          transition: .1s;
        }

        &:focus-within {
          outline: 2px solid #000;
          outline-offset: 2px;
          box-shadow: 2px 2px 2px -2px #666;

          .card-title a {
            text-decoration: underline;
          }
        }

        span.date-display-single {
          color: #666;
          font-family: 'Graphik';
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }

        .card-title {
          margin-bottom: 0;
          font-size: 22px;
          font-family: "Crimson Text";
          font-weight: 400;
          color: #333;
          margin-top: 8px;

          a {
            font-size: 22px;
            font-family: "Crimson Text";
            font-weight: 400;
            color: #333;

            &:focus {
              outline: 0;
            }

            &:hover {
              text-decoration: underline;
            }

            // &:before {
            //   content: '';
            //   position: absolute;
            //   left: 0;
            //   top: 0;
            //   width: 100%;
            //   height: 100%;
            // }
          }
        }

        &>div>div {
          display: flex !important;
          align-items: flex-start;
          gap: 10px;

          .text-wrapper.date-no-date {
            // span.date-display-single {
            //   display: none;
            // }
          }

          img {
            border-radius: 100%;
            width: 70px;
            height: 70px;
            max-height: 70px;

            @media (max-width:700px) {
              width: 55px;
              height: 55px;
              max-height: 55px;
            }
          }
        }
      }
    }

    .prev-button {
      display: none;
    }

    .next-button {
      position: absolute;
      background: transparent;
      border: 0;
      border-left: 3px solid #000;
      border-top: 3px solid #000;
      right: -20px;
      top: calc(50% - 6px);
      font-size: 0;
      padding: 0;
      width: 15px;
      height: 15px;
      transform: rotate(-225deg);

      @media (max-width:1100px) {
        right: -25px;
        top: calc(50% - 6px);
      } 
      @media (max-width:700px) {
        right: -15px;
   
      } 
    }

    &:has(.disable-arrows) {
      .prev-button,
        .next-button {
        display: none !important;
      }
    }

    @media(min-width:1400px) {
      &:has(.less-than-or-equal-to-3-slides) {
        .next-button {
          display: none;
        }
      }
    }

    @media(min-width:1050px) {
      &:has(.less-than-or-equal-to-2-slides) {
        .next-button {
          display: none;
        }
      }
    }
  }
}
