.instagram-wrapper {
  @include rem(padding, 66px 0 70px);
  background: #000;
  color: #fff;
  .instagram-header {
    @include rem(margin-bottom, 40px);
    h2 {
      @include rem(margin, 0 0 15px);
      @include font-size-rem(24px);
    }
    a {
      position: relative;
      @include rem(padding-left, 20px);
      font-family: $font-crimson;
      @include font-size-rem(24px);
      @include line-height-adapt(36px);
      text-decoration: none;
      display: inline-block;
      .icon-instagram {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-30%);
        @include font-size-rem(16px);
        line-height: 1;
        &:focus, &:hover {
          color: inherit !important;
        }
      }
    }
  }
  .view.view-display-id-instagram {
    background: none;
    & > .container {
      padding: 0;
    }
    .row {
      width: 100%;
    }
    .row [class^=col-] {
      @include rem(margin-bottom, 30px);
      a {
        display: block;
        position: relative;
        &:focus, &:hover {
          &:after {
            opacity: 1;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          opacity: 0;
          z-index: 2;
          transition: opacity .3s;
        }
      }
    }
  }
}