/*------------------------------------*\
	Anecdote.CSS
\*------------------------------------*/


.ui-anecdote {
	@include rem((margin-bottom: 40px,
			max-width: 665px,
			min-height: 240px,
			padding: 35px 20px));
	border: 1px solid #E6E6E6;

	@include desktop {
		@include rem((padding: 40px 50px))
	}

	h5.source {
		margin-top: 0;
		color: $color-primary;
		@include font-size-adapt(14px, 16px);
		font-weight: normal;

		&:before {
			content: '— ';
		}
	}

	p {
		color: #262626;
		font-family: $font-crimson;
		@include font-size-adapt(16px, 21px);
		@include line-height-adapt(24px);

		&:last-child {
			margin: 0 !important;
		}
	}

	&>ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			@include rem(padding, 10px 0 10px 20px);
			color: #262626;
			font-family: $font-crimson;
			@include font-size-adapt(16px, 21px);
			@include line-height-adapt(24px);

			&:before {
				float: left;
				display: inline-block;
				content: '•';
				color: #cccccc;
				@include rem(margin-left, -20px);
			}

			ul li,
			ul li ul li,
			ul li ul li ul li {
				font-size: inherit;
			}

			ul {
				margin-left: 0;
				list-style: none;
			}
		}
	}

	.view-accessibilite {
		ul {
			li {
				margin-left: 0;
				@include rem(margin-right, 10px);
			}
		}
	}
}

.ui-biglink {
	margin-bottom: rem(40px);
	@include rem(padding, 40px 20px);
	text-align: center;
	border: 1px solid #E6E6E6;

	@include desktop {
		@include rem(padding, 50px 100px);
	}

	h4 {
		margin-top: 0;
		font-family: $font-crimson;
		color: #000;
		@include font-size-adapt(20px, 24px);
		line-height: 1.5;
		text-transform: none;
	}
}