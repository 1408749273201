.node-hub-lieu {
  background: #000;
  overflow: hidden;
  .lieu-header {
    z-index: 1;
  }
  .container {
    z-index: 2;
  }
  .ui-intro {
    @include rem((
      margin: 0 -30px,
      padding: 40px 30px
    ));
    @include desktop {
      @include rem(margin-top, -330px);
      @include rem(margin-bottom, 60px);
    }
    &:before {
      @include rem(bottom, 30px);
      height: auto;
    }
    &:after {
      display: none;
    }
    & + .full-width {
      .ui-places.dark {
        padding: 0;
        @include rem(padding-top, 60px);
        @include desktop {
          padding-top: 0;
        }
        & > .container {
          padding-top: 0;
          @include rem(padding-bottom, 40px);
        }
      }
    }
  }
  .view-domaine {
    .view-header {
      @include rem(padding-bottom, 30px);
    }
    .view-filters {
      .nav-bar {
        padding: 0;
        .views-exposed-widget {
          float: none;
          width: 100%;
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .share-print-size {
    position: absolute;
    @include rem((
      top: 100px,
      right: 30px
    ))
    li {
      float: none;
      position: relative;
      @include rem(margin-top, -1px);
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .full-width {
    z-index: 4;
  }
}
