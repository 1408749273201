.view.view-boutique {
  border-bottom: 1px solid #e6e6e6;

  .row .col-md-6 {
    @include rem(margin-bottom, 40px);

    @include desktop {
      margin-bottom: 0;
    }

    &:nth-last-child(-n+2) {
      @include tablet {
        margin-bottom: 0;
      }
    }
  }

  figure {
    margin: 0;
    padding: 0;
    cursor: pointer;

    .img-container {
      position: relative;
      padding: rem(20px);
      height: rem(300px);
      background: #E8EDEC;

      &:focus,
      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.6);
        opacity: 0;
        transition: all 0.3s;
        z-index: 2;
      }

      a {
        position: absolute;
        top: rem(20px);
        right: rem(20px);
        bottom: rem(20px);
        left: rem(20px);
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        border: 5px solid #fff;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    figcaption {
      padding-bottom: 0;
      text-align: left;

      h5 {
        position: relative;
        margin: 0;
        line-height: 1.3;
        overflow: hidden;

        @include desktop {
          @include rem(margin-bottom, 20px);
        }

        a {
          position: relative;
          display: block;
          color: inherit;

          &:hover span {
            transform: translateX(0%);
          }

          span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: transparent;
            overflow: hidden;
            transform: translateX(-100%);
            transition: all 0.5s;

            &:before {
              display: inline;
              content: attr(data-hover);
              background-image: linear-gradient(to right, #333 0%, #333 100%);
              background-repeat: repeat-x;
              background-position: 0 95%;
              background-size: 100% 1px;
            }
          }
        }
      }

      p {
        margin: 0;
        @include font-size(15px);
        line-height: 1.3;
      }
    }
  }

  .row>div:focus-within {
    outline: 2px solid #000;
    outline-offset: 3px;

    figure .img-container:before {
      opacity: 1;
    }
  }
}
