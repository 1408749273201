/*------------------------------------*\
		EVENTS.CSS
\*------------------------------------*/

.ui-events {
	padding: em(40) 0;
	.row {
		border-bottom: 1px solid #e6e6e6;
		&:last-child {
			border: none;
		}
		.col {
			padding: em(40) 0;
		}
	}

	.title {
		margin-bottom: 0;
	}
	.subtitle {
		margin-top: 0;
		font-weight: 700;
		i {
			line-height: 1.2em;
		}
	}
	.info {
		padding: em(40) 0 0;
		date {
			display: block;
			line-height: em(62);
		}
	}

	.col-small {
		padding: em(40) 0;
		.info {
			padding: 0;
		}
	}
}

.ui-calendair {
	position: relative;
	border-top: 1px solid #CCCCCC;
	border-bottom: 1px solid #CCCCCC;
	@include rem(margin-bottom, 40px);
	.view-grouping {
		position: relative;
		.view-grouping-header {
			@include rem(padding, 20px 0);
			color: #666;
			font-family: $heading-font-family;
			@include font-size(470px);
			line-height: 1;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.1;
			z-index: 1;
			pointer-events: none;
		}
		&:nth-child(2n) {
			.view-grouping-header {
				left: auto;
				right: 0;
			}
		}
		.view-grouping-content {
			position: relative;
			margin: 0 auto;
			z-index: 2;
			@include desktop {
				@include rem(padding, 0 250px 0 0);
			}
			h3 {
				@include font-size-adapt(22px, 42px);
				@include line-height-adapt(26px);
				@include desktop {
					@include rem(padding, 60px auto 30px);
				}
			}
			.views-row {
				position: relative;
				@include rem(padding, 15px 0);
				border-bottom: 1px solid #cccccc;
				&:last-child {
					border: none;
				}
				@include desktop {
					@include rem(padding, 20px 0 20px 100px);
				}
				.views-field-field-date {
					display: block;
					color: #666;
					@include font-size(15px);
					@include rem(margin-bottom, 5px);
					@include desktop {
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 0;
						min-width: rem(100px);
						margin-bottom: 0;
						margin-right: rem(20px);
						transform: translateY(-50%);
					}
				}
				.views-field-title, .views-field-field-breve-accroche {
					display: inline-block;
					color: #333333 !important;
					@include font-size(18px);
					@include line-height-adapt(24px);
					vertical-align: middle;
					@include desktop {
						width: 100%;
					}
					p {
						margin: 0;
					}
					a {
						color: inherit !important;
						text-decoration: none !important;
					}
				}
			}
		}
	}
	.view-content, .view-footer {
		.more-link, .btn {
			@include rem(margin-top, 15px);
			@include desktop {
				margin: 0;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				z-index: 10;
			}
		}
	}
}

.page-breves .ui-calendair  {
	position: relative;
	border: 0;
	padding: rem(20px) 0 !important;
	margin-bottom: rem(40px);
	.view-grouping {
		position: relative;
		.view-grouping-content {
			padding: 0;
			.views-row {
				padding: em(10px) 0;
				border-bottom: 1px solid #CCCCCC;
				&.views-row-last {
					border: none;
				}
				.views-field-field-date {
					position: relative;
					top: auto;
					left: 0;
					min-width: rem(100px);
					margin-right: rem(20px);
					display: inline-block;
					color: #666;
					@include font-size(15px);
					transform: translateY(0);
				}
				.views-field-title, .views-field-field-breve-accroche {
					@include desktop {
						width: calc(100% - 130px);
					}
				}
			}
		}
	}
}
