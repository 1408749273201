.view.view-visite-parcours {
  & > .container {
    @media (max-width:700px) {
      padding: 0 20px;
    }

    @include desktop {
      padding: 0;
    }
  }
  .view-header {
    @media (max-width:700px) {
      padding: 0 !important;
      margin: 30px 0!important;
    }
    h2 {
      font-family: $font-crimson;
      color: $color-primary;
      @include font-size(30px);
      line-height: 1;
      text-transform: none;
      @media (max-width:700px) {
        font-size: 18px!important;
        line-height: 26px!important;
      }
    }
  }
}
