.node-chapelle-direct {
  background: #000;
  .article-header {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      z-index: 2;
    }
  }
  .links {
    display: none;
  }
  .instagram-wrapper {
    @include rem(padding, 66px 0 100px);
    background: #000;
    color: #fff;
    .instagram-header {
      @include rem(margin-bottom, 40px);
      h2 {
        @include rem(margin, 0 0 15px);
        @include font-size-rem(24px);
      }
      a {
        font-family: $font-crimson;
        @include font-size-rem(24px);
      }
    }
  }
  .voyage-video {
    a:before {
      background: rgba(#000, 0.5);
    }
  }
}