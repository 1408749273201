/*------------------------------------*\
    NAVBAR.CSS
\*------------------------------------*/

/**
 * Nav bar
 */


.nav-bar{
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    @include rem(padding, 25px 0);
    position: relative;
    border-bottom: 1px solid rgba(#0f0f0f, 0.1);
    @include clearfix;

    .nav-label {
        vertical-align: middle;
    }

    .views-exposed-form .views-exposed-widget {
      margin: 0;
      padding: 0;
    }
    .ajax-progress-throbber { display: none !important}
    .form-type-bef-link {
      display: block;
      @include rem(margin-bottom, 10px);
      @include desktop {
        display: inline-block;
        margin-bottom: 0;
        @include rem(margin-right, 50px);
      }
      &:last-child {
        margin: 0;
      }
      a {
        color: #666666;
        line-height: 1;
        @include rem(padding-bottom, 5px);
        border-bottom: 1px solid transparent;
        &:focus, &:hover {
          color: $color-primary;
        }
        &.active {
          color: #000;
          border-bottom-color: #000;
        }
      }
    }

    .selectric-wrapper .selectric-items {
      width: 100%;
      @include rem(max-height, 400px);
      box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.3);
      z-index: 101;
      @include desktop {
        width: auto;
      }
    }

    /**
     * Image
     */

    img{
        vertical-align: top;
    }


    /**
     * Toggle button
     */



    @include media-query(desktop){
        .btn--toggle{
            display: none;
        }
    }

    /**
     * Dropdowns
     */

    .dropdown{

        > a{
            padding-right: 0;
        }
    }

}

.view.dark {
  .nav-bar{
    .form-type-bef-link {
      a {
        &.active {
          color: #fff;
          border-bottom-color: #fff;
        }
      }
    }
    .bef-select-as-links {
      .selectric-wrapper {
        .selectric {
          width: 100%;
          color: #fff;
          background: none;
          border: 1px solid rgba(#fff, 0.3);
          &:after {
            border-top-color: #fff;
          }
        }
      }
    }
  }
}


/**
 * Inverted Nav bar
 */

.nav-bar--inverted{
    color: #333333;
    background: $color-light-brown;
    ul > li > a {
        color: inherit !important;
        &:focus, &:hover {
            color: $color-primary;
        }
    }

    /**
     * Inverted Inputs
     */

    .text-input{
        // @extend .input-inverted;
    }

    /**
     * Inverted button
     */

    .btn{
        // @extend .btn--inverted;
    }


    /**
     * Inverted dropdowns
     */

    .dropdown{

        &:hover > a, &:hover{
            background: $color-primary;
            border-radius: $base-radius $base-radius 0 0;
        }

        &:after{
            color: #fff;
        }
    }

    /**
     * Dropdown
     */

    .ui-dropdown{
        background: $color-primary;
        border: none;
        border-top-left-radius: 0;

        a:hover{
            background-color: darken($color-primary, 5%);
        }

        /* Divider */

        .seperator{
            border-bottom-color: lighten($color-primary, 10%);
        }
    }
}

.display-swicher {
    a {
        display: inline-block;
        padding: $input-padding;
        padding-left: 0;
        padding-right: 0;
        opacity: 0.2;
        color: $text-primary;
        @include rem(margin-right, 5px);
        transform: scale(0.8);
        transform-origin: 50% 50%;
        transition: $transition-duration;
        i {
            font-size: em(14);
            vertical-align: middle;

        }
        &.active, &:hover, &:focus {
            opacity: 1;
            transform: scale(1);
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
