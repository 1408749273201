.node-type-long-read-le-notre,
.node-type-long-read-seconde-guerre {
  overflow-x: unset !important;

  .theme-people {
    @media (max-width:900px) {
      .slick__slide figure figcaption p {
        display: none;
      }

      button.slick-arrow {
        top: auto;
        bottom: -30px;

        &.slick-prev {
          right: 50px;
          left: auto;
        }
      }
    }
  }

  .mfp-container {
    padding: 0;

    figure img {
      max-height: 85vh !important;
    }
  }

  #main {
    @media (max-width: 1100px) {
      margin-top: 70px;

      &>div:not(#content-area),
      .biblio {
        position: relative;
        z-index: 20;
      }
    }
  }



  header#header {
    position: fixed;
    top: 0;

    .long-back {
      color: #ffffff;
      @include font-size-rem-adapt(14px);

      @media (max-width: 900px) {
        color: transparent;
      }

      i {
        display: inline-block;
        margin-right: 20px;
        margin-right: 1.25rem;
        transform: rotate(90deg);
        color: #ffffff;

        @media (max-width: 900px) {
          margin: 0;
          position: absolute;
          left: 15px;
          top: 28px;
        }
      }
    }

    .audio-wave {
      display: none;
      vertical-align: middle;
      @include rem((margin-right: 10px,
          width: 30px,
          height: 17px,
        ));

      @include tablet {
        display: inline-block;
      }

      @media (max-width:900px) {
        right: 35px;
        position: absolute;
        top: 32px;
        display: block;
      }

      &.play {
        &>div {
          animation: line-scale-pulse-out-rapid 0.9s -0.2s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
        }
      }

      &>div {
        display: inline-block;
        vertical-align: top;
        animation-fill-mode: both;
        @include rem((width: 1px,
            height: 17px,
            margin: 0 1px,
          ));
        border-radius: 1px;
        background: $white;
        transition: all 0.2s cubic-bezier(0.11, 0.49, 0.38, 0.78);

        &:nth-child(1),
        &:nth-child(10) {
          transform: scaley(0.1);
          animation-delay: 0s !important;
        }

        &:nth-child(2) {
          transform: scaley(0.3);
          animation-delay: -0.1s !important;
        }

        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8) {
          transform: scaley(0.8);
          animation-delay: -0.25s !important;
        }

        &:nth-child(4),
        &:nth-child(9) {
          transform: scaley(0.6);
          animation-delay: -0.2s !important;
        }

        &:nth-child(5),
        &:nth-child(7) {
          transform: scaley(1);
          animation-delay: -0.15s !important;
        }
      }
    }

    #toggleAudio {
      @media (max-width: 900px) {
        display: block;
        position: absolute;
        right: 0;
        top: 35px;
      }
    }

    .block-lang_dropdown {
      @media (max-width: 900px) {
        display: block;
        position: absolute;
        top: 25px;
        left: 40px;
      }
    }
  }

  .node-long-read-seconde-guerre {
    .ui-header {
      @include rem(padding, 50px 0);
      @include rem(min-height, 600px);

      @media (max-width: 900px) {
        min-height: 200px;
      }

      h1 {
        @include font-size-rem-adapt(64px);
        @include line-height-adapt(80px);

        @media (max-width: 900px) {
          font-size: 2rem;
          line-height: 3rem;
        }

        span {
          @include font-size-rem-adapt(36px);
          @include line-height-adapt(45px);
          font-family: 'Apollon', Arial, sans-serif;
          color: #ffffff;

          @media (max-width: 900px) {
            font-size: 0.8em;
            line-height: 2rem;
            margin-top: 20px;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.3;
        z-index: 1;
        display: block;
        background-size: 400% 400%;
      }
    }

    .text--lead {
      @include font-size-rem-adapt(32px);
      @include line-height-adapt(44px);
      color: #000000;
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 60px;

      @media (max-width: 900px) {
        font-size: 1.4em;
        line-height: 1.3;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      a {
        color: #ba9e66;
        border: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  footer#page-footer {
    .top-footer {
      .col {
        .btn {
          display: none;
        }
      }
    }
  }

  .big-timeline-container {
    max-width: 1800px;
    margin: -150px auto 0;

    @media (max-width: 1900px) {
      margin: -100px 20px 0 20px;
    
    }

    @media (max-width: 900px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  .timeline-wrapper {
    max-width: 100%;
  }

  .big-timeline {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
    padding-left: 50px;

    @media (max-width: 900px) {
      padding-left: 30px;
    }

    .select-wrapper {
      max-width: 100px;
      position: sticky;
      align-self: flex-start;
      top: 80px;
      margin-left: 50px;
      margin-top: 50px;
      z-index: 10;

      @media (max-width: 600px) {
        top: -100px;
        margin-left: 0;
        margin-top: 0;
        z-index: 10;
        left: 10px;
        position: absolute;

        .selectric-hide-select.selectric-is-native {
          overflow: visible;
        }
      }

      &.fixed {
        @media (max-width: 600px) {
          position: fixed;
          left: 20px;
          top: 80px;
          margin-left: 0;
          z-index: 23;
        }
      }

      // &.fixed {
      //   top: 26px; 
      //   position: fixed;
      // }


      .selectric {
        width: 100px;

        @media (max-width: 600px) {
          width: 130px;
          border: none;
          background: transparent;

          span {
            font-size: 33px;
          }

          &:after {
            width: 11px;
            height: 11px;
            border: 2px solid transparent;
            border-top-color: #ba9e66;
            border-left-color: #ba9e66;
            transform: rotate(225deg);
            top: 30px;
            right: 15px;
          }
        }

      }

      .selectric-items {
        min-width: 100px !important;
      }
    }

    .century-container {
      display: flex;
      align-items: flex-start;
      position: relative;
    }

    .century-year {
      position: sticky;
      align-self: flex-start;
      top: 90px;
      width: 230px;
      min-width: 230px;
      text-align: right;
      margin: 40px 0 0;
      @media(max-width:900px) {
        min-width:130px;
        width:130px;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }

    .year-container .year,
    .century-year {
      font-family: 'Apollon';
      font-style: normal;
      font-weight: 400;
      font-size: 180px;
      line-height: 100%;
      color: #ba9e66;
      margin-bottom: 20px;
      @media(max-width:1300px) {
        font-size:110px;
      }

      @media (max-width: 600px) {
        font-size: 50px;
        margin: 0;
        position: relative;
        left: 17px;
      }
    } 

    .year-container {
      margin: 40px 0;

      @media (max-width: 600px) {
        margin: 20px 0;
      }
    }

    .century-year,
    .year {
      transform: translateX(-40px);
    }

    .year-container .content {
      padding-left: 80px;
      position: relative;

      @media (max-width: 600px) {
        padding-left: 30px;
      }

      .date-box,
      .black-bullet,
      .quote,
      .text {
        &:not(:nth-child(2)) {
          margin-top: 30px;

          @media (max-width: 900px) {
            margin-top: 30px;
            font-size: 14px;
            line-height: 24px;
          }
        }

        @media (max-width: 900px) {
          font-size: 14px;
          line-height: 24px;
        }

        color: #000;
        font-size: 19px;
        line-height: 32px;

        a {
          transition: 0.1s;

          &:focus,
          &:hover {
            transition: 0.1s;
            color: #000;
          }
        }
      }

      .bar-wrapper {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 8px;
        background: #f1efea;
        border-radius: 105px;

        @media (max-width: 900px) {
          width: 4px;
        }

        &:after {
          content: '';
        }

        .bar {
          background: #ba9e66;
          border-radius: 104px;
          position: absolute;
          height: 0;
          left: 0;
          top: 0;
          width: 8px;

          @media (max-width: 900px) {
            width: 4px;
          }
        }
      }
    }

    .black-bullet {
      margin: 20px 0;
      position: relative;
      font-size: 19px;
      line-height: 32px;

      &+.text {
        font-size: 19px;
        line-height: 32px;
      }

      .bullet {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background: #000;
        position: absolute;
        left: -80px;
        top: 10px;
        display: block;
        z-index: 10;
      }

      &:before,
      &:after {
        content: '';
        width: 12px;
        height: 18px;
        position: absolute;
        left: -82px;
        background-image: url(/sites/all/themes/versailles/images/timeline/line-mask.svg);
        background-size: 100% 100%;
      }

      &:before {
        top: -4px;
        transform: rotate(180deg);
      }

      &:after {
        top: 14px;
      }
    }
  }

  .date-box {
    background: #f1efea;
    padding: 50px;
    position: relative;

    @media (max-width: 900px) {
      padding: 20px;
    }

    .top {
      display: flex;
      font-family: 'Apollon';

      @media (max-width: 900px) {
        flex-direction: column;
      }

      &>* {
        font-size: 24px;
        line-height: 30px;
        min-width: 186px;
        margin: 0;

        @media (max-width: 900px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .date {
        @media (max-width: 900px) {
          margin-bottom: 20px;
        }
      }

      &>.date {
        white-space: nowrap;
        min-width: fit-content;
        padding-right: 5px;
        font-weight: bold;
      }

      &.linebreak {
        flex-direction: column;

        .title {
          margin-top: 10px;
        }
      }
    }

    .top+p {
      font-weight: 400;
      font-size: 19px;
      line-height: 32px;

      @media (max-width: 900px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .quote {
    border: 1px solid #e6e6e6;
    padding: 56px;

    @media (max-width: 900px) {
      padding: 20px;
    }

    .icon {
      width: 88px;
      height: 88px;
      top: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 50%;
      border-color: #ba9e66;
      background: #fff;

      @media (max-width:900px) {
        width: 44px;
        height: 44px !important;

        img {
          width: 15px;
        }
      }

      &:before,
      &:after {
        left: 37px !important;
        display: none;
      }
    }

    .quote-text {
      font-family: 'Crimson Text';
      margin-top: 0;
      font-size: 24px;
      line-height: 34px;

      @media (max-width: 900px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .quote-legend {
      font-family: 'Crimson Text';
      font-size: 18px;
      line-height: 24px;

      @media (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .video-container {
    @include rem(margin-bottom, 40px);

    @include desktop {
      margin: 0;
      margin-top: 50px;
    }

    max-width: 690px;

    @media (max-width: 900px) {
      margin-bottom: 20px;
    }

    .icon-youtube:before {
      content: '';
      width: 56px;
      height: 56px;
      background-image: url(/sites/all/themes/versailles/images/timeline/video-widget/play.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
    }

    a.image {
      display: block;
      position: relative;

      &:focus,
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        z-index: 1;
        transition: all 0.3s;
      }

      img {
        width: 100%;
        height: auto;

        @media (max-width: 900px) {
          height: 205px !important;
          object-fit: cover;
          width: calc(100vw - 60px);
          display: block;
          margin: 0 auto;
        }
      }

      i {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        @include rem((width: 62px,
            height: 62px,
          ));
        @include font-size(62px);
        line-height: 1;
        color: #fff;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        transition: all 0.3s;
      }
    }

    &+.text {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 32px;
    }
  }

  .image-single {
    a {
      border-bottom: none !important;

      // @media (max-width: 600px) {
      //   pointer-events: none;
      // }
    }

    a:hover img {
      // transform: scale(1.05);
    }

    img {
      margin: 50px 0;
      max-width: 100%;
      height: auto;
      margin-bottom: 0;
      max-width: 500px;

      // transition: all 0.3s ease;
      @media (max-width: 900px) {
        margin: 20px 0 0 0;
        max-width: 100%;
      }
    }
  }

  .image-full-width img {
    width: 100vw;
    max-width: 100vw;
    position: relative;

    @media (min-width: 1901px) {
      margin-left: calc(-50vw + 540px);
      margin-right: calc(-50vw + 745px);
    }

    @media (max-width: 1900px) {
      margin-left: -310px;
    }

    @media (max-width: 900px) {
      margin-left: -59px;
      max-width: calc(100vw + 20px);
      margin-bottom: 10px;
      width: calc(100vw + 20px);
    }
  }

  .image .caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 20px 0;
    clear: both;
  }

  .image-multiple {
    display: block;
    margin: 50px auto;

    .caption {
      padding-top: 40px;
    }

    a {
      width: 54%;
      display: block;
      text-decoration: none;
      border-bottom: none !important;

      &.collection {
        margin: 0 !important;
        float: none !important;
      }

      // @media (max-width: 600px) {
      //   pointer-events: none;
      // }

      &:focus {
        outline: none;
      }
      
      img {

        transition: all 0.3s ease;
      }

      &:hover img {
        transform: scale(1.05);
      }

      img {
        width: 100%;
        height: auto;
        max-width: 500px;
      }

      &.guerre-image-popup {
        @media (max-width: 900px) {
          margin-bottom: 30px;
        }
      }
    }

    a:nth-child(even) {
      float: right;
      margin-right: 200px;

      @media (max-width: 900px) {
        margin-bottom: 20px;
        margin-right: 50px;
      }
    }

    a:nth-child(odd) {
      margin-left: 30px;
      float: left;

      @media (max-width: 900px) {
        margin-left: 40px;
      }
    }

    a:not(:first-child) {
      margin-top: -100px;
      position: relative;
      z-index: 5;

      @media (max-width:900px) {
        margin-top: -50px;
      }
    }
  }

  .video-widget,
  .timeline-audio-player,
  .date-box,
  .quote {
    position: relative;

    .icon {
      position: absolute;
      left: -120px;
      width: 88px;
      height: 88px;
      top: calc(50% - 44px);

      @media (max-width:900px) {
        left: -50px;
        width: 44px;
        height: 68px;
        top: 15px;
      }

      img {
        position: relative;
        z-index: 10;
      }

      &:before,
      &:after {
        content: '';
        width: 12px;
        height: 18px;
        position: absolute;
        left: 38px;
        background-image: url(/sites/all/themes/versailles/images/timeline/line-mask.svg);
        background-size: 100% 100%;
      }

      &:before {
        top: -14px;
        transform: rotate(180deg);
      }

      &:after {
        bottom: -14px;
      }
    }
  }

  .timeline-audio-player {
    border: 1px solid #e6e6e6;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 0;

    .image {
      width: 100px;
      min-width: 100px;
      margin-right: 24px;

      @media (max-width:900px) {
        display: none;
      }
    }

    .player {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .left {
        width: 100%;
        margin-right: 30px;
      }
    }

    .title {
      font-family: 'Apollon';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    .timeline-player__progress {
      background: #e6e6e6;
      width: 100%;
      height: 4px;
      position: relative;
      margin-top: 20px;

      span {
        background: #ba9e66;
        width: 0;
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .timeline-player__play {
      width: 56px;
      height: 56px;
      border-radius: 56px;
      background: #f1efea;
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      transition: all 0.3s ease;

      &:focus,
      &:hover {
        background: #b8b8b8;
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 0 9px 14px;
        border-color: transparent transparent transparent #ba9e66;
        display: block;
      }
    }

    .timeline-player__pause {
      width: 56px;
      height: 56px;
      border-radius: 56px;
      background: #f1efea;
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: none;
      display: none;
      transition: all 0.3s ease;

      &:focus,
      &:hover {
        background: #b8b8b8;
      }

      &:after,
      &:before {
        content: '';
        width: 6px;
        height: 20px;
        display: block;
        background: #ba9e66;
      }
    }
  }

  .video-widget {
    margin: 100px 0;

    @media (max-width: 900px) {
      margin: 50px 0;
    }

    .video-bottom-text {
      font-weight: 400;
      font-size: 19px;
      line-height: 32px;
      color: #ba9e66;
      margin: 20px 0 50px;

      @media (max-width: 900px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .title-container {
      position: relative;

      .title {
        font-family: 'Apollon';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #ba9e66;

        @media (max-width: 900px) {
          position: relative;
          top: 11px;
          font-size: 22px;
          left: 10px;
          line-height: 32px;
        }
      }
    }

    .video-black-bg {
      background: black;
      width: 100vw;
      max-width: 100vw;
      min-height: 200px;
      position: relative;
      overflow: hidden;
      margin-top: 35px;

      @media (min-width: 1901px) {
        margin-left: calc(-50vw + 540px);
        margin-right: calc(-50vw + 745px);
      }

      @media (max-width: 1900px) {
        margin-left: -310px;
      }

      @media (max-width: 900px) {
        margin-left: -60px;
        max-height: 245px;
      }

      .videos-container {
        padding: 100px 0;
        position: relative;
        margin: 20px 0;
        display: flex;
        margin-left: -20%;

        @media (max-width: 900px) {
          padding: 0;
          transform: none !important;
        }

        &:before {
          content: '';
          position: absolute;
          width: 200%;
          height: 40px;
          background-image: url(/sites/all/themes/versailles/images/timeline/video-widget/video_pattern.png);
          background-position: center;
          top: 20px;

          @media (max-width: 900px) {
            height: 11px;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 200%;
          height: 40px;
          background-image: url(/sites/all/themes/versailles/images/timeline/video-widget/video_pattern.png);
          background-position: center;
          bottom: 20px;

          @media (max-width: 900px) {
            height: 11px;
          }
        }

        .video-container,
        .video-placeholder {
          min-width: 927px;
          height: 521px;
          border: 1px solid #666666;
          display: block;
          margin: 0 40px;

          @media (max-width: 900px) {
            height: 205px;
            max-width: 100%;
            width: 100vw;
            min-width: 100%;
          }
        }

        .video-placeholder {

          @media (max-width: 900px) {
            display: none;
          }
        }
      }
    }
  }

  .ui-anecdote {
    max-width: 961px;
    margin: 100px auto 0;
    padding: 40px 50px;

    @media (max-width: 900px) {
      max-width: 100%;
      margin: 50px 20px 0;
    }
  }
}

.century-content {
  @media (max-width: 1800px) {
    max-width: calc(100% - 200px);
  }

  @media (max-width: 900px) {
    max-width: calc(100% - 20px);
  }
}

.century-container:last-of-type .year-container:last-of-type .year-container:last-of-type:after {
  content: '';
  height: 56px;
  width: 8px;
  background-image: url(/sites/all/themes/versailles/images/timeline/bar_end.svg);
  background-position: center;
  position: absolute;
  bottom: 35px;
}

.node-type-long-read-seconde-guerre {
  .red {
    color: red !important
  }

  .smartbanner {
    display: none;
  }

  .view-widget-presse .container {
    margin-top: 100px;
    max-width: 1060px;

    @media (max-width: 900px) {
      margin-top: 0;
      padding-top: 20px;
    }

    .widget-multiple-video-player.widget-videos {
      padding-left: 0;
      margin-left: 0 !important;
      max-width: 100% !important;

      .iframe-container {
        padding-top: 0;
      }

      .main-cover-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        cursor: pointer;

        img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
        }

        &:before {
          content: '';
          width: 56px;
          height: 56px;
          background-image: url(/sites/all/themes/versailles/images/timeline/video-widget/play.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: block;
          position: absolute;
          left: calc(50% - 28px);
          top: calc(50% - 28px);
          transition: all 0.3s ease;
        }

        &:focus,
        &:hover {
          &:before {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  #page-footer {
    @media (max-width:900px) {
      .share-print-size {
        display: block;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        margin-bottom: 2.5rem;
      }
    }
  }
}

.mosaique-guerre {
  background: #f1efea;
  padding: 100px 0;

  @media (max-width:900px) {
    padding: 50px 0;
  }

  .show-more {
    display: table;
    margin: 30px auto;
    border: 1px solid #ba9e66;
    padding: 10px 40px;

    &:focus,
    &:hover {
      color: #f1efea;
      background: #ba9e66;
    }
  }

  h2 {
    text-align: Center;
    margin-bottom: 20px;
  }

  .grid-container {
    max-width: 930px;
    margin: 0 auto;
  }

  .photo,
  .sizer {
    width: 282px;
    max-width: 282px;
  }

  .photo {
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    @media (max-width: 900px) {
      position: unset !important;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: calc(100% - 60px);

      img {
        width: 100%;
      }
    }
  }

  .photo .cover {
    position: absolute;
    display: none;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding: 24px;
    color: white;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    a {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      margin-top: 10px;
    }

    .date {
      font-family: 'Crimson Text';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #979797;
    }

    .title {
      font-family: 'Apollon';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      margin-top: 5px;
    }
  }

  .photo:hover .cover {
    display: flex;
  }
}

.sticky-left-buttons {
  position: fixed;
  left: 5px;
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: calc(50% - 140px);
  height: 0;
  max-width: 300px;
  z-index: 50;

  @media (max-width: 900px) {
    flex-direction: row;
    bottom: 0;
    top: unset;
    background-color: #f1efea;
    max-width: 100%;
    left: 0;
    height: auto;
    padding: 15px 5px 15px 5px;
    width: 100%;
    justify-content: space-around;
  }

  .sticky-button {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border: none !important;

    @media (max-width: 900px) {
      margin-bottom: 0;
      position: relative;
      flex-direction: column;
      width: 70px;
      text-align: center;
    }

    span {
      opacity: 0;
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      transition: all 0.5s ease;
      background: white;
      padding: 5px 10px;
      border-radius: 15px;

      @media (max-width: 900px) {
        margin: 10px 0 0 0;
        font-size: 12px;
        opacity: 1;
        padding: 0;
        line-height: 16px;
        background: 0 0;
        border-radius: unset;
      }
    }

    &:focus,
    &:hover {
      span {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 600px) {
  .select-wrapper {
    z-index: 20;

    .selectric {
      // font-family: 'Apollon';
      font-style: normal; 
      font-weight: 400;
      font-size: 37px;
      line-height: 100%;
      color: #ba9e66;
      width: 130px;
    }
  }
}

.visuels-popup {
  .text {
    padding-left: 40px;
  }

  .subtitle {
    font-family: 'Apollon';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
  }

  .title {
    font-family: 'Crimson Text';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #979797;
    margin-top: 20px;
  }
}

.biblio {
  padding: 80px 20px;
  max-width: 1100px;
  margin: 0 auto;
  @media(max-width:1100px) {
    max-width: 100%;
  }
 
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 8px;
    border-bottom: none;
    padding-bottom: 24px;

    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }

  .accordeon-wrapper {
    .accordeon {
      border-bottom: 1px solid grey;

      .accordeon-content {
        max-height: 0;
        overflow: hidden;
        transition: .2s;

      }

      a {

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 0;
        font-family: 'Crimson Text';
        text-transform: unset;
        border-top: 1px solid grey;
        // border-bottom: 1px solid grey;
        border-bottom: none;
        padding: 32px 0;
        position: relative;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 14px;
          height: 2px;
          background-color: #323232;
          right: 0;
          top: 46px;
        }

        &:after {
          transform: rotate(90deg);
          transition: .1s;
        }

        &.open {
          border-bottom: none;

          &:after {
            transform: rotate(0deg);
            transition: .1s;
          }

          &+.accordeon-content {
            transition: .2s;
            max-height: 3000px;
            overflow: hidden;
          }
        }

      }

      &+.accordeon h3 {
        border-top: none;
      }

    }

    &+h2 {
      margin-top: 80px;

      @media (max-width:900px) {
        margin-top: 30px;
      }
    }
  }

  h2.media {
    margin-top: 80px;

    @media (max-width:900px) {
      margin-top: 30px;
    }
  }

  .quotidien {
    max-width: 250px;
  }
}

.podcasts-wrapper {
  max-width: 926px;
  margin: 60px auto 100px;
  padding-top: 40px;

  @media (max-width: 966px) {
    max-width: calc(100vw - 40px);
    margin: 30px auto 30px;
    padding-top: 20px;
  }
}

.iframe-content {
  position: relative;

  .cookieConsentPopin {
    height: 100%;
  }
}

.guerre-image-popup {
  .mfp-container {
    display: flex;
    align-items: center;
  }

  .mfp-content {
    background: #f1efea;
    width: 90vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .mfp-close {
      font-size: 40px;
      color: #BA9E66;
      border: 1px solid #ba9e66;
      width: 50px;
      left: 40px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: calc(50% - 50px);
      padding: 0;

      @media (max-width:900px) {
        left: auto;
        bottom: auto;
        top: 10px;
        right: 10px;
      }

      &:focus,
      &:hover {
        background: #ba9e66;
        color: #FFF;
      }
    }
  }
}




.guerre-visuels-popup {
&.mfp-wrap {
  max-height: 100vh;
  position: fixed !important;
  top: 0 !important;
}
  .mfp-container {
    display: flex;
    align-items: center;
    @media(max-width:900px), (orientation: portrait) {
      padding-bottom:80px;
      padding-top:80px;
    }
  }

  .mfp-content {
    background: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .mfp-close {
      font-size: 40px;
      color: #BA9E66;
      border: 1px solid #ba9e66;
      width: 50px;
      left: 40px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: calc(50% - 50px);
      padding: 0;
      margin: 0;
      opacity: 1;

      @media (max-width:900px), (orientation: portrait) {
        left: auto;
        bottom: auto;
        top: 10px;
        right: 20px;
      }

      &:focus,
      &:hover {
        background: #ba9e66 !important;
        color: #fff !important;
      }
    }

    .slick-slider {
      max-width: 100vw;
    }

    .popup-content {
      @media(min-width:900px) and (orientation: landscape) {
        display: flex !important;
      }
    }

    .slick-popup button.slick-arrow.slick-next {
      left: auto;
      right: 40px;
      position: absolute;
      top: calc(50% - 25px);
      width: 50px;
      height: 50px;
      background: transparent;
      color: white;
      border: 1px solid #ba9e66;
      transition: unset;

      @media (max-width:900px), (orientation: portrait) {
        right: auto;
        left: 80px;
        top: 35px;
          bottom: auto;
      }

      &:hover {
        background: #ba9e66;

        i {
          color: #FFF;
        }
      }
    }

    .slick-popup button.slick-arrow.slick-prev {
      left: auto;
      right: 90px;
      z-index: 100000;
      position: absolute;
      width: 50px;
      height: 50px;
      top: calc(50% - 25px);
      background: transparent;
      color: white;
      border: 1px solid #ba9e66;
      transition: unset;

      @media (max-width:900px), (orientation: portrait) {
        left: 20px;
        right: auto;
        top: 35px;
          bottom: auto;
      }

      &:hover {
        background: #ba9e66;

        i {
          color: #FFF;
        }
      }
    }
  }

  &.beige-mode {
    .mfp-content {
      background: #f1efea;

      .mfp-close {
        color: #BA9E66;
        background: url(../images/mpf-close.svg) no-repeat center center !important;
        color: transparent !important;

        &:focus,
        &:hover {
          background: #ba9e66 !important;
          color: #fff !important;
        }
      }

      .slick-popup button.slick-arrow.slick-next,
      .slick-popup button.slick-arrow.slick-prev {
        color: #ba9e66;
        border-color: #ba9e66;
        transition: unset;
      }

      .slick-popup button.slick-arrow.slick-next {
        @media (max-width:900px), (orientation: portrait) {
          right: 20px;
          left: auto;
          top: auto;
          bottom: 40px;
        }
      }

      .slick-popup button.slick-arrow.slick-prev {
        @media (max-width:900px), (orientation: portrait) {
          left: 20px;
          right: auto;
          top: auto;
          bottom: 40px;
        }
      }
    }
  }
}

.guerre-visuels-popup-slider {
  .slick-slide {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 990px), (orientation: portrait) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100vh;
      overflow: auto;
      padding-top: 80px;
    }

    &>div {
      @media (max-width: 990px), (orientation: portrait) {
      width:100%;
      }
    }

    .element {
      display: flex !important;
      align-items: flex-start;

      @media (max-width: 990px), (orientation: portrait) {
        flex-direction: column;
        align-items:center;
      }

    }

    .image-container {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      @media (max-width: 990px), (orientation: portrait) {
        max-width:500px;
      }
    }

    img {
      width: 453px;
      height: auto !important;
      max-height: calc(min(100vh - 80px, 800px));
      object-fit: contain;
      align-self: center;
      @media(min-width:1400px) {
        width:600px;
      }
      @media (max-width: 1100px) { 
        width: 380px;
      }

      @media (max-width: 990px), (orientation: portrait) {
        width: calc(100% - 40px);
        margin: 0 20px;
        // max-height: 50vh;
        object-fit: contain;
      }
    }

    .text {
      width: 453px;
      padding-left: 100px;
      max-height: calc(100vh - 40px);
      overflow: auto;
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
      @media(min-width:1400px) {
        width:600px;
      }
      @media (max-width: 1100px) {
        width: 380px;
        padding-left: 50px;
      }

      @media (max-width: 990px), (orientation: portrait) {
        padding-left: 20px !important;
        padding-right: 60px;
        margin-top: 30px;
        width: unset;
        max-width:500px;
      }

      @media (max-width:900px) {
        width: auto;
        padding: 0 20px !important;
      }

      .title {
        color: white;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Apollon', Arial, sans-serif;
        // text-transform: uppercase;
      }

      .subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        font-family: 'Crimson Text';
        color: #979797;
      }

      p {
        font-family: 'Graphik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: white;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.beige-mode {
    .slick-slide {
      .title {
        color: black;
      }

      .subtitle {
        color: #979797;
      }

      p {
        color: black;
      }
    }
  }
}


.big-photo-container {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: all .3s ease;
  background: #f1efea;
  z-index: 99999999;

  &.active {
    opacity: 1 !important;
    pointer-events: auto;
    position: fixed !important;

    .close-photo-button {
      @media(max-width:900px) {
        top: 25%;
        left: 20px;
      }
    }
  }

  img {
    max-width: 90%;
    max-height: 90%;

  }
}

.expand-slider-photo {
  img {
    cursor: zoom-in;
  }
}

.close-photo-button {
  border: 1px solid #ba9e66;
  width: 50px;
  left: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50% - 25px);
  padding: 0;
  transition: background .3s ease;
  color: #000;
  font-size: 50px;
  margin-top: 10px;
  position: absolute;
  left: 40px;
  cursor: pointer;
}

.illustration.full-width.fixed {
  @media(max-width:600px) {
    position: fixed;
    top: 30px;
    background: white; 
    z-index: 22;
  }
}

.logged-in.node-type-long-read-seconde-guerre {
  header#header {
    top: 29px;
  }
}

.node-type-long-read-seconde-guerre .theme-people .slick__slide figure img {
  height: 480px !important;
  width: auto;

  @media(max-width:900px) {
    height: 400px !important;
  }
}

.node-type-long-read-seconde-guerre .scroll-to-top {
  position: fixed;

  @media(max-width:900px) {
    bottom: 105px;
    right: 0;
    margin: 0;
    height: 50px;
    width: 50px;
  }
}

.i18n-en.node-type-long-read-seconde-guerre .scroll-to-top {
  position: fixed;

  @media(max-width:900px) {
    bottom: 120px;
    right: 0;
    margin: 0;
    height: 50px;
    width: 50px;
  }
}

#protagonistes {
  overflow: hidden;
}

.tac_activate.tac_activate_ausha {
  opacity: 0;
}

.node-type-long-read-le-notre{
  .guerre-visuels-popup-slider{
    .slick-slide{
      .text{
        p{
          @media(max-width:900px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      } 
    } 
  }
}