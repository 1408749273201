.node.node-hub-espace-abonnes-2 {
  .top-info {
    @include rem(margin-top, 80px);
    .col-image {
      @include rem(margin-bottom, 50px);
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('../images/shanes-2.png') no-repeat center center;
      background-size: contain;
      @include desktop {
        margin: 0;
      }
    }
    .col-left, .col-right {
      padding-top: 0;
    }
    .col-left {
      h2 {
        @include rem(margin-bottom, 20px);
        @include font-size-rem-adapt(20px);
        @include line-height-adapt(25px);
        line-height: 1;
      }
      p {
        padding: 0 !important;
        @include rem(margin-bottom, 25px);
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(25px);
        &.text--gold {
          @include rem(margin-bottom, 35px);
          font-family: $base-font-family;
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(30px);
        }
      }
      ul {
        li {
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
        }
      }
      .view-colonne {
        margin: 0;
        padding: 0;
        .container {
          margin: 0;
          width: 100%;
          .first {
            @include desktop {
              padding-left: 0;
            }
          }
          .last {
            @include desktop {
              padding-right: 0;
              text-align: right;
            }
          }
        }
      }
    }
    .col-right {
      border: none;
      h2 {
        padding: 0;
        @include rem(margin-bottom, 25px);
        font-family: $base-font-family;
        color: #000;
        @include font-size-rem-adapt(18px);
        line-height: 1;
        text-transform: uppercase;
      }
      p {
        @include rem(margin-bottom, 25px);
        color: #9B9B9B;
        @include font-size-rem-adapt(16px);
        @include line-height-adapt(25px);
      }
      .btn {
        &:before {
          border-color: $color-primary;
        }
      }
    }
  }

  .view.view-display-id-avantages {
    background: url('../images/abonner-bg.jpg') no-repeat center bottom #BA9E66;
    & > .container {
      @include desktop {
        @include rem(padding, 39px 0 77px);
      }
    }
    .view-header {
      @include rem(padding-bottom, 30px);
      h3.title {
        color: #fff;
        text-align: left !important;
      }
    }
    .view-content {
      .col-xs-12 {
        @include rem(margin-bottom, 40px);
        @include desktop {
          margin-bottom: 0;
        }
      }
      .outer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @include rem(padding, 30px 20px 80px);
        background: #fff;
        text-align: center;
        @media (max-width:700px) {
          text-align: left;
          @include rem(padding, 30px 20px 60px);
        }

        h4 {
          flex: 1 0 100%;
          @include rem(margin, 0 0 20px);
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(32px);
        }
        p {
          @include rem(margin, 0 0 10px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(29px);
        }
        a {
          position: absolute;
          @include rem((left: 20px, right: 20px, bottom: 30px));
          @include font-size-rem-adapt(20px);
          line-height: 1;
          text-decoration: underline;
          @media (max-width:700px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .view.view-display-id-prog_mois_5, .view.view-display-id-prog_mois_4, .view.view-display-id-prog_mois_3, .view.view-display-id-prog_mois_2 {
    & > .container {
      @include rem(padding-top, 75px);
    }
    .view-header {
      p.text--gold {
        margin: 0 auto;
        @include rem(max-width, 700px);
        color: $color-primary;
        font-family: $font-crimson;
        @include font-size-rem-adapt(24px);
        @include line-height-adapt(34px);
        text-align: center;
      }
    }
    .attachment {
      .view.view-id-espace_abonnes {
        & > .container {
          padding: 0;
        }
        .view-content {
          position: relative;
          @include rem(margin-bottom, 40px);
          overflow: hidden;
          img {
            max-width: none;
            @include rem(min-height, 320px);
            @include tablet {
              max-width: 100%;
              min-height: none;
            }
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0.6;
            background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
            pointer-events: none;
          }
          .text--wrapper {
            position: absolute;
            left: 0;
            width: 100%;
            color: #fff;
            @include rem(padding, 0 30px);
            @include rem(bottom, 70px);
            text-align: center;
            z-index: 4;
            a {
              color: #fff;
            }
            p {
              margin: 0;
              @include font-size-rem-adapt(20px);
              @include line-height-adapt(24px);
              text-transform: uppercase;
            }
            h3 {
              @include rem(margin, 20px 0 0);
              @include font-size-rem-adapt(36px);
              @include line-height-adapt(42px);
            }
          }
        }
      }
    }

    .view-content {
      .outer {
        @include tablet {
          margin-bottom: 2.5rem;
        }
        @include desktop {
          margin-bottom: 0;
        }
        img {
          width: 100%;
        }
        a {
          display: block;
          color: #333333;
          @include rem(margin-bottom, 15px);
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(22px);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .view.view-display-id-prog_mois_5 {
    .view-footer {
      @include rem(margin-top, 30px);
      @include rem(padding, 30px 0 30px 0);
      background: #F1EFEA;
      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        margin: 20px auto;
        text-align: left;
        @include tablet {
          margin: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -ms-flex-preferred-size: 33.333%;
          flex-basis: 33.333%;
          max-width: 33.333%;
        }
        &.text--gold {
          @include rem(padding-left, 40px);
          color: $color-primary;
          font-family: $font-crimson;
          @include font-size-rem-adapt(24px);
          @include line-height-adapt(30px);
        }
        &:nth-child(2) {
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);
          @include tablet {
            @include rem(padding, 0 0 0 40px);
          }
        }
        &:last-child {
          text-align: right;
          @include tablet {
            @include rem(padding-right, 70px);
          }
        }
      }
      .btn {
        &:before {
          border-color: $color-primary;
        }
      }
    }
  }

  .view.view-display-id-prog_mois_4 {
    .view-footer {
      margin: 0;
      .inner {
        display: flex;
        align-items: flex-end;
        @include rem(padding, 20px);
        height: 100%;
        background: #F0EFEA;
        p {
          margin: 0;
          @include rem(margin-bottom, 50px);
          color: #4A4A4A;
          @include font-size-rem-adapt(18px);
          @include line-height-adapt(29px);
          &:last-child {
            margin: 0;
          }
        }
        .btn {
          display: block;
          color: #fff;
          background: $color-primary;
          &:before {
            border-color: $color-primary;
          }
        }
      }
    }
  }

  .view.view-display-id-block_full .ui-header {
    @include rem(padding, 105px 30px);
    @include rem(min-height, 430px);
    .container {
      @include rem(max-width, 900px);
    }
  }
  .ui-header p.subtitle {
    color: #fff;
  }

  .view.ui-news p.cat {
    color: $color-primary;
  }

  .view.view-hub-actualites .view-header, .view.view-hub-expositions .view-header, .view.view-hub-spectacles .view-header {
    @include rem(padding-bottom, 50px);
  }

  .view.view-id-push_image .outer {
    background: none;
    text-align: center;
    .inner {
      @include rem(padding, 30px 30px 45px);
      p {
        color: #333333;
        @include rem(margin, 10px 0 30px);
      }
      .btn {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        color: #333333;
        .ext {
          display: none;
        }
        &:before {
          background: none;
        }
      }
    }
  }
}
