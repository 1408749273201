.node.node-collections {
  .text-content {
    @include rem(padding, 60px 0);

    @include desktop {
      @include rem(padding, 90px 0);
    }

    @media (max-width:700px) {
      @include rem(padding, 40px 0);
    }

    &>h2 {
      padding: 0;
      margin: 0;
      @include rem(max-width, 530px);
      @include font-size-adapt(20px, 42px);
      @include line-height-adapt(25px, 52px);
      border: none;

    }

    p.text--lead {
      padding-bottom: 0;
      @include rem(margin-top, 10px);
      border: none;

      @include desktop {
        @include rem(margin-top, 30px);
      }
    }
  }

  .view.view-collections {
    &>.container {
      @include rem(padding-bottom, 60px);

      @include desktop {
        @include rem(padding-bottom, 100px);
      }
    }

    .view-header {
      @include rem(padding-bottom, 30px);

      h3.title {
        margin: 0;
      }
    }

    .view-filters {
      .nav-bar {
        padding: 0;

        .views-exposed-widget {
          float: none;
          width: 100%;
          padding: 0;
          text-align: center;
        }
      }
    }

    .view-header {

      @media (max-width:700px) {
        padding-top: 0;
        padding-bottom: 20px;
      }
    }
    .filters {

      @media (max-width:700px) {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 10px;
      }

      button {

        @media (max-width:700px) {
          font-size: 14px;
          padding:0;
          padding-bottom: 1px;
          border-width: 1px;
          margin-left: 0;
          margin-right: 0;
        }

        &.active,
        &:focus, &:hover {

          @media (max-width:700px) {
            padding-bottom: 1px;
            border-width: 1px;
          }
        }
      }
    }
  }
}
