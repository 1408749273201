.node.node-visite-section {
  .main-content.with-nav {
    @include desktop {
      // @include rem(padding-top, 81px);
      padding-top: 0;
    }
  }

  .page-nav {
    text-align: right;
    @include rem(padding, 30px 0);

    @include desktop {
      @include rem(padding, 15px 0);
    }
  }

  .top-info {
    .col-right {
      .share-print-size {
        @include desktop {
          position: relative;
          display: block;
          @include rem(margin-bottom, 30px);
          padding-left: 0;
          right: auto;
          @include clearfix;
        }
      }
    }
  }

  .view.view-billets-tarifs {
    &>.container {
      padding-top: 0;

      @media (max-width:700px) {
        padding: 0 20px 20px 20px !important;
      }
    }

    .nav-bar .form-type-bef-link {
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .view-categories.view-display-id-block_services {
    .container {
      border-top: 1px solid #E6E6E6;

      .view-header {
        h3 {
          text-align: left;
        }
      }
    }
  }

  .field-collection-view {
    .full-width {
      @include rem(padding, 0 0 40px);
      border-bottom: 1px solid #E6E6E6;

      @include desktop {
        @include rem(padding, 0 0 50px);
      }
    }

    .ui-header {
      @include rem(margin, 0 -30px 40px);
      @include rem(padding, 0 30px);

      @include desktop {
        min-height: 444px;
      }

      @include tablet {
        margin: 0;
        padding: 0;
        height: 100%;
      }

      @media (max-widtH:700px) {
        min-height: 375px
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        transition: all 0.3s;
        z-index: 1;
      }

      &:focus, &:hover {
        &:before {
          opacity: 0.4;
        }
      }

      &>div {
        position: relative;
        z-index: 2;
      }

      a {
        margin: 0;

        @media (max-width:700px) {
          background: #000;
        }
      }
    }

    .inner {
      h4 {
        @include rem(margin, 0 0 20px);
        @include font-size-adapt(20px, 24px);
        @include line-height-adapt(20px, 24px);
        text-transform: lowercase;
        text-transform: none;
      }

      .field-item {
        @include rem(margin, 0 0 10px);
      }

      a {
        display: inline;
        color: #0F0F0F;
        @include font-size(16px);
        line-height: 1.6em;
        border-bottom: 1px solid #d8d8d8;

        &:focus, &:hover {
          color: $color-primary;
          border-color: $color-primary;
        }
      }
    }
  }
}

.node-type-visite-section .view-billets-tarifs.view-display-id-page_groupe .views-exposed-form .form-radios {
  display: none
}

// DATE PICKER POSITION

.node-type-visite-section{
  &.page-node-655,
  &.page-node-656,
  &.page-node-1162,
  &.page-node-1181{
    .text-content{
      &.col-xs-12{
        padding: 30px 0;
        @media (max-width:1100px) {
          padding: 20px;
        }
        p{
          font-size: 18px;
          line-height: 20px;
          margin: 0 0 20px 0;
          &:last-of-type{
            margin: 0;
          }
          @media (max-width: 700px) {
            font-size: 14px;
            line-height: 24px;
            margin: 0 0 10px 0;
            text-align: left !important;
            span,
            a{
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .page-nav{
      .menu{
        @media (max-width:1100px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }

    .main-content{

      >.full-width{
        @media (max-width: 700px) {
          margin: 0;
          left: 0;
        }
      }

      .view-alerte{
        border-top: 1px solid #ba9e66;
        border-bottom: 1px solid #ba9e66;
        .views-row{
          margin-bottom: 0 !important;
        }
      }

      .view-billets-tarifs {
        .view-filters{
          .date{
            display:none !important;
            // position: absolute !important;
            // margin: 0;
            // z-index: 100;
            // width: 530px;
            // height: 66px;
            // bottom: 0;
            // margin-left: auto;
            // margin-right: auto;
            // right: 0;
            // left: 0;
            // @media (max-width: 700px) {
            //   z-index: 5;
            //   left: 20px;
            //   width: calc(100% - 100px);
            //   margin: 0;
            // }
          }
        }
      }

      &.no-content{
        &.no-alert{
          .view-billets-tarifs {
            .view-filters{
              .date{
                bottom: 300px;
                @media (max-width: 700px) {
                  bottom: 200px;
                }
              }
            }
          }
        }
      }

      &.has-alert{
        &.has-content{
          .view-billets-tarifs {
            .view-filters{
              .date{
                bottom: 500px;
                @media (max-width: 1100px) {
                  bottom: 600px;
                }
                @media (max-width: 700px) {
                  bottom: 400px;
                }
              }
            }
          }
        }
      }
      &.has-content{
        .view-billets-tarifs {
          .view-filters{
            .date{
              bottom: 400px;
              @media (max-width: 700px) {
                bottom: 300px;
              }
            }
          }
        }
      }
      &.has-alert{
        .view-billets-tarifs {
          .view-filters{
            .date{
              bottom: 400px;
              @media (max-width: 700px) {
                bottom: 300px;
              }
            }
          }
        }
      }
    }
  }
}