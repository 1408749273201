/*------------------------------------*\
	TOOLTIP.CSS
\*------------------------------------*/
/* This is the core CSS of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
	/* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
	display: flex;
	pointer-events: none;
	/* this may be overriden in JS for fixed position origins */
	position: absolute;
}

.tooltipster-box {
	/* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
	flex: 1 1 auto;
	width: 100%;
	.text {
		display: block;
		text-align: left;
		font-size: 13px;
		font-family: 'Graphik';
		line-height: 1.2;
	}
}

.tooltipster-content {
	/* prevents an overflow if the user adds padding to the div */
	box-sizing: border-box;
	/* these make sure we'll be able to detect any overflow */
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
}

.tooltipster-ruler {
	/* these let us test the size of the tooltip without overflowing the window */
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
	opacity: 0;
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7,0.3,1);
	transform: scale3d(0.7,0.3,1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
	&.tooltipster-left {
		transform-origin: 100% 50%;
		-webkit-transform: scale3d(0.7,0.3,1);
		transform: scale3d(0.7,0.3,1);
	}
}
.tooltipster-fade.tooltipster-show {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
	transform: translate3d(0,0,0) rotate3d(0,0,0,0);
}

/* grow */

.tooltipster-grow {
	-webkit-transform: scale(0,0);
	-moz-transform: scale(0,0);
	-o-transform: scale(0,0);
	-ms-transform: scale(0,0);
	transform: scale(0,0);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
	-webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
	-webkit-transform: scale(1,1);
	-moz-transform: scale(1,1);
	-o-transform: scale(1,1);
	-ms-transform: scale(1,1);
	transform: scale(1,1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
	opacity: 0;
	-webkit-transform: rotateZ(4deg);
	-moz-transform: rotateZ(4deg);
	-o-transform: rotateZ(4deg);
	-ms-transform: rotateZ(4deg);
	transform: rotateZ(4deg);
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-ms-transition-property: -ms-transform;
	transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
	opacity: 1;
	-webkit-transform: rotateZ(0deg);
	-moz-transform: rotateZ(0deg);
	-o-transform: rotateZ(0deg);
	-ms-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
	-webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	-o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
	transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
	-webkit-transition-property: top;
	-moz-transition-property: top;
	-o-transition-property: top;
	-ms-transition-property: top;
	transition-property: top;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
	top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	top: 0 !important;
	opacity: 0;
}

/* slide */

.tooltipster-slide {
	-webkit-transition-property: left;
	-moz-transition-property: left;
	-o-transition-property: left;
	-ms-transition-property: left;
	transition-property: left;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
	left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
	-webkit-transition-property: all;
	-moz-transition-property: all;
	-o-transition-property: all;
	-ms-transition-property: all;
	transition-property: all;
	left: 0 !important;
	opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tooltipster-update-fade {
	animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
	25% {
		transform: rotate(-2deg);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0);
	}
}

.tooltipster-update-rotate {
	animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.tooltipster-update-scale {
	animation: tooltipster-scaling 600ms;
}



.tooltip:not(.text-top) {
	position: relative;
	display: inline;
	color: inherit !important;
	cursor: help;
  text-decoration: none;
	@include rem(padding-bottom, 2px);
	border-bottom: 1px dotted $color-primary;
	// background:
	// // // make the underline dashed
	// repeating-linear-gradient(to right,
	// 	rgba(255,255,255,0),
	// 	rgba(255,255,255,0) 1px,
	// 	rgba(255,255,255,1) 1px,
	// 	rgba(255,255,255,1) 2px
	// ),
	// // // draw the underline, 2px and red
	// linear-gradient(to top,
	// 	rgba($color-primary, 0) 1px,
	// 	rgba($color-primary, 1) 1px,
	// 	rgba($color-primary, 1) 2px,
	// 	rgba($color-primary, 0) 2px
	// );
	&:focus, &:hover {
		color: inherit;
		border-bottom: 1px dotted $color-primary;
	}
}

.tooltip-theme {
	min-width: rem(260px);
	max-width: rem(460px);
	width: auto;
	border-radius: none;
	background: #E6E6E6;
	color: #333333;
	text-align: center;
	&:before {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -9px;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 9px 0 9px;
		border-color: #E6E6E6 transparent transparent transparent;
	}
	&.tooltipster-left {
		&:before {
			top: 50%;
			left: 100%;
			margin: 0;
			margin-top: -9px;
			border-width: 9px 0 9px 9px;
			border-color: transparent transparent transparent #E6E6E6;
		}
	}
	a {
		@include rem(margin-top, 10px);
	}
}
/* Use this next selector to style things like font-size and line-height: */
.tooltip-theme .tooltipster-content {
	padding: rem(20px);
	font-family: 'Graphik' !important;;
	line-height: 1.2 !important; 
	font-size: 13px !important;
}

.tooltip-theme .tooltipster-content p {
	display: block;
	margin-bottom: rem(5px);
	text-align: left;
  font-size: 13px;
	font-family: 'Graphik';
	line-height: 1.2;
}

.tooltip-theme .tooltipster-content a { 
	color: $color-primary;
	// font-family: $base-font-family;
	font-family: 'Graphik';
  font-size: 13px;
  line-height: 1.2;
	// @include font-size(14px);
	// line-height: 1.2;
	border-bottom: 1px solid $color-primary;
}

.tooltip-theme .tooltipster-content .tooltip-more{
	text-align:left;
	margin-top:10px;
	a{
		display:inline-block;
	}
}

.tooltip-theme .tooltipster-content a:hover{
	color: #000;
}

.tooltip + .tooltip-content:not(.tooltipster-show) {
	position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  
}
