.node-type-long-read-lieu {
  #main {
    margin-top:70px;
  }
  .anchor-map {
    width:14.375rem;
    margin-top:1rem;
    transition:margin 0.1s linear;
    &.fixed {
      margin-top:2.5rem;
    }
  }

  .tooltipster-box {
      // max-width: 150px;
      // margin:0 auto;
  }

  .tooltipster-content{
    // text-align : center;
  }

  .tooltipster-content a {
      display:none;
  }

}

.node-long-read-lieu.longread-hameau,
.node-long-read-lieu.longread-maison-reine {

  .full-width{
    z-index: 11;
  }
  
  .switch{
    position: fixed;
    z-index: 10;
    background-color: #ba9e66;
    // background-image: url(/sites/all/themes/versailles/images/door-open-icon.svg);
    background-repeat: no-repeat;
    background-size: 65% 65%;
    background-position: center;
    right: 10%;
    width: 80px;
    height: 80px;
    bottom: 5%;
    transform: translateX(-10%);
    border-bottom: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
      // span{
        // display:none;
        // color: #ba9e66;
      // }
    // &:hover span{
      // display: block;
      // position: relative;
      // left: 50px;
      // width: 140px;
      // padding-left: 10px;
      // font-size: 14px;
    // }
    @media(max-width:1349px) {
      right: 5%;
    }
    @media(max-width:720px) {
      transform: translateX(-50%);
      bottom: 0%;
      width: 60px;
      height: 60px;
    }
  }

  .anchor-content .full-width {
      left: 43.5%;
      @media(max-width:720px) {
        left:50%;
      }
      .full-width {
        left:0;
      }
  }
  .text-content {


    &.side-hider {
      padding-left:0 !important;
      background: white;
      position: relative;
      z-index: 5;
      width:100%;
.intro, .view-id-widget_maquette.view-display-id-block_1 {
  max-width:850px;
  margin:0 auto;
}

.view-id-widget_maquette.view-display-id-block_1 {
  .ui-maquette .container {
    width:100%;
    margin-right: 0;
  }
  @media(max-width:720px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.svg-wrapper {
  margin-bottom:30px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
      width:1000px;
      height:648px;
    }
  }
}
    }




    @media(min-width:720px) {
    padding-left:100px;
      }
      .full-width {

        @media(min-width:720px) {
        transform:translateX(-40px);
      }
        .full-width {
          transform:none;
        }
      }
    & > p {

      line-height: 1.5em;
      max-width:665px;
      @media(max-width:600px) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    & > h2 {
      margin-right: 1.25rem;
      margin-left: 0;
      margin-bottom: 30px;
      padding-top:30px; 
      @media(max-width:600px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .lieu-header:before {
    display: block;
    height: 550px;
    height: 34.375rem;
    background: linear-gradient(-180deg,transparent 0,#000 200%);
    z-index: 1;
    @media(min-width:720px) {
      // display:none;
    }

  }

  & > header {
    padding: 14.375rem 0 12.5rem;
    height: auto;
    background-size: cover;
    background-attachment: scroll;
    z-index: 1;

    & > video {
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      object-fit: cover;
      transform: translateX(-50%) translateY(-50%);
    }

    #overlay {
      display: none;
    }

    &.longread__header > .container {
      padding: 0;
      background: 0 0;
      height: 270px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .row {
        width: 100%;
      }
      @media(max-width:720px) {
        background: transparent;
        padding: 0;
      }
    }

    .longread__header__title {
      max-width: 100%;
      font-size: 64px;
      margin: 0 auto 30px;
      @media(max-width:720px) {
        font-size: 30px;
        line-height: 50px;
      }
    }

    .buttons{
      margin-top: 50px;
      a{
      z-index: inherit;
      margin: 0 1.25rem;
        @media(max-width:720px) {
          max-width: 250px;
        }
      }
      // .btn:first-child {
        // color: #000;
        // &:before{
          // border-color: #fff;
          // background: #fff;
        // }
        // &:after{
          // color: #fff;
          // border-color: #000;
          // background: #000;
          // @media(max-width:720px) {
            // background: #FFF;
          // }
        // }

        // &:focus, &:hover {
          // color: #fff;
        // }
      // }
    }
  }

  .intro p {
    line-height: 1.5em;
  }

  .view-widget-maquette {
    & > .container {
      padding-top: 0 !important;
    }

    .container {
      padding-bottom: 60px;
      padding-top: 40px;
      @media(max-width:600px) {
        padding: 2.5rem 0 40px;

        .col-xs-12 {
          padding-left: 0;
          padding-right: 0;

          .container {
            padding: 0;
          }
        }
      }

      .ui-maquette .container {
        padding-bottom: 0;
        @media(min-width:1200px) {
          margin-right: -120px;
width: calc(100% + 120px);
position: relative;
        }
      }
    }

    .ui-maquette {
      &.no-padding {
        padding:0;
      }
      iframe {
        width: 100% !important;
        height: 450px;
        @media(max-width:600px) {
          height: 300px;
        }
      }

      .embed-iframe {
        position: relative;
        display: block;
        width: 100%;
        max-height: 450px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          lefT: 0;
          width: 100%;
          height: 100%;
          background-image: url("/sites/all/themes/versailles/images/widgets/iframe_placeholder_2.jpg");
          z-index: 100;
          display: block;
          opacity: 1;
          transition: opacity 0.3s linear;
          pointer-events: none;
          background-size:cover;
        }

        &.hide-placeholder {
          &:before {
            opacity: 0;
          }
        }
      }

      h3 {
        font-family: Graphik,serif;
        font-size: 19px;
        font-weight: 400;
        text-transform: none;
      }

      .audioplayer,
      audio {
      display: block;
        @media(min-width:600px) {
          margin-bottom:20px;
        }
      }

      p.audio-name {
        font-family: Graphik,serif;
        font-size: 15px;
        font-weight: 500;
        display: block;
      }

      .audioplayer {
        border-bottom: 1px solid #CCCCCC;
        padding-bottom:15px;
        .accordion__content {
          padding-bottom: 20px !important;
        }
      }

      .ui-accordion {
        @media(max-width:600px) {
          margin-top: 20px;
        }

        p.accordion__header {
          display: block;
          padding: 0;
          border: none;
          margin-bottom: 20px;
          font-family: Graphik,serif;
          font-weight: 400;
          font-size: 15px;
          color: #666666;

          &:focus, &:hover {
            background: transparent;
            color: #666666;
          }

          &:before {
            display: none;
          }

          &:after {
            border-color: #000000 transparent transparent transparent;
            border-width: 4px 4px 0 4px;
            right: 5px;
          }
        }

        .accordion__content {
          padding: 0;
          border: none;
          display: none;

          .to-show-more {
            overflow: hidden;
            height: 100%;
            transition: max-height 0.3s linear;
          }
        }
      }
    }
  }

  .view-widget-double-image {
    background-image: url("/sites/all/themes/versailles/images/widgets/sun.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f0efea;
    margin-bottom: 90px;
    margin-top: 80px;
    @media(max-width:600px) {
      background-size: 600px auto;
    }

    img {
      margin: 0 auto;
      display: block;
      padding-top: 20px;
    }

    .longreadArt__title {
      font-size: 19px;
      font-weight: 400;
      font-family: Graphik,serif;
      margin-top: 20px;
      color: #39393A;
      max-width: 420px;
      margin: 45px auto 10px;
      display: block;
    }

    .longreadArt__source {
      font-size: 14px;
      font-weight: 400;
      color: #979797;
      max-width: 420px;
      margin: 15px auto 10px;
      display: block;
    }

    .longreadArt__desc p {
      max-width: 420px;
      font-size: 17px;
      font-weight: 400;
      color: #39393A;
      line-height: 30px;
      margin: 0 auto;
      display: block;
      padding-bottom: 25px;
    }
  }

  .view-widget-carrousel {
    
    margin: 60px 0 0px !important;
    background:#f0efea !important;
    h2{
      font-family: 'Apollon',Arial,sans-serif;
      font-size: 24px;
      font-size: 1.5em;
      text-transform: lowercase;
      text-align: center !important;
      color: #000;
      @media(max-width:720px) {
        text-align: left !important;
      }
    }
    h3 {
      color:black;
      @media(max-width:720px) {
        text-align: left !important;
      }
    }

    .view-header {
      .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1100px;
        font-family: 'Apollon',Arial,sans-serif;
        font-weight: 400;
        font-size: 26px;
        font-size: 1.625em;
        line-height: 1.4em;
        @media(max-width:600px) {
          padding: 0;
        }
      }
    }

    .view-content {
      .views-field-nothing {
        width: 100%;
        margin: 0 20px;
        position: relative;
        @media(max-width:600px) {
          margin: 0 5px;
        }

        img {
          opacity:0.5;
          transition: opacity 0.3s linear;
        }
      }

      .slick-active .views-field-nothing img {
        opacity: 1;
      }

      figcaption {
        font-size:14px;
        font-family: Graphik,serif;
        font-weight:400;
        opacity: 0;
        transition: opacity 0.3s linear;
        @media(max-width:720px) {
          font-size:14px;
        }
        p {
          font-family: Graphik,serif;
          font-weight:400;
          color:#39393a;
        }
      }

      .slick-active figcaption {
        opacity: 1;
      }

      // figure img {
      //   @media(max-width:1500px) and (min-width:900px) {
      //     max-width:50%;
      //     margin:0 auto;
      //   }
      // }

      .slick-arrow {
        background: white;
        border-color: black;
        opacity: 1 !important;
        @media(max-width:600px) {
          top: 80px;
          opacity:0.6 !important;

        }

        i {
          color: black;
        }
      }
    }
  }

  .view-id-push_image.view-display-id-block_full {
margin-top: 100px;
  }

  .beneficie-block {
    background: #F1EFEA;

    .clickable-block {
      padding: 50px 0;
      color:black;

      img {
        max-width: 120px;
      }
    }
  }

  .voyage-player {
    padding: 0;

    audio {
      display: none !important;
    }

    .voyage-player__time {
      margin-right: 1.25rem;
    }

    .voyage-player__control {
      margin-left: 1.25rem;
    }
  }

  .voyage-player .voyage-player__control button.voyage-player__pause:after {
    right: auto;
    left: 10px;
  }
}

.voyage-player .voyage-player__tracks {
  display: flex;
  padding: 0;
  background: #BA9E66;
}

.voyage-player__next,
.voyage-player__prev {
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
  border-bottom: none !important;
  word-wrap: normal !important;
  word-break: normal !important;
  min-height: 50px;
}

.voyage-player__info {
  color: white;
  margin: 0;
  font-size: 14px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voyage-player__next {
  background-image: url("/sites/all/themes/versailles/images/widgets/next.svg");
  background-size: 12px;
  background-position: center left;
  background-repeat: no-repeat;
  width: 22px;
  height: 100%;
  overflow: hidden;
  text-indent: 25px;
  margin-left: 5px;
}

.voyage-player__prev {
  background-image: url("/sites/all/themes/versailles/images/widgets/next.svg");
  background-size: 12px;
  background-position: center left;
  background-repeat: no-repeat;
  width: 22px;
  height: 100%;
  overflow: hidden;
  text-indent: 25px;
  transform: rotate(180deg);
  margin-right: 5px;
}

.voyage-player__next.active,
.voyage-player__prev.active {
  opacity: 1;
  pointer-events: auto;
}

#PROCESSING tspan,
.maplink tspan {

  font-size:18px;
  font-family:"Apollon-Regular, Apollon";
  font-weight:normal;
  fill:#333333;
  text-transform:uppercase;
}
.anchor-content {
svg {
  width:100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
min-height:740px;
}
}

svg {
  @media(max-width:720px) {
    display:none;
  }
}
}

.maplink-link {
  cursor: pointer;
  @media(max-width:720px) {
    display: none !important;
  }
}

.view-module-sticky-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9 !important;
}

.view-module-sticky.view-id-module_sticky > .container {
  padding:0;
}

.view-module-sticky.view-id-module_sticky {
  padding-top: 70px;
  padding-bottom: 70px;
  max-width: 720px;

.desk {
  @media(max-width:720px) {
    display:none;
  }
}


  .block-header {
    line-height: 1.1;
    border-bottom: 1px solid #f1efea;
    margin-bottom:40px;
    letter-spacing: -1px;
    padding-bottom: 10px;
    @media(max-width:720px) {
      display:none;
    }
  }

  .desk .container {
    display:flex;
    align-items:flex-start;
    .text {
      h3 {
            margin-top: -7px;
        margin-bottom:40px;
        font-family: Graphik,serif;
        font-size:24px;
        text-transform:uppercase;
      }
      p {
        font-size:19px;
      }
      .btn.btn--primary {
        width:245px;
        margin-top:40px;
        text-align:center;
      }
    }
    img {
      margin-right: 25px;
max-width: 180px;
    }

  }
}

.view-module-sticky.view-id-module_sticky .mobile {
  display:none;
  @media(max-width:720px) {
      display:block;
      background-size:cover;
      background-position:center;
      background-repeat:no-repeat;
    .container {
      margin-top: 50px;
margin: 50px 20px 0;
width: calc(100% - 40px);
img {
  width:100%;
  height:auto;
}
      h2, h3 {
        font-size:16px;
        font-weight:400;
        text-transform:uppercase;
        font-family: Graphik,serif;
      }
      .btn-group {
        width: 100%;
        margin-top: 20px;
        a {
          color:#ba9e66 !important;
          width:100%;
          background:white;
          &:after {
            display:none;
          }
        }
      }

  }
  }
}

// .anchor-map .desk {
//   margin-top:50px;
//   h2 {
//     font-size:16px;
//     font-weight:400;
//     text-transform:uppercase;
//     font-family: Graphik,serif;
//   }
//   .btn-group {
//     width: 100%;
//     margin-top: 20px;
//     a {
//       color:#ba9e66 !important;
//       width:100%;
//       &:focus, &:hover {
//         color:white !important;
//       }
//     }
//   }
// }


.ticket.ui-billet.light {
  .billet {
    padding:0 10px;
    margin-top: 5px !important;
  }
    .btn-group {
      align-items:center;
      height: 40px;
      @media(max-width:720px) {
        margin-bottom:10px;
      }
    }
    .price {
      padding: 0;
      display: block;
      border: none !important;
      &:after, &:before {
        display:none;
      }
    }
    .btn {
      padding:0;
      min-height:0;
    }
    .text-top.tooltip {
      max-width: 20px;
    }
    .btn.btn--primary {
      background:transparent !important;
      color: #ba9e66;
      margin-left:10px;
      padding-left:15px;
      border-left:1px solid #979797 !important;
      overflow:visible;
      &:before, &:after {
        display:none;
      }
      .ext {
        background:transparent !important;
        color: #ba9e66;
      }
      &:focus, &:hover {
        color: #ba9e66 !important;
        .ext {
          color: #ba9e66 !important;
        }
      }
    }
  }
  
  
  
.node-long-read-lieu.longread-maison-reine{
  .embed-iframe {
    &:before {
      background-image: url("/sites/all/themes/versailles/images/widgets/VERSAILLES_02-min.jpg") !important;
    }
  }
  .svg-wrapper{
    margin: 60px 0px !important;
  }
}
