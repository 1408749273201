//.view-hub-actualites, .view-hub-expositions, view-hub-spectacles

.view.view-hub-actualites,
.view.view-hub-expositions,
.view.view-hub-spectacles {
  .view-header {
    @include rem(padding, 20px 0 45px);

    @media (max-width:700px) {
      padding: 0 0 20px 0;

      h3 {
        text-align: left !important;
      }
    }
  }

  &>.container {
    @media (max-width:700px) {
      padding: 20px !important;
    }
  }
}

.full-width:last-child {

  .view.view-hub-actualites,
  .view.view-hub-expositions,
  .view.view-hub-spectacles {
    border-bottom: none;
  }
}

.view.view-hub-editorial {
  @media (max-width:700px) {
    .view-header {
      padding-bottom: 20px;

      h3 {
        text-align: left !important;
      }
    }

    >.container {
      padding: 20px;
    }
  }
}

.view-hub-actualites.view-display-id-block_masonry {
  .item {
    .img {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        opacity: 0;
        transition: all .3s;
        z-index: 2;
      }
    }

    &:focus-within,
    &:hover {
      .img {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.view.ui-news,
.view.view-grid-list,
.view.view-hub-actualites,
.view.view-hub-editorial,
.view.view-hub-expositions,
.view.view-hub-spectacles,
.view-manuel-des-ventes {
  border-bottom: 1px solid #e6e6e6;

  @media (max-width:700px) {
    border-bottom: none;
  }

  .row {
    @include desktop {
      @include rem(margin-bottom, 75px);
    }

    &:last-child {
      @include desktop {
        margin-bottom: 0;
      }
    }

    [class^="col-"] {
      @include rem(margin-bottom, 30px);

      @include desktop {
        margin: 0;
      }
    }
  }

  .outer {
    position: relative;
    cursor: pointer;
    @include rem(padding-top, 50px);

    @include desktop {
      @include rem(padding-top, 30px);
    }
  }

  h4 {
    position: relative;
    @include rem(margin, 5px 0);
    @include font-size-adapt(20px, 24px);
    line-height: 1.3;
    overflow: hidden;

    a {
      position: relative;
      display: block;
      color: inherit;

      &:hover span {
        transform: translateX(0%);
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: transparent;
        overflow: hidden;
        transform: translateX(-100%);
        transition: all 0.5s;

        &:before {
          display: inline;
          content: attr(data-hover);
          background-image: linear-gradient(to right, #333 0%, #333 100%);
          background-repeat: repeat-x;
          background-position: 0 95%;
          background-size: 100% 1px;
        }
      }
    }
  }

  p {
    margin: 0;
    color: #666;
    @include font-size(14px);
    line-height: 1.4;
  }

  p.date-lieu {
    text-transform: uppercase;
    margin-top: 20px;
  }

  p.cat {
    position: absolute;
    top: 0;
    left: 0;
    @include rem(margin-bottom, 5px);
    color: #404041;
    @include font-size(14px);
    line-height: 1;
    text-transform: uppercase;

    @media (max-width:700px) {
      top: 30px;
    }
  }

  p.date {
    @include rem(margin, 0 0 5px);
    color: #989898;
    @include font-size(14px);
    line-height: 1.5;
    text-transform: uppercase;

    a {
      color: inherit;
    }
  }

  .view-content span.label {
    display: block;
    @include rem(margin-top, 10px);
    color: #ba9e66;
    @include rem(font-size, 17px);
    line-height: 1;
    text-transform: uppercase;
  }

  .news>a,
  .outer>a {
    display: block;
    @include rem(margin-bottom, 20px);
    position: relative;
    overflow: hidden;

    &:hover:before {
      opacity: 1;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.6);
      opacity: 0;
      transition: all 0.3s;
      z-index: 2;
    }

    img {
      margin: 0;
    }
  }

  &.view-display-id-block_moment,
  &.view-display-id-block_venir {
    border-bottom: none;
  }

  &.view-display-id-block_une,
  &.view-display-id-news_une {
    border: none;

    .container {
      @include rem(padding-bottom, 0);
    }
  }

  &.view-display-id-block_moment,
  &.view-display-id-block_une,
  &.view-display-id-news_une,
  &.view-display-id-block_veni,
  &.view-display-id-block_venir {
    &>.container {
      @include rem(padding-bottom, 20px);

      @media (max-width:700px) {
        padding: 20px !important;
      }
    }

    p.cat {
      position: relative;
    }

    .first {
      @include desktop {
        @include rem(margin-bottom, 40px);
      }

      // &.last {
      //   margin: 0;
      // }
    }

    .view-content {
      &>div {
        @include rem(margin-bottom, 40px);

        &.last,
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .row {
      text-align: center;

      [class^="col-"] {
        position: relative;
        margin: 0;

        @media (max-widtH:700px) {
          text-align: left;
        }
      }

      @include media-query(tablet) {
        text-align: left;
      }

      .col-md-7:first-child {
        &>a {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;
          @include rem(margin-bottom, 25px);

          @include tablet {
            margin: 0;
          }

          &:hover:before {
            opacity: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.6);
            opacity: 0;
            transition: all 0.3s;
            z-index: 2;
          }
        }
      }

      img {
        margin: 0;
      }

      p.date {
        @include rem(margin-bottom, 5px);
      }

      p.cat {
        position: relative;
        margin: 0;
        color: #333;
        @include font-size(15px);
        line-height: 1.7;
      }

      h4 {
        margin-top: 0;
        @include rem(margin-bottom, 20px);
        @include font-size-adapt(20px, 24px);
        line-height: 1.3;

        @media (max-width:700px) {
          @include rem(margin-bottom, 10px);
        }
      }

      p {
        @include rem(margin-bottom, 40px);
        @include font-size(16px);
        @include font-size-adapt(14px, 16px);
        line-height: 1.8;

        @media (max-width:700px) {
          @include rem(margin-bottom, 10px);
        }

        &:last-child {
          margin: 0;
        }
      }

      a.btn {
        @include media-query(desktop) {
          position: absolute;
          bottom: 0;
          @include rem(left, 20px);
        }
      }
    }
  }

  &.view-display-id-block_masonry {
    .container {
      @include rem(padding-bottom, 40px);

      @media (max-width:700px) {
        padding: 20px !important;
      }
    }

    .item {
      cursor: pointer;

      &:focus-within {
        outline: 2px solid #000;
      }
    }

    .masonry-item {
      margin: 0;
      @include rem(padding, 20px 0 60px);
      width: 100%;
      background: none;
      border: none;

      @include media-query(tablet) {
        width: calc(50% - 20px);
      }

      @include media-query(desktop) {
        width: calc(33.333% - (80px / 3));
      }
    }

    h4 {
      @include font-size(22px);
      @include rem(line-height, 26px);
    }

    img {
      display: block;
      @include rem(margin-bottom, 10px);
    }

    p:not(.cat):not(.date) {
      margin: 0;
      line-height: 1.4;
    }
  }

  &.view-display-id-block_videos {
    .youtube-container--responsive {
      @include rem(margin-bottom, 20px);
    }

    h4 {
      @include font-size(20px);
      line-height: 1.2;
    }
  }
}

@media (max-width:700px) {
  .view.view-hub-actualites {
    .outer {
      @include rem(padding-top, 20px);
    }
  }
}

.view.view-grid-list {
  img {
    margin-bottom: 20px;
  }
}

.section-ressources-pedagogiques,
.node-type-hub-ressources-pedagogiques,
.node-type-ressource-pedagogique,
.node-type-webform-presse,
.page-presse-recherche,
.page-espace-presse,
.section-presse,
.presse-category,
.node-type-hub-presse,
.node-type-presse-communique {

  .mobile-menu .menu-name-menu-menu-ressources>ul,
  .menu-name-menu-menu-presse>ul {
    &>li.expanded {
      position: relative;
      margin-bottom: -30px;
      padding-bottom: 30px;

      @media (max-width:900px) {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &>a {
        padding-right: 24px;
        transition: all 0.3s linear;
        white-space: nowrap;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: attr(title);
          content: attr(title) / "";
          height: 0;
          visibility: hidden;
          overflow: hidden;
          user-select: none;
          pointer-events: none;
          font-weight: 500;

          @media speech {
            display: none;
          }
        }
      }

      &>a span:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        transform: translateY(-25%);
        //top: calc(50% - 15px);
        top: 20px;
        right: 0.625rem;
        border-top-color: #666;

        @media (max-width:700px) {
          top: 37px;
        }
      }

      &>ul {
        display: block;
        list-style: none;
        top: 65px;
        margin: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        padding: 0;
        width: auto !important;
        background: #fff;
        min-width: 15rem;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-out, top 0.3s ease-out, visibility 0.3s ease-out;
        pointer-events: none;
        box-shadow: 0 0 2px #333;

        li {
          margin: 0;

          a {
            display: block;
            color: #333;
            padding: 1.4375rem 0.9375rem;
            white-space: nowrap;
            border-bottom: 1px solid #e6e6e6;
            transition: all 150ms ease-out;
            text-align: left;

            &:focus,
            &:hover {
              color: #333;
              background: #f1efea;
            }
          }
        }
      }

      &.dropdown-active {
        &>a {
          font-weight: 500;
          position: relative;

          &:before {
            content: '';
            height: 3px;
            background: #ba9e66;
            position: absolute;
            right: 0;
            bottom: -25px;
            width: 100%;
            display: block;
          }

          .text:after {
            top: 50%;
          }

        }

        &>ul {
          pointer-events: auto;
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s ease-out, top 0.3s ease-out;
        }
      }
    }
  }
}


.view.view-hub-actualites {

  &.view-display-id-block_une .row {
    cursor: pointer;

    img {
      transition: .2s;
    }

    .btn {
      border: 1px solid #eee;

      &:before {}
    }
  }

  &.view-display-id-block_une .row:hover {
    img {
      filter: brightness(.5);
      transition: .2s;
    }

    .btn {
      color: #fff;

      &:after {
        transform-origin: center bottom;
        transform: scaleY(1) !important;
      }
    }
  }
}

.view.view-hub-actualites p.cat,
.view-manuel-des-ventes.view-display-id-news_hub p.cat {
  position: unset;
}

.view.view-hub-expositions.view-display-id-block_moment .row [class^=col-] {
  @media (max-width:700px) {
    text-align: left;
  }
}


.view-hub-famille {
  &.view-id-hub_famille.view-display-id-ressources {
    .view-content {
      .row {
        position: relative;

        h4 {
          font-size: 20px;

          a {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        p {
          margin: 0;
          color: #666;
          font-size: 14px;
          font-size: .875em;
          line-height: 1.4;
          line-height: 1.8;
        }

        .news {
          img {
            transition: .2s;
          }

          &:focus-within img,
          &:hover img {
            transition: .2s;
            filter: brightness(.5);
          }
        }
      }
    }
  }
}

.view.ui-news.view.view-hub-actualites {
  p.date {
    margin-top: 10px;
  }

  h4 {
    @media (min-width:720px) {
      font-size: 22px;
      line-height: 26px;
    }

    @media (max-width:720px) {
      font-size: 20px;
    }
  }
}

.view.view-hub-actualites.ui-news.view-display-id-block_une .row {
  h4 {
    @media (max-width:700px) {
      margin-top: 10px;
    }
  }

  p {
    line-height: 1.7;
  }
}

.view.view-hub-actualites{
  &.view-display-id-block_rapport,
  &.view-display-id-block_rapport_two{
    .container{
      padding-top: 10px;
      .view-header{
        padding: 20px 0 10px 0;
        h3{
          margin-bottom: 20px;
        }
        .chapo{
          font-size: 14px;
          line-height: 20px;
          p{
            // font-size: 14px;
            // line-height: 20px;
          }
        }
      }
      .row{
        margin-bottom: 20px;
        @media (max-width:700px) {
          margin-bottom: 0;
        }
      }
    }
  }

}