.node-date-agenda {
  
	.full--only {
		margin-bottom: 30px;
	}

	.full--only a {
		font-size: 14px;
		color: #ba9e66;
		line-height: 22px;
		text-decoration: underline;
	}

	.container {
    max-width: 720px;
	@media(max-width:800px) {
		max-width:100vw;
	}
	}
	.agenda-header {
    @include rem(padding, 60px 30px 30px);
    @include desktop {
      @include rem(padding, 60px 0 40px);
    }
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 100;
      @include rem(padding, 10px 70px 10px 30px);
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
      @include desktop {
        margin: 0 -190px;
        @include rem(padding, 20px 90px);
      }
      .container {
        max-width: none;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .weatherbox {
        display: none;
      }
      h1 {
        display: none;
        margin: 10px;
        font-size: 24px;
        line-height: 1;
        sup{
          font-size: 14px;
          top: -7px;
        }
        @include desktop {
         display: block; 
        }
      }
      .buttons-row .btn {
        min-height: 50px;
        padding: 12px 20px !important;
        font-size: 17px;
        font-size: 1.0625em;
        line-height: 24px;
        line-height: 1.5rem;
      }
      .buttons-row .date-dropdown .btn-primary {
        min-height: 50px;
        padding: 12px 20px 12px 55px !important
      }
      .no-stick {
        display: none;
      }
    }
		.container {
			padding: 0;
		}
		.date-weather {
			i {
				margin-right: 14px;
				line-height: 21px;
				font-size: 21px;
				&:before {
					vertical-align: top;
				}
			}
		}
		h1 {
			margin: 10px 0 20px;
      sup{
        font-size: 22px;
        top: -20px;
      }
		}
	}
	.buttons-row {
    @include desktop {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    @media (max-width:1175px){
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    @media (max-width:500px){
      flex-direction: column;
      align-items: normal;
    }
     
		.dropdown {
			margin: 0;
			&.show {
				.btn-dropdown {
					color: #ba9e66;
					.caret {
						transform: rotate(-180deg);
					}
				}
			}
		}
		.dropdown-menu {
			float: left;
			display: none;
			z-index: 100;
			background: #ffffff;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
			&.show {
				display: block;
			}
		}
		a:not(.btn) {
			font-size: 14px;
			color: #666666;
			line-height: 22px;
			text-decoration: underline;
		}
		.btn {
      @include rem(margin-right, 20px);
      margin-bottom: 10px;
      @include desktop {
        margin-bottom: 0;
      }
			&.inactive {
				background: #f1efea;
				border-color: #f1efea;
				pointer-events: none;
				&:before,
				&:after {
					display: none !important;
				}
			}
		}
		.date-dropdown {
			.btn {
				outline: none !important;
				&:before,
				&:after {
					display: none;
				}
			}
			.btn-primary {
				@include rem(padding, 18px 20px 20px 55px);
				background: transparent;
				color: #666666;
				@include font-size-adapt(17px);
				@include line-height-adapt(24px);
				border: 1px solid #e6e6e6;
				i {
					color: #ba9e66;
				}
			}
		}
	}
	.container {
    padding: 20px 30px;
    @include desktop {
      padding: 40px 0;
    }
	}
	.row-alert {
		position: relative;
    @include rem(padding, 30px 30px 25px);
    @include desktop {
      @include rem(padding, 30px 0 25px);
    }
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -100vw;
			width: 200vw;
			height: 100%;
			background: #f1efea;
		}
		.container {
			padding: 0;
		}
		p {
			margin: 0;
			color: #666666;
			font-size: 14px;
      line-height: 22px;
      padding-bottom: 5px;
			&.todo {
				color: #d0021b;
			}
		}
	}
	.date-agenda--hub {
    .view .row [class^=col-] {
      margin: 0;
      .outer {
        height: auto;
      }
    }
    .outer {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #E6E6E6;
      @include desktop {
        border-bottom: 0;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
		h4.title {
			margin: 0 0 5px;
      min-height: 45px;
		}
		.hours {
			margin-bottom: 10px;
      @include desktop {
        margin-bottom: 27px;
      }
			display: block;
			font-size: 14px;
			color: #666666;
			line-height: 20px;
			&.closed{
				color: #D0021B;
			}
		}
		.attendance,
		.grandes-eaux {
			font-size: 13px;
			color: #979797;
    }
    .grandes-eaux {
      position: relative;
      padding-left: 25px;
      display: block;
      background-repeat: no-repeat;
      background-position: left top;
      line-height: 24px;
      &:before{
        display:none;
      }
      &.icon-fountain{
        background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.677 8.661V.041H0v8.62h18.677z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M17.211 19.952s-.109-.38-.109-.922.109-.922.109-.922h-5.755l-.489-1.247s-.868.109-1.683.109c-.814 0-1.683-.109-1.683-.109l-.434 1.247H1.412s.108.38.108.922-.108.922-.108.922H17.21z' fill='%237293CB'/%3E%3Cg transform='translate(0 6.79)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M14.714.04c-3.258 0-4.561 2.766-5.376 4.989C8.524 2.806 7.221.04 3.963.04 1.574.04 0 1.72 0 3.944c0 .705.217 1.518.543 2.169l1.194-.38c-.054-.162-.325-.921-.325-1.789 0-1.463.923-2.548 2.497-2.548 2.226 0 3.312 1.79 4.56 5.042l.87 2.223.868-2.223c1.249-3.253 2.335-5.042 4.56-5.042 1.575 0 2.498 1.085 2.498 2.548 0 .868-.271 1.627-.326 1.79l1.195.379c.326-.65.543-1.464.543-2.169 0-2.223-1.575-3.903-3.963-3.903' fill='%237293CB' mask='url(%23b)'/%3E%3C/g%3E%3Cpath d='M6.407 2.44c-1.195 0-1.9.704-2.226 1.518l.868.488c.326-.488.652-.868 1.303-.868 1.086 0 1.738 1.193 2.335 3.307l.434 1.573h.435l.434-1.573c.597-2.114 1.249-3.307 2.335-3.307.651 0 .977.38 1.303.868l.868-.488c-.325-.814-1.031-1.518-2.226-1.518-1.574 0-2.497 1.138-2.932 2.982C8.904 3.578 7.981 2.44 6.407 2.44m2.931-1.03a.684.684 0 0 0 .706-.705A.684.684 0 0 0 9.338 0a.718.718 0 0 0-.705.705c0 .38.325.705.705.705z' fill='%237293CB'/%3E%3C/g%3E%3C/svg%3E");
      }
      &.icon-garden{
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 6.667a3.333 3.333 0 1 0-6.667 0 3.333 3.333 0 0 0 6.667 0M16 13.785H8.609V12H7.39v1.785H0C0 17.212 3.589 20 8 20s8-2.788 8-6.215zM9.333 1.334a1.333 1.333 0 1 0-2.666-.001 1.333 1.333 0 0 0 2.666 0' fill='%2356B069' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
	}

	.date-agenda--expo,
	.date-agenda--spectacle {
		.view.view-date-agenda {
			.view-header {
				padding-bottom: 20px;
				margin-bottom: 0;
				border-bottom: 1px solid #e6e6e6;
			}
			.view-content {
				.outer {

		  	.row{
				
					padding: 20px 0;
          @include desktop {
            padding: 40px 0;
          }
					border-bottom: 1px solid #e6e6e6;
					&.is-star{
						display: none;
					}
			}

            .col-xs-12{
              @include rem(margin-bottom, 20px);
            }
					p.date {
						margin: 0 0 10px;
						font-size: 14px;
						color: #989898;
						line-height: 21px;
						text-transform: uppercase;
					}
					h4 {
						margin: 0 0 20px;
						font-family: Graphik, serif;
						font-size: 24px;
						color: #333333;
						text-transform: uppercase;
					}
					p {
						font-size: 14px;
						line-height: 20px;
						a {
							color: inherit;
						}
					}
					.link {
						margin-top: 5px;
						a {
							font-size: 14px;
							color: #ba9e66;
							line-height: 22px;
							text-decoration: underline;
							&:hover{
								opacity:.7;
							}
						}
					}
				}
			}
		}
	}
  
	.date-agenda--expo{
		.view.view-date-agenda {
			.view-content {
				.outer {
					p {
						margin: 0 0 15px;
						color: #666666;
					}
				}
			}
		}
	}
  
	.date-agenda--spectacle {
		.view.view-date-agenda {
			.view-content {
				.outer {
					p {
						margin: 0;
						color: #989898;
            span.icon-moon,
            span.icon-garden,
            span.icon-fountain{
              background-repeat: no-repeat;
              padding-left: 25px;
              line-height: 25px;
              display: block;
              &:before{
                display:none;
              }
            }
            span.icon-fountain {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.677 8.661V.041H0v8.62h18.677z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M17.211 19.952s-.109-.38-.109-.922.109-.922.109-.922h-5.755l-.489-1.247s-.868.109-1.683.109c-.814 0-1.683-.109-1.683-.109l-.434 1.247H1.412s.108.38.108.922-.108.922-.108.922H17.21z' fill='%237293CB'/%3E%3Cg transform='translate(0 6.79)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M14.714.04c-3.258 0-4.561 2.766-5.376 4.989C8.524 2.806 7.221.04 3.963.04 1.574.04 0 1.72 0 3.944c0 .705.217 1.518.543 2.169l1.194-.38c-.054-.162-.325-.921-.325-1.789 0-1.463.923-2.548 2.497-2.548 2.226 0 3.312 1.79 4.56 5.042l.87 2.223.868-2.223c1.249-3.253 2.335-5.042 4.56-5.042 1.575 0 2.498 1.085 2.498 2.548 0 .868-.271 1.627-.326 1.79l1.195.379c.326-.65.543-1.464.543-2.169 0-2.223-1.575-3.903-3.963-3.903' fill='%237293CB' mask='url(%23b)'/%3E%3C/g%3E%3Cpath d='M6.407 2.44c-1.195 0-1.9.704-2.226 1.518l.868.488c.326-.488.652-.868 1.303-.868 1.086 0 1.738 1.193 2.335 3.307l.434 1.573h.435l.434-1.573c.597-2.114 1.249-3.307 2.335-3.307.651 0 .977.38 1.303.868l.868-.488c-.325-.814-1.031-1.518-2.226-1.518-1.574 0-2.497 1.138-2.932 2.982C8.904 3.578 7.981 2.44 6.407 2.44m2.931-1.03a.684.684 0 0 0 .706-.705A.684.684 0 0 0 9.338 0a.718.718 0 0 0-.705.705c0 .38.325.705.705.705z' fill='%237293CB'/%3E%3C/g%3E%3C/svg%3E");
            }
            span.icon-garden {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 6.667a3.333 3.333 0 1 0-6.667 0 3.333 3.333 0 0 0 6.667 0M16 13.785H8.609V12H7.39v1.785H0C0 17.212 3.589 20 8 20s8-2.788 8-6.215zM9.333 1.334a1.333 1.333 0 1 0-2.666-.001 1.333 1.333 0 0 0 2.666 0' fill='%2356B069' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
            span.icon-moon {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='17' height='19' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h17v19H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M7.89 17.457c-4.328-.826-7.2-5.14-6.401-9.62.446-2.495 1.928-4.571 4.018-5.743a9.77 9.77 0 0 0-.634 2.13c-.935 5.238 2.424 10.286 7.486 11.253a8.93 8.93 0 0 0 2.15.147c-1.805 1.6-4.208 2.293-6.62 1.833m8.257-3.52a7.633 7.633 0 0 1-3.542.162c-4.327-.827-7.2-5.143-6.4-9.62a8.318 8.318 0 0 1 1.408-3.366.718.718 0 0 0 .01-.81.666.666 0 0 0-.735-.278C3.443.997.802 3.965.157 7.584c-.935 5.238 2.423 10.285 7.486 11.252 3.552.678 7.087-.78 9.226-3.807a.72.72 0 0 0 .012-.812.663.663 0 0 0-.735-.28' fill='%2369F' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
            }
					}
					.link.arrow {
            .btn--primary{
              color:#ba9e66;
              min-height: 0;
              padding: 0;
              overflow: visible;
              &:hover{
                color:#ba9e66 !important;
                .ext{
                  background: 0 0 !important;
                  color: #ba9e66 !important;
                }
              }
            }
						.btn--primary:after,
						.btn--primary:before {
              display: none;    
						}
					}
				}
			}
		}
	}
  
  .date-agenda--visite{
    &.unique-billet{
      .view-display-id-detail_billet{
        .view-header{
          display:block;
        }
        .container{
          padding: 20px 30px;
          @include desktop {
            padding: 40px 0;
          }
        }
      }
    }
    .view-display-id-detail_billet{
      .view-header{
        // display:none;
      }
      .container{
        padding: 20px 30px;
        @include desktop {
          padding: 0px;
        }
      }
      .row{
        .col-md-9{
          margin-bottom: 0px;
          @include desktop {
            margin-bottom: 40px;
          }
          .btn-group{
            .btn--primary{
              width: auto;
							&:hover{
								text-decoration: underline!important;
							}
            }
          }
        }
      }
    }
    
   .view-display-id-detail_visite{
      padding: 0;
      .row{
        .col-md-9{
          margin-bottom: 0px;
          @include desktop {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  
	.date-agenda--parcours {
		.container {
      padding: 40px 30px;
      @include desktop {
        padding: 80px 0;
      }
		}
		.view {
			background: #f1efea;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -100vw;
				width: 200vw;
				height: 100%;
				background: #f1efea;
			}
		}
		.view-header {
			p.text--gold {
				font-family: $font-crimson;
				margin: 0 0 50px;
        font-size: 30px;
        line-height: 1;
				color: #ba9e66;
			}
			h2 {
				margin: 0;
				font-size: 24px;
				color: #333333;
				line-height: 26px;
			}
		}
		.view-content {
			& > div {
				margin-bottom: 40px;
				background: #fff;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
  }
  
  .view-display-id-detail_parcours,
  .view-display-id-detail_parcours_closed{  
    .row {
      margin: 0;
    }
    .col-md-6 {
      padding: 0;
      margin-bottom: 0;
      & + .col-md-6 {
        padding: 20px 30px 30px 20px;
        margin-bottom: 0;
      }
    }
    h4.title {
	margin: 0 0 10px;
	font-family: Graphik,serif;
	font-size: 20px;
	line-height: 24px;
	color: #333;
	text-transform: uppercase;
    }
    p {
      margin: 0 0 10px;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 18px;
    }
    .bottom {
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
  
  .view-display-id-detail_famille{
    .row {
      .btn.btn--primary{
        width: auto;
      }
      .col-md-9{
        margin-bottom: 0px;
        @include desktop {
          margin-bottom: 40px;
        }
      }
    }
  }

	.date-agenda--next-prev {
		.container {
			padding: 0;
			max-width: 100%;
		}
		.row {
			@include desktop {
				@include rem(margin, 0 -190px);
			  }
			& > div {
        padding: 0;
        background: #fff;
        &:focus, &:hover {
          background: #f1efea;
        }
			}
			& > div:first-child {
        border-bottom: 1px solid #e6e6e6;
        @include desktop {
          border-bottom: none;
          border-right: 1px solid #e6e6e6;
        }
			}
    }
    .view {
      background: none;
    }
		h2 {
			padding: 40px 0;
			position: relative;
			margin: 0;
			font-size: 24px;
			color: #333333;
			line-height: 26px;
			text-align: center;
			background: #f1efea;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -100vw;
				width: 200vw;
				height: 100%;
				background: #f1efea;
				z-index: -1;
			}
		}
		.date-wrapper {
			padding: 40px 0;
			text-align: center;
			cursor: pointer;
			.date-weather {
				color: #666666;
				i {
					display: inline-block;
					font-size: 21px;
					line-height: 1;
					margin-right: 10px;
					vertical-align: middle;
				}
			}
			h4 {
				margin: 10px auto 20px;
				font-family: Graphik, serif;
				font-size: 24px;
				color: #333333;
				text-transform: uppercase;
			}
			.row-attendance {
				font-size: 13px;
				color: #979797;
				.attendance {
					background: none !important;
				}
			}
		}
	}
}
