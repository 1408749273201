.view-date-agenda.view-display-id-page {
  .view-filters {
    margin: 0;

    .lo {
      position: absolute;
      right: -40px;
      top: 20px;

      @media (max-width:1175px) {
        right: 8px;
        top: 60px;
      }
    }

    .form-submit {
      // outline: none !important;

      @include desktop {
        position: relative;
        width: 24px;
        height: 24px;
        text-indent: -9999px;
        border-radius: 50%;
        border: none !important;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23F1EFEA' cx='12' cy='12' r='12'/%3E%3Cg fill='%23282826'%3E%3Cpath d='M7.875 7.081l9.035 9.036-.785.785L7.09 7.867z'/%3E%3Cpath d='M7.09 16.117l9.035-9.036.785.786-9.035 9.035z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }

    .dropdown {
      margin: 0;

      &.show {
        .btn-dropdown {
          color: #ba9e66;

          .caret {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .dropdown-menu {
      float: left;
      display: none;
      z-index: 100;
      background: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);

      &.show {
        display: block;
      }
    }

    .btn {
      outline: none !important;

      &:focus {
				outline: 2px solid #000!important;
        outline-offset: 2px;
      }

      &:before,
      &:after {
        display: none;
      }
    }

    .btn-primary {
      @include rem(padding, 13px 55px 13px 55px);
      min-height: 50px;
      background: transparent;
      color: #666666;
      @include font-size-adapt(17px);
      @include line-height-adapt(24px);
      border: 1px solid #e6e6e6;

      @include desktop {
        @include rem(padding, 18px 20px 20px 55px);
        min-height: 60px;
        font-size:16px;
      }

      i {
        color: #ba9e66;
        top: 28px;
      }
      i.icon-calendair:before {
        background-image: url(/sites/all/themes/versailles/images/clock_min.svg);
        height: 16px;
        width: 16px;
        content:''; 
        display:block;
      }
    }

    .date-dropdown {
      position: relative;
      margin-bottom: 10px;

      @include desktop {
        margin-bottom: 0;
        margin-right: 60px;
      }

      @media (max-width:1175px) {
        margin: 0 20px 0 0;
      }

      @media (max-width:500px) {
        margin: 0 0 20px 0;
      }
    }

    .btn-dropdown {
      @include rem(padding, 20px 10px);
      display: flex;
      color: #333333;
      border: none;
      background: none;
      align-items: center;

      .caret {
        @include rem(margin-left, 10px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #ba9e66 transparent transparent transparent;
      }
    }

    .views-exposed-widgets {
      @include desktop {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      @media (max-width:1175px) {
        display: flex;
        flex-direction: row;
      }

      @media (max-width:500px) {
        flex-direction: column;
      }

      label {
        @include rem(margin-right, 40px);
        color: #666666;
        font-weight: 400;
      }

      .views-exposed-widget {
        .dropdown-menu {
          @include rem(max-width, 320px);
          @include rem(max-height, 500px);
          overflow-x: hidden;
        }

        .bef-toggle {
          display: block;
          text-align: left;
          font-size: 14px;
          line-height: 22px;
          color: #0f0f0f;
          @include padding(20px 50px);
          border-bottom: 1px solid #e4e4e4;
          overflow: hidden;
          position: relative;
          &:focus {   outline:none; background: #f1efea;}
          &:before {
            position: absolute;
            content: '';
            top: 50%;
            display: block;
            width: 20px;
            width: 1.25rem;
            height: 20px;
            height: 1.25rem;
            left: 20px;
            left: 1.25rem;
            border-radius: 2px;
            border-radius: .125rem;
            background: #fff;
            border: 1px solid #e6e6e6;
            transform: translateY(-50%);

            @media(max-width:1175px) {
              left: 0;
            }
          }

          &:after {
            display: none;
            content: '';
            border-left: 2px solid #ba9e66;
            border-bottom: 2px solid #ba9e66;
            height: 8px;
            width: 15px;
            position: absolute;
            top: 29px;
            left: 23px;
            transform: translateY(-40%) rotate(-45deg);

            @media(max-width:1175px) {
              left: 3px;
              top: 19px;
            }
          }

          &.clicked {
            &:after {
              display: block;
            }
          }
        }

        .form-type-bef-checkbox {
          border-bottom: 1px solid #e4e4e4;
          overflow: hidden;

          label {
            position: relative;
            display: block;
            margin: 0;
            text-align: left;
            font-size: 14px;
            line-height: 22px;
            color: #0f0f0f;
            @include padding(20px 50px);

            &:focus,
            &:hover {
              background: #f1efea;
            }

            &:before {
              position: absolute;
              content: '';
              top: 50%;
              display: block;
              @include rem((width: 20px,
                  height: 20px,
                  left: 20px,
                  border-radius: 2px));
              background: #fff;
              border: 1px solid #e6e6e6;
              transform: translateY(-50%);
            }

            &:after {
              display: none;
              position: absolute;
              content: '';
              top: 50%;
              @include rem((width: 16px,
                  height: 12px,
                  left: 22px,
                ));
              transform: translateY(-50%);
              background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Filtres' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='T05d_Agenda_filtres_visites' transform='translate(-797.000000, -635.000000)' fill='%23BA9E66'%3E%3Cg id='Group-13' transform='translate(775.000000, 405.000000)'%3E%3Cg id='Écuries' transform='translate(20.000000, 214.000000)'%3E%3Cpolygon id='Path' points='16.6474459 16 7.72966337 25.1476615 3.15931173 21.5267263 2 23.0741926 7.23176574 27.2211303 7.89317983 27.7471083 8.48769866 27.137289 18 17.3797837 16.6474459 16'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

              @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
                top: 48%;
                transform: translateY(-40%) rotate(-45deg);
                border-left: 2px solid #ba9e66;
                border-bottom: 2px solid #ba9e66;
                height: 6px;
                width: 14px;
                left: 23px;
              }

              @supports (-ms-ime-align:auto) {
                top: 48%;
                transform: translateY(-40%) rotate(-45deg);
                border-left: 2px solid #ba9e66;
                border-bottom: 2px solid #ba9e66;
                height: 6px;
                width: 14px;
                left: 23px;
              }
            }
          }

          input {
            width: 0 !important;
            position: absolute;
            top: 100%;
            left: 100%;
            visibility: hidden;
            opacity: 0;

            &:checked+label:after {
              display: block;
            }
          }

          &:last-child {
            border: none;
          }
        }
      }
    }

    .filters {
      @include desktop {
        display: flex;
        align-items: center;
        margin: 0;
      }

      &.hidden-desktop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 900;
        background: #fff;
        overflow: auto;

        .header-filters {
          position: relative;
          @include rem(padding, 30px);
          border-bottom: 1px solid #E6E6E6;

          .views-reset-button {
            text-align: left;

            .form-submit {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #666666;
              line-height: 24px;
              text-decoration: underline;
            }
          }

          .close-button {
            position: absolute;
            @include rem((top: 30px,
                right: 30px,
                width: 18px,
                height: 18px));
            z-index: 50;
            background: none;
            border: none;
            outline: none;

            &:before,
            &:after {
              position: absolute;
              top: 0;
              left: 50%;
              content: ' ';
              @include rem((margin-left: -1px,
                  width: 2px,
                  height: 23px));
              background-color: #262626;
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }
        }

        .content-filters {
          padding: 0 20px;

          .views-exposed-widget {
            padding: 40px 0;
            border-bottom: 1px solid #E6E6E6;

            label.filter-name {
              margin-bottom: 10px;
              text-align: left;
              font-size: 16px;
              color: #666666;
              letter-spacing: 0;
              line-height: 28px;
            }

            .form-type-bef-checkbox {
              border-bottom: none;

              label {
                padding: 10px 40px;

                &:before {
                  left: 0;
                }

                &:after {
                  left: 2px;
                }
              }
            }

            .bef-toggle {
              padding: 10px 40px;
              border: none;
              &:focus {    background: #f1efea;}
            }
          }
        }
      }
    }
  }

  .view-content {
    .full-width {
      background: #f1efea;
    }

    .slick {
      @include rem(padding, 30px 0);

      @include desktop {
        @include rem(padding, 100px 0 20px);
      }
    }
  }

  .slick-track .slick-slide .slide__content {
    display: block;
  }

  .slick__arrow {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    justify-items: flex-end;
    @include rem((padding: 30px 0,
      ));

    @include desktop {
      display: flex;
    }

    button.slick-arrow {
      position: relative;
      top: auto;
      background: none;
      @include rem((width: 40px,
          height: 40px,
        ));
      transform: none;
      border-color: #ba9e66;

      &:focus,
      &:hover {
        &:not(.slick-disabled) {
          color: inherit !important;
          background-color: #ba9e66;
        }
      }
    }

    button.slick-arrow.slick-prev {
      right: auto;
    }

    button.slick-arrow.slick-next {
      left: 0;
      @include rem(margin-left, 10px);
    }

    button.slick-arrow.slick-disabled {
      display: block !important;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .date-wrapper {
    @include rem(margin-bottom, 15px);
    width: 100%;
    @include rem(min-height, 290px);
    background: #fff;
    cursor: pointer;
    transition: .2s;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.15);

    &:hover{
      box-shadow: 0 0 9px 0 rgba(0,0,0,.25);
      transition: .2s;
    }

    @include desktop {
      margin-bottom: 0;
      @include rem(max-width, 245px);
    }

    .date-header {
      @include rem(padding, 20px);

      .row-date {
        display: flex;
        @include rem(margin-bottom, 10px);

        .date-num {
          @include rem(margin-right, 10px);

          h3 {
            margin: 0;
            @include font-size(42px);
            line-height: 1;

            sup {
              font-size: 20px;
              top: -15px;
            }
          }
        }

        .date-day-month {

          .date-day,
          .date-month {
            color: #666666;
            @include font-size(14px);
            @include line-height-adapt(20px);
            margin: 0px;
          }

          .date-day {
            text-transform: lowercase;
          }

          .date-month {
            color: #000002;
          }
        }

        .date-weather {
          flex: 1 0 auto;
          text-align: right;
          @include font-size-adapt(21px);

          i {
            color: #666666;
          }
        }
      }

      .attendance {
        @include rem(padding-left, 24px);
        color: #666666;
        @include font-size(14px);
        @include line-height-adapt(20px);
      }
    }

    .row-alert {
      @include rem(padding, 10px 20px);
      padding-bottom: 5px;
      background: #f1efea;

      p {
        margin: 0;
        color: #666;
        @include font-size(14px);
        @include line-height-adapt(22px);
        padding-bottom: 5px;

        &.todo {
          color: #d0021b;
        }
      }
    }

    .row-expo-wrapper {
      overflow: hidden;
      max-height: 350px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 290px;
        left: 0;
        width: 100%;
        height: 60px;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #ffffff 73%);
      }
    }

    .row-expo {
      @include rem(margin, 20px 0);
      @include rem(padding, 0 20px);

      .row-title {
        position: relative;
        overflow: hidden;

        @include desktop {
          max-width: 200px;
        }

        white-space: nowrap;
        @include rem(margin-bottom, 5px);
        color: #0f0f0f;
        @include font-size(14px);
        @include line-height-adapt(22px);

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 40px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 73%);
        }

        span.icon-fountain,
        span.icon-garden,
        span.icon-moon {
          background-repeat: no-repeat;
          padding-left: 25px;
          line-height: 25px;
          display: block;

          &:before {
            display: none !important;
          }
        }

        span.icon-fountain {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18.677 8.661V.041H0v8.62h18.677z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M17.211 19.952s-.109-.38-.109-.922.109-.922.109-.922h-5.755l-.489-1.247s-.868.109-1.683.109c-.814 0-1.683-.109-1.683-.109l-.434 1.247H1.412s.108.38.108.922-.108.922-.108.922H17.21z' fill='%237293CB'/%3E%3Cg transform='translate(0 6.79)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M14.714.04c-3.258 0-4.561 2.766-5.376 4.989C8.524 2.806 7.221.04 3.963.04 1.574.04 0 1.72 0 3.944c0 .705.217 1.518.543 2.169l1.194-.38c-.054-.162-.325-.921-.325-1.789 0-1.463.923-2.548 2.497-2.548 2.226 0 3.312 1.79 4.56 5.042l.87 2.223.868-2.223c1.249-3.253 2.335-5.042 4.56-5.042 1.575 0 2.498 1.085 2.498 2.548 0 .868-.271 1.627-.326 1.79l1.195.379c.326-.65.543-1.464.543-2.169 0-2.223-1.575-3.903-3.963-3.903' fill='%237293CB' mask='url(%23b)'/%3E%3C/g%3E%3Cpath d='M6.407 2.44c-1.195 0-1.9.704-2.226 1.518l.868.488c.326-.488.652-.868 1.303-.868 1.086 0 1.738 1.193 2.335 3.307l.434 1.573h.435l.434-1.573c.597-2.114 1.249-3.307 2.335-3.307.651 0 .977.38 1.303.868l.868-.488c-.325-.814-1.031-1.518-2.226-1.518-1.574 0-2.497 1.138-2.932 2.982C8.904 3.578 7.981 2.44 6.407 2.44m2.931-1.03a.684.684 0 0 0 .706-.705A.684.684 0 0 0 9.338 0a.718.718 0 0 0-.705.705c0 .38.325.705.705.705z' fill='%237293CB'/%3E%3C/g%3E%3C/svg%3E");
        }

        span.icon-garden {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 6.667a3.333 3.333 0 1 0-6.667 0 3.333 3.333 0 0 0 6.667 0M16 13.785H8.609V12H7.39v1.785H0C0 17.212 3.589 20 8 20s8-2.788 8-6.215zM9.333 1.334a1.333 1.333 0 1 0-2.666-.001 1.333 1.333 0 0 0 2.666 0' fill='%2356B069' fill-rule='evenodd'/%3E%3C/svg%3E");
        }

        span.icon-moon {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='17' height='19' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h17v19H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M7.89 17.457c-4.328-.826-7.2-5.14-6.401-9.62.446-2.495 1.928-4.571 4.018-5.743a9.77 9.77 0 0 0-.634 2.13c-.935 5.238 2.424 10.286 7.486 11.253a8.93 8.93 0 0 0 2.15.147c-1.805 1.6-4.208 2.293-6.62 1.833m8.257-3.52a7.633 7.633 0 0 1-3.542.162c-4.327-.827-7.2-5.143-6.4-9.62a8.318 8.318 0 0 1 1.408-3.366.718.718 0 0 0 .01-.81.666.666 0 0 0-.735-.278C3.443.997.802 3.965.157 7.584c-.935 5.238 2.423 10.285 7.486 11.252 3.552.678 7.087-.78 9.226-3.807a.72.72 0 0 0 .012-.812.663.663 0 0 0-.735-.28' fill='%2369F' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
        }
      }

      .row-subtitle {
        color: #666666;
        @include font-size(13px);
        line-height: 1;
        margin: 0;
      }
    }

    .row-visite {
      position: relative;
      @include rem(padding, 0 20px 0 54px);
      color: #0f0f0f;
      @include font-size(14px);
      @include line-height-adapt(24px);
      margin: 0 0 10px 0;

      span {
        position: absolute;
        top: 0;
        @include rem((left: 20px,
            width: 24px,
            height: 24px));
        background: #ba9e66;
        border-radius: 50%;
        color: #fff;
        text-align: center;
      }
    }

    .row-link {
      position: relative;
      @include rem(margin-top, 20px);
      @include rem(padding, 20px);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #e6e6e6;
      }

      a {
        @include font-size(14px);
        @include line-height-adapt(24px);
        text-decoration: underline;
      }
    }
  }

  .view-empty {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    background: #f1efea;

    p {
      margin: 0 auto;
      padding: 100px 0 220px;
      max-width: 720px;
      color: $color-primary;
      font-family: $font-crimson;
      font-size: 30px;
      line-height: 1;
      text-align: center;
    }
  }
}

.view-date-agenda.view-display-id-calendar {
  @include rem(max-width, 530px);
  border: 1px solid #d8d8d8;

  .view-header {
    @include rem(padding, 0 30px);
    background: #e6e6e6;

    .date-nav-wrapper {
      position: relative;
      margin: 0;
      padding: 0;
      text-align: center;
      border-bottom: 1px solid #d8d8d8;

      .date-nav,
      .date-heading {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        width: auto;
      }

      .pager {
        padding: 0;
        position: static;
      }

      h3 {
        display: inline-block;
        color: #333;
        font-family: $base-font-family;
        @include rem(padding, 0 20px);
        @include font-size-adapt(20px, 24px);
        @include line-height-adapt(50px);
        text-transform: capitalize;
      }

      .date-prev,
      .date-next {
        float: none;
        padding: 0;
        position: absolute;
        top: 50%;
        left: auto;
        right: 100%;
        text-align: right;
        @include rem((margin-top: -5px,
            width: 10px,
            height: 10px));
        z-index: 1;
        border-radius: none;
        background: none;

        a {
          display: block;
          margin: 0;
          padding: 0;
          min-width: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 10px 5px 0;
          border-color: transparent #000 transparent transparent;
          line-height: 1;
          text-indent: -99999px;
          background: none;
        }
      }

      .date-next {
        right: auto;
        left: 100%;

        a {
          border-width: 5px 0 5px 10px;
          border-color: transparent transparent transparent #000;
        }
      }
    }
  }

  .calendar-calendar {
    .month-view {
      border-bottom: 1px solid #d8d8d8;

      thead {
        border-bottom: 10px solid #fff;
      }

      tbody {
        border-bottom: 16px solid #fff;
      }

      th.days {
        @include rem(padding, 0);
        color: #333;
        @include font-size-adapt(16px);
        @include line-height-adapt(50px);
        font-weight: normal;
        background: #e6e6e6;
        border: none;

        div {
          margin: 0 auto;
          @include rem((width: 40px,
              height: 56px));
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333333;
          text-transform: capitalize;
        }
      }

      td.mini {
        @include rem(padding, 0);
        color: #8c8d93;
        @include font-size-adapt(16px);
        @include rem(line-height, 4px);
        font-weight: normal;
        text-align: center;
        border: none;

        &>div {
          margin: 0 auto;
          border: none !important;
        }

        .calendar-empty {
          display: none;
        }

        a,
        span.no-date {
          margin: 0 auto;
          display: flex;
          @include rem((width: 40px,
              height: 40px));
          color: #333333 !important;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          line-height: 1;
        }

        .mini-day-on,
        .mini-day-off {
          background: none !important;
        }

        .cal-ferme a,
        .cal-ferme span.no-date {
          color: rgba(#979797, 0.5) !important;
        }

        .cal-faible>* {
          background-color: rgba(#4a90e2, 0.3);
        }

        .cal-moyenne>* {
          background-color: rgba(#4fd46d, 0.3);
        }

        .cal-soutenue>* {
          background-color: rgba(#f5a623, 0.3);
        }

        .cal-importante>* {
          background-color: rgba(#d0061f, 0.3);
        }

        .cats {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          span {
            position: relative;
            display: flex;
            flex-grow: 1;
            width: 33%;
            line-height: 1;
            vertical-align: top;
            @include rem((height: 4px, margin: 2px 0));
            transition: all 0.2s;

            &.transparent {
              opacity: 0.2;
            }

            &.hover {
              transform: scale(1.4);
            }

            &:before {
              content: '';
              display: block;
              margin: 0 auto;
              @include rem((width: 4px, height: 4px));
              line-height: 1;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .view-footer {
    margin: 0;
    @include rem(padding, 20px);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        @include rem(margin-right, 20px);
        @include font-size-adapt(14px);
        line-height: 1;
        color: #666666;
        background: none !important;

        &:last-child {
          margin-right: 0;
        }

        &.cal-faible,
        &.cal-moyenne,
        &.cal-soutenue,
        &.cal-importante {
          position: relative;
          @include rem(padding-left, 13px);

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            @include rem((width: 8px,
                height: 8px));
            border-radius: 50%;
            transform: translateY(-50%);
          }
        }

        &.cal-faible:after {
          background-color: rgba(#4a90e2, 0.3);
        }

        &.cal-moyenne:after {
          background-color: rgba(#4fd46d, 0.3);
        }

        &.cal-soutenue:after {
          background-color: rgba(#f5a623, 0.3);
        }

        &.cal-importante:after {
          background-color: rgba(#d0061f, 0.3);
        }
      }
    }
  }
}

#agenda-popup-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(#000, 0.6);
  z-index: 1000;

  &.gone {
    display: none !important;
  }
}

.agenda-popup-loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .lo-circular.lo {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

#agenda-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  background: #fff;
  z-index: 1001;
  overflow-x: hidden;
}

#agenda-popup-content {
  @include desktop {
    @include rem(padding, 0 190px);
  }

  .full-width {
    width: 100%;
    margin-left: -50%;
    left: 50%;
  }

  .full--only {
    display: none;
  }

  .buttons-row {
    .dropdown {
      &.show {
        .btn-dropdown {
          color: #ba9e66;

          .caret {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .dropdown-menu {
      float: left;
      display: none;
      z-index: 100;
      background: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);

      &.show {
        display: block;
      }
    }

    .date-dropdown {
      .btn {
        outline: none !important;

        &:before,
        &:after {
          display: none;
        }
      }

      .btn-primary {
        @include rem(padding, 18px 20px 20px 55px);
        background: transparent;
        color: #666666;
        @include font-size-adapt(17px);
        @include line-height-adapt(24px);
        border: 1px solid #e6e6e6;
        font-size:16px;

        i {
          color: #ba9e66;
          top: 28px;
          &:before{
            background-image: url(/sites/all/themes/versailles/images/clock_min.svg);
            height: 16px;
            width: 16px;
            content: '';
            display: block;
          }
        }
      }
    }
    .no-stick:hover{
      color: #ba9e66;
    }
  }

  .date-agenda--next-prev {
    .view-display-id-day_prev {
      .view-content {
        width: 100%;
        margin-right: 0;

        @include desktop {
          // width: calc(100% + 190px);
          // margin-left: -190px;
        }
      }
    }

    .view-display-id-day_next {
      .view-content {
        width: 100%;
        margin-right: 0;

        @include desktop {
          // width: calc(100% + 190px);
          // margin-right: -190px;
        }
      }
    }
  }

  .view-date-agenda{
    .views-field-nothing{
      span.field-content{
        span{
          white-space: nowrap;
        }
      }
    }
  }
}

#agenda-popup-close {
  position: sticky;
  @include rem((top: 36px,
      width: 18px,
      height: 18px));
  left: calc(100% - 43px);
  z-index: 105;
  background: none;
  border: none;
  outline: none;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    content: ' ';
    @include rem((margin-left: -1px,
        width: 2px,
        height: 23px));
    background-color: #262626;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
