.view.view-push-abonnement {
  @include rem(margin-top, 40px);
  @include rem(padding, 0 30px);
  background: #f1efea;
  @include desktop {
    @include rem(margin-top, 70px);
    padding: 0;
  }
  .container {
    padding: 0;
    overflow: hidden;
    .image {
      margin: 0;
      @include rem(padding, 40px 0 70px);
      overflow: hidden;
      @include desktop {
        @include rem(padding, 60px 0);
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .text {
      margin: 0;
      @include rem(padding, 0 40px 40px);
      @include desktop {
        @include rem(padding, 60px 0 20px);
      }
      h4 {
        @include rem(margin, 0 0 10px);
        @include font-size-adapt(20px, 24px);
        @include line-height-adapt(26px);
        @include desktop {
          @include rem(margin, 0 0 5px);
        }
      }
      p {
        margin: 0;
        color: #ba9e66;
        font-family: $font-crimson;
        @include font-size-adapt(16px, 20px);
        @include line-height-adapt(22px, 26px);
        line-height: 1.3;
      }
    }
    .link {
      @include rem(padding, 0 40px 40px);
      text-align: right;
      align-self: flex-end;
      @include desktop {
        @include rem(padding, 60px 0);
      }
      a.btn {
        color: #333 !important;
        @include desktop {
          @include rem(margin-right, 50px);
        }
      }
    }
  }
  &.view-display-id-newsletter {
    margin: 0;
    background: #f1efea;
    .view-content {
      .text {
        @include rem(padding, 60px 20px);
        h4 {
          @include rem(margin, 0 0 25px);
          color: #000;
          @include font-size-rem-adapt(20px);
          @include line-height-adapt(26px);  
        }
        p {
          color: #000;
          font-family: $base-font-family;
          @include rem(margin, 0 0 35px);
          @include font-size-rem-adapt(16px);
          @include line-height-adapt(25px);  
        }
        .btn {
          color: #fff;
          &:before {
            background: $color-primary;
          }
          .ext {
            display: none;
          }
        }
      }
      .image {
        @include rem(margin-bottom, 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/shanes-2.png') no-repeat center center;
        overflow: auto;
        @include desktop {
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
    }
  }
}