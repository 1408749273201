/*------------------------------------*\
		DISCOVER.CSS
\*------------------------------------*/

.ui-discover {
	overflow: hidden;
	.item {
		position: relative;
		.inner {
			position: relative;
			@include rem(padding, 50px);
			background: url(../images/palace.jpg) no-repeat center center;
			transition: background 0.5s ease-in-out;
			transform: rotate3d(0,0,0,0deg);
			h3 {
				transition: background 0.5s ease-in-out;
			}			
		}
		&:focus, &:hover {
			z-index: 5;
			.inner {
				background: url(../images/hover.jpg) no-repeat center center;
				h3 {
					color: $color-primary;
					small, .small {
						color: #fff;
					}
				}
			}
		}
	}
}

.ui-discover-square {
	.item {
		.inner {
			position: relative;
			background: url(../images/palace.jpg) no-repeat center center;
			background-size: cover;
			&:before {
				content: "";
				display: block;
				padding-top: 60%;
			}
		}
		&:focus, &:hover {
			z-index: 5;
			.inner {
				background: url(../images/hover.jpg) no-repeat center center;
				h3 {
					color: #fff;
				}
			}
		}
	}	
}