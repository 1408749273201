.view.view-carnets {
    .view-header {
        @include rem(padding, 0 0 40px);
        h3.title {
            font-family: $heading-font-family;
            @include font-size(24px);
            @include media-query(tablet) {
                @include font-size(42px);
            }
        }
    }
    .view-content {
      .outer {
        & > a:not(.ext) {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;
          @include rem(margin-bottom, 25px);

          &:hover:before {
            opacity: 1;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.6);
            opacity: 0;
            transition: all 0.3s;
            z-index: 2;
          }
        }
      }
      h4 {
          margin: 0 0 10px 0;
          @include font-size-adapt(18px, 24px);
          line-height: 1.4;
          @include media-query(tablet) {
              line-height: 1.1;
          }
          a {
              color: inherit;
          }
      }
      p {
          margin: 0;
          color: #666;
          @include font-size-adapt(14px);
          @include line-height-adapt(20px);
      }
      p.cat {
          @include rem(margin-bottom, 5px);
          color: #404041;
          @include font-size(14px);
          line-height: 1;
          text-transform: uppercase;
      }
      p.date {
          @include rem(margin, 0 0 5px);
          color: #989898;
          @include font-size(14px);
          line-height: 1.5;
          text-transform: uppercase;
      }
      a {
        color: inherit;
      }
      img{
        margin-bottom: 20px;
      }
    }
}
