.anchor-map {
  display: none !important;
  position: relative;
  @include rem(margin, 40px 0);
  padding: 0;
  list-style: none;
  z-index: 4;
  @include desktop {
    display: block !important;
    position: absolute;
    top: 0;
    width: em(150);
  }
  ul {
    margin:0;
    padding:0;
    list-style:none; 
  }
  &.fixed {
    @include desktop {
      position: fixed;
      top: 0;
    }
  }
  li {
    position: relative;
    margin: 0;
    margin-bottom: rem(-5px);
    padding: 0 0 em(30) em(20);
    border-left: 1px solid #D8D8D8;
    cursor: pointer;
    @media(max-height:800px) {
      padding: 0 0 em(20) em(20);
    }
    &:last-child {
      border-left: none;
      padding: 0 0 0 em(20);
      .progress {
        @include rem(max-height, 14px);
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: - em(7);
      content: '';
      display: block;
      width: em(14);
      height: em(14);
      background: #fff;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      z-index: 5;
    }
    .progress {
      display: block;
      position: absolute;
      top: 0;
      left: -1px;
      width: 1px;
      background: $color-primary;
      z-index: 4;

    }
    p, a {
      margin: 0;
      display: block;
      color: #333333;
      font-family: $heading-font-family;
      @include font-size(16px);
      line-height: 1.2;
      text-transform: lowercase;
      @media(max-height:800px) {
        @include font-size(13px);
        line-height: 1.1;
      }
      span {
        display: block;
        margin-bottom: em(5);
        color: #666666;
        font-family: $base-font-family;
        @include font-size(15px);
        line-height: 1;
      }
    }
    &:focus, &:hover {
      p, a {
        color: $color-primary;
        span {
          color: inherit;
        }
      }
    }
    &.active, &.done {
      &:before {
        border-color: $color-primary;
      }
      p, a {
        color: $color-primary;
        span {
          color: inherit;
        }
      }
    }
  }
}


.node-type-presse-communique {
  .anchor-map {
    z-index:15;
  }
}