.view.view-widget-diaporama {
  @include rem(margin, 0 0 30px);
  .container {
    @include rem(padding, 0 30px);
    @include desktop {
      padding: 0;
    }
  }
  .ui-grid {
    .item {
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0,0,0,.6);
        opacity: 0;
        transition: all .3s;
        z-index: 2;
      }
      &:focus, &:hover {
        &:before {
          opacity: 1;
        }
      }
      img {
        margin: 0 !important;
        cursor: pointer;
      }
      figcaption {
        display: none;
      }
      figure.legend-off-list, figure.legend-on-list {
        position: relative;
        z-index: 4;
      }
      figure.legend-on-list {
        figcaption {
          position: relative;
          display: block;
          @include rem(padding, 10px 0 40px);
          background: #fff;
          z-index: 3;
          p {
            @include font-size-rem-adapt(14px);
            @include line-height-adapt(19px);
          }
        }
      }
    }
  }
}

.slick-popup {
  .slick-slide {
    opacity: 0.5;
    transition: all 0.3s;
    &.slick-active {
      opacity: 1;
    }
  }
  figure.description {
    @include rem(padding, 30px);
    figcaption {
      padding-left: 0;
    }
  }
  button.slick-arrow {
    z-index: 90;
  }
  button.slick-arrow.slick-prev {
    @include rem(left, 20px);
    right: auto;
  }
  button.slick-arrow.slick-next {
    left: auto;
    @include rem(right, 20px);
  }
}
