.chapelle-main-faq {
  background: #F1EFEA;
  .links {
    display: none;
  }
  .view {
    background: transparent;
  }
  .view.view-chapelle.view-display-id-faq_liste {
    & > .container {
      @include rem(padding-bottom, 60px);
    }
  }
  .view.view-widget-cta {
    @include rem(margin, 0 -15px !important);
    @include rem(max-width, 910px);
    @include tablet {
      margin: 0 auto !important;
      width: 100%;
      @include rem(padding-bottom, 80px);
    }
  }
  .view.view-push-image {
    background: #fff;
  }
}