.view-home.view.view-display-id-block_hub_lieux {
  text-align: center;

  &>.container {
    @include rem(padding, 20px 0 0);

    @include desktop {
      @include rem(padding, 30px 0 0);
    }
  }

  div[class^='col-'] {
    @include rem(margin-bottom, 40px);
    transition: all 0.3s;

    @media (max-width:700px) {
      @include rem(margin-bottom, 20px);
    }
  }

  .view-header {
    @include rem(padding-bottom, 40px);

    h3.title {
      @include rem(margin-bottom, 20px);
    }

    @include desktop {
      @include rem(padding-bottom, 50px);
    }
  }

  .slick-slide>div {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  @media(max-width:700px) {

    .slick-slide {
      box-sizing: border-box;
      min-height: 100%;
      background: #f1efea;
      margin-right: 20px;
      padding-bottom:80px;
      position:relative;
    }

    .slick-list {
      padding: 0 20px;
    }
  }

  .outer {
    position: relative;
    // max-width: 255px; 
    // min-width: 255px;
    width: 100%;

    @include rem(padding, 0 20px);
    cursor: pointer;
    transition: all 0.3s;
    box-sizing: border-box;

    @include desktop {
      @include rem(padding, 0 0 100px);
    }

    &:focus-within,
    &:hover {
      background: #F1EFEA;
    }

    @media (max-width:700px) {
      background: #f1efea;
      max-width: 70vw;
      min-width: 70vw;
      position:unset;
    }
  }

  .btn-group {
    position: relative;
    width: 100%;
    @include rem(padding, 0 20px);

    @include tablet {
      @include rem(max-width, 500px);
    }

    @include desktop {
      width: auto;
    }

    .element {
      position: relative;
      display: inline-block;
      width: 50%;
      @include rem(height, 50px);
      @include rem(padding, 15px 5px 15px 30px);
      border: 1px solid #E6E6E6;
      background: #fff;
      vertical-align: top;

      @include tablet {
        width: auto;
        @include rem(padding, 15px 20px 15px 40px);
      }

      &:first-child {
        margin-right: -6px;
      }

      i {
        position: absolute;
        top: 50%;
        @include rem(left, 5px);
        display: block;
        width: 1em;
        height: 1em;
        color: #666666;
        @include font-size(17px);
        transform: translateY(-50%);
        cursor: help;
        pointer-events: none;

        @include tablet {
          @include rem(left, 15px);
        }

        &:before {
          vertical-align: top;
        }

        &.icon-calendair {
          top: 25px !important;
          left: 15px !important;
          &:before {
            content: "";
            background-image: url(../../images/clock_min.svg);
            height: 16px;
            width: 16px;
            display: inline-block;
            position: relative;
            top: -2px;
          }
        }
      }

      input {
        @include rem(max-width, 200px);
        width: 100%;
        height: 100%;
        padding: 0;
        @include font-size(16px);
        line-height: 1;
        border: none;
        text-align: center;
        cursor: pointer;

        @include desktop {
          width: auto;
          @include rem(max-width, 150px);
        }
      }
    }

    .weatherbox {
      display: inline-block;
      text-align: center;
      @include rem(padding, 15px 20px 15px 30px);

      @include tablet {
        @include rem(padding, 15px 20px 15px 40px);
      }

      i {
        @include rem(left, 10px);

        @include tablet {
          @include rem(left, 15px);
        }
      }

      &.hide {
        display: none;
      }

      span {
        cursor: help;
      }
    }

    .datepickerbox {
      position: relative;
      padding: 0;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        @include rem(right, 10px);
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        opacity: 0.4;
        pointer-events: none;
      }

      input {
        @include rem(padding, 15px 10px 15px 30px);

        @include tablet {
          @include rem(padding, 15px 20px 15px 40px);
        }

        &:focus,
        &:active,
        &:focus,
        &:hover {
          background: #E6E6E6 !important;
          outline: none;
        }
      }
    }

    .lo {
      position: absolute;
      top: 50%;
      left: calc(100% + 20px);
      margin-top: -10px;
    }
  }

  .image,
  .views-field-field-hub-lieu-visuel-fil-fer {
    @include rem(padding, 20px 20px 25px);

    img {
      display: block;
      max-width: 100%;
      @include rem(height, 120px !important);
      margin: 0 auto;
    }
  }

  h4.title {
    @include rem(margin, 0 0 5px);
    @include font-size(18px);
    @include rem(line-height, 22px);

    a {
      color: inherit;
    }
  }

  p.subtitle {
    @include font-size(14px);
    @include rem(line-height, 20px);
    min-height: 40px;
    @media(max-width:700px) {
      color: #666666;
    }
  }

  p {
    margin: 0;
  }

  .info {
    @include rem(margin-top, 5px);

    @include desktop {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @include rem(margin-top, 30px);
      @include rem(padding-bottom, 20px);
      @include rem(min-height, 70px);
    }

    @media (max-width:700px) {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      // margin-left: -20px;
      // margin-right: -20px;
      min-height: 60px;
      position: absolute;
      width: calc(100% - 20px);
      display: block;
      bottom: 5px;
      left:10px;
    }

    @media (max-width:700px) and (min-width:500px) {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 20px;
    }

    span {
      display: block;
      color: #979797;
      @include font-size(13px);
      line-height: 1;

      &.hours {
        @include rem(margin-bottom, 10px);
        color: #666666;
        @include font-size(14px);
        @include rem(line-height, 20px);

        @media (max-width:700px) {
          font-size: 12px;
          line-height: 16px;
          color: #000;
        }

        @media (max-width:700px) and (min-width:500px) {
          margin-bottom: 0;
        }

        &.closed {
          color: #D0021B;
        }
      }

      &.grandes-eaux {
        position: relative;
        display: inline-block;
        @include rem(line-height, 16px);
        @include rem(padding-left, 25px);
        vertical-align: top;
        cursor: help;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          @include rem((margin-top: -10px,
              width: 20px,
              height: 20px));
          color: #7293CB;
          @include font-size(20px);
          line-height: 1;
        }
      }

      &.attendance {
        position: relative;
        display: inline-block;
        @include rem(line-height, 16px);
        @include rem(padding-left, 20px);
        vertical-align: top;
        cursor: help;

        @media (max-width:700px) {
          font-size: 12px;
          line-height: 16px;
          color: #000;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          @include rem((margin-top: -8px,
              width: 16px,
              height: 16px));
          border: 2px solid transparent;
          opacity: 50%;
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          @include rem((margin-top: -3px,
              width: 6px,
              height: 6px,
              left: 5px));
          border-radius: 50%;
        }

        &.cal-faible {
          background: none;

          &:before {
            border-color: #7293CB;
          }

          &:after {
            background: #7293CB;
          }
        }

        &.cal-soutenue {
          background: none;

          &:before {
            border-color: #EAA673;
          }

          &:after {
            background: #EAA673;
          }
        }

        &.cal-moyenne {
          background: none;

          &:before {
            border-color: #4FD46D;
          }

          &:after {
            background: #4FD46D;
          }
        }

        &.cal-importante {
          background: none;

          &:before {
            border-color: #D0021B;
          }

          &:after {
            background: #D0021B;
          }
        }
      }
    }
  }

  .btn {
    width: 100%;
    @include rem(margin, 0 0 15px);

    &:last-child {
      margin-bottom: 0;
    }

    @include desktop {
      width: auto;
      @include rem(margin, 0 20px);
    }
  }

  .next-button,
  .prev-button {
    position: absolute;
    background: 0 0;
    border: 0;
    border-left: 3px solid #000;
    border-top: 3px solid #000;
    right: -20px;
    top: 50%;
    font-size: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    transform: rotate(-225deg);

    @media(max-width:1150px) {
      right:15px;
    }

    @media (max-width:700px) {
      width: 15px;
      height: 15px;
      right: 15px;
      top: 60%;
    }

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: -20px;
      width: 60px;
      height: 60px;
    }
  }

  .prev-button {
    right: auto;
    left: -20px;
    transform: rotate(315deg);

    @media(max-width:1150px) {
      left:15px;
    }

    // @media (max-width:700px) {
    //   left: 5px;
    // } 

  }
}
