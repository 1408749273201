/* Views */
.anchor-content .full-width {
  z-index: 11;
  pointer-events: all;
}

.lo {
  display: none;
  vertical-align: middle;
  @include rem(margin, 20px 20px);

  @include desktop {
    @include rem(margin, 0 20px);
  }
}

$size: 20px;
$ring-color: #ba9e66;
$bg-color: rgba(#333, 0.3);

.lo-circular {
  animation: lo-circ-rotate 1s infinite linear;
  box-sizing: border-box;
  background: transparent;
  border: $size/8 solid #f1efea;
  border-color: $ring-color $bg-color $bg-color;
  border-radius: $size/2;
  height: $size;
  width: $size;
}

@keyframes lo-circ-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.view {
  background: #fff;

  &.dark {
    color: #fff;
    background: #000 !important;

    &>.container {
      @include desktop {
        @include rem(padding, 70px 0 60px 0);
      }
    }

    .view-footer {
      .btn {
        &:before {
          border: 1px solid rgba(#fff, 0.3) !important;
        }

        &:focus {
          outline-color: #fff;
        }
      }
    }

    a:focus {
      outline-color: #fff;
    }
  }

  &.brown {
    background: #f1efea;
  }

  &>.container {
    @include rem(padding, 40px 30px);

    @include desktop {
      @include rem(padding, 60px 0 60px 0);
    }

    &>.view-content {
      &>[class^="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .view-header {
    @include rem(padding, 0 0 40px);

    * {
      margin: 0;
    }

    h4 {
      text-align: center;
    }

    h3 {
      font-family: $heading-font-family;
      @include font-size-adapt(20px, 24px);
      text-transform: lowercase;
      text-align: center !important;
    }
  }

  @media (max-width:700px) {
    &.view-billets-tarifs {
      .view-filters {
        .selectric-form-select {
          display: none !important;
        }

        .bef-select-as-links {
          .form-type-bef-link {
            display: block !important;
          }
        }
      }

      .view-empty {
        p {
          text-align: center;
          margin: 30px 0 0;
          padding: 10px 0;
          font-size: 14px;
          background: #f1efea;
        }
      }
    }
  }

  .view-filters {
    @include rem(margin-bottom, 40px);

    .views-exposed-widget {
      float: none;
      padding: 0;
      display: block;

      @include desktop {
        @include rem(margin-right, 20px);
        display: inline-block;
        vertical-align: middle;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .views-submit-button {
      display: none;
    }

    .selectric-wrapper {
      display: block !important;
    }

    .bef-select-as-links {
      .form-type-bef-link {
        display: none !important;

        @include desktop {
          display: inline-block !important;
        }
      }

      .selectric-wrapper {
        @include desktop {
          display: none !important;
        }

        .selectric {
          width: 100%;
        }
      }
    }
  }

  .view-content {
    &>h3 {
      @include font-size-adapt(18px, 24px);
      text-align: center;
    }
  }

  .row {
    [class^="col-"] {
      @include rem(margin-bottom, 40px);

      @include tablet {
        margin: 0;
      }

      &:last-child {
        @include desktop {
          margin: 0;
        }
      }

      .outer {
        height: 100%;

        figure {
          height: 100%;
        }
      }
    }
  }

  .view-footer {
    @include rem(margin-top, 40px);
    text-align: center;

    @include desktop {
      @include rem(margin-top, 60px);
    }

    .btn {
      width: 100%;

      @include tablet {
        width: auto;
      }
      @media (max-width:900px) {
        font-size: 14px;
      }
    }
  }
}

.view-display-id-block_actus {
  border-bottom: 1px solid #E6E6E6;
}

.view-display-id-block_events {
  @media (max-width:700px) {
    .container {
      padding: 20px;

      .col-lg-3 {
        margin-bottom: 30px !important;

        .outer {
          padding-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  .view-footer {
    margin-top: rem(50px);

    a {
      @include tablet {
        @include rem(margin, 0 20px);
      }

      &:focus {
        outline-color: #000;
      }
    }
  }

}

.view-partenaires-medias {
  .column {
    position: relative;
    margin-bottom: rem(30px);
    border: 1px solid #D8D8D8;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .views-field {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 80%;
      max-height: 80%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.view-ressource {
  .views-exposed-widgets {
    margin-bottom: rem(25px);
  }

  .views-exposed-widget {

    .description,
    label {
      display: none;
    }
  }

  .view-content {
    .column {
      padding: rem(25px) 0;
      border-bottom: 1px solid #F1EFEA;
    }
  }
}

.view-references {
  &.view-display-id-double {
    .ui-header {
      text-align: center;
    }
  }
}

.video-container {
  @include rem(margin-bottom, 20px);

  .videocontent {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    margin-bottom: rem(10px);

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .vjs-fullscreen {
    padding-top: 0;
  }
}


.view-id-ressources_pedagogiques.view-display-id-related_ref {
  background: white;
  z-index: 30;
  position: relative;
}


.view-display-id-node.ui-showcase.view.dark .collections-wrapper .filters {
  display: none !important;
}

.view-display-id-block_autres_lieux.view.dark{
  figcaption a:focus {
    outline-color: #000;
}
}