/*------------------------------------*\
    TAGS.CSS
\*------------------------------------*/

.ui-tags{
    
    margin-left: 0;

    > li{       
        margin: 0;
        @include rem(margin-right, 1/3);
        display: inline-block;
        color: #fff;
        background: $color-primary;
        border-radius: $base-radius;
        padding: 0.2em 1em;
        padding-right: 35px;
        position: relative;
        a {
            color: #fff;
        }
    }


    /* Remove tag */

    .tag__remove:after{
        position: absolute;
        content: "\f00d";
        font-family: FontAwesome;
        right: 10px;
        top: 3px;
        color: #fff;        
    }


    /* Remove hover*/

    .tag__remove:hover:after{
        color: $base-color;
    }
}


/**
 * Soft tags
 */

.ui-tags--soft{

    > li{
        border-radius: 200px;
    }
}


/**
 * Pointer tags
 */

.ui-tags--pointer{
    
    > li{
        
        margin-left: 15px;
        padding-left: 0.5em;

        &:after, &:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(31, 37, 61, 0);
            border-right-color: $color-primary;
            border-width: 14px;
            margin-top: -14px;
        }
        
        &:before {
            border-color: rgba(31, 37, 61, 0);
            border-right-color: $color-primary;
            border-width: 13px;
            margin-top: -13px;
        }
    }
}

.tag {
    margin-bottom: em(5);
    color: $color-primary;
    @include font-size(15px);
    text-transform: uppercase;

}