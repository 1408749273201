/*------------------------------------*\
    ACCORDION.CSS
\*------------------------------------*/

%accordion-arrow{
    content: "\f107";
    font-family: FontAwesome;
    float: right;
    font-size: 18px;
}

%accordion-arrow-active{
    content: "\f106";
}

.ui-accordion{
    list-style: none;
    margin-left: 0;
    @include rem(margin-bottom);
    -webkit-tap-highlight-color: transparent;
}

/* Header */

.accordion__header{
    position: relative;
    margin: 0;
    @include rem(padding, 20px 60px 20px 20px);
    font-family: $base-font-family;
    @include font-size-adapt(14px, 18px);
    @include line-height-adapt(20px, 18px);
    cursor: pointer;
    @include user-select(none);
    border: 1px solid #E6E6E6;
    text-transform: none;
    z-index: 2;
    transition: all 0.3s;
    @include desktop {
      @include rem(padding, 30px 80px 30px 40px);
    }
    &:after {
      position: absolute;
      top: 50%;
      @include rem(right, 40px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      border-color: #E6E6E6 transparent transparent transparent;
      transform: translateY(-50%);
      transform-origin: 50% 0;
    }
    &:focus, &:hover {
      color: #fff;
      background: #000;
    }
    &.active {
      border-bottom: 0;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        @include rem((
          left: 20px,
          right: 20px,
          height: 1px
        ));
        background: #E6E6E6;
        @include desktop {
          @include rem((
            left: 40px,
            right: 40px,
          ));
        }
      }
      &:after {
        transform: rotate(180deg) translateY(-50%);
      }
    }
}

/* Content */

.accordion__content{
    position: relative;
    @include rem(margin-top, -1px);
    @include rem(padding, 20px);
    display: none;
    background: #fff;
    border: 1px solid #E6E6E6;
    border-top: 0;
    z-index: 1;
    @include desktop {
      @include rem(padding, 30px 40px);
    }
    p, li {
      @include rem(margin, 0 0 20px);
    }
    ul {
      margin: 0;
      @include rem(padding-left, 20px);
    }
    p, li {
      @include font-size-adapt(14px, 17px);
      @include rem(line-height, 20px);
    }
}


/**
 * Bordered Accordion
 */

.ui-accordion--bordered{

    border: 1px $base-ui-color solid;
    border-bottom-width: 0;

    .accordion__header, .accordion__content{
        padding-left: 1em;
    }

    /* Content */

    .accordion__content{
        border-bottom: 1px $base-ui-color solid;
    }



    /* First child */

    .accordion__header:first-child{
        border-top-style: none;
    }


}
