/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    #header, #page-footer, .page-nav, .links, .anchor-map,  .share-print-size, .ui-breadcrumbs, .tabs {
      display: none !important;
    }

    #main {
      margin: 0 !important;
      padding: 0 !important;
    }

    header.ui-header {
      canvas {
        display: block !important;
      }
      &:before {
        display: none !important;
      }
      .btn {
        display: none !important;
      }
    }

    .ui-header {
      canvas {
        display: block !important;
        position:absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .view-footer {
      display: none !important;
    }

    .ui-intro {
      margin: 0 !important;
      &:before, &:after {
        display: none !important
      }
    }

    .ui-history .history-content .chapter {
      height: auto !important;
      margin: 0 !important;
      &:before {
        display: none !important;
      }
      .read-more {
        display: none !important;
      }
    }

    .animated, .ui-grid .item, .ui-grid .sizer {
      opacity: 1 !important;
    }
    .ui-grid .item, .ui-grid .sizer {
      float: none !important;
      position: static !important;
      display: inline-block;
      top: auto !important;
      left: auto !important;
    }

    .ui-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto !important;
      .sizer {
        display: none !important;
      }
    }

    .view.view-agenda {
      display: none;
    }

    #agenda-box {
      position: relative !important;
    }

    .view-push-image, .view-collections, .view-widget-cta, .view-domaine, .view-boutique {
      display: none !important;
    }

    // a,
    // a:visited {
    //     text-decoration: underline;
    // }
    //
    // a[href]:after {
    //     content: " (" attr(href) ")";
    // }
    //
    // abbr[title]:after {
    //     content: " (" attr(title) ")";
    // }

    /*
     * Don't show links for images, or javascript/internal links
     */

    // .ir a:after,
    // a[href^="javascript:"]:after,
    // a[href^="#"]:after {
    //     content: "";
    // }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
