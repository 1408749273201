.node-type-long-read-chapelle {
  .ui-intro {
    @include rem((
      margin: 0 -30px,
      padding: 70px 30px
    ));
    @include desktop {
      @include rem(margin-top, -330px);
      @include rem(padding-top, 120px);
    }
    .text--lead {
      @include desktop {
        @include rem(margin-bottom, 90px);
      }
    }
  }
  .text-content {
    .view-widget-cta:last-child {
      @include rem(margin-bottom, -70px);
      .full-width {
        margin-bottom: 0 !important;
      }
    }
  }
  .longreadProduct {
    z-index: 5;
  }
  .longreadProduct .longreadProduct__title,
  .longreadArt--topoffset
   {
    transform: none;
    text-transform: none;
  }
  .longreadArt--topoffset .longreadArt__picture {
    @include tablet {
      @include rem(margin-top, -160px);
    }
  }
  .longreadArt--topoffset {
    margin-top: 0;
    @include tablet {
      @include rem(margin-top, 190px);
    }
  }
  .longreadArt--topoffset .container {
    opacity: 1;
  }
  .voyage-video {
    margin: 0;
  }
  .view-display-id-moy2 {
    .ui-header {
      @include rem(min-height, 240px);
      h3 {
        @include rem(margin-bottom, 40px);
        @include font-size-rem-adapt(24px);
      }
      .btn {
        margin: 0;
      }
    }
  }
  .voyage-video {
    a:before {
      background: rgba(#000, 0.5);
    }
  }
  .view-widget-svg {
    svg {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 340px;
        height: 194px;
      }
    }
  }
}