/*------------------------------------*\
		PLACES.CSS
\*------------------------------------*/


.ui-places.dark {
  @include rem(padding, 40px 20px);

  .item {
    transition: background 0.2s ease;

    &:focus-within,
    &:focus,
    &:hover {
      .outer {
        background: #ba9e66;
      }

      & * {
        color: #fff !important;
      }
    }

    &:focus-within {
      outline: 2px solid #fff;
      outline-offset: 3px;
    }

    figure {
      background: transparent;
    }
  }
}

.ui-places {
  @include rem(padding, 50px 0);

  h2 {
    color: #fff;
  }

  .item {
    display: flex;
    @include rem(margin-bottom, 40px);

    @media (max-width:700px) {
      @include rem(margin-bottom, 20px);
    }

    .outer {
      display: flex;
      background: #fff;
      transition: background 0.2s ease;

      @media (max-width:700px) {
        width: 100%;
      }
    }

    .inner {
      @include rem(padding, 0 20px);
      overflow: hidden;
    }

    figure {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      width: 100%;

      &:focus,
      &:hover {
        &>a:before {
          opacity: 1;
        }
      }

      &>a {
        display: block;
        position: relative;
        flex: 0 0 auto;
        width: 100%;

        img {
          margin: auto;
          width: 100%;
          max-width: 100%;
          max-height: 100%
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .6);
          opacity: 0;
          transition: all .3s;
          z-index: 2;
        }
      }

      figcaption {
        position: relative;
        width: 100%;
        height: auto;
        padding: rem(20px) rem(40px);
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
          width: 100%;
          margin: 0;
          @include font-size-adapt(18px, 22px);
          transition: color 0.2s ease;

          a {
            color: inherit;

            &:focus,
            &:hover {
              color: inherit;
            }
          }
        }

        p {
          width: 100%;
          margin: 0;
          margin-top: rem(10px);
          color: #D0021B;
          line-height: 1.2;
          transition: color 0.2s ease;
        }
      }
    }
  }

  &.view-display-id-block_lieux_stars {
    .views-row {
      margin-bottom: rem(80px);
      padding-bottom: rem(80px);
      border-bottom: 1px solid #E6E6E6;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    h4.kilo {
      margin: rem(50px) 0 0;
      line-height: 1.2;
    }

    h5 {
      line-height: rem(25px);
    }

    .view-display-id-block_6_lieux {
      padding-bottom: rem(20px);
    }
  }
}
