@keyframes backgroundAnim {
  from { background: rgba(#000, 0.5); }
  to { background: rgba(#000, 0.2); }
}
@keyframes textAnim {
  from {
    opacity: 0;
    transform: translateY(100%);
   }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.node-chapelle-metier {  
  .ui-header.big-header {
    background-attachment: fixed;
    background-size: auto 105%;
    background-repeat: no-repeat;
    transition: background-size 1s ease-in-out;
    justify-content: center;
    &:before {
      @include tablet {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 2;
        background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 81%);
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1;
      background: rgba(#000, 0.5);
    }
    @include tablet {
      @include rem(padding-top, 210px);
      justify-content: flex-start;
      background-size: auto 105%;
    }
    .text--left {
      position: relative;
      margin: 0 auto;
      text-align: center !important;
      width: 100%;
      z-index: 3;
      @include tablet {
        position: fixed;
        margin-bottom: 0;
        text-align: left !important;
      }
      .container {
        margin: 0 auto !important;
      }
      h1 {
        @include rem(max-width, 630px);
      }
    }
  }
  .ui-intro {
    @include rem(padding-top, 20px);
    padding-bottom: 0;
    @include tablet {
      @include rem(margin-top, -335px);
      @include rem(padding-top, 100px);
    }
    h3.h3 {
      @include rem(margin, 50px 0 20px);
      @include font-size-adapt(24px);
      @include line-height-adapt(30px);
      @include tablet {
        margin-top: 0;
      }
    }
    .col-padded {
      text-align: center;
      @include tablet {
        text-align: left;
        @include rem(padding, 0 55px);
      }
    }
    .col-left {
      border-bottom: 1px solid #979797;
      @include rem((
        margin-bottom: 30px,
        padding-bottom: 30px
      ));
      @include tablet {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
        border-right: 1px solid #979797;
      }
    }
    .text--lead {
      margin: 0 !important;
    }
    .col-right {
      p {
        color: rgba(#4A4A4A, 0.6);
        margin: 0;
        @include rem(margin-bottom, 20px);
        font-size: 1rem;
        line-height: 1.4rem;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: inline-block;
          text-decoration: underline;
        }
      }
    }
    ul {
      margin: 0;
      @include rem(padding, 0 0 0 20px);
      li {
        @include rem(margin-bottom, 20px);
        font-family: $font-crimson;
        @include font-size-rem-adapt(20px);
        @include line-height-adapt(23px);
      }
    }
  }
  .breadcrumbs {
    border: none;
    @include rem(padding, 50px 0 80px);
    .ui-breadcrumbs {
      margin: 0;
    }
  }
  .share-print-size {
    position: absolute;
    @include rem((
      top: 177px,
      right: 45px
    ));
    li {
      display: block;
      margin: 0 0 -1px;
      float: none;
    }
  }
  .mt-4 {
    @include rem(margin-top, 130px);
  }
  .quote-wrapper {
    @include rem(margin, 20px auto);
    @include rem(padding, 25px 30px);
    border: 1px solid #E6E6E6;
    @include tablet {
      @include rem(margin, 60px auto);
      @include rem(padding, 60px 95px);
    }
    p.quote-text {
      margin: 0;
      font-family: $font-crimson;
      @include font-size-rem-adapt(21px);
      @include line-height-adapt(30px);
    }
    p.quote-author {
      @include rem(margin, 40px 0 0 0);
      color: $color-primary;
      @include font-size-rem-adapt(19px, 16px);
      text-transform: uppercase;
      @include tablet {
        display: block;
      }
    }
  }
  .view-widget-cta {
    margin-bottom: 0 !important;
    .full-width {
      margin-bottom: 0 !important;
    }
  }
}