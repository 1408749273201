.node-chapelle-metiers-liste {
  color: #fff;
  background: #000;
  .article-header {
    margin: 0 auto;
    @include rem(max-width, 720px);
    @include rem(padding, 70px 0 0);
    min-height: 0;
    text-align: center;
    h1 {
      @include rem(margin-bottom, 10px);
      @include tablet {
        @include rem(margin-bottom, 20px);
      }
    }
    p {
      margin: 0;
      font-family: $font-crimson;
      color: $color-primary;
      @include font-size-rem-adapt(20px, 24px);
      @include line-height-adapt(20px, 34px);
    }
  }
  .view.view-chapelle {
    background: transparent;
    .container {
      @include rem(padding, 60px 0 20px);
      @include tablet {
        @include rem(padding, 100px 0 40px);
      }
    }
    .view-content {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      .job {
          display: flex;
          @include rem(margin-bottom, 30px);
          @include rem(padding, 0 100px);
          width: 100%;
          max-width: 100%;
          color: #fff;
          text-align: center;
          align-items: center!important;
          @include tablet {
            @include rem(margin-bottom, 80px);
            display: block;
            flex-basis: 0;
            flex-grow: 1;
            @include rem(padding, 0 40px);
          }
          @include desktop {
            flex: 1 auto;
            max-width: 33.333333%;
          }
          .image {
            position: relative;
            display: block;
            margin: 0 auto;
            @include rem((
              height: 55px,
              width: 55px
            ));
            border: 1px solid #fff;
            border-radius: 50%;
            overflow: hidden;
            @include tablet {
              @include rem((
                height: 170px,
                width: 170px
              ));
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              min-width: 100%;
              min-height: 100%;
              transition: all 0.4s ease;
              transform: translate(-50%, -50%) scale(1);
              &:focus, &:hover {
                transform: translate(-50%, -50%) scale(1.1);
              }
            }
          }
          p {
            @include rem(margin, 0 0 0 15px);
            color: #fff;
            @include tablet {
              @include rem(margin, 30px 10px 0);
            }
            a {
              color: #fff;
            }
          }
        }
      }
  }
}