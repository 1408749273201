@keyframes backgroundAnim {
  from {
    background: rgba(#000, 0.5);
  }

  to {
    background: rgba(#000, 0.2);
  }
}

@keyframes textAnim {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.node-personnage {
  .container{
    @media (max-width:700px){
      padding:0;
    }
  }
  .ui-header.big-header {
    justify-content: flex-end;
    background-attachment: fixed;
    background-size: auto 105%;
    background-repeat: no-repeat;
    transition: background-size 1s ease-in-out;

    @media (max-width:700px) {
      background-image: none;
      padding-left:20px;
      padding-right:20px;
    }

    &.loaded {
      &:after {
        animation-name: backgroundAnim;
        animation-duration: 2s;
        animation-fill-mode: forwards;
      }

      .text--left {
        animation-name: textAnim;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
      }
    }

    &.scrolled {
      background-size: auto 100%;

      .text--left h1 {
        opacity: 0;
      }
    }

    &:before {
      @include tablet {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 2;
        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.00) 0%, #000000 81%);
      }
    }

    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1;
      background: rgba(#000, 0.5);
    }

    @include tablet {
      @include rem(padding-top, 210px);
      justify-content: flex-start;
      background-size: auto 105%;
    }

    .text--left {
      position: relative;
      margin: 0 auto;
      text-align: center !important;
      width: 100%;
      z-index: 3;
      opacity: 1;

      @include tablet {
        position: fixed;
        margin-bottom: 0;
        text-align: left !important;
      }

      @media (max-width:700px) {
        text-align: left !important;
      }

      .container {
        margin: 0 auto !important;
      }

      h1 {
        @include rem(max-width, 630px);
        transition: opacity 0.5s ease-in-out;
      }
    }
  }

  .ui-intro {
    @include tablet {
      @include rem(margin-top, -335px);
    }

    h3.h3 {
      @include rem(margin, 50px 0 20px);
      @include font-size-adapt(24px);
      @include line-height-adapt(30px);

      @include tablet {
        margin-top: 0;
      }
    }

    .col-padded {
      text-align: center;

      @include tablet {
        text-align: left;
        @include rem(padding, 0 55px);
      }
    }

    .col-left {
      border-bottom: 1px solid #979797;
      @include rem((margin-bottom: 30px,
          padding-bottom: 30px));

      @include tablet {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
        border-right: 1px solid #979797;
      }
    }

    .text--lead {
      margin: 0 !important;
    }

    .col-right {
      p {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1rem;
        line-height: 1.4rem;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }

  .breadcrumbs {
    border: none;
    @include rem(padding, 50px 0 80px);

    .ui-breadcrumbs {
      margin: 0;
    }
  }

  .share-print-size {
    position: absolute;
    @include rem((top: 177px,
        right: 45px));

    li {
      display: block;
      margin: 0 0 -1px;
      float: none;
    }
  }

  .mt-4 {
    @include rem(margin-top, 130px);

    @media (max-width:900px) {
      @include rem(margin-top, 30px);
    }
  }

  &.type-statue {
    .ui-header {
      &.big-header {
        justify-content: center;

        @include tablet {
          justify-content: flex-start;
        }
      }

      .adopted {
        display: block;
        @include rem(margin, 20px auto 0);

        @include tablet {
          margin: 0;
        }
      }
    }

    .breadcrumbs {
      display: none;
    }

    .ui-intro {
      @include rem(padding-top, 20px);

      @include tablet {
        @include rem(padding-top, 100px);
      }
    }

    .quote-wrapper {
      @include rem(margin, 20px auto);
      @include rem(padding, 25px 30px);
      border: 1px solid #E6E6E6;

      @include tablet {
        @include rem(margin, 60px auto);
        @include rem(padding, 60px 95px);
      }

      p.quote-text {
        margin: 0;
        font-family: $font-crimson;
        @include font-size-rem-adapt(21px);
        @include line-height-adapt(30px);
      }

      p.quote-author {
        @include rem(margin, 40px 0 0 0);
        color: $brown;
        @include font-size-rem-adapt(19px, 16px);
        text-transform: uppercase;

        @include tablet {
          display: block;
        }
      }
    }

    .theme-people figure {
      background: transparent;

      figcaption h5 {
        color: #fff;
      }
    }
  }

  // fix for mobile image main cover
  .ui-header.big-header .mobile {
    display: none;
  }

  @media (max-width:700px) {
    .ui-header.big-header {
      background-image: none;
      padding:0;

      .mobile {
        display: block;

        img {
          width: 100%;
        }
      }

      .text--left {
        position: absolute;
        bottom: 40px;
        left: 20px;   
      }
    }
  }
}
