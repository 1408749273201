.view.view-display-id-block_full {
  padding: 0;

  .ui-header {
    @include rem(padding, 130px 30px);
    text-align: center;
    height: 50vh;

    @include desktop {
      @include rem(padding, 90px 30px);
    }

    @media (min-width:700px) {
      .container a.btn.btn--secondary {
        &:before {
          border-color: #fff !important;
        }

        &:after {
          background-color: #ba9e66;
        }

        &:focus,
        &:hover {
          color: #fff !important;
        }
      }
    }

    @media (max-width:700px) {
      .container a.btn.btn--secondary {
        color: #000 !important;
        font-size: 14px;
        
        &:before {
          border-color: #ba9e66 !important;
          background-color: #ba9e66;
        }

        &:after {
          background-color: #000;
          border: 1px solid #fff;
        }

        &:focus,
        &:hover {
          color: #fff !important;

          .ext:after {
            color: #fff;
          }
        }
      }
    }

    &:focus,
    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(#000, 0.6);
      transition: all 0.3s;
      z-index: 1;
      opacity: 0.5;
    }

    .container {
      position: relative;
      padding: 0;
      z-index: 2;
      @media(max-width:1180px) {
        margin:0 100px;
        max-width:calc(100vw - 200px) !important;
      }
      @media(max-width:900px) {
        margin:0 20px;
        max-width:calc(100vw - 40px) !important;
      }
    } 

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }

    p.small {
      @include rem(margin, 0 0 12px);
      font-family: $base-font-family;
      color: $color-primary;
      @include font-size-adapt(14px, 20px);
      text-transform: uppercase;

      @include desktop {
        @include rem(margin, 0 0 15px);
      }
    }

    h2,
    h3 {
      @include rem(max-width, 730px);
      @include rem(margin, 0 auto 30px);
      @include font-size-adapt(22px, 42px);
      line-height: 1.2;

      @include tablet {
        @include rem(margin-bottom, 25px);
        line-height: 1;
      }
    }

    p {
      margin-left: auto;
      margin-right: auto;
      @include rem(max-width, 580px);
    }
  }
}

.view.view-id-push_image {
  @media (max-width:700px) {

    .views-row {
      .ui-header {
        padding: 0;

        &:before {
          display: none;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: calc(100% - 0px);
          width: 100vw;
          background: linear-gradient(180deg, #ffffff00 0, #00000025 25%, #000000e6 50%, #000000 75%, #000000 100%);
        }

        h2 {
          margin-bottom: 20px;
          text-align: left;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          text-align: left;
        }

        .container {
          margin-top: auto;

          .btn-group>.btn:first-child:not(:last-child) {
            span.ext {
              &:after {
                content: '→';
                position: relative;
                top: 3px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: .5s;
              }
            }

          }

          .btn-group>.btn:last-child:not(:first-child) {
            padding: 0;
            color: #000;
            font-size: 16px;
            line-height: 16px;
            text-decoration: underline #ba9e66 !important;
            text-underline-offset: 7px;
            text-align: left;
            display: block;
            min-height: unset;
            overflow: visible;
            color: #fff !important;

            &:before,
            &:after {
              display: none;
            }

            span.ext {
              color: #fff !important;

              &:after {
                content: '→';
                position: relative;
                top: 3px;
                background: 0 0;
                display: inline-block;
                overflow-y: hidden;
                text-decoration: none;
                transform: unset !important;
                max-width: 20px;
                left: 7px;
                transition: .5s;
              }
            }
          }
        }
      }
    }
  }

  &>.container {
    @include desktop {
      @include rem(padding, 70px 0 35px);
    }

    ;
  }

  .outer {
    position: relative;
    cursor: pointer;
    background: #f1efea;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .inner {
      @media (max-width:700px) {
        @include rem(padding, 20px 20px 40px);
      }

      @include media-query(tablet) {
        @include rem(padding, 30px 30px 90px);
      }

      @include desktop {
        @include rem(padding, 30px 30px 90px);
      }

      ;

      h4 {
        @include font-size(20px);
        line-height: 26px;
        margin: 0 0 10px 0;

        @include desktop {
          @include font-size(20px);
          margin: 0;
          margin-bottom: 20px;
        }

        ;

        @include tablet {
          @include font-size(20px);
          margin: 0;
        }
      }

      p {
        display: none;

        @include desktop {
          display: block;
        }

        ;
        @include rem(margin, 0 0 20px);
        color: #666;
        font-family: $font-crimson;
        @include font-size(20px);
        line-height: 1.2;
      }

      .btn {
        width: auto;
        position: absolute;
        bottom: 30px;
        right: 20px;

        &:focus {
          outline-color: #000;
        }

        span.ext {

          &:after {
            content: '→';
            position: relative;
            top: 4px;
            background: 0 0;
            display: inline-block;
            overflow-y: hidden;
            text-decoration: none;
            transform: unset !important;
            max-width: 20px;
            left: 7px;
            transition: .5s;
          }
        }

        @media (max-width:700px) {
          padding: 0;
          min-height: 0;
          font-size: 14px;
          line-height: 24px;
          position: absolute;
          -webkit-text-decoration: underline #ba9e66 !important;
          text-decoration: underline #ba9e66 !important;
          text-underline-offset: 6px;
          left: 0;
          right: auto;
          bottom: 15px;
          text-align: inherit;
          margin-left: 20px;
          display: flex;
          width: 100%;

          &:before {
            content: none;
          }

          &:after {
            content: '→';
            position: relative;
            top: 0;
            background: 0 0;
            display: inline-block;
            overflow-y: hidden;
            text-decoration: none;
            transform: unset !important;
            max-width: 20px;
            max-height: 14px;
            left: 5px;
            transition: .5s;
          }

          &:focus,
          &:hover {
            color: #ba9e66 !important;

            &:after {
              left: 10px;
            }
          }

          .ext {
            display: none;
          }
        }

        @include tablet {
          right: auto;
          @include rem(left, 30px);
        }
      }
    }
  }
}


// MAIN SECTIONS (HEADER SELECT)
.section-abonnes,
.section-subscribers,
.section-soutenir-versailles,
.section-support-versailles,
// .section-preparer-ma-visite,
// .section-plan-your-visit,
.section-enseignants,
.section-teachers,
.section-professionnels-tourisme,
.section-tourism-professionals,
.section-associations-ce,
.section-associations,
.section-relais-culturels,
.section-groupe-handicap,
.section-groupe-publics-eloignes,
.section-presse,
.section-press {

  // CODE FOR WHITE-BG PUSH-IMAGE WIDGET (DESK & MOBILE)
  .view-push-image {

    .outer {
      background-color: transparent;
      text-align: center;

      .inner {

        .btn {
          left: auto;
          right: auto;
          position: relative;
          bottom: 0;

          &:before {
            background: 0 0;
          }
        }
      }

      @media (max-widtH:700px) {
        .inner {
          padding-bottom: 0 !important;
          text-align: left;
          padding-left: 0 !important;

          h4 {
            margin-bottom: 10px;
          }

          .btn {
            width: 100%;
            display: inline-block;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            color: #333;
            margin-left: 0;
          }
        }
      }
    }
  }
}
