.view.view-widget-reference {
  .container {
    padding: 0;
  }
  .view-content {
    & > div {
      @include rem(margin-bottom, 30px);
      &:last-child {
        margin: 0;
      }
    }
  }
  .col-xs-12 + .col-xs-12 {
    position: relative;
    @include rem(padding-bottom, 30px);
    h3 {
      @include rem(margin, 10px 0 5px);
      @include font-size-adapt(24px);
      @include line-height-adapt(26px);
    }
    p {
      @include font-size-adapt(14px);
      @include line-height-adapt(20px);
      color: #666666;
    }
    .link {
      position: absolute;
      bottom: 0;
      @include rem(left, 20px);
    }
  }
}